import React from 'react';
import classes from "./UserForm/User.module.scss";
import {deleteUserThunk} from "../../store/reducers/usersReducer";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import ModalWindow from "../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";

const DeleteUserModal = ({isOpen, user, setIsShowModal, firstName, lastName, gender, goBack}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleCloseClick = () => {
        setIsShowModal(false)
    }

    const handleDeleteUser = () => {
        dispatch(deleteUserThunk(user?.id)).then(() => {
            if (goBack) navigate(-1)
            setIsShowModal(false)
        })
    }

    return (
        <ModalWindow
            isOpen={isOpen}
            title={`Are you sure you want to delete user  ${firstName} ${lastName}?`}
            primaryButton={'Delete user'}
            secondaryButton={'Cancel'}
            onClose={handleCloseClick}
            handleSecondaryClick={handleCloseClick}
            handlePrimaryClick={handleDeleteUser}
        >
            <div className={classes.ModalText}>
                It also removes all {gender === 1 ? 'her' : 'his'} data.
            </div>
        </ModalWindow>
    );
};

export default DeleteUserModal;