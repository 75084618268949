import React, {useState} from 'react';
import classes from './DateTimeRangePicker.module.scss'
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import TimePickerNew from "../TimePicker/TimePickerNew";
import {usePopper} from "react-popper";
import {BUTTON_TYPES, Button} from "../../Button/Buttons";
import {usePopperClickOutside} from "../../../Shared/hooks/usePopperClickOutside";
import {format as dateFormat, getHours, getMinutes, getSeconds, set} from 'date-fns'

const DateTimeRangePicker = (
    {
        placeholder, className,
        minDate, maxDate, format, startDate, endDate,
        onSubmit,
        ...rest
    }) => {

    const [start, setStart] = useState(startDate)
    const [end, setEnd] = useState(endDate)

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const {styles, attributes} = usePopper(referenceElement, popperElement, {});

    const {visible, toggleVisible} = usePopperClickOutside({referenceElement, popperElement})

    const handleDateChange = ([start, end]) => {
        setStart(start)
        setEnd(end)
    }

    const resetButtonClick = () => {
        setStart(null)
        setEnd(null)
        onSubmit([null, null])
        toggleVisible()
    }

    const handleSubmitButtonClick = () => {
        onSubmit([start, end])
        toggleVisible()
    }

    const handleTimeChange = (time, setter) => {
        setter((prev) => {
            if (prev) {
                return set(prev, time
                    ? {
                        hours: getHours(time),
                        minutes: getMinutes(time),
                        seconds: getSeconds(time),
                    }
                    : {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                    })
            }
        })
    }

    const handleStartTimeChange = (time) => {
        handleTimeChange(time, setStart)
    }
    const handleEndTimeChange = (time) => {
        handleTimeChange(time, setEnd)
    }


    return (
        <>
            <input
                className={`${classes.DateTimePickerInput} ${className ?? ''}`}
                ref={setReferenceElement}
                type="text"
                readOnly
                placeholder={placeholder}
                onClick={toggleVisible}
                value={`${startDate ? dateFormat(startDate, format) + ' -' : ''}${endDate ? dateFormat(endDate, format) : ''}`}
            />
            {
                visible &&
                <div className={classes.DateTimePickerContainer} ref={setPopperElement}
                     style={styles.popper}  {...attributes.popper}>
                    <DateRangePicker
                        value={start}
                        isClearable={true}
                        placeholder={placeholder}
                        format={format}
                        onChange={handleDateChange}
                        selected={start}
                        startDate={start}
                        endDate={end}
                        selectsRange={true}
                        minDate={minDate}
                        maxDate={maxDate}
                        inline
                        {...rest}
                    />
                    <div className={classes.TimePickersContainer}>
                        <TimePickerNew
                            className={classes.TimePicker}
                            label={'Start'}
                            value={start}
                            onChange={handleStartTimeChange}

                        />
                        <TimePickerNew
                            className={classes.TimePicker}
                            label={'End'}
                            value={end}
                            onChange={handleEndTimeChange}

                        />
                    </div>
                    <div className={classes.ButtonsContainer}>
                        <Button
                            className={classes.Button}
                            onClick={handleSubmitButtonClick}
                        >
                            Apply
                        </Button>
                        <Button
                            className={classes.Button}
                            variant={BUTTON_TYPES.SECONDARY}
                            onClick={resetButtonClick}
                        >
                            Reset dates
                        </Button>
                    </div>
                </div>
            }
        </>
    );
};

export default DateTimeRangePicker;