import React from 'react';
import classes from "../Poll.module.scss";
import {PlusButton} from "../../../../submodules/naoo-web-components/Components/Button/Buttons";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Option from "../../Option/Option";
import Section from "../../../../Components/FormComponents/Section/Section";

const Answers = ({
                     setOptions,
                     defaultOption,
                     handleDragEnd,
                     getListStyle,
                     options,
                     handleOption,
                     handleDeleteOption
                 }) => {
    const handlePlusButtonClick = () => {
        setOptions(prevState => {
            return [defaultOption, ...prevState]
        })
    }
    return (
        <Section title={'Answers'}>
            <PlusButton
                className={classes.Button}
                onClick={handlePlusButtonClick}
            >
                Add answer
            </PlusButton>
            <div className={classes.OptionsContainer}>
                <div className={classes.Header}>
                    <div className={classes.Answer}>Answer</div>
                    <div className={classes.Tags}>Tags</div>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {
                                    options.map((item, index) => {
                                            return (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <Option
                                                            id={item.id}
                                                            initialValue={item}
                                                            index={index}
                                                            onChange={handleOption}
                                                            onDelete={handleDeleteOption}
                                                            provided={provided}
                                                            snapshot={snapshot}
                                                        />
                                                    )}
                                                </Draggable>
                                            )
                                        }
                                    )
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </Section>
    );
};

export default Answers;
