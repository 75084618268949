import React from 'react';
import classes from "./BusinessCard.module.scss";
import MoreDropdown from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import Slider from "../../../../../../submodules/naoo-web-components/Components/Slider/Slider";
import MoreItem from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {
    hideConfirmationPopup,
    removeDeletedReportFromList,
    setPopupState
} from "../../../../../../store/reducers/reportsReducer";
import {useDispatch} from "react-redux";
import {deleteBusinessThunk} from "../../../../../../store/reducers/businessesReducer";
import {useNavigate} from "react-router-dom";

const BusinessCard = ({business}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleDeleteBusinessClick = () => {
        dispatch(setPopupState({
            isOpen: true,
            title: `Are you sure you want to delete the business ${business?.name}?`,
            primaryButton: 'Yes, delete business',
            handlePrimaryClick: () => {
                dispatch(deleteBusinessThunk(business.id))
                    .then((response) => {
                        if (response.meta.requestStatus === 'fulfilled') {
                            navigate(-1)
                            dispatch(removeDeletedReportFromList())
                            dispatch(hideConfirmationPopup())
                        }
                    })
            }
        }))
    }

    const images = []
    if (business?.logo) images.push(business.logo)
    if (business?.cover) images.push(business.cover)
    if (business?.profileMedia) {
        images.push(...business.profileMedia.map(item=>item.media))
    }

    return (
        <div className={classes.Container}>
            <div className={classes.LogoContainer}>
              <Slider media={images}/>
            </div>
            <div className={classes.InfoContainer}>
                <div className={classes.Name}>
                    <span>{business.name}</span>
                </div>
                <div className={classes.Info}>
                    <span>{business.followersCount} followers</span>
                    <span>{business.followeesCount} following</span>
                </div>
                <div className={classes.More}>
                    <MoreDropdown>
                       <MoreItem text={'Delete business'} danger onClick={handleDeleteBusinessClick}/>
                    </MoreDropdown>
                </div>
            </div>
        </div>
    );
};

export default BusinessCard;