import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { deletePromotionThunk } from '../../store/reducers/promotionsReducer'
import ModalWindow from '../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow'

const DeletePromotionModal = ({ isOpen, message, promotionId, setIsShowModal, goBack }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleDeleteClick = () => {
    dispatch(deletePromotionThunk(promotionId)).then(() => {
      if (goBack) navigate(-1)
      setIsShowModal(false)
    })
  }

  const handleCloseClick = () => {
    setIsShowModal(false)
  }

  return (
    <ModalWindow
      title={`Are you sure you want to delete promotion ${message}?`}
      isOpen={isOpen}
      onClose={handleCloseClick}
      primaryButton={'Delete promotion'}
      handlePrimaryClick={handleDeleteClick}
      secondaryButton={'Cancel'}
      handleSecondaryClick={handleCloseClick}
    />
  )
}

export default DeletePromotionModal