import React, { Fragment, useEffect, useState } from 'react'
import classes from './UserMultiselect.module.scss'
import TabBar from '../../submodules/naoo-web-components/Components/TabBar/TabBar'
import TabBarItem from '../../submodules/naoo-web-components/Components/TabBar/TabBarItem'
import Input from '../../submodules/naoo-web-components/Components/Input/Input'
import { useDispatch, useSelector } from 'react-redux'
import {
  searchUsersByNextToken,
  searchUsersThunk,
  selectSearchFetching,
  selectSearchNextToken,
  selectSearchUsers,
  setSearchFetching,
} from '../../store/reducers/usersReducer'
import { useObserver } from '../../submodules/naoo-web-components/Shared/hooks/useObserver'
import { useDebounce } from '../../submodules/naoo-web-components/Shared/hooks/useDebounce'
import { getUsername, returnObservableItem } from '../../submodules/naoo-web-components/Shared/utility/utils'
import Avatar from '../../submodules/naoo-web-components/Components/Avatar/Avatar'
import { ReactComponent as CloseIcon } from '../../submodules/naoo-web-components/Shared/UI/assets/closeIcon.svg'
import { addMessageThunk } from '../../submodules/naoo-web-components/Shared/reducers/messagesReducer'
import { createMessage, MESSAGES_TYPES } from '../../submodules/naoo-web-components/Components/Message/Message'
import { getUserById } from '../../api/userApi'
import { setSearchParams } from '../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer'
import validator from 'validator'

const UserMultiselect = ({ users, onChangeUsers, targetType, onChangeTargetType = () => {}, contentType }) => {
  const [activeTab, setActiveTab] = useState('All users')
  const [searchString, setSearchString] = useState('')
  const debounceSearchString = useDebounce(searchString, 1000)
  const [searchUsersById, setSearchUsersById] = useState([])
  const [listOptions, setListOptions] = useState(users)

  const dispatch = useDispatch()
  const nextToken = useSelector(selectSearchNextToken)
  const fetching = useSelector(selectSearchFetching)
  const searchItems = useSelector(selectSearchUsers)
  const [element, setElement] = useState(null)

  const LIMIT = 10

  useEffect(() => {
    if (activeTab === 'User data') {
      if (validator.isEmail(debounceSearchString)) {
        dispatch(
          setSearchParams({
            email: debounceSearchString,
            phone: undefined,
            searchString: undefined,
          })
        )
      } else if (validator.isMobilePhone(debounceSearchString)) {
        dispatch(
          setSearchParams({
            phone: debounceSearchString,
            email: undefined,
            searchString: undefined,
          })
        )
      } else {
        dispatch(
          setSearchParams({
            searchString: debounceSearchString,
            phone: undefined,
            email: undefined,
          })
        )
      }

      dispatch(searchUsersThunk())
    } else {
      if (debounceSearchString && activeTab === 'ID') {
        const arrIds = debounceSearchString
          .split(',')
          .map((id) => id.trim())
          .filter((id) => !!id.length)
        Promise.allSettled(arrIds.map((id) => getUserById(id))).then((results) => {
          const users = []
          results.forEach((result, index) => {
            if (result.status === 'fulfilled') {
              users.push(result.value.data)
            } else {
              dispatch(
                addMessageThunk({
                  message: createMessage({
                    type: MESSAGES_TYPES.ERROR,
                    message: `ID ${arrIds[index]} not found. Check ID`,
                  }),
                })
              )
            }
            setSearchUsersById(users)
          })
        })
      }
    }
    // eslint-disable-next-line
  }, [activeTab, debounceSearchString, dispatch])

  useEffect(() => {
    onChangeUsers(listOptions)
  }, [listOptions, onChangeUsers])

  useEffect(() => {
    if (activeTab === 'All users') {
      onChangeTargetType('allUsers')
    } else {
      onChangeTargetType('custom')
    }
  }, [activeTab, onChangeTargetType])

  useEffect(() => {
    if (targetType === 'custom' && activeTab === 'All users') {
      setActiveTab('Username')
    }
    // eslint-disable-next-line
  }, [targetType])

  useObserver(element, fetching, setSearchFetching, nextToken, searchUsersByNextToken)

  const handleSearchStaringChange = (event) => {
    setSearchString(event.target.value)
  }

  return (
    <div className={classes.Container}>
      <TabBar
        initialActiveTab={activeTab}
        className={`${activeTab === 'All users' ? classes.Tabs : classes.TabsWithSearchBar}`}
        navItemClick={(tab) => setActiveTab(tab)}>
        <TabBarItem label={'All users'} disabled={contentType.idName === 'points'} />
        <TabBarItem label={'User data'} />
        <TabBarItem label={'ID'} />
      </TabBar>

      {activeTab !== 'All users' && (
        <div className={classes.SearchBarContainer}>
          <div className={classes.SearchBar}>
            {activeTab === 'User data' && (
              <Input
                placeholder={'Search first name, last name, email, phone'}
                value={searchString}
                onChange={handleSearchStaringChange}
              />
            )}

            {activeTab === 'ID' && (
              <textarea
                className={classes.TextArea}
                placeholder={'Search ID or enter IDs separated by commas'}
                value={searchString}
                onChange={handleSearchStaringChange}
              />
            )}

            {listOptions?.map((user) => (
              <div className={classes.SearchItem} key={user.id}>
                <Avatar
                  className={classes.Logo}
                  name={user?.firstname}
                  lastname={user?.lastname}
                  img={user?.avatar?.url}
                />
                <span className={classes.Name}>{getUsername(user)}</span>
                <span className={classes.Id}>{user.id}</span>

                <div
                  className={classes.Close}
                  onClick={() => {
                    setListOptions((prevState) => prevState.filter((u) => u.id !== user.id))
                  }}>
                  <CloseIcon className={classes.CloseIcon} width={8} height={8} />
                </div>
              </div>
            ))}
          </div>
          {!!searchItems.length && searchString && activeTab === 'User data' && (
            <div className={classes.Options}>
              {searchItems
                ?.filter((item) => {
                  const isSelected = listOptions?.find((value) => {
                    return item?.id === value?.id
                  })
                  return !isSelected
                })
                ?.map((user, index, array) => {
                  return (
                    <Fragment key={user.id}>
                      <div
                        className={classes.Option}
                        onClick={() => {
                          setListOptions((prevState) => [...prevState, user])
                        }}>
                        <Avatar
                          className={classes.Avatar}
                          name={user?.firstname}
                          lastname={user?.lastname}
                          img={user?.avatar?.url}
                        />
                        <div className={classes.Name} title={getUsername(user)}>
                          <span>{getUsername(user)}</span>
                        </div>
                        <div className={classes.Id}>
                          <span>{user.id}</span>
                        </div>
                      </div>
                      {returnObservableItem(array.length, LIMIT, index, setElement)}
                    </Fragment>
                  )
                })}
            </div>
          )}
          {!!searchUsersById.length && searchString && activeTab === 'ID' && (
            <div className={classes.Options}>
              {searchUsersById
                ?.filter((item) => {
                  const isSelected = listOptions?.find((value) => {
                    return item?.id === value?.id
                  })
                  return !isSelected
                })
                ?.map((user) => (
                  <div
                    className={classes.Option}
                    onClick={() => {
                      setSearchString('')
                      setListOptions((prevState) => [...prevState, user])
                    }}>
                    <Avatar
                      className={classes.Avatar}
                      name={user?.firstname}
                      lastname={user?.lastname}
                      img={user?.avatar?.url}
                    />
                    <div className={classes.Name} title={getUsername(user)}>
                      <span>{getUsername(user)}</span>
                    </div>
                    <div className={classes.Id}>
                      <span>{user.id}</span>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default UserMultiselect
