import classes
    from "./ImgAndButtons.module.scss";
import Slider from "../../../../submodules/naoo-web-components/Components/Slider/Slider";
import React from "react";


const ImgAndButtons = ({post, children}) => {
    return (
        <div className={classes.ImgPlusButtons}>
            <div className={classes.Img}>
                <Slider media={post?.media} isShowVideoControls/>
            </div>
            <div className={classes.ButtonsContainer}>
                {children}
            </div>
        </div>
    );
};

export default ImgAndButtons;
