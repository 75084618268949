import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {TRANSACTION_CATEGORIES} from '../../shared/Constants'
import {getPointsTransactions, getPointsTransactionsStats} from "../../api/pointsTransactionApi";
import {searchParamsSelector} from "../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";

const initialState = {
    pointsTransactions: [],
    pointsTransactionsStats: [],
    activeTransaction: null,
    status: null,
    nextToken: '',
    fetching: false,
}

export const fetchPointsTransactions = createAsyncThunk(
    'transactions/fetchPointsTransactions',
    async (payload, {getState}) => {
        const {
            sourceUser,
            targetUser,
            category,
            startDate,
            endDate
        } = searchParamsSelector(getState())

        const response = await getPointsTransactions({
            sourceUserId: sourceUser?.id,
            targetUserId: targetUser?.id,
            category: category?.value,
            startTime: !!startDate ? startDate.toISOString() : null,
            endTime: !!endDate ? endDate.toISOString() : null,
        })
        return response.data
    })

export const fetchPointsTransactionsByNextToken = createAsyncThunk(
    'transactions/fetchPointsTransactionsByNextToken',
    async (payload, {getState}) => {
        const {
            sourceUser,
            targetUser,
            category,
            startDate,
            endDate
        } = searchParamsSelector(getState())
        const nextToken = selectNextToken(getState())
        const response = await getPointsTransactions({
            nextToken,
            sourceUserId: sourceUser?.id,
            targetUserId: targetUser?.id,
            category: category?.value,
            startTime: !!startDate ? startDate.toISOString() : null,
            endTime: !!endDate ? endDate.toISOString() : null,
        })
        return response.data
    }
)

export const fetchPointsTransactionsStats = createAsyncThunk(
    'transactions/fetchPointsTransactionsStats',
    async (payload, {getState}) => {
        const {
            sourceUser,
            targetUser,
            startDate,
            endDate
        } = searchParamsSelector(getState())
        const response = await getPointsTransactionsStats({
            // sourceWalletId: payload?.sourceUser?.wallet?.id,
            // targetWalletId: payload?.targetUser?.wallet?.id,
            sourceUserId: sourceUser?.id,
            targetUserId: targetUser?.id,
            startDate: !!startDate ? startDate.toISOString() : null,
            endDate: !!endDate ? endDate.toISOString() : null,
        })
        return response.data
    }
)

const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setFetching: (state, action) => {
            state.fetching = action.payload
        },
        setActiveTransaction: (state, action) => {
            state.activeTransaction = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPointsTransactions.pending, (state, action) => {
            state.status = 'loading'
        })
        builder.addCase(fetchPointsTransactions.fulfilled, (state, action) => {
            state.pointsTransactions = action.payload.transactions || []
            state.nextToken = action.payload.nextToken
            state.status = 'successfully'
        })
        builder.addCase(fetchPointsTransactions.rejected, (state, action) => {
            state.status = 'error'
        })
        builder.addCase(fetchPointsTransactionsByNextToken.fulfilled, (state, action) => {
            if (action.payload.transactions) {
                state.pointsTransactions.push(...action.payload.transactions)
            }
            state.nextToken = action.payload.nextToken
            state.fetching = false
        })
        builder.addCase(fetchPointsTransactionsStats.fulfilled, (state, action) => {
            state.pointsTransactionsStats = action.payload?.statsByCategory || []
            state.pointsTransactionsStats.forEach((item) => {
                const category = TRANSACTION_CATEGORIES.find((c) => c.id === item.categoryId)
                item.category = category.name
            })
        })
    },
})

export default transactionsSlice.reducer

export const {setFetching, setStatus, setActiveTransaction} = transactionsSlice.actions

export const selectTransactions = (state) => state.transactions.pointsTransactions
export const selectNextToken = (state) => state.transactions.nextToken
export const selectFetching = (state) => state.transactions.fetching
export const selectStatus = (state) => state.transactions.status
export const selectActiveTransaction = (state) => state.transactions.activeTransaction
export const selectPointsTransactionsStats = (state) => state.transactions.pointsTransactionsStats
