import React from 'react';
import Sidebar from "../../submodules/naoo-web-components/Components/Sidebar/Sidebar";
import Logo from '../../shared/icons/logo.svg'
import SidebarItems from "../../submodules/naoo-web-components/Components/Sidebar/UI/SidebarItems";
import SidebarItem from "../../submodules/naoo-web-components/Components/Sidebar/UI/SidebarItem";
import {ROUTES} from "../../submodules/naoo-web-components/Shared/constants";
import classes from './AdminSidebar.module.scss'

const AdminSidebar = ({children}) => {
    return (
        <Sidebar>
            <img className={classes.Logo} src={Logo} alt="" onClick={()=>{}}/>
            {children}
            <SidebarItems>
                <SidebarItem title={'Businesses'} to={ROUTES.ADMIN_DASHBOARD.BUSINESSES}/>
                <SidebarItem title={'Polls'} to={ROUTES.ADMIN_DASHBOARD.POLLS}/>
                <SidebarItem title={'Users'} to={ROUTES.ADMIN_DASHBOARD.USERS}/>
                <SidebarItem title={'Send notification'} to={ROUTES.ADMIN_DASHBOARD.NOTIFICATIONS}/>
                <SidebarItem title={'Cash Redemptions'} to={ROUTES.ADMIN_DASHBOARD.CASH_REDEMPTIONS}/>
                <SidebarItem title={'Points transactions'} to={ROUTES.ADMIN_DASHBOARD.POINTS_TRANSACTIONS}/>
                {/*<SidebarItem title={'Vouchers'} to={'/vouchers'}/>*/}
                <SidebarItem title={'Competitions'} to={ROUTES.ADMIN_DASHBOARD.COMPETITIONS}/>
                <SidebarItem title={'Posts'} to={ROUTES.ADMIN_DASHBOARD.POSTS}/>
                <SidebarItem title={'Promotions'} to={ROUTES.ADMIN_DASHBOARD.PROMOTIONS}/>
                <SidebarItem title={'Tags'} to={ROUTES.ADMIN_DASHBOARD.TAGS}/>
                <SidebarItem title="Chat" to={'/chat'}/>
                <SidebarItem title={'Content reports'} to={ROUTES.ADMIN_DASHBOARD.REPORTS}/>
                <SidebarItem title={'Log out'} to={ROUTES.AUTH.LOGOUT}/>
            </SidebarItems>
        </Sidebar>
    );
};

export default AdminSidebar;
