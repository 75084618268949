import React from 'react';
import DatePickerNew from "../DatePicker/DatePicker";

const DateRangePicker = (
    {
        value, placeholder, onChange,
        minDate, maxDate, format, startDate, endDate,
        ...rest
    }) => {
    return (
        <DatePickerNew
            value={value}
            isClearable={true}
            placeholder={placeholder}
            format={format}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            minDate={minDate}
            maxDate={maxDate}
            {...rest}
        />
    );
};

export default DateRangePicker;