import React, {useEffect, useState} from "react";
import {useOnClickOutside} from "../../../submodules/naoo-web-components/Shared/hooks/useclickOutside";
import {useDebounce} from "../../../shared/hooks/useDebounce";
import {ReactComponent as ClearIcon} from "../../../shared/icons/closeIcon.svg";
import {ReactComponent as DownIcon} from "../../../shared/icons/down.svg";
import classes from './BusinessItem.module.scss'
import {getBusinesses} from "../../../api/businessApi";
const BusinessItem = ({business,label, onItemClick, className, isShowAvatar, isClearable}) => {
    const [searchString, setSearchString] = useState('')
    const [isShow, setIsShow] = useState(false)

    const [searchItems, setSearchItems] = useState([])

    const ref = useOnClickOutside(() => {
        setIsShow(false)
    })

    const debounceSearchString = useDebounce(searchString, 1000)

    useEffect(() => {
        getBusinesses({
            q: debounceSearchString
        })
            .then((response) => {
                setSearchItems(response.data.businesses)
            })
            .catch((error) => {
                console.log(error)
            })

    }, [debounceSearchString])

    return(
        <div className={`${classes.Container} ${className}`} ref={ref}>
            <span className={classes.Label}>{label}</span>
            {
                business
                    ? <div className={'SelectedBusiness'}>
                        <span className={'SelectedBusinessName'}>
                             {business.name}
                          </span>
                        <span className={'SelectedBusinessID'}>
                            {business.id}
                        </span>
                        {
                            isClearable &&
                            <div className={'CloseIcon'} onClick={() => {
                                onItemClick('')
                            }}>
                                <ClearIcon/>
                            </div>
                        }
                    </div>
                    : <div className={'InputContainer'}
                           onClick={() => {
                               setIsShow(true)
                           }}
                    >
                        <input
                            className={`Input ${isShow && 'InputDropdownOpen'}`}
                            placeholder={'Select business'}
                            value={searchString}
                            type="text"
                            onChange={(event) => {
                                setSearchString(event.target.value)
                                if (event.target.value === '') {
                                    setIsShow(false)
                                    // onClear()
                                }
                            }}
                        />
                        <DownIcon className={'Icon'}/>
                    </div>
            }

            {
                isShow &&
                <div className={'ItemsContainer'}>
                    {
                        searchItems.map((business) => {
                            return (
                                <div
                                    key={business.id}
                                    className={'BusinessItem'}
                                    onClick={() => {
                                        onItemClick(business)
                                        setIsShow(false)
                                        setSearchString('')
                                    }}
                                >
                                    <div className={'BusinessName'}>
                                        <span>{business.name}</span>
                                    </div>
                                    <div className={'BusinessID'}>
                                        <span>{business.id}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default BusinessItem