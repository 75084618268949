import React from 'react';
import classes from "../User.module.scss";
import {Field} from "../../../../shared/Form/FormField";
import DatePickerNew from "../../../../submodules/naoo-web-components/Components/Date/DatePicker/DatePicker";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";

const BirthdateField = () => {
    const user = useSelector(selectActiveUser)
    return (
        <div className={classes.BirthDateContainer}>
            <span className={`${classes.Label}`}>Birth date</span>
            <Field name={'birthdate'}
                   initialValue={user?.birthdate ? new Date(user?.birthdate) : new Date()}>
                {
                    (birthDate, setBirthDate) => {
                        const handleBirthDate = (date) => {
                            setBirthDate(date)
                        }
                        return (
                            <DatePickerNew
                                className={classes.DateTimePicker}
                                value={new Date(birthDate)}
                                format={'dd/MM/yyyy'}
                                placeholder={'Birth date'}
                                onChange={handleBirthDate}
                            />
                        )
                    }
                }
            </Field>

        </div>
    );
};

export default BirthdateField;