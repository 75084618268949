import React from 'react';
import classes from './Tag.module.scss'

const Tag = ({className,title, onClick}) => {
    return (
        <span className={`${classes.Tag} ${className}`} onClick={onClick}>
            #{title}
        </span>
    );
};

export default Tag;
