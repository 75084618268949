import React from 'react'
import UserMultiselect from '../../../../Components/UserMultiselect/UserMultiselect'
import Section from '../../../../Components/FormComponents/Section/Section'

const Main = ({ targetType, contentType, setTargetType, users, setUsers, handleUsersDropdown }) => {
  return (
    <Section title={'Target'}>
      <UserMultiselect
        users={users}
        onChangeUsers={setUsers}
        targetType={targetType}
        onChangeTargetType={setTargetType}
        contentType={contentType}
      />
    </Section>
  )
}

export default Main
