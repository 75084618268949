import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {
    generateUrlWithQueryParams
} from "../submodules/naoo-web-components/Api/generateUrlWithQueryParams/generateUrlWithQueryParams";

const getOfferById = (id)=>{
    return createRequest('main', 'get', `/offers/${id}`)
}
const getOffers = ({businessId, nextToken})=>{
    const url = generateUrlWithQueryParams('/offers',{
        nextToken,
        businessId
    })
    return createRequest('main', 'get',url ,null)
}
const updateOffer = (offer, offerId)=>
    createRequest('main', 'patch',`/offers/${offerId}`, offer )
const createOffer = (offer)=>
    createRequest('main','post', '/offers', offer)
const deleteOffer = (offerId)=>
    createRequest('main','delete', `/offers/${offerId}`, null)
const getOfferStats = ({id, startTime, endTime }) => {
    const url = generateUrlWithQueryParams(`/offers/${id}/stats_history`, {
        startTime,
        endTime,
    })
    return createRequest('main', 'get', url , null)
}

export {
    getOfferById,
    getOffers,
    updateOffer,
    createOffer,
    deleteOffer,
    getOfferStats,
}