import React from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {deleteOfferThunk, setActiveOffer} from "../../../../store/reducers/offersReducer";
import {ROUTES} from "../../../../submodules/naoo-web-components/Shared/constants";
import MoreDropdown from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";

const OfferMoreDropdown = ({offerId, prevLocation, businessId, onShowStatisticsClick}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleEditClick = () => {
        dispatch(setActiveOffer(offerId))
        navigate(ROUTES.ADMIN_DASHBOARD.BUSINESSES + `/${businessId}/update/offers/${offerId}/update`, prevLocation)
    }

    const handleDeleteClick = () => {
        dispatch(deleteOfferThunk(offerId))
    }

    const handleShowStatisticsClick = () => {
        onShowStatisticsClick(offerId)
    }

    return (
        <MoreDropdown vertical>
            <MoreItem text={'Edit offer'} onClick={handleEditClick}/>
            <MoreItem text={'Delete offer'} onClick={handleDeleteClick} danger/>
            <MoreItem text={'Show statistics'} onClick={handleShowStatisticsClick}/>
        </MoreDropdown>
    );
};

export default OfferMoreDropdown;