import React from "react";
import CreateOrUpdatePoll from "./CreateOrUpdatePoll/CreateOrUpdatePoll";
import {useSelector} from "react-redux";
import {selectActivePoll} from "../../../store/reducers/poolsReducer";

const UpdatePollContainer = () => {
    const poll = useSelector(state => selectActivePoll(state))
    return (
      <CreateOrUpdatePoll poll={poll}/>
    );
};

export default UpdatePollContainer;
