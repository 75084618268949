import React from 'react';
import classes from "../Notification.module.scss";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {ReactComponent as NaooIcon} from "../../../../shared/icons/naooIcon.svg";
import {contentTypes} from "../NotificationTemplateContainer";
import CompetitionsDropdown from "../CompetitionsDropdown/CompetitionsDropdown";
import Section from "../../../../Components/FormComponents/Section/Section";
import Dropdown from "../../../../submodules/naoo-web-components/Components/Dropdown/Dropdown";
import Margin from "../../../../submodules/naoo-web-components/Components/Margin/Margin";

const Content = (
    {
        contentType, contentTypeLabel, contentValue,
        handleContentChange, handlePointsChange, setContentType,
        handleCompetitionChange
    }) => {
    const WITHOUT_CONTENT = 'Without content'

    //CONTENT
    let content = null
    switch (contentType) {
        case WITHOUT_CONTENT :
            content = null
            break;
        case "naoo-points":
            content = (
                <div className={classes.PointsContainer}>
                    <Input
                        label={'Points amount'}
                        name={'points'}
                        placeholder={'Points amount'}
                        value={contentValue.points}
                        onChange={handlePointsChange}
                    />
                    <NaooIcon/>
                </div>
            )
            break;
        case "offer":
            content = (
                <Input
                    label={'Offer link '}
                    name={'offerId'}
                    placeholder={'naoo://offers/id'}
                    maxLength={512}
                    value={contentValue.offerId}
                    onChange={handleContentChange}
                />)
            break;
        case "competition":
            content = (
                <CompetitionsDropdown
                     label={'Competition'}
                     onItemClick={(competition)=>{
                         handleCompetitionChange(competition)
                     }}
                     isClearable={true}
                     competition={contentValue.competitionId}
                />
            )
            break;
        case "suggestion":
            content = (
                <Input
                    label={'Suggestion link'}
                    name={'suggestionId'}
                    placeholder={'naoo://suggestion/id'}
                    maxLength={512}
                    value={contentValue.suggestionId}
                    onChange={handleContentChange}
                />
            )
            break;
        default:
            content = null
    }
    //CONTENT

    return (
        <Section title={'Add content'}>
            <span className={classes.Label}>Select content type</span>
            <Dropdown
                options={Object.keys(contentTypes)}
                placeholder={'Select content type'}
                value={contentTypeLabel}
                onChange={(type) => {
                    setContentType(contentTypes[type])
                }}
            />
            <Margin top={24}>
                {
                    content
                }
            </Margin>
        </Section>
    );
};

export default Content;

