import React from 'react';
import classes from "./Title.module.scss";

const Title = ({text}) => {
    return (
        <div className={classes.Title}>
            <span>{text}</span>
        </div>
    );
};

export default Title;