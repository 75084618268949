import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {v4} from "uuid";
import {createPollThunk, selectStatus, setStatus} from "../../../../store/reducers/poolsReducer";
import {selectTags} from "../../../../store/reducers/tagsReducer";
import TopNav from "../../../../Components/TopNav/TopNav";
import {FullscreenPreloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import classes from '../Poll.module.scss'
import Question from "./Question";
import ModalWindow from "./ModalWindow";
import Answers from "./Answers";
import {
    createMessage,
    MESSAGES_TYPES
} from "../../../../submodules/naoo-web-components/Components/Message/Message";
import topNavClasses from "../../../../Components/TopNav/TopNav.module.scss";
import {addMessageThunk} from "../../../../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import Container from "../../../../Components/FormComponents/Container/Container";

const CreateOrUpdatePoll = ({poll}) => {
    const tags = useSelector(state => selectTags(state))
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [pollTitle, setPollTitle] = useState(poll?.textEn || '')
    const [pollTags, setPollTags] = useState(poll?.tags || [])
    const [pollTitleDe, setPollTitleDe] = useState(poll?.textDe || '')
    const [answerMinOptions, setAnswerMinOptions] = useState(poll?.answerMinOptions || 1)
    const [answerMaxOptions, setAnswerMaxOptions] = useState(poll?.answerMaxOptions || 1)
    const defaultOption = {text: '', tags: [], id: v4()}
    const [options, setOptions] = useState(poll ? [...poll?.options] : [defaultOption])
    const status = useSelector(state => selectStatus(state))
    const [optionIdsToDelete] = useState(poll?.options.map(it=>it.id))

    useEffect(()=>{
        if (!!poll) {
            dispatch(addMessageThunk({
                message: createMessage({
                    type: MESSAGES_TYPES.WARNING,
                    message: 'WARNING: Editing poll options invalidates existing data.',
                    children: <span className={classes.ChildrenMessage} onClick={() => navigate(-1)}>&nbsp;Undo</span>
                })
            }))
        }
    },[poll, dispatch, navigate])

    const handlePollTagsSelect = useCallback((tags) => {
        setPollTags(tags)
    }, [])

    const handleOption = useCallback((index, option) => {
        setOptions((prev) => {
            prev[index] = option
            return [...prev]
        })
    }, [])

    const handleDeleteOption = ({index}) => {
        setOptions((prev) => {
            return [...prev.filter((item, itemindex) => index !== itemindex)]
        })
    }

    const handleOnSuccessClick = () => {
        const reorderOptions = options.map((option, index) => ({
                text: option.textEn,
                textDe: option.textDe,
                order: index,
                tagIds: option.tags?.map(item=>item.id)
            })
        )

        if (poll) {
            setIsShowModal(true)
            return
        }
        dispatch(createPollThunk({
            text: pollTitle,
            textDe: pollTitleDe,
            tagIds: pollTags.map(item=>item.id),
            options: reorderOptions,
            answerMinOptions: answerMinOptions,
            answerMaxOptions: answerMaxOptions,
        }))
    }

    if (status === 'successfully') {
        navigate(-1)
        dispatch(setStatus(null))
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleDragEnd = (event) => {
        if (!event.destination) {
            return;
        }
        const items = reorder(
            options,
            event.source.index,
            event.destination.index
        );
        setOptions(items)
    }


    const [isShowModal, setIsShowModal] = useState(false)
    const getListStyle = (isDraggingOver, draggableStyle) => ({
        ...draggableStyle
    });


    const topNavButtons = [
        {
            title: 'Delete question',
            onClick:() => {
                poll
                    ? console.log('delete item')
                    : navigate(-1)
            },
            isDisabled: false,
            className: topNavClasses.DangerButton
        },
        {
            title: 'Save',
            onClick:handleOnSuccessClick,
            isDisabled: false,
            className: topNavClasses.SuccessButton
        },
    ]
    return (<>
            {
                status === 'loading' && <FullscreenPreloader/>
            }
                <ModalWindow
                    isOpen={isShowModal}
                    poll={poll}
                    pollTitle={pollTitle}
                    pollTitleDe={pollTitleDe}
                    pollTags={pollTags}
                    options={options}
                    setIsShowModal={setIsShowModal}
                    answerMinOptions={answerMinOptions}
                    answerMaxOptions={answerMaxOptions}
                    optionIdsToDelete={optionIdsToDelete}
                />
            <Container>
                <TopNav
                    buttons={topNavButtons}
                    onBack={() => navigate(-1)}
                />
                <Question
                    tags={tags}
                    pollTags={pollTags}
                    handlePollTagsSelect={handlePollTagsSelect}
                    pollTitle={pollTitle}
                    pollTitleDe={pollTitleDe}
                    setPollTitle={setPollTitle}
                    setPollTitleDe={setPollTitleDe}
                    lengthOptions={options.length}
                    answerMinOptions={answerMinOptions}
                    answerMaxOptions={answerMaxOptions}
                    setAnswerMaxOptions={setAnswerMaxOptions}
                    setAnswerMinOptions={setAnswerMinOptions}
                />
                <Answers
                    options={options}
                    defaultOption={defaultOption}
                    getListStyle={getListStyle}
                    handleDeleteOption={handleDeleteOption}
                    handleDragEnd={handleDragEnd}
                    handleOption={handleOption}
                    setOptions={setOptions}
                />
            </Container>
        </>
    );
};

export default CreateOrUpdatePoll;
