import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  cashRedeemRequestConfirm,
  cashRedeemRequestFulfilled,
  cashRedeemRequestReject,
  createCashRedeemOption,
  getCashRedeemOptions,
  getCashRedeemRequest,
  getCashRedeemRequests,
  updateCashRedeemOption,
} from '../../api/cashRedemptionApi'
import { searchParamsSelector } from '../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer'


export const fetchCashRedeemRequests = createAsyncThunk(
  'cashRedeemRequests/fetchCashRedeemRequests',
  async (payload, { getState }) => {
    const state = getState()
    const { status, userId } = searchParamsSelector(state)
    const response = await getCashRedeemRequests({ status, userId })
    return response.data
  },
)

export const fetchCashRedeemRequestsByNextToken = createAsyncThunk(
  'cashRedeemRequests/fetchCashRedeemRequestsByNextToken',
  async (payload, { getState }) => {
    const state = getState()
    const { status, userId } = searchParamsSelector(state)
    const response = await getCashRedeemRequests({
      nextToken: payload?.nextToken,
      status,
      userId,
    })
    return response.data
  },
)

export const fetchCashRedeemRequestThunk = createAsyncThunk('cashRedeemRequests/fetchCashRedeemRequestsById', async (payload) => {
  const response = await getCashRedeemRequest(payload)
  return response.data
})

export const cashRedeemRequestRejectThunk = createAsyncThunk('cashRedeemRequests/RequestRejectThunk', async (id) => {
  const response = await cashRedeemRequestReject(id)
  return response.data
})

export const cashRedeemRequestFulfilledThunk = createAsyncThunk(
  'cashRedeemRequests/RequestFulfilledThunk',
  async (id) => {
    const response = await cashRedeemRequestFulfilled(id)
    return response.data
  },
)
export const cashRedeemRequestConfirmThunk = createAsyncThunk('cashRedeemRequests/RequestConfirmThunk', async (id) => {
  const response = await cashRedeemRequestConfirm(id)
  return response.data
})

const getIndexById = (state, action) => {
  return state.cashRedeemRequests.findIndex((item) => item.id === action.payload.id)
}

export const fetchCashRedeemOptions = createAsyncThunk('fetchCashRedeemOptions', async () => {
  const response = await getCashRedeemOptions()
  return response.data
})

export const updateCashRedeemOptionsThunk = createAsyncThunk('updateCashRedeemOptionsThunk', async (payload) => {
  const { option, id } = payload
  const response = await updateCashRedeemOption({
    option,
    id,
  })
  return response.data
})

export const createCashRedeemOptionsThunk = createAsyncThunk('createCashRedeemOptionsThunk', async (payload) => {
  const response = await createCashRedeemOption(payload)
  return response.data
})

const initialState = {
  cashRedeemRequests: [],
  options: [],
  activeOption: null,
  activeRequest: null,
  status: null,
  fetching: false,
  nextToken: '',
}

const cashRedeemRequestsSlice = createSlice({
  name: 'cashRedeemRequests',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setActiveOption: (state, action) => {
      state.activeOption = state.options.find((item) => item.id === action.payload)
    },
    setActiveRequest: (state, action) => {
      state.activeRequest = state.cashRedeemRequests.find((item) => item.id === action.payload)
    },
    setFetching: (state, action) => {
      state.fetching = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCashRedeemRequests.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(fetchCashRedeemRequests.fulfilled, (state, action) => {
      state.cashRedeemRequests = action.payload.pointsToCashRedeemRequests
      state.status = null
      state.nextToken = action.payload.nextToken
      state.userId = action.payload.userId
      state.status = action.payload.status
    })
    builder.addCase(fetchCashRedeemRequests.rejected, (state) => {
      state.status = 'error'
    })
    builder.addCase(fetchCashRedeemRequestsByNextToken.fulfilled, (state, action) => {
      state.cashRedeemRequests.push(...action.payload.pointsToCashRedeemRequests)
      state.nextToken = action.payload.nextToken
      state.fetching = false
    })
    builder.addCase(cashRedeemRequestRejectThunk.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(cashRedeemRequestRejectThunk.fulfilled, (state, action) => {
      state.status = null
      state.cashRedeemRequests[getIndexById(state, action)] = action.payload
      if (state.activeRequest) {
        state.activeRequest = action.payload
      }
    })
    builder.addCase(cashRedeemRequestRejectThunk.rejected, (state) => {
      state.status = 'error'
    })
    builder.addCase(cashRedeemRequestFulfilledThunk.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(cashRedeemRequestFulfilledThunk.fulfilled, (state, action) => {
      state.status = null
      state.cashRedeemRequests[getIndexById(state, action)] = action.payload
      if (state.activeRequest) {
        state.activeRequest = action.payload
      }
    })
    builder.addCase(cashRedeemRequestFulfilledThunk.rejected, (state) => {
      state.status = 'error'
    })
    builder.addCase(cashRedeemRequestConfirmThunk.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(cashRedeemRequestConfirmThunk.fulfilled, (state, action) => {
      state.status = null
      state.cashRedeemRequests[getIndexById(state, action)] = action.payload
      if (state.activeRequest) {
        state.activeRequest = action.payload
      }
    })
    builder.addCase(cashRedeemRequestConfirmThunk.rejected, (state) => {
      state.status = 'error'
    })
    builder.addCase(fetchCashRedeemOptions.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(fetchCashRedeemOptions.fulfilled, (state, action) => {
      state.status = null
      state.options = action.payload
    })
    builder.addCase(fetchCashRedeemOptions.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(updateCashRedeemOptionsThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updateCashRedeemOptionsThunk.fulfilled, (state, action) => {
      state.options = state.options.map((option) => {
        if (option.id === action.payload.id) return action.payload
        return option
      })
      state.status = 'successfully'
    })
    builder.addCase(updateCashRedeemOptionsThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(createCashRedeemOptionsThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(createCashRedeemOptionsThunk.fulfilled, (state, action) => {
      state.options.unshift(action.payload)
      state.status = 'successfully'
    })
    builder.addCase(createCashRedeemOptionsThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(fetchCashRedeemRequestThunk.fulfilled, (state, action) => {
      state.activeRequest = action.payload
    })
  },
})

export default cashRedeemRequestsSlice.reducer
export const { setStatus, setActiveOption, setFetching, setActiveRequest } = cashRedeemRequestsSlice.actions

export const selectCashRedeemRequests = (state) => state.cashRedeemRequests.cashRedeemRequests
export const selectStatus = (state) => state.cashRedeemRequests.status
export const selectCashRedeemOptions = (state) => state.cashRedeemRequests.options
export const selectActiveOption = (state) => state.cashRedeemRequests.activeOption
export const selectActiveRequest = (state) => state.cashRedeemRequests.activeRequest
export const selectNextToken = (state) => state.cashRedeemRequests.nextToken
export const selectFetching = (state) => state.cashRedeemRequests.fetching
