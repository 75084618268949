import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectActiveTag, selectError, selectStatus, setStatus,} from "../../../store/reducers/tagsReducer";
import {FullscreenPreloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import TopNav from "../../../Components/TopNav/TopNav";
import Input from "../../../submodules/naoo-web-components/Components/Input/Input";
import spinnerClasses from "../../Spinner.module.scss";
import classes from "./TagForm.module.scss";
import {createMessage, MESSAGES_TYPES} from "../../../submodules/naoo-web-components/Components/Message/Message";
import {addMessageThunk} from "../../../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import Container from "../../../Components/FormComponents/Container/Container";
import Section from "../../../Components/FormComponents/Section/Section";
import Margin from "../../../submodules/naoo-web-components/Components/Margin/Margin";
import {format} from "date-fns";

// const isValidTag = (tag) => {
//     // eslint-disable-next-line
//     const regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g
//     return !regex.test(tag)
// }

const TagForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const tag = useSelector(state => selectActiveTag(state))
    const status = useSelector(state => selectStatus(state))
    const error = useSelector(selectError)

    const [tagState, setTagState] = useState({
        id: '',
        title: '',
    })

    // const errors = {
    //     id:
    //         tagState.id.length === 0 ||
    //         tagState.id.length > 36 ||
    //         (!isValidTag(tagState.id) && !tag),
    //     title: tagState.title.length === 0,
    // }

    useEffect(() => {
        setTagState({
            id: tag?.id || '',
            title: tag?.title || '',
        })
    }, [tag])

    const handleTagState = (event) => {
        setTagState(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    // const handleOnSuccessClick = () => {
    //     if (tag) {
    //         dispatch(updateTagThunk({
    //             tag: tagState,
    //             id: tag?.id
    //         }))
    //     } else {
    //         dispatch(createTagThunk(tagState))
    //     }
    // }

    const handleBackButtonClick = () => {
        navigate(-1)
    }

    // const handleDeleteClick = () => {
    //
    // }

    const emptyHandler = () => {
    }


    const topNavButtons = [
        // {
        //     title: 'Delete tag',
        //     onClick: handleDeleteClick,
        //     isDisabled: !tag,
        //     className: `${topNavClasses.DangerButton} ${handleErrors() ? topNavClasses.Disabled : ''}`
        // },
        // {
        //     title: 'Save',
        //     onClick: handleOnSuccessClick,
        //     isDisabled: handleErrors(errors),
        //     className: `${topNavClasses.SuccessButton} ${handleErrors(errors) ? topNavClasses.Disabled : ''}`
        // },
    ]

    useEffect(() => {
        if (status === 'successfully') {
            dispatch(setStatus(null))
            navigate(-1)
        }
    }, [dispatch, navigate, status])

    useEffect(() => {
        if (error) {
            dispatch(addMessageThunk({
                message: createMessage({
                    message: error,
                    type: MESSAGES_TYPES.ERROR
                })
            }))
        }
    }, [error, dispatch])

    return (
        <>
            {
                (status === 'loading') &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }
            <Container>
                <TopNav
                    buttons={topNavButtons}
                    onBack={handleBackButtonClick}
                />
                <div className={classes.FormContainer}>
                    <div className={classes.MainContainer}>
                        <Section title={'Tag'}>
                            <Margin top={24}>
                                <Input
                                    label={'ID'}
                                    placeholder={'ID'}
                                    value={tagState.id}
                                    name={'id'}
                                    onChange={tag ? () => {
                                    } : handleTagState}
                                />
                            </Margin>
                            <Margin top={24}>
                                <Input
                                    label={'Title'}
                                    placeholder={'Enter title in English'}
                                    name={'title'}
                                    value={tagState.title}
                                    onChange={handleTagState}
                                />
                            </Margin>
                        </Section>
                        {
                            tag &&
                            <Section title={'Info'}>
                                <Margin top={24}>
                                    <Input
                                        label={'Created at'}
                                        name={'createdAt'}
                                        value={tag?.createdAt ? format(new Date(tag?.createdAt), 'dd.MM.yyyy') : '-'}
                                        onChange={emptyHandler}
                                    />
                                </Margin>
                                <Margin top={24}>
                                    <Input
                                        label={'Updated at'}
                                        name={'updatedAt'}
                                        value={tag?.updatedAt ? format(new Date(tag?.updatedAt), 'dd.MM.yyyy') : '-'}
                                        onChange={emptyHandler}
                                    />
                                </Margin>
                            </Section>
                        }
                    </div>
                </div>
            </Container>
        </>
    );
};

export default TagForm;