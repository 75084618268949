import React from 'react';
import classes from "./Report.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {
    hideConfirmationPopup,
    selectActiveReport,
    selectConfirmationPopup,
    updateReportThunk
} from "../../../store/reducers/reportsReducer";
import {useNavigate} from "react-router-dom";
import {showReportByType} from "../utils/showReportByType/showReportByType";
import {BUTTON_TYPES, Button} from "../../../submodules/naoo-web-components/Components/Button/Buttons";
import ModalWindow from "../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";
import {ReactComponent as CloseIcon} from "../../../submodules/naoo-web-components/Shared/UI/assets/closeIcon.svg";
import {getReasonReport} from "../utils/getReasonReport";

const Report = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const report = useSelector(selectActiveReport)
    const {
        isOpen, title,
        primaryButton,
        handlePrimaryClick
    } = useSelector(selectConfirmationPopup)

    const handleCloseClick = () => {
        navigate(-1)
    }

    const UserContentReportStatusNew = 1
    const UserContentReportStatusResolved = 2

    const isNewReportStatus = report.status === UserContentReportStatusNew

    const handleButtonClick = () => {
        dispatch(updateReportThunk({
            report: {
                status: isNewReportStatus ? UserContentReportStatusResolved : UserContentReportStatusNew
            },
            id: report.id
        }))
    }

    const handleCancelPopupClick = () => {
        dispatch(hideConfirmationPopup())
    }

    return (
       <div className={classes.Container}>
           <ModalWindow
               isOpen={isOpen}
               title={title}
               primaryButton={primaryButton}
               secondaryButton={'No, cancel'}
               handlePrimaryClick={handlePrimaryClick}
               handleSecondaryClick={handleCancelPopupClick}
               onClose={handleCancelPopupClick}
           />
           <div className={classes.ReportHeader}>
               <div className={classes.Info}>
                   <div className={classes.InfoBlock}>
                       <span>Reported by:</span>
                       <span>{report?.user?.firstname} {report?.user?.lastname}</span>
                   </div>
                   <div className={classes.InfoBlock}>
                       <span>Reason:</span>
                       <span>{getReasonReport(report?.reason)}</span>
                   </div>
               </div>

               <Button
                   variant={isNewReportStatus ? BUTTON_TYPES.NOT_ROUNDED : BUTTON_TYPES.NOT_ROUNDED_SECONDARY}
                   className={classes.Button}
                   onClick={handleButtonClick}
               >{isNewReportStatus ? 'RESOLVE' : 'UNRESOLVE'}</Button>
               <CloseIcon
                   className={classes.CloseIcon}
                   width={25}
                   height={25}
                   onClick={handleCloseClick}
               />
           </div>
           <div className={classes.Container}>
               {
                   showReportByType(report)
               }
           </div>
       </div>
    );
};

export default Report;