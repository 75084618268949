import React from 'react';
import classes from'./ReportedOffer.module.scss'
import OfferCard from "./OfferCard/OfferCard";

const ReportedOffer = ({offer}) => {
    return (
        <div className={classes.Container}>
            <OfferCard offer={offer}/>
            <div className={classes.Info}>
                <div className={classes.About}>
                    <div className={classes.Title}>
                        <span>Title</span>
                    </div>
                    <div className={classes.Text}>
                        <span>{offer?.title}</span>
                    </div>
                </div>
                <div className={classes.About}>
                    <div className={classes.Title}>
                        <span>Description</span>
                    </div>
                    <div className={classes.Text}>
                        <span>{offer?.text}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportedOffer;