import React, { Fragment, useEffect, useState } from 'react'
import layoutClasses from '../Layout.module.scss'
import AdminSidebar from '../Sidebar/AdminSidebar'
import classes from './Reports.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchReports,
  fetchReportsByNextToken,
  selectFetching,
  selectNextToken,
  selectReports,
  setActiveReport,
  setFetching,
} from '../../store/reducers/reportsReducer'
import { Preloader } from '../../submodules/naoo-web-components/Components/Preloader/Preloader'
import spinnerClasses from '../Spinner.module.scss'
import { makeContentOfReport } from './utils/makeReportContent/makeReportContent'
import { returnObservableItem } from '../../submodules/naoo-web-components/Shared/utility/utils'
import { useObserver } from '../../submodules/naoo-web-components/Shared/hooks/useObserver'
import { getReportType, REPORT_TYPES } from './utils/getReportType/getReportType'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import { setActiveUser } from '../../store/reducers/usersReducer'
import TableWrapper from '../../Components/Table/TableWrapper/TableWrapper'
import TableHeader from '../../Components/Table/TableHeader/TableHeader'
import TableItem from '../../Components/Table/TableItem/TableItem'
import { format } from 'date-fns'
import { fetchComments } from '../../store/reducers/postsReducer'
import { getReasonReport } from './utils/getReasonReport'
import SearchDropdown from '../../Components/Search/SearchDropdown/SearchDropdown'

const Reports = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [status, setStatus] = useState('New')

  const reports = useSelector(selectReports)
  const fetching = useSelector(selectFetching)
  const nextToken = useSelector(selectNextToken)

  const [element, setElement] = useState(null)
  const LIMIT = 10


  useEffect(() => {
    const searchStatus = status === 'New' ? 1 : 2
    dispatch(fetchReports({ status: searchStatus }))
  }, [dispatch, status])

  useObserver(
    element,
    fetching, setFetching,
    nextToken, fetchReportsByNextToken,
  )

  const handleReportClick = (report) => () => {
    dispatch(setActiveReport(report))

    switch (getReportType(report)) {
      case REPORT_TYPES.REPORTED_POST :
        dispatch(fetchComments({
          id: report[REPORT_TYPES.REPORTED_POST].id,
        }))
        break
      case REPORT_TYPES.REPORTED_OFFER :
        // dispatch(fetchComments({
        //     id: report[REPORT_TYPES.REPORTED_OFFER].id
        // }))
        break
      case REPORT_TYPES.REPORTED_COMMENT :
        // const comment = report[REPORT_TYPES.REPORTED_COMMENT]
        // if (comment.parentCommentId) {
        //     dispatch(fetchReportedReply({
        //         replyId: comment.id,
        //         parentCommentId: comment.parentCommentId
        //     }))
        // } else {
        //     dispatch(setReportedComment(comment))
        // }
        break
      case REPORT_TYPES.REPORTED_USER :
        dispatch(setActiveUser(report[REPORT_TYPES.REPORTED_USER]))
        break
      default : {
      }
    }
    dispatch(setStatus(null))
    navigate(`/reports/${report.id}`)
  }

  const match = useMatch('/reports')

  const handleStatusUpdate = (status) => {
    setStatus(status)
  }

  return (
    <>
      <Outlet />
      {
        match &&
        <div className={layoutClasses.Container}>
          <AdminSidebar>
            <div style={{ height: 50 }} />
          </AdminSidebar>
          <div className={layoutClasses.Main}>
            <div className={classes.SearchContainer}>
              Status
              <SearchDropdown
                className={classes.SearchBox}
                value={status}
                options={['New', 'Resolved']}
                onChange={handleStatusUpdate}
                placeholder={'Status'}
                isClearable={true}
              />
            </div>

            <TableWrapper>
              {/*HEADER*/}
              <TableHeader className={classes.Header}>
                <div className={classes.Content}>
                  <span>Content</span>
                </div>
                <div className={classes.CreatedAt}>
                  <span>Created at</span>
                </div>
                <div className={classes.ReportedBy}>
                  <span>Reported by</span>
                </div>
                <div className={classes.Reason}>
                  <span>Reason</span>
                </div>
              </TableHeader>
              {/*HEADER*/}
              {/*ITEMS*/}
              {
                reports?.map((report, index, array) => (
                  <Fragment key={report.id}>
                    <TableItem className={classes.ReportItem} onClick={handleReportClick(report)}>
                      {
                        report.status === 1 &&
                        <div className={classes.NewReport} />
                      }
                      {
                        makeContentOfReport(report)
                      }
                      <div className={classes.CreatedAt}>
                        <span>{format(new Date(report.createdAt), 'dd.MM.yy')}</span>
                      </div>
                      <div className={classes.ReportedBy}>
                        <span>{report?.user?.firstname} {report?.user?.lastname}</span>
                      </div>
                      <div className={classes.Reason}>
                        <span>{getReasonReport(report.reason)}</span>
                      </div>
                    </TableItem>
                    {
                      returnObservableItem(array.length, LIMIT, index, setElement)
                    }
                  </Fragment>
                ))
              }
              {
                fetching && <Preloader className={spinnerClasses.paginationSpinnerSize} />
              }
              {/*ITEMS*/}
            </TableWrapper>
          </div>
        </div>
      }
    </>
  )
}

export default Reports