import React from 'react'
import classes from './SearchComponentWrapper.module.scss'

const SearchComponentWrapper = ({ children }) => {
  return (
    <div className={classes.Container}>
      {children}
    </div>
  )
}

export default SearchComponentWrapper