import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useMatch, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    addCategory,
    addProfileMediaThunk,
    deleteProfileMediaThunk,
    getBusiness,
    removeCategory,
    selectActiveTab,
    selectBusiness,
    selectCategories,
    setActiveTab,
    setAddress,
    setCoordinates,
    setCover,
    setDescription,
    setEmail,
    setHours,
    setLogo,
    setName,
    setPhone,
    setWebsite,
    updateBusinessThunk
} from "../../../store/reducers/businessesReducer";
import {isValidWebsite} from "../../../submodules/naoo-web-components/Shared/utility/websiteValidation";
import {isValidPhoneNumber} from "../../../submodules/naoo-web-components/Shared/utility/phoneNumberValidation";
import {isValidEmail} from "../../../submodules/naoo-web-components/Shared/utility/emailValidation";
import {FullscreenPreloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import TopNav from "../../../Components/TopNav/TopNav";
import NameCategoryAboutAndLogo from "../../../submodules/naoo-web-components/Business/NameCategoryAboutAndLogo";
import Contacts from "../../../submodules/naoo-web-components/Business/Contacts";
import CoverAndGallery from "../../../submodules/naoo-web-components/Business/CoverAndGallery";
import Location from "../../../submodules/naoo-web-components/Business/Location";
import Schedule from "../../../submodules/naoo-web-components/Business/Schedule";
import classes from './Business.module.scss'
import spinnerClasses from '../../Spinner.module.scss'
import {ROUTES} from "../../../submodules/naoo-web-components/Shared/constants";
import {setActiveOffer} from "../../../store/reducers/offersReducer";
import topNavClasses from "../../../Components/TopNav/TopNav.module.scss";
import DeleteBusinessModal from "./DeleteBusinessModal/DeleteBusinessModal";
import OfferTable from "../../Offers/OfferTable/OfferTable";
import Container from "../../../Components/FormComponents/Container/Container";
import TabBar from "../../../submodules/naoo-web-components/Components/TabBar/TabBar";
import TabBarItem from "../../../submodules/naoo-web-components/Components/TabBar/TabBarItem";

const Business = () => {
    const business = useSelector(state => selectBusiness(state))
    // const vouchers = useSelector(selectVouchers)
    const activeTab = useSelector(state => selectActiveTab(state))
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const {id} = useParams()

    const categories = useSelector(state => selectCategories(state))
    const [logoName, setLogoName] = useState('')
    const [coverName, setCoverName] = useState('')
    const [isLoadingGallery, setIsLoadingGallery] = useState(false)
    const [isUpdatingBusiness, setIsUpdatingBusiness] = useState(false)
    const [isShowModal, setIsShowModal] = useState(false)
    const logo = business?.logo?.url || business?.logo
    const cover = business?.cover?.url || business?.cover

    const [isOnline, setIsOnline] = useState(!business?.address)

    const handleCheckBoxClick = () => {
        setIsOnline(!isOnline)
    }

    useEffect(() => {
        dispatch(getBusiness(id))
    }, [id, dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const errors = {
        name: business?.name?.length === 0,
        website: !isValidWebsite(business?.website),
        phone: !isValidPhoneNumber(business?.phone) && business?.phone?.length !== 0,
        email: !isValidEmail(business?.email) && business?.phone?.length !== 0,
        logo: business?.logo === '',
        cover: business?.cover === '',
        address: business?.address === '' && !isOnline,
        categories: business?.businessCategories?.length === 0
    }

    const handleErrors = () => {
        if (activeTab === 'Offers' || activeTab === 'Vouchers') return false
        for (const error in errors) {
            if (errors[error]) return true
        }
        return false
    }

    const updateBusinessHandler = () => {
        setIsUpdatingBusiness(true)
        dispatch(updateBusinessThunk({
            business: {
                name: business.name,
                businessCategories: business.businessCategories,
                description: business.description,
                phone: business.phone,
                website: business.website,
                email: business.email,
                logo: business.logo,
                cover: business.cover,
                hours: business.customHours,
                profileMedia: business.profileMedia,
                coordinates: !isOnline ? [business.coordinates[1], business.coordinates[0]] : null,
                address: !isOnline ? business.address : null
            },
            businessId: business.id
        })).then(() => {
            setIsUpdatingBusiness(false)
            navigate(ROUTES.ADMIN_DASHBOARD.BUSINESSES)
        })

    }

    const createNewOfferClickHandler = () => {
        dispatch(setActiveOffer({}))
        navigate(ROUTES.ADMIN_DASHBOARD.BUSINESSES + `/${business.id}/update/offers/create`, location.pathname)
    }

    // const createNewVoucherClickHandler = () => {
    //     dispatch(setActiveVoucher({}))
    //     dispatch(setStatus(null))
    //     navigate(ROUTES.ADMIN_DASHBOARD.BUSINESSES + `/${business.id}/update/vouchers/create`)
    // }

    const handleBackButtonClick = () => {
        dispatch(setActiveTab('Profile'))
        navigate(-1)
    }

    const deleteBusinessHandler = () => {
        setIsShowModal(true)
    }

    const handleNameChange = (event) => {
        dispatch(setName(event.target.value))
    }

    const handleAboutChange = (event) => {
        dispatch(setDescription(event.target.value))
    }

    const handleCategoryChange = (item) => {
        item.isSelected ? dispatch(removeCategory(item)) : dispatch(addCategory(item))
    }

    const handleLogoOnDrop = (acceptedFiles) => {
        setLogoName(acceptedFiles[0].name)
        const imgUrl = URL.createObjectURL(acceptedFiles[0])
        dispatch(setLogo(imgUrl))
    }

    const handleRemoveLogo = () => {
        dispatch(setLogo(''))
    }

    const handleWebsiteChange = (event) => {
        dispatch(setWebsite(event.target.value))
    }

    const handleEmailChange = (event) => {
        dispatch(setEmail(event.target.value))
    }

    const handlePhoneChange = (event) => {
        dispatch(setPhone(event.target.value))
    }

    const handleGalleryChanges = (items) => {
        setIsLoadingGallery(true)
        dispatch(addProfileMediaThunk({
            businessId: business.id,
            urlList: items,
            mediaIds:business.profileMedia?.map(item=>item.id)
        })).then(() => {
            setIsLoadingGallery(false)
        })
    }

    const deleteGalleryItem = (key) => {
        dispatch(deleteProfileMediaThunk({businessId: business.id, mediaId: key}))
    }

    const handleCoverOnDrop = (acceptedFiles) => {
        setCoverName(acceptedFiles[0].name)
        const imgUrl = URL.createObjectURL(acceptedFiles[0])
        dispatch(setCover(imgUrl))
    }

    const handleRemoveCover = () => {
        dispatch(setCover(''))
    }

    const handleClickIntoMap = (address, lat, lng) => {
        dispatch(setAddress(address.formatted_address))
        dispatch(setCoordinates([lat, lng]))
    }

    const handleAddressChange = (event) => {
        dispatch(setAddress(event.target.value))
    }

    // const handleEditMoreDropdownClick = (voucher) => () => {
    //     dispatch(setActiveVoucher(voucher.id))
    //     dispatch(setStatus(null))
    //     navigate(`${ROUTES.ADMIN_DASHBOARD.BUSINESSES}/${business.id}/update/vouchers/${voucher.id}/update`)
    // }


    const buttonsSettings = {
        Offers: {
            dangerTitle: '',
            submitTitle: 'Create new offer',
            dangerHandler: null,
            submitHandler: createNewOfferClickHandler
        },
        Profile: {
            dangerTitle: 'Delete business',
            submitTitle: 'Save',
            dangerHandler: deleteBusinessHandler,
            submitHandler: updateBusinessHandler
        },
        // Vouchers: {
        //     dangerTitle: '',
        //     submitTitle: 'Create new voucher',
        //     dangerHandler: null,
        //     submitHandler: createNewVoucherClickHandler
        // },
    }

    const topNavButtons = [
        {
            title: buttonsSettings[activeTab].dangerTitle,
            onClick: buttonsSettings[activeTab].dangerHandler,
            isDisabled: false,
            className: `${topNavClasses.DangerButton}`
        },
        {
            title: buttonsSettings[activeTab].submitTitle,
            onClick: buttonsSettings[activeTab].submitHandler,
            isDisabled: handleErrors(errors),
            className: `${topNavClasses.SuccessButton} ${handleErrors() ? topNavClasses.Disabled : ''}`
        },
    ]
    const match = useMatch(`${ROUTES.ADMIN_DASHBOARD.BUSINESSES}/:id/update`)

    if (!business) return null

    return (
        <>
            <Outlet/>
            <DeleteBusinessModal isOpen={isShowModal} business={business} setIsShowModal={setIsShowModal}/>
            {
                isUpdatingBusiness &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }
            {
                match &&
                <Container>
                    <TopNav
                        buttons={topNavButtons}
                        title={business?.name}
                        onBack={handleBackButtonClick}
                    />
                    <TabBar initialActiveTab={activeTab} navItemClick={(tab) => dispatch(setActiveTab(tab))}>
                        <TabBarItem label={'Profile'}>
                            <NameCategoryAboutAndLogo
                                business={business}
                                errors={errors}
                                logo={logo}
                                categories={categories}
                                logoName={logoName}
                                handleAboutChange={handleAboutChange}
                                handleCategoryChange={handleCategoryChange}
                                handleLogoOnDrop={handleLogoOnDrop}
                                handleNameChange={handleNameChange}
                                handleRemoveLogo={handleRemoveLogo}
                            />
                            <Contacts
                                business={business}
                                errors={errors}
                                handleEmailChange={handleEmailChange}
                                handlePhoneChange={handlePhoneChange}
                                handleWebsiteChange={handleWebsiteChange}
                            />
                            <CoverAndGallery
                                business={business}
                                errors={errors}
                                businessId={business?.id}
                                cover={cover}
                                coverName={coverName}
                                handleGalleryChanges={handleGalleryChanges}
                                handleCoverOnDrop={handleCoverOnDrop}
                                handleRemoveCover={handleRemoveCover}
                                galleryIsLoading={isLoadingGallery}
                                deleteGalleryItem={deleteGalleryItem}
                            />
                            <Location
                                business={business}
                                errors={errors}
                                handleClickIntoMap={handleClickIntoMap}
                                handleAddressChange={handleAddressChange}
                                isOnline={isOnline}
                                handleCheckBoxClick={handleCheckBoxClick}
                            />
                            <Schedule
                                business={business}
                                errors={errors}
                                setHours={setHours}
                                timePickerClass={classes.TimepickerBgc}
                            />
                        </TabBarItem>
                        <TabBarItem label={'Offers'}>
                            <OfferTable/>
                        </TabBarItem>
                        {/*<TabBarItem label={'Vouchers'}>*/}
                        {/*    <div className={classes.ItemsContainer}>*/}
                        {/*        <div className={classes.ItemsHeader}>*/}
                        {/*            <div className={classes.Title}>*/}
                        {/*                Vouchers*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.VoucherTableHeader}>*/}
                        {/*            <div className={classes.Title}>*/}
                        {/*                <span>Title</span>*/}
                        {/*            </div>*/}
                        {/*            <div className={classes.Description}>*/}
                        {/*                <span>Description</span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        {*/}
                        {/*            vouchers?.map((item) => (*/}
                        {/*                <div className={classes.VoucherTableItem} key={item?.id}>*/}
                        {/*                    <img className={classes.Logo} src={item?.media?.url} alt=""/>*/}
                        {/*                    <div className={classes.Title}>*/}
                        {/*                        <span>{item.title}</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div className={classes.Description}>*/}
                        {/*                        <span>{item.description}</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div className={classes.More}>*/}
                        {/*                        <MoreDropdown vertical>*/}
                        {/*                            <MoreItem text={'Edit'} onClick={handleEditMoreDropdownClick(item)}/>*/}
                        {/*                        </MoreDropdown>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            ))*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</TabBarItem>*/}
                    </TabBar>
                </Container>
            }
        </>
    );
};

export default Business;