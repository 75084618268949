import React, {useEffect} from 'react';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    createTemplateThunk, fetchTemplateByIdThunk,
    selectActiveTemplate,
    selectStatus, setActiveTemplate, setStatus,
    updateTemplateThunk
} from "../../../store/reducers/notificationTemplatesReducer";
import topNavClasses from "../../../Components/TopNav/TopNav.module.scss";
import Notification from "./Notification";
import {useNotification} from "./useNotification";
import {addMessageThunk} from "../../../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import {createMessage, MESSAGES_TYPES} from "../../../submodules/naoo-web-components/Components/Message/Message";
import {sendNotification, sendNotificationWithPoints} from "../../../api/notificationApi";

export const contentTypes = {
    'Without content': {name: 'open-app', label: 'Without content'},
    'Points': {name: 'naoo-points', label: 'Points', idName: 'points'},
    'Offer': {name: 'offer', label: 'Offer', idName: 'offerId'},
    'Competition': {name: 'competition', label: 'Competition', idName: 'competitionId'},
    'Suggestion': {name: 'suggestion', label: 'Suggestion', idName: 'suggestionId'},
    'Find contacts': {name: 'find-contacts', label: 'Find contacts'},
}

const getValueFromContentTypesByName = (name)=>{
    for (const key in contentTypes) {
        if (contentTypes[key].name === name) return contentTypes[key]
    }
    return null
}

const NotificationTemplateContainer = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const template = useSelector(state=>selectActiveTemplate(state))
    const status = useSelector(state => selectStatus(state))

    const {id} = useParams()
    const query = new URLSearchParams(useLocation().search)
    const copy = query.get('copy')

    const {
        title, setTitle,
        img, setImg,
        imgUrl, setImgUrl,
        imgLoading,
        description, setDescription,
        targetType, setTargetType,
        contentType, setContentType,
        contentValue, setContentValue,
        users, setUsers,
        isLoading, setIsLoading,
        handleErrors,
        handleDropzone,
        clearDropzone,
        handleContentChange,
        handleCompetitionChange,
        handlePointsChange,
        handleUsersDropdown,
        combineData,
        combinePointsData
    } = useNotification()


    useEffect(() => {
        if (id){
            dispatch(fetchTemplateByIdThunk(id))
        }
    }, [id, dispatch])

    useEffect(()=>{
            setTitle(template?.title || '')
            setImg(template?.mediaUrl || '')
            setImgUrl(template?.mediaUrl || '')
            setDescription(template?.message || '')
            const type = getValueFromContentTypesByName(template?.type)
            setContentType(type ||  contentTypes["Without content"])

            switch( type?.name){
                case contentTypes["Without content"].name : setContentValue({
                    points: '',
                    offerId: '',
                    competitionId: '',
                    suggestionId: '',
                })
                    break
                case contentTypes.Offer.name : setContentValue({
                    offerId: template?.offerId || ''
                })
                    break
                case contentTypes.Competition.name : setContentValue({
                    competitionId: {
                        id: template?.competitionId || ''
                    }
                })
                    break
                case contentTypes.Points.name : setContentValue({
                    points: template?.points || ''
                })
                    break
                case contentTypes.Suggestion.name : setContentValue({
                    suggestionId: template?.suggestionId || ''
                })
                    break
                default : setContentValue({
                    points: '',
                    offerId: '',
                    competitionId: '',
                    suggestionId: '',
                })
            }
    }, [
        template, setContentType, setContentValue,
        setDescription, setImg, setImgUrl,
        setTitle
    ])


    const onSaveTemplateClickHandler = ()=>{
        const data = combineData()
        data.message = description
        if (template && !copy){
            dispatch(updateTemplateThunk({
                data,
                id:template.id
            }))
        }
        else{
            dispatch(createTemplateThunk(data))
        }
    }

    const onSuccessClickHandler = (event, sendNotificationFunction) => {
        let data
        if (contentType.idName === 'points'){
            data = combinePointsData()
        }
        else{
            data = combineData()
            data.text = description
        }
        setIsLoading(true)
        sendNotificationFunction(data)
            .then(() => {
                onSaveTemplateClickHandler()
                setIsLoading(false)
                dispatch(addMessageThunk({
                    message: createMessage({
                        type: MESSAGES_TYPES.SUCCESS,
                        message: 'Notification sent',
                    })
                }))
                dispatch(setStatus('successfully'))
            })
            .catch(error => {
                console.error(error)
                setIsLoading(false)
            })
    }

    const onBackClickHandler = () =>{
        navigate(-1)
        dispatch(setActiveTemplate())
    }


    const topNavButtons = [
        {
            title:'Discard template',
            onClick:onBackClickHandler,
            isDisabled: false,
            className: topNavClasses.DangerButton
        },
        {
            title:'Save template',
            onClick:onSaveTemplateClickHandler,
            isDisabled: (handleErrors()),
            className: `${topNavClasses.SuccessButton} ${handleErrors() ? topNavClasses.Disabled: ''}`
        },
        {
            title: 'Send notification & save template',
            onClick:(event)=>{
                onSuccessClickHandler(event, contentType.idName === 'points'
                    ? sendNotificationWithPoints
                    : sendNotification
                )
            },
            isDisabled: handleErrors(),
            className: `${topNavClasses.SuccessButton} ${handleErrors() ? topNavClasses.Disabled: ''}`
        },
    ]

    useEffect(()=>{
        if (status === 'successfully') {
            dispatch(setStatus(null))
            dispatch(setActiveTemplate())
            navigate(-1)
        }
    }, [status, dispatch, navigate])

    return (
        <Notification
            status={status}
            img={img}
            imgUrl={imgUrl}
            contentType={contentType}
            contentValue={contentValue}
            clearDropzone={clearDropzone}
            description={description}
            isLoading={isLoading}
            handleContentChange={handleContentChange}
            handleDropzone={handleDropzone}
            handlePointsChange={handlePointsChange}
            handleUsersDropdown={handleUsersDropdown}
            imgLoading={imgLoading}
            onBackClickHandler={onBackClickHandler}
            setContentType={setContentType}
            setDescription={setDescription}
            setImg={setImg}
            setImgUrl={setImgUrl}
            setTargetType={setTargetType}
            setTitle={setTitle}
            setUsers={setUsers}
            targetType={targetType}
            title={title}
            topNavButtons={topNavButtons}
            users={users}
            handleCompetitionChange={handleCompetitionChange}
        />
    );
};

export default NotificationTemplateContainer;