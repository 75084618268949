import React, {Fragment, useState} from 'react';
import Comment from "../Comment/Comment";
import {returnObservableItem} from "../../../../../submodules/naoo-web-components/Shared/utility/utils";
import {useSelector} from "react-redux";
import {useObserver} from "../../../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import {
    fetchCommentsByNextToken, selectActivePost,
    selectComments,
    selectFetchingComments,
    selectNextTokenComments, setFetchingComments
} from "../../../../../store/reducers/postsReducer";

const Comments = ({MoreDropdown}) => {
    const post = useSelector(selectActivePost)
    const comments = useSelector(selectComments)
    const nextToken = useSelector(selectNextTokenComments)
    const fetching = useSelector(selectFetchingComments)
    const [element, setElement] = useState(null)
    const LIMIT = 10

    useObserver(
        element,
        fetching, setFetchingComments,
        nextToken, fetchCommentsByNextToken
    )

    return (
       <>
           {
               comments?.map((comment, index, array) => (
                   <Fragment key={comment.id}>
                       <Comment comment={comment} postId={post?.id} MoreDropdown={MoreDropdown}/>
                       {
                           returnObservableItem(array.length, LIMIT, index, setElement)
                       }
                   </Fragment>
               ))
           }
       </>
    );
};

export default Comments;