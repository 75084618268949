import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classes from '../RedeemOption.module.scss'
import {
  setActiveOption,
  setStatus,
  updateCashRedeemOptionsThunk,
} from '../../../../store/reducers/cashRedeemRequestsReducer'
import { ROUTES } from '../../../../submodules/naoo-web-components/Shared/constants'
import MoreDropdown from '../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown'
import MoreItem from '../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem'

const RedeemOptionMoreDropdown = ({ optionId, type, isActive }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleEditClick = () => {
    dispatch(setActiveOption(optionId))
    navigate(`${ROUTES.ADMIN_DASHBOARD.CASH_REDEMPTIONS}/options/${optionId}/update`)
  }

  const handleActivateClick = () => {
    dispatch(
      updateCashRedeemOptionsThunk({
        option: {
          isActive: !isActive,
          type: type,
        },
        id: optionId,
      })
    ).then(() => {
      dispatch(setStatus(null))
    })
  }

  return (
    <>
      <div className={classes.MoreIconContainer}>
        <MoreDropdown vertical bottomPosition width={96}>
          <MoreItem text={isActive ? 'Deactivate' : 'Activate'} onClick={handleActivateClick} />
          <MoreItem text={'Edit'} onClick={handleEditClick} />
        </MoreDropdown>
      </div>
    </>
  )
}

export default RedeemOptionMoreDropdown
