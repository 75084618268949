import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserThunk, selectActiveUser } from '../../../store/reducers/usersReducer'
import { useParams } from 'react-router-dom'
import { FormContextProvider } from '../../../shared/Form/UseFormContext'
import User from './User'
import { FullscreenPreloader } from '../../../submodules/naoo-web-components/Components/Preloader/Preloader'

const UpdateUserContainer = () => {
  const user = useSelector(selectActiveUser)
  const { id } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserThunk(id))
  }, [dispatch, id])

  return (
    <FormContextProvider>
      {!user && <FullscreenPreloader />}
      {user && <User user={user} />}
    </FormContextProvider>
  )
}

export default UpdateUserContainer