import {getReportType, REPORT_TYPES} from "../getReportType/getReportType";
import React from "react";
import Post from "../../Report/Components/Post/Post";
import ReportedComment from "../../Report/Components/ReportedComment/ReportedComment";
import ReportedUser from "../../Report/Components/ReportedUser/ReportedUser";
import ReportedBusiness from "../../Report/Components/ReportedBusiness/ReportedBusiness";
import ReportedOffer from "../../Report/Components/ReportedOffer/ReportedOffer";


export const showReportByType = (report) =>{
    const reports = {
        [REPORT_TYPES.REPORTED_BUSINESS]: (
            <ReportedBusiness business={report[REPORT_TYPES.REPORTED_BUSINESS]}/>
        ),
        [REPORT_TYPES.REPORTED_OFFER]: (
            <ReportedOffer offer={report[REPORT_TYPES.REPORTED_OFFER]}/>
        ),
        [REPORT_TYPES.REPORTED_POST]: (
            <Post post={report[REPORT_TYPES.REPORTED_POST]} isReported reportId={report.id}/>
        ),
        [REPORT_TYPES.REPORTED_COMMENT]: (
           <ReportedComment comment={report[REPORT_TYPES.REPORTED_COMMENT]}/>
        ),
        [REPORT_TYPES.REPORTED_USER]: (
            <ReportedUser user={report[REPORT_TYPES.REPORTED_USER]}/>
        ),
    }

   return  reports[getReportType(report)] ?? null
}

