import { Navigate, Route, Routes } from 'react-router-dom'
import { PrivateWrapper } from './PrivateWrapper'
import { ROUTES } from '../submodules/naoo-web-components/Shared/constants'
import Polls from '../app/Polls/Polls'
import CreateOrUpdatePoll from '../app/Polls/Poll/CreateOrUpdatePoll/CreateOrUpdatePoll'
import UpdatePollContainer from '../app/Polls/Poll/UpdatePollContainer'
import Poll from '../app/Polls/Poll/Poll'
import Businesses from '../app/Businesses/Businesses'
import Business from '../app/Businesses/Business/Business'
import Notifications from '../app/Notifications/Notifications'
import NotificationTemplateContainer from '../app/Notifications/Notification/NotificationTemplateContainer'
import PostsPage from '../app/Posts/PostsPage'
import CreateOrUpdatePost from '../app/Posts/Components/PostForm/CreateOrUpdatePost'
import UpdatePostContainer from '../app/Posts/Components/PostForm/UpdatePostContainer'
import Competitions from '../app/Competitions/Competitions'
import Competition from '../app/Competitions/Competition/Competition'
import CashRedemptions from '../app/CashRedemptions/CashRedemptions'
import RedeemOptionForm from '../app/CashRedemptions/RedeemOptionForm/RedeemOptionForm'
import Request from '../app/CashRedemptions/Request/Request'
import Users from '../app/Users/Users'
import UpdateUserContainer from '../app/Users/UserForm/UpdateUserContainer'
import Promotions from '../app/Promotions/Promotions'
import Promotion from '../app/Promotions/Promotion/Promotion'
import ListRegistrationPromotion from '../app/Promotions/ListRegistrationPromotion/ListRegistrationPromotion'
import Tags from '../app/Tags/Tags'
import TagForm from '../app/Tags/TagForm/TagForm'
import Reports from '../app/Reports/Reports'
import Report from '../app/Reports/Report/Report'
import Logout from '../submodules/naoo-web-components/Auth/Logout'
import Auth from '../submodules/naoo-web-components/Auth/Auth'
import logo from '../shared/icons/naooAdminLogo.svg'
import ForgotPassword from '../submodules/naoo-web-components/Auth/ForgotPassword'
import React from 'react'
import Transactions from '../app/Transactions/Transactions'
import CreateOrUpdateOffer from '../app/Offers/CreateOrUpdateOffer/CreateOrUpdateOffer'
import VoucherForm from '../app/Vouchers/VoucherForm/VoucherForm'
import Transaction from '../app/Transactions/Transaction/Transaction'
import PostPreviewPage from '../app/Posts/PostPreviewPage'
import QrCode from '../submodules/naoo-web-components/Posts/QrCode/QrCode'
import Chat from '../submodules/naoo-web-components/Chat/Chat'
import ApiClient from '../submodules/naoo-web-components/Api/client'

export const useProjectRoutes = () => {
  const apiClient = new ApiClient()
  const user = apiClient?.getCurrentUser()
  return (
    <Routes>
      <Route path='/' element={<PrivateWrapper />}>
        <Route path={ROUTES.ADMIN_DASHBOARD.POLLS} element={<Polls />}>
          <Route path={'create'} exact element={<CreateOrUpdatePoll />} />
          <Route path={':id/update'} element={<UpdatePollContainer />} />
          <Route path={':id'} element={<Poll />} />
        </Route>
        <Route path={ROUTES.ADMIN_DASHBOARD.BUSINESSES} element={<Businesses />}>
          <Route path={':id/update'} element={<Business />}>
            <Route path={'offers/:offerId/update'} element={<CreateOrUpdateOffer />} />
            <Route path={'offers/create'} element={<CreateOrUpdateOffer />} />
            <Route path={`vouchers/:voucherId/update`} element={<VoucherForm />} />
            <Route path={'vouchers/create'} element={<VoucherForm />} />
          </Route>
        </Route>
        <Route path={'/notifications'} element={<Notifications />}>
          <Route path={':id/update'} element={<NotificationTemplateContainer />} />
          <Route path={'create'} element={<NotificationTemplateContainer />} />
        </Route>
        <Route path={ROUTES.ADMIN_DASHBOARD.POSTS} element={<PostsPage />}>
          <Route path={'create'} element={<CreateOrUpdatePost />} />
          <Route path={':id/update'} element={<UpdatePostContainer />} />
          <Route path={':id/preview'} element={<PostPreviewPage />} />
          <Route path={':id/qr'} element={<QrCode />} />
        </Route>
        <Route path={ROUTES.ADMIN_DASHBOARD.COMPETITIONS} element={<Competitions />}>
          <Route path={'create'} element={<Competition />} />
          <Route path={':id'} element={<Competition />} />
          <Route path={':id/update'} element={<Competition />} />
        </Route>
        <Route path={ROUTES.ADMIN_DASHBOARD.CASH_REDEMPTIONS} element={<CashRedemptions />}>
          <Route path={'options/create'} element={<RedeemOptionForm />} />
          <Route path={'options/:id/update'} element={<RedeemOptionForm />} />
          <Route path={':id'} element={<Request />} />
        </Route>
        <Route path={ROUTES.ADMIN_DASHBOARD.USERS} element={<Users />}>
          <Route path={':id/update'} element={<UpdateUserContainer />} />
        </Route>
        <Route path={ROUTES.ADMIN_DASHBOARD.PROMOTIONS} element={<Promotions />}>
          <Route path={'create'} element={<Promotion />} />
          <Route path={':id/update'} element={<Promotion />} />
          <Route path={':id/activations'} element={<ListRegistrationPromotion />} />
        </Route>
        <Route path={ROUTES.ADMIN_DASHBOARD.TAGS} element={<Tags />}>
          <Route path={'create'} element={<TagForm />} />
          <Route path={':id/update'} element={<TagForm />} />
        </Route>
        {/*<Route path={'/vouchers'} element={<Vouchers/>}/>*/}
        <Route path={ROUTES.ADMIN_DASHBOARD.REPORTS} element={<Reports />}>
          <Route path={':id'} element={<Report />} />
        </Route>
        <Route path={ROUTES.ADMIN_DASHBOARD.POINTS_TRANSACTIONS} element={<Transactions />}>
          <Route path={':id'} element={<Transaction />} />
        </Route>
        <Route path={'/chat'} element={<Chat userId={user?.getUsername()} />} />
        <Route path={ROUTES.AUTH.LOGOUT} exact element={<Logout />} />
        <Route path={'*'} element={<Navigate to={ROUTES.ADMIN_DASHBOARD.POLLS} />} />
      </Route>

      <Route path={ROUTES.AUTH.AUTH}
             element={(<Auth logo={logo}
                             redirectPath={ROUTES.ADMIN_DASHBOARD.POLLS}
                             isAdminDashboard={true}
             />)}
      />
      <Route path={ROUTES.AUTH.FORGOT_PASSWORD} element={<ForgotPassword logo={logo} />} />
      <Route path={'*'} element={<Navigate to={ROUTES.AUTH.AUTH} />} />
    </Routes>
  )
}