import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sendImage } from '../../submodules/naoo-web-components/Shared/utility/sendImage'
import { setUserShadowBanById } from '../../shared/utils/setUserShadowBanById'
import { addUserRestrictionById } from '../../shared/utils/addUserRestrictionById'
import { removeUserRestrictionById } from '../../shared/utils/removeUserRestrictionById'
import { createPostDeepLink } from '../../api/firebaseDeepLinkApi'
import { format } from 'date-fns'
import {
  createLike,
  createPost,
  deleteComment,
  deleteLike,
  deletePost,
  getComments,
  getPosts,
  getPostStats,
  updatePost,
} from '../../api/postApi'
import { createMediaPresignedUrl, getMedia } from '../../api/mediaApi'
import { searchParamsSelector } from '../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer'

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async (payload, { getState }) => {
  const { searchString, user } = searchParamsSelector(getState())
  const response = await getPosts({
    q: !!searchString ? searchString : null,
    userId: user?.id,
  })
  return response.data
})

export const fetchPostsByNextToken = createAsyncThunk('posts/fetchPostsByNextToken', async (payload, { getState }) => {
  const state = getState()
  const { searchString, user } = searchParamsSelector(state)
  const response = await getPosts({
    userId: user?.id,
    q: !!searchString ? searchString : null,
    nextToken: payload.nextToken,
  })
  return response.data
})

export const fetchFeaturedPosts = createAsyncThunk('posts/fetchFeaturedPosts', async (payload, { getState }) => {
  const state = getState()
  const { searchString, user } = searchParamsSelector(state)
  const response = await getPosts({
    q: !!searchString ? searchString : null,
    userId: user?.id,
    naooFeaturedVideos: true,
  })
  return response.data
})

export const fetchPostsWithVideo = createAsyncThunk('posts/fetchPostsWithVideo', async (payload, { getState }) => {
  const state = getState()
  const { searchString, user } = searchParamsSelector(state)
  const response = await getPosts({
    q: !!searchString ? searchString : null,
    userId: user?.id,
    hasVideo: true,
  })
  return response.data
})

export const fetchPostsWithMediaModerationLabels = createAsyncThunk('posts/fetchPostsWithMediaModerationLabels', async (payload, { getState }) => {
  const state = getState()
  const { searchString, user } = searchParamsSelector(state)
  const response = await getPosts({
    q: !!searchString ? searchString : null,
    userId: user?.id,
    hasAnyMediaRekognitionModerationLabel: true,
  })
  return response.data
})

export const fetchFeaturedPostsByNextToken = createAsyncThunk(
  'posts/fetchFeaturedPostsByNextToken',
  async (payload, { getState }) => {
    const state = getState()
    const { searchString, user } = searchParamsSelector(state)
    const response = await getPosts({
      nextToken: payload.nextToken,
      q: !!searchString ? searchString : null,
      userId: user?.id,
      naooFeaturedVideos: true,
    })
    return response.data
  }
)

export const fetchPostsWithVideoByNextToken = createAsyncThunk(
  'posts/fetchPostsWithVideoByNextToken',
  async (payload, { getState }) => {
    const state = getState()
    const { searchString, user } = searchParamsSelector(state)
    const response = await getPosts({
      nextToken: payload.nextToken,
      q: !!searchString ? searchString : null,
      userId: user?.id,
      hasVideo: true,
    })
    return response.data
  }
)

export const fetchPostsWithMediaModerationLabelsByNextToken = createAsyncThunk(
  'posts/fetchPostsWithMediaModerationLabelsByNextToken',
  async (payload, { getState }) => {
    const state = getState()
    const { searchString, user } = searchParamsSelector(state)
    const response = await getPosts({
      nextToken: payload.nextToken,
      q: !!searchString ? searchString : null,
      userId: user?.id,
      hasAnyMediaRekognitionModerationLabel: true,
    })
    return response.data
  }
)

const sendMedia = (items, userID) => {
  return items?.map(async (item) => {
    if (item.id) {
      return { id: item.id }
    }
    const mediaResponse = await sendImage(item?.url, createMediaPresignedUrl, getMedia, userID)
    return { id: mediaResponse.id }
  })
}

export const createPostThunk = createAsyncThunk('posts/createPost', async (post, { getState }) => {
  const media = await Promise.all(sendMedia(post.galleryItems, post.userID))
  let data = {
    text: post.text,
    userId: post.userID,
    tags: post.tags,
    publicationDate: post.publicationDate,
  }
  if (media) {
    data.mediaIds = media.map((item) => item.id)
  }
  const response = await createPost(data)
  return response.data
})

export const updatePostThunk = createAsyncThunk('posts/updatePost', async (payload, { getState }) => {
  let data = {
    text: payload.post.text,
    userId: payload.post.userID,
    tags: payload.post.tags,
    publicationDate: payload.post.publicationDate,
    naooFeaturedVideo: payload.post.naooFeaturedVideo,
  }

  if (payload.post.galleryItems) {
    const media = await Promise.all(sendMedia(payload.post.galleryItems, payload.post.userID))
    console.log('media', media)
    data.mediaIds = media.map((item) => item.id)
  }
  const response = await updatePost(data, payload.id)
  return response.data
})

export const deletePostThunk = createAsyncThunk('posts/deletePost', async (payload) => {
  await deletePost(payload)
  return payload
})

export const creteLikeThunk = createAsyncThunk('posts/creteLikeThunk', async (payload) => {
  await createLike(payload)
})

export const deleteLikeThunk = createAsyncThunk('posts/deleteLikeThunk', async (payload) => {
  await deleteLike(payload)
})

export const getPostStatsThunk = createAsyncThunk('posts/getPostStatsThunk', async (payload) => {
  const response = await getPostStats({
    ...payload,
  })
  return response.data
})

export const createDeepLink = createAsyncThunk('posts/createDeepLink', async (payload, { getState }) => {
  const { posts } = getState()
  if (posts.deepLink) return { shortLink: posts.deepLink }
  const response = await createPostDeepLink(`https://naoo.com/posts/${payload.postId}`)
  return response.data
})

export const fetchComments = createAsyncThunk('posts/fetchComments', async (payload) => {
  const response = await getComments({
    id: payload.id,
  })
  return response.data
})

export const fetchCommentsByNextToken = createAsyncThunk('posts/fetchCommentsByNextToken', async (payload) => {
  const response = await getComments({
    id: payload.id,
    nextToken: payload.nextToken,
  })
  return response.data
})

export const deleteCommentThunk = createAsyncThunk('comments/deleteCommentThunk', async (payload) => {
  await deleteComment(payload)
  return payload
})

const initialState = {
  posts: [],
  featuredPosts: [],
  postsWithVideo: [],
  nextTokenFeatured: '',
  nextTokenWithVideo: '',
  fetchingFeatured: false,
  fetchingWithVideo: false,
  nextTokenComments: '',
  fetchingComments: false,
  activePost: null,
  isSendImg: false,
  status: null,
  nextToken: '',
  fetching: false,
  postStats: [],
  isAdminDashboard: true,
  sharedPosts: [],
  deepLink: '',
  comments: [],
}

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setActivePost: (state, action) => {
      state.activePost = action.payload
    },
    setIsSendImg: (state, action) => {
      state.isSendImg = action.payload
    },
    setFetching: (state, action) => {
      state.fetching = action.payload
    },
    setFetchingFeatured: (state, action) => {
      state.fetchingFeatured = action.payload
    },
    setFetchingWithVideo: (state, action) => {
      state.fetchingWithVideo = action.payload
    },
    setFetchingWithMediaModerationLabels: (state, action) => {
      state.fetchingWithMediaModerationLabels = action.payload
    },
    setFetchingComments: (state, action) => {
      state.fetchingFeatured = action.payload
    },
    addUserRestrictionInPosts: (state, action) => {
      const { userId, restrictionType } = action.payload
      state.posts = addUserRestrictionById(state.posts, userId, restrictionType)
    },
    removeUserRestrictionInPosts: (state, action) => {
      const { userId, restrictionType } = action.payload
      state.posts = removeUserRestrictionById(state.posts, userId, restrictionType)
    },
    addUserShadowBanInPosts: (state, action) => {
      const userId = action.payload
      state.posts = setUserShadowBanById(state.posts, userId, true)
    },
    removeUserShadowBanInPosts: (state, action) => {
      const userId = action.payload
      state.posts = setUserShadowBanById(state.posts, userId, false)
    },
    setIsViewed: (state, action) => {
      state.posts.find((it) => it.id === action.payload).isViewed = true
    },
    setIsViewedFeatured: (state, action) => {
      state.featuredPosts.find((it) => it.id === action.payload).isViewed = true
    },
    setIsViewedWithVideo: (state, action) => {
      state.postsWithVideo.find((it) => it.id === action.payload).isViewed = true
    },
    setIsViewedWithMediaModerationLabels: (state, action) => {
      state.postsWithMediaModerationLabels.find((it) => it.id === action.payload).isViewed = true
    },
  },
  extraReducers: (builder) => {
    // fetch posts
    builder.addCase(fetchPosts.pending, (state) => {
      state.status = 'loading'
      state.fetching = true
    })
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.posts = action.payload.posts?.map((it) => ({ ...it, isViewed: false })) ?? []
      state.status = null
      state.nextToken = action.payload.nextToken
      state.fetching = false
    })
    builder.addCase(fetchPosts.rejected, (state) => {
      state.status = 'error'
      state.fetching = false
    })
    builder.addCase(fetchPostsByNextToken.fulfilled, (state, action) => {
      state.posts.push(...(action.payload.posts?.map((it) => ({ ...it, isViewed: false })) ?? []))
      state.nextToken = action.payload.nextToken
      state.fetching = false
    })
    // fetch featured posts
    builder.addCase(fetchFeaturedPosts.pending, (state) => {
      state.status = 'loading'
      state.fetchingFeatured = true
    })
    builder.addCase(fetchFeaturedPosts.fulfilled, (state, action) => {
      state.featuredPosts = action.payload.posts?.map((it) => ({ ...it, isViewed: false })) ?? []
      state.status = null
      state.nextTokenFeatured = action.payload.nextToken
      state.fetchingFeatured = false
    })
    builder.addCase(fetchFeaturedPosts.rejected, (state) => {
      state.status = 'error'
      state.fetchingFeatured = false
    })
    builder.addCase(fetchPostsWithVideo.pending, (state) => {
      state.status = 'loading'
      state.fetchingWithVideo = true
    })
    builder.addCase(fetchFeaturedPostsByNextToken.fulfilled, (state, action) => {
      state.featuredPosts.push(...(action.payload.posts?.map((it) => ({ ...it, isViewed: false })) ?? []))
      state.nextTokenFeatured = action.payload.nextToken
      state.fetchingFeatured = false
    })
    // fetch posts with video
    builder.addCase(fetchPostsWithVideo.fulfilled, (state, action) => {
      state.status = null
      state.postsWithVideo = action.payload.posts?.map((it) => ({ ...it, isViewed: false })) ?? []
      state.nextTokenWithVideo = action.payload.nextToken
      state.fetchingWithVideo = false
    })
    builder.addCase(fetchPostsWithVideo.rejected, (state, action) => {
      state.status = 'error'
      state.fetchingWithVideo = false
    })
    builder.addCase(fetchPostsWithVideoByNextToken.pending, (state) => {
      state.status = 'loading'
      state.fetchingWithVideo = true
    })
    builder.addCase(fetchPostsWithVideoByNextToken.fulfilled, (state, action) => {
      state.status = null
      state.postsWithVideo.push(...(action.payload.posts?.map((it) => ({ ...it, isViewed: false })) ?? []))
      state.nextTokenWithVideo = action.payload.nextToken
      state.fetchingWithVideo = false
    })
    builder.addCase(fetchPostsWithVideoByNextToken.rejected, (state, action) => {
      state.status = 'error'
      state.fetchingWithVideo = false
    })
    // fetch posts with media moderation labels
    builder.addCase(fetchPostsWithMediaModerationLabels.pending, (state) => {
      state.status = 'loading'
      state.fetchingWithMediaModerationLabels = true
    })
    builder.addCase(fetchPostsWithMediaModerationLabels.fulfilled, (state, action) => {
      state.status = null
      state.postsWithMediaModerationLabels = action.payload.posts?.map((it) => ({ ...it, isViewed: false })) ?? []
      state.nextTokenWithMediaModerationLabels = action.payload.nextToken
      state.fetchingWithMediaModerationLabels = false
    })
    builder.addCase(fetchPostsWithMediaModerationLabels.rejected, (state, action) => {
      state.status = 'error'
      state.fetchingWithMediaModerationLabels = false
    })
    builder.addCase(fetchPostsWithMediaModerationLabelsByNextToken.pending, (state) => {
      state.status = 'loading'
      state.fetchingWithMediaModerationLabels = true
    })
    builder.addCase(fetchPostsWithMediaModerationLabelsByNextToken.fulfilled, (state, action) => {
      state.status = null
      state.postsWithMediaModerationLabels.push(...(action.payload.posts?.map((it) => ({ ...it, isViewed: false })) ?? []))
      state.nextTokenWithMediaModerationLabels = action.payload.nextToken
      state.fetchingWithMediaModerationLabels = false
    })
    builder.addCase(fetchPostsWithMediaModerationLabelsByNextToken.rejected, (state, action) => {
      state.status = 'error'
      state.fetchingWithMediaModerationLabels = false
    })
    // thunks
    builder.addCase(createPostThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(createPostThunk.fulfilled, (state, action) => {
      state.posts.unshift(action.payload)
      state.status = 'successfully'
    })
    builder.addCase(createPostThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(updatePostThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(updatePostThunk.fulfilled, (state, action) => {
      state.posts = state.posts.map((post) => {
        if (post.id === action.payload.id) return action.payload
        return post
      })

      if (action.payload.naooFeaturedVideo) {
        state.featuredPosts.push(action.payload)
      } else {
        state.featuredPosts = state.featuredPosts.filter((post) => post.id !== action.payload.id)
      }
      state.activePost = action.payload
      state.status = 'successfully'
    })
    builder.addCase(updatePostThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(deletePostThunk.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(deletePostThunk.fulfilled, (state, action) => {
      state.posts = state.posts.filter((item) => item.id !== action.payload)
      state.featuredPosts = state.featuredPosts.filter((item) => item.id !== action.payload)
      state.status = 'successfully'
    })
    builder.addCase(deletePostThunk.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(creteLikeThunk.fulfilled, (state, action) => {
      state.activePost.userLiked = true
      state.activePost.likesCount++
    })
    builder.addCase(deleteLikeThunk.fulfilled, (state, action) => {
      state.activePost.userLiked = false
      state.activePost.likesCount--
    })
    builder.addCase(getPostStatsThunk.fulfilled, (state, action) => {
      state.postStats = action.payload.reverse().map((item) => {
        item.labelDate = format(new Date(item.startDate), 'dd MMM')
        return item
      })
    })
    builder.addCase(createDeepLink.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(createDeepLink.fulfilled, (state, action) => {
      state.deepLink = action.payload.shortLink
      state.status = 'successfully'
    })
    builder.addCase(createDeepLink.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(fetchComments.pending, (state, action) => {
      state.status = 'loading'
    })
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      state.activePost.comments = action.payload.comments || []
      state.nextTokenComments = action.payload.nextToken
      state.status = null
    })
    builder.addCase(fetchComments.rejected, (state, action) => {
      state.status = 'error'
    })
    builder.addCase(fetchCommentsByNextToken.fulfilled, (state, action) => {
      if (action?.payload?.comments) {
        state.activePost.comments.push(...action.payload.comments)
      }
      state.nextTokenComments = action.payload.nextToken
    })
    builder.addCase(deleteCommentThunk.fulfilled, (state, action) => {
      state.activePost.comments = state.activePost.comments.filter((comment) => comment.id !== action.payload.cid)
    })
  },
})

export default postsSlice.reducer
export const {
  setStatus,
  setActivePost,
  setIsSendImg,
  setFetching,
  setFetchingFeatured,
  setFetchingWithVideo,
  setFetchingWithMediaModerationLabels,
  removeUserShadowBanInPosts,
  addUserShadowBanInPosts,
  removeUserRestrictionInPosts,
  addUserRestrictionInPosts,
  setFetchingComments,
  setIsViewed,
  setIsViewedFeatured,
  setIsViewedWithVideo,
  setIsViewedWithMediaModerationLabels,
} = postsSlice.actions

export const selectStatus = (state) => state.posts.status
export const selectPosts = (state) => state.posts.posts
export const selectFeaturedPosts = (state) => state.posts.featuredPosts
export const selectPostsWithVideo = (state) => state.posts.postsWithVideo
export const selectPostsWithMediaModerationLabels = (state) => state.posts.postsWithMediaModerationLabels
export const selectShares = (state, search) => state.posts.sharedPosts

export const selectActivePost = (state) => state.posts.activePost
export const selectNextToken = (state) => state.posts.nextToken
export const selectFetching = (state) => state.posts.fetching

export const selectNextTokenFeatured = (state) => state.posts.nextTokenFeatured
export const selectNextTokenWithVideo = (state) => state.posts.nextTokenWithVideo
export const selectNextTokenWithMediaModerationLabels = (state) => state.posts.nextTokenWithMediaModerationLabels

export const selectFetchingFeatured = (state) => state.posts.fetchingFeatured
export const selectFetchingWithVideo = (state) => state.posts.fetchingWithVideo
export const selectFetchingWithMediaModerationLabels = (state) => state.posts.fetchingWithMediaModerationLabels

export const selectPostStats = (state) => state.posts.postStats
export const selectIsAdmin = (state) => state.posts.isAdminDashboard
export const selectDeepLink = (state) => state.posts.deepLink
export const selectComments = (state) => state.posts.activePost?.comments
export const selectFetchingComments = (state) => state.posts.fetchingComments
export const selectNextTokenComments = (state) => state.posts.nextTokenComments
