import {createAsyncThunk, createSlice, unwrapResult} from '@reduxjs/toolkit'
import {sendImage} from '../../submodules/naoo-web-components/Shared/utility/sendImage'
import {fetchOffers, fetchOffersByNextToken} from './offersReducer'
import {
    deleteBusiness,
    getBusinessById,
    getBusinesses,
    getCategories,
    updateBusiness
} from "../../api/businessApi";
import {createMediaPresignedUrl, getMedia} from "../../api/mediaApi";
import {searchParamsSelector} from "../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";

export const fetchBusinesses = createAsyncThunk(
    'businesses/fetchBusinesses',
    async (payload, {getState}) => {
        const state = getState()
        const {searchString} = searchParamsSelector(state)
        const response = await getBusinesses({
            q: searchString
        })
        return response.data
    })

export const fetchBusinessesByNextToken = createAsyncThunk(
    'businesses/fetchBusinessesByNextToken',
    async (payload, {getState}) => {
        const state = getState()
        const {searchString} = searchParamsSelector(state)
        const response = await getBusinesses({
            q: searchString,
            nextToken: payload?.nextToken,
        })
        return response.data
    })

export const getBusiness = createAsyncThunk('businesses/getBusiness', async (payload, {getState, dispatch}) => {
    const fetchOffersAndVouches = (businessId) => {
        dispatch(fetchOffers({businessId}))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                if (originalPromiseResult.nextToken) {
                    dispatch(
                        fetchOffersByNextToken({
                            nextToken: originalPromiseResult.nextToken,
                        })
                    )
                }
            })
        // dispatch(fetchVouchers({ businessId }))
        // 	.then(unwrapResult)
        // 	.then((originalPromiseResult) => {
        // 		if (originalPromiseResult.nextToken) {
        // 			dispatch(
        // 				fetchVouchersByNextToken({
        // 					nextToken: originalPromiseResult.nextToken,
        // 				})
        // 			)
        // 		}
        // 	})
    }

    const {businesses} = getState()
    let businessId
    if (!businesses.activeBusiness) {
        const response = await getBusinessById(payload)
        const data = response.data
        businessId = data.id
        fetchOffersAndVouches(businessId)
        return data
    }
    if (!businessId) {
        businessId = businesses.activeBusiness.id
    }
    fetchOffersAndVouches(businessId)
    return businesses.activeBusiness
})

export const updateBusinessThunk = createAsyncThunk('businesses/updateBusinessThunk', async (payload) => {
    let business = payload.business
    const deleteIdAndUrl = ({mediaId, mediaUrl, ...rest}) => rest
    if (payload.business.logo) {
        const logo = payload.business?.logo?.id
            ? payload.business.logo
            : await sendImage(payload.business.logo, createMediaPresignedUrl, getMedia)
        business.logoMediaId = deleteIdAndUrl(logo).id
    }
    if (payload.business.cover) {
        const cover = payload.business?.cover?.id
            ? payload.business.cover
            : await sendImage(payload.business.cover, createMediaPresignedUrl, getMedia)
        business.coverMediaId = deleteIdAndUrl(cover).id
    }
    business.businessCategoryIds = business.businessCategories?.map(category => category.id)
    const businessWithoutMedia = ({profileMedia, ...rest}) => rest
    const response = await updateBusiness(
        {
            ...businessWithoutMedia(payload.business),
        },
        payload?.businessId
    )
    return response.data
})

export const fetchCategories = createAsyncThunk('businesses/fetchCategories', async (payload, {getState}) => {
    const categories = getState().businesses.categories
    if (categories.length === 0) {
        const response = await getCategories()
        return response.data
    }
    return categories
})

export const addProfileMediaThunk = createAsyncThunk(
    'businesses/addProfileMediaThunk',
    async ({businessId, urlList, mediaIds}) => {
        const mediaIdList = []
        for (const url of urlList) {
            const {mediaId} = await sendImage(url, createMediaPresignedUrl, getMedia)
            mediaIdList.push(mediaId)
        }
        if (mediaIds) {
            mediaIdList.push(...mediaIds)
        }
        const response = await updateBusiness({
            profileMediaIds: mediaIdList
        }, businessId)
        return response.data
    }
)

export const deleteProfileMediaThunk = createAsyncThunk(
    'businesses/deleteProfileMediaThunk',
    async ({businessId, mediaId}, {getState}) => {
        const {activeBusiness: {profileMedia}} = getState().businesses
        const response = await updateBusiness({
            profileMediaIds: profileMedia.filter(item => item.id !== mediaId).map(item => item.id)
        }, businessId)
        return response.data
    }
)

export const deleteBusinessThunk = createAsyncThunk('business/deleteBusinessThunk', async (payload) => {
    await deleteBusiness(payload)
    return payload
})

// export const createBusinessShadowBanThunk = createAsyncThunk(
// 	'business/createBusinessShadowBanThunk',
// 	async (payload) => {
// 		await createBusinessShadowBan(payload)
// 		return payload
// 	}
// )
//
// export const deleteBusinessShadowBanThunk = createAsyncThunk(
// 	'business/deleteBusinessShadowBanThunk',
// 	async (payload) => {
// 		await deleteBusinessShadowBan(payload)
// 		return payload
// 	}
// )

const initialState = {
    businesses: [],
    activeBusiness: null,
    offers: [],
    status: null,
    categories: [],
    activeTab: 'Profile',
    nextToken: '',
    fetching: false,
}

const businessesSlice = createSlice({
    name: 'businesses',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.activeBusiness.name = action.payload
        },
        addCategory: (state, action) => {
            //delete action.payload.isSelected
            state.activeBusiness.businessCategories.push(action.payload)
            state.categories.find((item) => item.id === action.payload.id).isSelected = true
        },
        removeCategory: (state, action) => {
            state.activeBusiness.businessCategories = state.activeBusiness.businessCategories.filter(
                (item) => item.id !== action.payload.id
            )
            state.categories.find((item) => item.id === action.payload.id).isSelected = false
        },
        setDescription: (state, action) => {
            state.activeBusiness.description = action.payload
        },
        setVerify: (state, action) => {
            state.activeBusiness.verify = action.payload
        },
        setWebsite: (state, action) => {
            state.activeBusiness.website = action.payload
        },
        setPhone: (state, action) => {
            state.activeBusiness.phone = action.payload
        },
        setEmail: (state, action) => {
            state.activeBusiness.email = action.payload
        },
        setLogo: (state, action) => {
            state.activeBusiness.logo = action.payload
        },
        setLogoDimensions: (state, action) => {
            state.activeBusiness.logoDimensions = action.payload
        },
        setCover: (state, action) => {
            state.activeBusiness.cover = action.payload
        },
        setProfileMedia: (state, action) => {
            state.activeBusiness.profileMedia = action.payload
        },
        addProfileMedia: (state, action) => {
            action.payload.forEach((item) => {
                state.activeBusiness.profileMedia.push(item)
            })
        },
        deleteProfileMedia: (state, action) => {
            console.log(action.payload)
            state.activeBusiness.profileMedia = state.activeBusiness.profileMedia.filter((item) => {
                if (action.payload?.media) {
                    return item.id !== action.payload
                }
                return item !== action.payload
            })
        },
        setAddress: (state, action) => {
            state.activeBusiness.address = action.payload
        },
        setCoordinates: (state, action) => {
            state.activeBusiness.coordinates = action.payload
        },
        setHours: (state, action) => {
            state.activeBusiness.customHours = action.payload.customHours
        },

        setActiveBusiness: (state, action) => {
            state.activeBusiness = state.businesses.find((item) => item.id === action.payload)
            state.activeBusiness.customHours = state.activeBusiness.hours
            if (!state.activeBusiness.businessCategories) {
                state.activeBusiness.businessCategories = []
            }
            if (state.activeBusiness && state.categories.length !== 0) {
                state.activeBusiness.businessCategories.forEach((item) => {
                    const category = state.categories.find((category) => category.id === item.id)
                    category.isSelected = true
                })
            }
            if (!state.activeBusiness.profileMedia) {
                state.activeBusiness.profileMedia = []
            }
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setFetching: (state, action) => {
            state.fetching = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBusinesses.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchBusinesses.fulfilled, (state, action) => {
            state.status = 'successfully'
            state.businesses = action.payload.businesses
            state.nextToken = action.payload.nextToken
        })
        builder.addCase(fetchBusinesses.rejected, (state) => {
            state.status = 'error'
        })
        builder.addCase(fetchBusinessesByNextToken.fulfilled, (state, action) => {
            if (action?.payload?.businesses) {
                state.businesses.push(...action.payload.businesses)
            }
            state.nextToken = action.payload.nextToken
            state.fetching = false
        })
        builder.addCase(updateBusinessThunk.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(updateBusinessThunk.fulfilled, (state, action) => {
            state.status = 'successfully'
            state.businesses = state.businesses.map((business) => {
                if (business.id === action.payload.id) return action.payload
                return business
            })
        })
        builder.addCase(updateBusinessThunk.rejected, (state) => {
            state.status = 'error'
        })
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.categories = action.payload.map((item) => {
                return {
                    ...item,
                    isSelected: false,
                }
            })
        })
        builder.addCase(addProfileMediaThunk.fulfilled, (state, action) => {
            state.activeBusiness = action.payload
        })
        builder.addCase(deleteProfileMediaThunk.fulfilled, (state, action) => {
            state.activeBusiness = action.payload
        })
        builder.addCase(getBusiness.fulfilled, (state, action) => {
            state.activeBusiness = action.payload
            if (action.payload.coordinates) {
                state.activeBusiness.coordinates = [action.payload.coordinates[1], action.payload.coordinates[0]]
            }
        })
        builder.addCase(deleteBusinessThunk.fulfilled, (state, action) => {
            state.businesses = state?.businesses?.filter((item) => item.id !== action.payload)
            state.activeBusiness = null
            state.offers = []
        })
        // builder.addCase(createBusinessShadowBanThunk.fulfilled, (state, action) => {
        // 	state.businesses.find((business) => business.id === action.payload).shadowBanned = true
        // })
        // builder.addCase(deleteBusinessShadowBanThunk.fulfilled, (state, action) => {
        // 	state.businesses.find((business) => business.id === action.payload).shadowBanned = false
        // })
    },
})

export default businessesSlice.reducer
export const {
    setStatus,
    setActiveBusiness,
    addCategory,
    addProfileMedia,
    deleteProfileMedia,
    removeCategory,
    setAddress,
    setCoordinates,
    setCover,
    setDescription,
    setEmail,
    setHours,
    setLogo,
    setLogoDimensions,
    setName,
    setPhone,
    setProfileMedia,
    setVerify,
    setWebsite,
    setActiveTab,
    setFetching,
} = businessesSlice.actions

export const selectStatus = (state) => state.businesses.status
export const selectBusinesses = (state) => state.businesses.businesses
export const selectBusiness = (state) => state.businesses.activeBusiness
export const selectCategories = (state) => state.businesses.categories
export const selectActiveTab = (state) => state.businesses.activeTab
export const selectNextToken = (state) => state.businesses.nextToken
export const selectFetching = (state) => state.businesses.fetching
