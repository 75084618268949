import React from 'react';
import classes from "../Notification.module.scss";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import Section from "../../../../Components/FormComponents/Section/Section";

const TitleAndText = ({title, setTitle, description, setDescription }) => {
    return (
        <Section title={'Title & Text'}>
            <Input
                className={classes.Input}
                label={'Title'}
                name={'title'}
                maxLength={48}
                placeholder={'Your notification title '}
                value={title}
                onChange={(event) => {
                    setTitle(event.target.value)
                }}
            />

            <div className={classes.DescriptionContainer}>
                <span className={`${classes.DescriptionLabel} ${classes.Label}`}>Text</span>
                <textarea
                    name={'text'}
                    className={classes.DescriptionInput}
                    placeholder="Your notification text"
                    value={description}
                    maxLength={512}
                    onChange={(event) => {
                        setDescription(event.target.value)
                    }}
                />
                <span className={classes.DescriptionLength}>{description.length}/512</span>
            </div>
        </Section>
    );
};

export default TitleAndText;
