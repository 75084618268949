import React from 'react';
import {useMentionData} from "../../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";
import classes from './Comment.module.scss'
import Avatar from "../../../../../submodules/naoo-web-components/Components/Avatar/Avatar";
import MoreDropdown from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {
    hideConfirmationPopup,
    removeDeletedReportFromList,
    setPopupState
} from "../../../../../store/reducers/reportsReducer";
import {useDispatch} from "react-redux";
import UserMoreItems from "../../../../Users/UserMoreItems/UserMoreItems";
import {setActiveUser} from "../../../../../store/reducers/usersReducer";
import {useNavigate} from "react-router-dom";
import {formatDistanceToNow} from "date-fns";
import {deleteCommentThunk} from "../../../../../store/reducers/postsReducer";

const Comment = ({comment, postId, MoreDropdownComponent}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {getTextWithMentions} = useMentionData(comment.text, comment.mentions)

    const avatar = comment?.business
        ? <Avatar
            className={classes.Logo}
            img={comment.business?.logo?.url}
            name={comment.business.name}
        />
        : <Avatar
            className={classes.Logo}
            img={comment?.user?.avatar?.url}
            name={comment?.user?.firstname}
            lastname={comment?.user?.lastname}
        />

    const handleDeleteCommentClick = () => {
        dispatch(setPopupState({
            isOpen: true,
            title: `Are you sure you want to delete comment?`,
            primaryButton: 'Yes, delete comment',
            handlePrimaryClick: () => {
                dispatch(deleteCommentThunk({ pid:postId, cid:comment.id }))
                    .then((response)=>{
                        if (response.meta.requestStatus === 'fulfilled' ) {
                            if (comment.isReported){
                                navigate(-1)
                                dispatch(removeDeletedReportFromList())
                            }
                            dispatch(hideConfirmationPopup())
                        }
                    })
            }
        }))
    }

    const handleMoreIconClick = () => {
        dispatch(setActiveUser(comment.user))
    }

    return (
        <div className={`${classes.Comment} ${comment?.isReported ? classes.Reported : ''}`}>
            {avatar}
            <div className={classes.Text}>
                <div className={classes.UserNameAndInfo}>
                    <div className={classes.Name}>
                        <span>
                            {
                                comment?.business
                                    ? comment.business.name
                                    : `${comment?.user?.firstname} ${comment?.user?.lastname}`
                            }
                        </span>
                    </div>
                    <div className={classes.Time}>
                        <span>{formatDistanceToNow(new Date(comment.updatedAt || comment?.createdAt), {addSuffix: true})}</span>
                    </div>
                    <div className={classes.More} onClickCapture={handleMoreIconClick}>
                        {
                            MoreDropdownComponent
                            ?? (
                                    <MoreDropdown>
                                        <MoreItem text={'Delete comment'} danger onClick={handleDeleteCommentClick}/>
                                        <UserMoreItems/>
                                    </MoreDropdown>
                                )
                        }

                    </div>
                </div>
                <div className={classes.Text}>
                    <span>{getTextWithMentions()}</span>
                </div>
                <div className={classes.Replies}>
                    {
                        comment?.replies?.map((reply)=>(
                            <Comment key={reply.id} comment={reply}/>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Comment;