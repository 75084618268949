import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { OFFER_STATUS } from '../../submodules/naoo-web-components/Shared/constants'
import { sendImage } from '../../submodules/naoo-web-components/Shared/utility/sendImage'
import { format } from 'date-fns'
import {createOffer, deleteOffer, getOfferById, getOffers, getOfferStats, updateOffer} from "../../api/offerApi";
import {createMediaPresignedUrl, getMedia} from "../../api/mediaApi";
const initialState = {
	offers: [],
	activeOffer: null,
	media: null,
	status: null,
	nextToken: '',
	fetching: false,
	stats: [],
}

export const getOffer = createAsyncThunk('offers/getOffer', async (payload, { getState }) => {
	const { offers } = getState()
	if (offers.activeOffer) return offers.activeOffer
	const response = await getOfferById(payload)
	return response.data
})

export const fetchOffers = createAsyncThunk('offers/fetchOffers', async (payload) => {
	const response = await getOffers({
		businessId: payload?.businessId,
	})
	return response.data
})

export const fetchOffersByNextToken = createAsyncThunk('offers/fetchOffersByNextToken', async (payload) => {
	const response = await getOffers({
		nextToken: payload?.nextToken,
	})
	return response.data
})

export const updateOfferThunk = createAsyncThunk('offers/updateOffer', async (payload) => {
	let offer = payload.offer
	const deleteIdAndUrl = ({ mediaId, mediaUrl, ...rest }) => rest
	if (payload.offer.media) {
		let logo = payload.offer?.media?.id
			? payload.offer.media
			: await sendImage(payload.offer.media, createMediaPresignedUrl, getMedia, offer.userId)
		if (logo.mediaId) logo = deleteIdAndUrl(logo)

		offer.mediaIds = [logo.id]
	}
	const response = await updateOffer(payload?.offer, payload?.offerId)
	return response.data
})

export const createOfferThunk = createAsyncThunk('offers/createOffer', async (payload) => {
	const offer = payload
	let response
	if (offer.media) {
		const deleteIdAndUrl = ({ mediaId, mediaUrl, ...rest }) => rest
		const logo = await sendImage(payload.media, createMediaPresignedUrl, getMedia)
		offer.media = [deleteIdAndUrl(logo)]
		response = await createOffer(payload)
	} else {
		const deleteMedia = ({ media, ...rest }) => rest
		response = await createOffer(deleteMedia(offer))
	}
	return response.data
})

export const deleteOfferThunk = createAsyncThunk('offers/deleteOffer', async (payload) => {
	await deleteOffer(payload)
	return payload
})

export const getOfferStatsThunk = createAsyncThunk('offers/getOfferStatsThunk', async (payload) => {
	const response = await getOfferStats({ ...payload })
	return response.data
})

const offersSlice = createSlice({
	name: 'offers',
	initialState,
	reducers: {
		setActiveOffer: (state, action) => {
			const offer = state.offers.find((item) => item.id === action.payload)
			if (!offer) {
				state.activeOffer = {
					title: '',
					description: '',
					decimals: 2,
					claimsLimit: 0,
					isAdminDashboard: true,
				}
				state.media = null
				return
			}
			state.activeOffer = { ...offer }
			state.activeOffer.customHours = offer.hours
			state.media = offer?.media?.[0]
			// state.activeOffer.discountedPrice =
			// 	(state.activeOffer.price - state.activeOffer.discount) / 10 ** state.activeOffer.decimals
			// state.activeOffer.price = state.activeOffer.price / 10 ** state.activeOffer.decimals
			state.activeOffer.isAdminDashboard = true
			state.status = null
		},
		resetActivePoll: (state) => {
			state.activePoll = null
		},
		setStatus: (state, action) => {
			state.status = action.payload
		},
		updateOfferData: (state, action) => {
			if (action.payload.businessId !== undefined) {
				state.activeOffer.businessId = action.payload.businessId
			}
			if (action.payload.title !== undefined) {
				state.activeOffer.title = action.payload.title
			}
			if (action.payload.text !== undefined) {
				state.activeOffer.text = action.payload.text
			}
			if (action.payload.media !== undefined) {
				state.activeOffer.media = action.payload.media
			}
			if (action.payload.price !== undefined) {
				state.activeOffer.price = action.payload.price
			}
			if (action.payload.discountedPrice !== undefined) {
				state.activeOffer.discountedPrice = action.payload.discountedPrice
			}
			if (action.payload.discount !== undefined) {
				state.activeOffer.discount = action.payload.discount
			}
			if (action.payload.currency !== undefined) {
				state.activeOffer.currency = action.payload.currency
			}
			if (action.payload.decimals !== undefined) {
				state.activeOffer.decimals = action.payload.decimals
			}
			if (action.payload.startTime !== undefined) {
				state.activeOffer.startTime = action.payload.startTime
			}
			if (action.payload.endTime !== undefined) {
				state.activeOffer.endTime = action.payload.endTime
			}
			if (action.payload.tags !== undefined) {
				state.activeOffer.tags = action.payload.tags
			}
			if (action.payload.points !== undefined) {
				state.activeOffer.points = action.payload.points
			}
			if (action.payload.claimsLimit !== undefined) {
				state.activeOffer.claimsLimit = action.payload.claimsLimit
			}
			if (action.payload.claimsPerUserLimit !== undefined) {
				state.activeOffer.claimsPerUserLimit = action.payload.claimsPerUserLimit
			}
			if (action.payload.canBeClaimedAtLocation !== undefined) {
				state.activeOffer.canBeClaimedAtLocation = action.payload.canBeClaimedAtLocation
			}
			if (action.payload.address !== undefined) {
				state.activeOffer.address = action.payload.address
			}
			if (action.payload.coordinates !== undefined) {
				state.activeOffer.coordinates = action.payload.coordinates
			}
			if (action.payload.hours !== undefined) {
				state.activeOffer.hours = action.payload.hours
				state.activeOffer.customHours = action.payload.hours
			}
			if (action.payload.isActive !== undefined) {
				state.activeOffer.isActive = action.payload.isActive
			}
			if (action.payload.customHours !== undefined) {
				state.activeOffer.customHours = action.payload.customHours
			}
			state.status = OFFER_STATUS.CHANGED
		},
		updateMedia: (state, action) => {
			state.media = action.payload.media
			state.status = OFFER_STATUS.CHANGED
		},
		setFetching: (state, action) => {
			state.fetching = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchOffers.fulfilled, (state, action) => {
			state.offers = action.payload.offers || []
			state.nextToken = action.payload.nextToken
		})
		builder.addCase(fetchOffersByNextToken.fulfilled, (state, action) => {
			state?.offers?.push(...action.payload?.offers)
			state.nextToken = action.payload.nextToken
			state.fetching = false
		})
		builder.addCase(updateOfferThunk.pending, (state) => {
			state.status = OFFER_STATUS.LOADING
		})
		builder.addCase(updateOfferThunk.fulfilled, (state, action) => {
			state.offers = state.offers.map((offer) => {
				if (offer.id === action.payload.id) return action.payload
				return offer
			})
			state.status = OFFER_STATUS.SUCCESSED
		})
		builder.addCase(updateOfferThunk.rejected, (state) => {
			state.status = OFFER_STATUS.FAILED
		})
		builder.addCase(getOffer.fulfilled, (state, action) => {
			state.activeOffer = action.payload
			state.media = action.payload.media?.[0]
			state.activeOffer.customHours = action.payload.hours
			state.activeOffer.discountedPrice = action.payload.price - action.payload.discount
			state.activeOffer.coordinates = [action.payload.coordinates[1], action.payload.coordinates[0]]
			state.activeOffer.isAdminDashboard = true
		})
		builder.addCase(createOfferThunk.pending, (state) => {
			state.status = OFFER_STATUS.LOADING
		})
		builder.addCase(createOfferThunk.fulfilled, (state, action) => {
			state.offers.unshift(action.payload)
			state.status = OFFER_STATUS.SUCCESSED
		})
		builder.addCase(createOfferThunk.rejected, (state) => {
			state.status = OFFER_STATUS.FAILED
		})
		builder.addCase(deleteOfferThunk.pending, (state, action) => {
			state.status = OFFER_STATUS.LOADING
		})
		builder.addCase(deleteOfferThunk.fulfilled, (state, action) => {
			state.status = null
			state.offers = state.offers.filter((item) => item.id !== action.payload)
		})
		builder.addCase(deleteOfferThunk.rejected, (state, action) => {
			state.status = OFFER_STATUS.FAILED
		})
		builder.addCase(getOfferStatsThunk.fulfilled, (state, action) => {
			state.stats = action.payload.reverse().map((item) => {
				item.labelDate = format(new Date(item.startDate), 'dd MMM')
				return item
			})
		})
	},
})

export default offersSlice.reducer
export const { setActiveOffer, resetActivePoll, setStatus, updateOfferData, updateMedia, setFetching } =
	offersSlice.actions

export const selectOffers = (state, limit) => {
	if (limit) {
		return state?.offers?.offers?.slice(0, limit)
	}
	return state.offers.offers
}
export const selectActiveOffer = (state) => state.offers.activeOffer
export const selectStatus = (state) => state.offers.status
export const selectMedia = (state) => state.offers.media
export const selectNextToken = (state) => state.offers.nextToken
export const selectFetching = (state) => state.offers.fetching
export const selectOfferStats = (state) => state.offers.stats
export const selectOffersLength = (state) => state.offers?.offers?.length
