import React from 'react';
import classes from './TabBar.module.scss'

const TabBarNavItem = ({navLabel, className, onChangeActiveTab, disabled}) => {
    const cls = [classes.NavItem,classes.Button, className]

    return (
        <button
            className={cls.join(' ')}
            disabled={disabled}
            onClick={()=>onChangeActiveTab(navLabel)}
        >
            {navLabel}
        </button>
    );
};

export default TabBarNavItem;