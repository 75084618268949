import React, {useEffect, useState} from 'react';
import classes from "./CreateOrUpdateOffer.module.scss";
import spinnerClasses from '../../Spinner.module.scss'
import TopNav from "../../../Components/TopNav/TopNav";
import {useDispatch, useSelector} from "react-redux";
import {
    createOfferThunk,
    getOffer,
    selectActiveOffer,
    selectMedia,
    selectStatus,
    setStatus,
    updateMedia,
    updateOfferData,
    updateOfferThunk
} from "../../../store/reducers/offersReducer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import OfferInfo from "../../../submodules/naoo-web-components/Offer/OfferInfo";
import OfferPricing from "../../../submodules/naoo-web-components/Offer/OfferPricing";
import OfferSettings from "../../../submodules/naoo-web-components/Offer/OfferSettings";
import {FullscreenPreloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import ModalWindow from "./Modal/ModalWindow";
import {OFFER_STATUS, ROUTES} from "../../../submodules/naoo-web-components/Shared/constants";
import {selectBusiness} from "../../../store/reducers/businessesReducer";
import topNavClasses from "../../../Components/TopNav/TopNav.module.scss";
import Dropzone from "../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";
import Input from "../../../submodules/naoo-web-components/Components/Input/Input";
import Container from "../../../Components/FormComponents/Container/Container";
import Section from "../../../Components/FormComponents/Section/Section";
import OfferSchedule from "../../../submodules/naoo-web-components/Offer/OfferSchedule";

const CreateOrUpdateOffer = () => {
    const {offerId} = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const offer = useSelector(selectActiveOffer)
    const media = useSelector(selectMedia)
    const offerStatus = useSelector(selectStatus)
    const business = useSelector(selectBusiness)
    const [isShowModal, setIsShowModal] = useState(false)
    const [prevLocation] = useState(location.state)
    const errors = {
        title: offer?.title?.length === 0,
        description: offer?.text === '',
        media: media === null,
        price: offer?.price === '',
        discountedPrice: +offer?.discountedPrice > +offer?.price,
    }
    const handleErrors = () => {
        for (const error in errors) {
            if (errors[error]) return true
        }
        return false
    }

    useEffect(() => {
        if (offerId) {
            dispatch(getOffer(offerId))
        }
    }, [offerId, dispatch])

    const onBackButtonClickHandler = (event) => {
        dispatch(setStatus(null))
        if (offerStatus === OFFER_STATUS.CHANGED) {
            setIsShowModal(true)
        } else {
            if (prevLocation) {
                navigate(-1)
            } else {
                navigate(ROUTES.ADMIN_DASHBOARD.BUSINESSES, {replace: true})
            }
        }
    }

    const onCloseModalHandler = () => {
        setIsShowModal(false)
        dispatch(setStatus(OFFER_STATUS.CHANGED))
    }

    const onSaveClickHandler = () => {
        const offerData = {
            title: offer.title,
            text: offer.text,
            media,
            startTime: offer.startTime,
            endTime: offer.endTime,
            hours: offer?.customHours?.length !== 0 ? offer.customHours : offer.hours,
            price: +offer.price,
            discount: offer.discount,
            decimals: offer.decimals,
            tagIds: offer?.tags?.map(tag=>tag.id),
            claimsLimit: offer.claimsLimit !== 0 ? offer.claimsLimit : null,
            claimsPerUserLimit: offer.claimsPerUserLimit,
            canBeClaimedAtLocation: offer.canBeClaimedAtLocation,
            coordinates: !!offer.canBeClaimedAtLocation ? [offer.coordinates?.[1], offer.coordinates?.[0]] : null,
            isActive: offer.isActive,
            points: +offer.points,
            userId: offer?.user?.id || 'naoo'
        }
        if (offerId) {
            dispatch(updateOfferThunk({
                offer: offerData,
                offerId: offer.id
            }))
        } else {
            offerData.businessId = business.id
            dispatch(createOfferThunk(offerData))
        }
    }

    useEffect(() => {
        if (offerStatus === OFFER_STATUS.SUCCESSED) {
            dispatch(setStatus(null))
            navigate(-1)
        }
    }, [offerStatus, dispatch, navigate])

    const clearDropzone = () => {
        dispatch(
            updateMedia({
                media: ""
            })
        )
    }

    const handleDropZone = (acceptedFiles) => {
        dispatch(
            updateMedia({
                media: URL.createObjectURL(acceptedFiles[0]),
            })
        )
    }

    const topNavButtons = [
        {
            title: 'Save',
            onClick: onSaveClickHandler,
            isDisabled: handleErrors(),
            className: `${topNavClasses.SuccessButton} ${handleErrors() ? topNavClasses.Disabled : ''}`
        },
    ]

    if (!business) navigate(ROUTES.ADMIN_DASHBOARD.BUSINESSES)
    if (!offer) return null

    return (
        <>
            {
                offerStatus === OFFER_STATUS.LOADING && <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }
            <ModalWindow
                isShow={isShowModal}
                offerTitle={offer.title}
                onClose={onCloseModalHandler}
            />
            <Container>
                <TopNav
                    buttons={topNavButtons}
                    title={offer?.title}
                    onBack={onBackButtonClickHandler}
                />
                <div className={classes.FormContainer}>
                    <div className={classes.MainContainer}>
                        <OfferInfo
                            offer={offer}
                            media={media}
                            updateMedia={updateMedia}
                            updateOffer={updateOfferData}
                        />
                        <OfferSchedule
                            offer={offer}
                            updateOffer={updateOfferData}
                        />

                        <OfferPricing
                            offer={offer}
                            updateOffer={updateOfferData}
                        />
                        <OfferSettings
                            offer={offer}
                            updateOffer={updateOfferData}
                        />
                    </div>
                    <div className={classes.PreviewContainer}>
                        <Section title={'Image preview'}>
                            <Dropzone
                                media={media?.url ?? media}
                                onChange={handleDropZone}
                                className={`${classes.DropZone} `}
                                onClear={clearDropzone}
                            />
                            <Input
                                className={classes.Input}
                                label={'Or enter image URL'}
                                name={'title'}
                                placeholder={'Your image URL'}
                                value={media?.url ? media?.url : media}
                                onChange={(event) => {
                                    dispatch(
                                        updateMedia({
                                            media: event.target.value,
                                        })
                                    )
                                }}
                            />
                        </Section>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default CreateOrUpdateOffer;