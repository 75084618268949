import { withAdmin } from './withAdmin'
import { createRequest } from '../submodules/naoo-web-components/Api/createRequest'

const getCashRedeemRequests = ({ nextToken, status, userId }) => {
  return createRequest(
    'main',
    'get',
    withAdmin('/points_to_cash_redeem_requests'),
    null,
    { nextToken, status, userId },
  )
}

const getCashRedeemRequest = (id) => {
  return createRequest(
    'main',
    'get',
    withAdmin(`/points_to_cash_redeem_requests/${id}`),
    null,
  )
}

const cashRedeemRequestReject = (id) => {
  let url = `/points_to_cash_redeem_requests/${id}/reject`
  return createRequest('main', 'post', withAdmin(url), null)
}
const cashRedeemRequestFulfilled = (id) => {
  let url = `/points_to_cash_redeem_requests/${id}/set_fulfilled`
  return createRequest('main', 'post', withAdmin(url), null)
}
const cashRedeemRequestConfirm = (id) => {
  let url = `/points_to_cash_redeem_requests/${id}/confirm`
  return createRequest('main', 'post', withAdmin(url), null)
}
const getCashRedeemOptions = () => {
  let url = `/points_to_cash_redeem_options`
  return createRequest('main', 'get', withAdmin(url), null)
}
const createCashRedeemOption = (option) => {
  let url = `/points_to_cash_redeem_options`
  return createRequest('main', 'post', withAdmin(url), option)
}
const updateCashRedeemOption = ({ option, id }) => {
  let url = `/points_to_cash_redeem_options/${id}`
  return createRequest('main', 'patch', withAdmin(url), option)
}

export {
  getCashRedeemRequest,
  getCashRedeemRequests,
  cashRedeemRequestReject,
  cashRedeemRequestConfirm,
  cashRedeemRequestFulfilled,
  getCashRedeemOptions,
  createCashRedeemOption,
  updateCashRedeemOption,
}