import React from 'react';
import classes from "./TableWrapper.module.scss";

const TableWrapper = ({className, children}) => {
    return (
        <div className={`${classes.Container} ${className ?? ''}`}>
            {children}
        </div>
    );
};

export default TableWrapper;