import React from 'react';
import classes from "../Poll.module.scss";
import {updatePollThunk} from "../../../../store/reducers/poolsReducer";
import {useDispatch} from "react-redux";
import ModalWindow from "../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";

const UpdatePollModal = (
    {
        poll, options, pollTitle, pollTitleDe, pollTags,
        setIsShowModal, answerMinOptions, answerMaxOptions,
        isOpen,optionIdsToDelete
    }) => {
    const dispatch = useDispatch()
    const handleCloseClick = () => {
        setIsShowModal(false)
    }

    const handleUpdateClick = () => {
        dispatch(updatePollThunk({
            id: poll.id,
            pollObject: {
                text: pollTitle,
                textDe: pollTitleDe,
                tagIds: pollTags.map(item=>item.id),
                optionsToCreate: options.map((option)=> ({
                        text: option.textEn,
                        textDe: option.textDe,
                        tagIds: option.tags?.map(item=>item.id),
                        order: option.order
                    })
                ),
                answerMinOptions: answerMinOptions,
                answerMaxOptions: answerMaxOptions,
                optionIdsToDelete
            }
        }))
    }

    return (
        <ModalWindow
            isOpen={isOpen}
            title={'Are you sure you want to update the question with the new answers?'}
            primaryButton={'Update question'}
            secondaryButton={'Cancel'}
            handlePrimaryClick={handleUpdateClick}
            handleSecondaryClick={handleCloseClick}
            onClose={handleCloseClick}
        >
            <div className={classes.ModalText}>
                It makes all previous data of this question <strong>invalid.</strong>
            </div>
        </ModalWindow>
    );
};

export default UpdatePollModal;
