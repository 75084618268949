import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import {
  cashRedeemRequestConfirmThunk,
  cashRedeemRequestFulfilledThunk,
  cashRedeemRequestRejectThunk,
  fetchCashRedeemOptions,
  fetchCashRedeemRequests,
  fetchCashRedeemRequestsByNextToken,
  selectCashRedeemOptions,
  selectCashRedeemRequests,
  selectFetching,
  selectNextToken,
  selectStatus,
  setActiveOption,
  setActiveRequest,
  setFetching,
} from '../../store/reducers/cashRedeemRequestsReducer'
import AdminSidebar from '../Sidebar/AdminSidebar'
import { Button } from '../../submodules/naoo-web-components/Components/Button/Buttons'
import Status from '../../Components/Status/Status'
import layoutClasses from '../Layout.module.scss'
import classes from './CashRedemptions.module.scss'
import spinnerClasses from '../Spinner.module.scss'
import { FullscreenPreloader } from '../../submodules/naoo-web-components/Components/Preloader/Preloader'
import RedeemOption from './RedeemOption/RedeemOption'
import { ROUTES } from '../../submodules/naoo-web-components/Shared/constants'
import { returnObservableItem } from '../../submodules/naoo-web-components/Shared/utility/utils'
import { useObserver } from '../../submodules/naoo-web-components/Shared/hooks/useObserver'
import TableWrapper from '../../Components/Table/TableWrapper/TableWrapper'
import TableHeader from '../../Components/Table/TableHeader/TableHeader'
import TableItem from '../../Components/Table/TableItem/TableItem'
import { format } from 'date-fns'
import TabBar from '../../submodules/naoo-web-components/Components/TabBar/TabBar'
import TabBarItem from '../../submodules/naoo-web-components/Components/TabBar/TabBarItem'
import { useFetchWithQueryParams } from '../../submodules/naoo-web-components/Shared/hooks/useFetchWithQueryParams'
import SearchInput from '../../Components/SearchInput/SearchInput'
import SearchDropdown from '../../Components/Search/SearchDropdown/SearchDropdown'
import { setSearchParams } from '../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer'
import { useDebounce } from '../../shared/hooks/useDebounce'

const CURRENT_PAGE = 'CashRedemptions'

const CashRedemptions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cashRedeemRequests = useSelector((state) => selectCashRedeemRequests(state))
  const status = useSelector((state) => selectStatus(state))
  const options = useSelector((state) => selectCashRedeemOptions(state))

  const [activeTab, setActiveTab] = useState('Options')
  const [statusFilter, setStatusFilter] = useState('Pending')

  const [element, setElement] = useState(null)
  const nextToken = useSelector((state) => selectNextToken(state))
  const fetching = useSelector((state) => selectFetching(state))
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 1000)

  const LIMIT = 10

  const [params, isReady] = useFetchWithQueryParams({
    initialValue: {
      status: 'pending',
      userId: null,
    },
    currentPage: CURRENT_PAGE,
  })

  useEffect(() => {
    dispatch(fetchCashRedeemOptions())
  }, [dispatch])

  useEffect(() => {
    if (isReady) {
      dispatch(fetchCashRedeemRequests())
    }
  }, [dispatch, isReady, params])

  useEffect(() => {
    dispatch(
      setSearchParams({
        userId: debouncedSearchValue,
      })
    )
  }, [debouncedSearchValue, dispatch])

  useObserver(element, fetching, setFetching, nextToken, fetchCashRedeemRequestsByNextToken)

  const match = useMatch(ROUTES.ADMIN_DASHBOARD.CASH_REDEMPTIONS)

  const handleSidebarButtonChanged = () => {
    dispatch(setActiveOption())
    navigate(ROUTES.ADMIN_DASHBOARD.CASH_REDEMPTIONS + '/options/create')
  }

  const handleStatusFilterChanged = (filter) => {
    setStatusFilter(filter)
    dispatch(
      setSearchParams({
        status: filter ? filter.toLowerCase() : null,
      })
    )
  }

  return (
    <>
      <Outlet />
      {status === 'loading' && (
        <FullscreenPreloader
          spinnerClassName={spinnerClasses.spinnerSize}
          overlayClassName={spinnerClasses.lightBlurPreloader}
        />
      )}
      {match && (
        <div className={layoutClasses.Container}>
          <AdminSidebar>
            <Button onClick={handleSidebarButtonChanged}>New option</Button>
          </AdminSidebar>
          <div className={layoutClasses.Main}>
            <TabBar initialActiveTab={activeTab} navItemClick={(tab) => setActiveTab(tab)}>
              <TabBarItem label={'Options'}>
                <div className={classes.OptionsContainer}>
                  {options.map((option) => (
                    <RedeemOption key={option.id} option={option} logoMediaUrl={option.logo?.url} />
                  ))}
                </div>
              </TabBarItem>
              <TabBarItem label={'Requests'}>
                <SearchDropdown
                  className={classes.SearchContainer}
                  value={statusFilter}
                  options={['Pending', 'Rejected', 'Fulfilled', 'Confirmed']}
                  onChange={handleStatusFilterChanged}
                  placeholder={'Status'}
                  isClearable={true}
                />

                <div className={classes.SearchContainer}>
                  <SearchInput placeholder='User ID...' onChange={setSearchValue} />
                </div>

                <TableWrapper className={classes.RequestsContainer}>
                  <TableHeader className={classes.Header}>
                    <div className={classes.Name}>
                      <span>Name</span>
                    </div>
                    <div className={classes.Created}>
                      <span>Created</span>
                    </div>
                    <div className={classes.RedemtionOption}>
                      <span>Redemtion option</span>
                    </div>
                    <div className={classes.IBAN}>
                      <span>IBAN</span>
                    </div>
                    <div className={classes.Status}>
                      <span>Status</span>
                    </div>
                  </TableHeader>
                  {/*ITEMS*/}
                  {cashRedeemRequests?.map((item, index, array) => (
                    <Fragment key={item.id}>
                      <TableItem
                        className={classes.Item}
                        onClick={() => {
                          dispatch(setActiveRequest(item.id))
                          navigate(`${ROUTES.ADMIN_DASHBOARD.CASH_REDEMPTIONS}/${item.id}`)
                        }}>
                        <div className={classes.Name}>
                          <span>
                            {item?.firstname} {item?.lastname}
                          </span>
                        </div>
                        <div className={classes.Created}>
                          <span>{item?.createdAt ? format(new Date(item?.createdAt), 'dd.MM.yyyy') : '-'}</span>
                        </div>
                        <div className={classes.RedemtionOption}>
                          <span>
                            {item.cash} CHF / {item.points} points
                          </span>
                        </div>
                        <div className={classes.IBAN}>
                          <span>{item?.iban || '–'}</span>
                        </div>
                        <div className={classes.Status}>
                          <Status
                            requestId={item.id}
                            status={item.status}
                            handlers={{
                              reject: cashRedeemRequestRejectThunk,
                              fulfilled: cashRedeemRequestFulfilledThunk,
                              confirm: cashRedeemRequestConfirmThunk,
                            }}
                          />
                        </div>
                      </TableItem>
                      {returnObservableItem(array.length, LIMIT, index, setElement)}
                    </Fragment>
                  ))}
                  {/*ITEMS*/}
                </TableWrapper>
              </TabBarItem>
            </TabBar>
          </div>
        </div>
      )}
    </>
  )
}

export default CashRedemptions
