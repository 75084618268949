import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useMatch, useNavigate} from 'react-router-dom'
import {fetchPolls, selectPolls, setActivePoll} from "../../store/reducers/poolsReducer";
import {selectTags} from "../../store/reducers/tagsReducer";
import Question from "./Question/Question";
import TagsDropdown from "./Tags/TagsDropdown/TagsDropdown";
import AdminSidebar from "../Sidebar/AdminSidebar";
import {ROUTES} from "../../submodules/naoo-web-components/Shared/constants";
import classes from './Polls.module.scss'
import layoutClasses from '../Layout.module.scss'
import SearchDropdown from "../../Components/Search/SearchDropdown/SearchDropdown";
import SearchComponentWrapper from "../../Components/Search/SearchComponentWrapper/SearchComponentWrapper";
import SearchContainer from "../../Components/Search/SearchContainer/SearchContainer";
import SearchInput from "../../Components/SearchInput/SearchInput";
import {Button} from "../../submodules/naoo-web-components/Components/Button/Buttons";

const Polls = () => {
    const [limit] = useState(null)
    const [pollTag, setPollTag] = useState(null)
    const [answerTag, setAnswerTag] = useState(null)
    const [isActiveFilter, setIsActiveFilter] = useState('All polls')
    const [q, setQ] = useState(null)

    const dispatch = useDispatch()
    const polls = useSelector((state) => selectPolls(state, {
            q,
            pollTag,
            answerTag,
            isOnlyActive: isActiveFilter !== 'All polls'
        })
    )
    const tags = useSelector(selectTags)

    const navigate = useNavigate()

    useEffect(() => {
        dispatch(
            fetchPolls({
                limit: limit,
            }))
    }, [dispatch, limit])


    const handlePollTagDropdownChange = useCallback((tag) => {
        setPollTag(tag)
    }, [])

    const handleAnswerTagDropdownChange = useCallback((tag) => {
        setAnswerTag(tag)
    }, [])

    const search = (
        <SearchContainer>
            <div className={classes.SearchInputContainer}>
                <SearchInput
                    name={'pollSearch'}
                    placeholder={'Search polls...'}
                    value={q}
                    onChange={(value) => setQ(value)}
                    onClear={() => setQ('')}
                />
            </div>
            <SearchComponentWrapper>
                <TagsDropdown initialValue={pollTag} options={tags} placeholder={'Poll tag'} addPlaceholder={true}
                              onChange={handlePollTagDropdownChange}/>
            </SearchComponentWrapper>
            <SearchComponentWrapper>
                <TagsDropdown initialValue={answerTag} options={tags} placeholder={'Answer tag'} addPlaceholder={true}
                              onChange={handleAnswerTagDropdownChange}/>
            </SearchComponentWrapper>
            <SearchComponentWrapper>
                <SearchDropdown
                    value={isActiveFilter}
                    options={['All polls', 'Only active']}
                    onChange={(filter) => {
                        setIsActiveFilter(filter)
                    }}
                    placeholder={'Type'}
                    isClearable={true}
                />
            </SearchComponentWrapper>
        </SearchContainer>
    )
    const questions = (
        <>
            {
                polls?.map((poll, index, array) => {
                        return <div className={classes.QuestionContainer} key={poll.id}>
                            <Question
                                title={poll.text}
                                tags={poll.tags}
                                options={poll.options}
                                answersCount={poll?.answersCount}
                                onClick={() => {
                                    dispatch(setActivePoll(poll.id))
                                    navigate(ROUTES.ADMIN_DASHBOARD.POLLS + '/' + poll.id)
                                }}
                                onTagClick={(tag) => {
                                    setPollTag(tag)
                                }}
                            />
                        </div>
                    }
                )
            }
        </>
    )
    const match = useMatch(ROUTES.ADMIN_DASHBOARD.POLLS)

    const handleSidebarButtonChanged = () => {
        navigate(ROUTES.ADMIN_DASHBOARD.POLLS + '/create')
    }

    return (
        <>
            <Outlet/>
            {
                match &&
                <div className={layoutClasses.Container}>
                    <AdminSidebar>
                        <Button onClick={handleSidebarButtonChanged}>New poll</Button>
                    </AdminSidebar>
                    <div className={layoutClasses.Main}>
                        {search}
                        {questions}
                    </div>
                </div>
            }
        </>
    );
};

export default Polls;
