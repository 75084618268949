import React from 'react';
import { HexColorPicker } from "react-colorful";
import classes from './ColorPicker.module.scss'
import Input from "../Input/Input";
import Margin from "../Margin/Margin";

const ColorPicker = ({color, setColor}) => {
    return (
        <>
            <div className={classes.Container}>
                <div className={classes.Label}>
                    <span>Choose color</span>
                </div>
                <HexColorPicker color={color} onChange={setColor}/>
                <Margin top={24}/>
                <Input
                    label={'HEX code'}
                    value={color.toUpperCase()}
                    onChange={(event)=>{setColor(event.target.value.toUpperCase())}}
                />
            </div>
        </>
    );
};

export default ColorPicker;