import React from 'react';
import TopNav from "../../../Components/TopNav/TopNav";
import Preview from "./Preview/Preview";
import TitleAndText from "./TitleAndText/TitleAndText";
import Content from "./Content/Content";
import Main from "./Main/Main";
import {FullscreenPreloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import classes from './Notification.module.scss'
import spinnerClasses from '../../Spinner.module.scss'
import Container from "../../../Components/FormComponents/Container/Container";


const Notification = (
    {
        isLoading, status = null, topNavButtons, onBackClickHandler,
        targetType, users, setTargetType, setUsers, contentType,
        handleUsersDropdown, title, description, setTitle,
        setDescription, contentValue, setContentType,
        handleContentChange, handlePointsChange, img,
        imgUrl, imgLoading, setImgUrl, setImg,
        handleDropzone, clearDropzone, handleCompetitionChange
    }) => {

    return (
        <>
            {
                (isLoading || status === 'loading') &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }
            <Container>
                <TopNav
                    buttons={topNavButtons}
                    onBack={onBackClickHandler}
                />
                <div className={classes.FormContainer}>
                    <div className={classes.MainContainer}>
                        <Main targetType={targetType} users={users} setTargetType={setTargetType}
                              setUsers={setUsers} contentType={contentType}
                              handleUsersDropdown={handleUsersDropdown}/>
                        <TitleAndText title={title} description={description} setTitle={setTitle}
                                      setDescription={setDescription}/>
                        <Content contentType={contentType?.name} contentTypeLabel={contentType?.label}
                                 contentValue={contentValue}
                                 setContentType={setContentType}
                                 handleContentChange={handleContentChange}
                                 handlePointsChange={handlePointsChange}
                                 handleCompetitionChange={handleCompetitionChange}
                        />
                    </div>
                    <Preview img={img} imgUrl={imgUrl} isLoading={imgLoading}
                             isDisabled={contentType.idName === 'points'}
                             setImgUrl={setImgUrl} setImg={setImg}
                             handleDropzone={handleDropzone}
                             onClear={clearDropzone}
                    />
                </div>
            </Container>
        </>
    );
};

export default Notification;
