import React from 'react';
import Answer from "../../../Components/Answer/Answer";
import classes from './Question.module.scss'
import Tag from "../../../Components/Tag/Tag";

const Question = ({title, tags, options, answersCount, onClick, onTagClick }) => {
    let allSelectedCount = 0
    options?.forEach((item) => {
        allSelectedCount += item.selectedCount
    })

    const tagTitles = (
        <div className={classes.Tags}>
            {
                tags?.map((tag) =>
                    <Tag key={tag.id} title={tag.id} onClick={(event)=>{
                        event.stopPropagation()
                        onTagClick(tag)
                    }}
                    />)
            }
        </div>
    )

    const optionItems = (
        <div className={classes.Answers}>
            {
                options?.map(option => <Answer key={option.id} title={option.text ?? option.textEn} progress={Math.round(option?.selectedCount / allSelectedCount * 100)}/>)
            }
        </div>
    )

    return (
        <div className={`${classes.Container}`} onClick={onClick}>
            <div className={classes.Header}>
                {tagTitles}
                <div className={classes.Info}>
                    {answersCount} answers
                </div>
            </div>

            <span className={classes.Title}>{title}</span>
            {optionItems}
        </div>
    );
};

export default Question;
