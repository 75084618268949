import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {withAdmin} from "./withAdmin";

const sendNotification = (notification)=>
    createRequest('main', 'post', withAdmin('/push_notifications'),notification)
const sendNotificationWithPoints = (notification)=>
    createRequest('main', 'post', withAdmin('/gifting/points'), notification)
const getNotificationTemplates = ()=>
    createRequest('main', 'get', withAdmin('/push_notification_templates'), null)
const getNotificationTemplateById = (id)=>
    createRequest('main', 'get', withAdmin(`/push_notification_templates/${id}`), null)
const createNotificationTemplate = (notificationTemplate)=>
    createRequest('main', 'post', withAdmin('/push_notification_templates'), notificationTemplate)
const deleteNotificationTemplate = (id)=>
    createRequest('main', 'delete', withAdmin(`/push_notification_templates/${id}`), null)
const updateNotificationTemplate = (notificationTemplate, id)=>
    createRequest('main', 'patch', withAdmin(`/push_notification_templates/${id}`), notificationTemplate)

export {
    sendNotification,
    sendNotificationWithPoints,
    getNotificationTemplates,
    getNotificationTemplateById,
    createNotificationTemplate,
    updateNotificationTemplate,
    deleteNotificationTemplate,
}