import React from 'react';
import classes from "../User.module.scss";
import {Field} from "../../../../shared/Form/FormField";
import Radio from "../../../../submodules/naoo-web-components/Components/Radio/Radio";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";

const TeamRoleField = () => {
    const user = useSelector(selectActiveUser)
    return (
        <div className={classes.Buttons}>
            <span className={classes.Subtitle}>Naoo team role</span>
            <Field name={'teamRole'} initialValue={user?.naooTeamRole || 0}>
                {
                    (teamRole, setTeamRole) => (
                        <>
                            <Radio
                                className={classes.Left}
                                name={'No'}
                                value={'No'}
                                label={'No'}
                                checked={teamRole === 0}
                                onChange={() => {
                                    setTeamRole(0)
                                }}
                            />
                            <Radio
                                className={classes.Right}
                                name={'Yes'}
                                value={'Yes'}
                                label={'Yes'}
                                checked={teamRole === 1}
                                onChange={() => {
                                    setTeamRole(1)
                                }}
                            />
                        </>
                    )
                }
            </Field>
        </div>
    );
};

export default TeamRoleField;