import React, {useEffect, useState} from 'react';
import TabBarNavItem from "./TabBarNavItem";
import classes from "./TabBar.module.scss";

const TabBar = ({className, children, initialActiveTab, navItemClick, type='MAIN'}) => {
    const [activeTab, setActiveTab] = useState(initialActiveTab || children[0].props.label)
    useEffect(()=> {
        setActiveTab(initialActiveTab)
    }, [initialActiveTab])

    const cls = [className]
    switch (type) {
        case "MAIN": cls.push(classes.TabBar)
            break
        case "SECONDARY": cls.push(classes.TabBarSecondary)
            break
        default: cls.push(classes.TabBar)
    }

    return (
        <div className={cls.join(' ')}>
            <div className={classes.TabContainer}>
                {children.map((child,index)=>(
                    <TabBarNavItem
                        key={index}
                        navLabel={child.props.label}
                        disabled={child.props.disabled}
                        onChangeActiveTab={
                            (activeTab)=>{
                                setActiveTab(activeTab)
                                navItemClick(activeTab)
                            }
                        }
                        className={activeTab === child.props.label ? classes.Active : ''}
                    />
                ))}
            </div>

            <div className={classes.TabContent}>
                {
                    React.Children.map(children,child => React.cloneElement(child,{activeTab}))
                }
            </div>
        </div>
    );
};

export default TabBar;