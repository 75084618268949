import React, {useState} from 'react';
import {Field} from "../../../../shared/Form/FormField";
import {sendImage} from "../../../../submodules/naoo-web-components/Shared/utility/sendImage";
import {createMediaPresignedUrl, getMedia} from "../../../../api/mediaApi";
import classes from "../User.module.scss";
import {Preloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import Dropzone from "../../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";

const AvatarField = () => {
    const user = useSelector(selectActiveUser)
    const [img, setImg] = useState(user?.avatar?.url || null)
    const [imgUrl, setImgUrl] = useState(user?.avatar?.url || '')
    const [imgLoading, setImgLoading] = useState(false)
    return (
        <Field name={'avatar'} initialValue={user?.avatar || null}>
            {
                (avatar, setAvatar) => {
                    const handleDropzoneClean = () => {
                        setImg('')
                        setImgUrl('')
                        setAvatar(null)
                    }

                    const handleDropzone = (images) => {
                        setImgLoading(true)
                        let imgUrl
                        if (images[0] instanceof File) {
                            imgUrl = URL.createObjectURL(images[0])
                        } else {
                            imgUrl = images[0]
                        }
                        sendImage(imgUrl, createMediaPresignedUrl, getMedia, user.id)
                            .then((data) => {
                                setAvatar(data)
                                setImg(data.mediaUrl)
                                setImgUrl(data.mediaUrl)
                                setImgLoading(false)
                            })
                            .catch((error) => {
                                console.log(error)
                                setImgLoading(false)
                            })
                    }
                    return (
                        <>
                            {
                                imgLoading
                                    ? <div className={classes.Loader}>
                                        <Preloader/>
                                    </div>
                                    : <Dropzone
                                        media={img}
                                        onChange={handleDropzone}
                                        className={`${classes.Dropzone} ${img && classes.WithoutBorder}`}
                                        onClear={handleDropzoneClean}
                                    />
                            }
                            <Input
                                label={'Or enter image URL'}
                                name={'title'}
                                placeholder={'Your image URL'}
                                value={imgUrl}
                                onChange={(event) => {
                                    handleDropzone([event.target.value])
                                }}
                            />
                        </>
                    )
                }
            }
        </Field>
    );
};

export default AvatarField;