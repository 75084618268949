import React from 'react';
import classes from './SearchInput.module.scss'
import SearchIcon from "../../shared/icons/searchIcon.svg";
import CloseIcon from "../../shared/icons/closeIcon.svg";

const SearchInput = ({name, value, placeholder, onChange}) => {

    const clearInput = () => {
        onChange('')
    }

    const handleInputChange = (event) => {
        onChange(event.target.value)
    }

    return (
        <div className={classes.Container}>
            <img className={classes.SearchIcon} src={SearchIcon} alt="Search icon"/>
            {value && <img
                onClick={clearInput}
                className={classes.CloseIcon}
                src={CloseIcon}
                alt="Close icon"/>}
            <input
                className={classes.Input}
                name={name}
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default SearchInput;
