export const REPORT_TYPES = {
    REPORTED_BUSINESS: 'reportedBusiness',
    REPORTED_OFFER: 'reportedOffer',
    REPORTED_POST: 'reportedPost',
    REPORTED_COMMENT: 'reportedComment',
    REPORTED_CHANNEL: 'reportedChannel',
    REPORTED_USER: 'reportedUser',
}

export const getReportType = (obj) => {
    if (!obj) return null

      for(let type in REPORT_TYPES){
          if (obj.hasOwnProperty(REPORT_TYPES[type])) {
              return REPORT_TYPES[type]
          }
      }
    return null
}