import React, {useEffect, useRef} from 'react';
import TableItem from "../../../Components/Table/TableItem/TableItem";
import classes from "../PostsPage.module.scss";
import PostPreview from "./PostPreview/PostPreview";
import {format, isAfter} from "date-fns";
import PostedBy from "./PostedBy/PostedBy";
import PostMoreDropdown from "./MoreDropdown/PostMoreDropdown";
import {useIntersectionObserver} from "../../../submodules/naoo-web-components/Shared/hooks/useIntersectionObserver";
import {useDispatch} from "react-redux";

const getContentType = (media) => {
    if (media?.[0]?.contentType?.includes('video')) return 'Video'
    if (media?.[0]?.contentType?.includes('image')) return 'Image'
    return '-'
}
const PostTableItem = ({
                           post,
                           handlePostItemClick,
                           handleShowStats,
                           MoreDropdownComponent,
                           setIsViewed
                       }) => {
    const dispatch = useDispatch()
    const ref = useRef(null)
    const entry = useIntersectionObserver(ref, {})
    const isVisible = !!entry?.isIntersecting

    useEffect(() => {
        if (!post.isViewed && isVisible) {
            dispatch(setIsViewed(post.id))
        }
    }, [isVisible, dispatch, post.id, post.isViewed, setIsViewed]);

    return (
        <TableItem className={classes.PostItem} onClick={handlePostItemClick(post)} ref={ref}>
            <PostPreview
                post={post}
                mediaClass={classes.Logo}
                textClass={classes.Post}
                delayed={isAfter(new Date(post?.publicationDate), new Date())}
                isVisible={isVisible}
            />
            <PostedBy
                textClass={classes.Account}
                mediaClass={classes.AccountLogo}
                channel={post.channel}
                user={post.user}
            />
            <div className={classes.PublishDate}>
                <span>
                    {isAfter(new Date(post?.publicationDate), new Date()) && 'Scheduled '}
                    {format(new Date(post?.publicationDate), 'dd.MM.yy HH:mm')}
                </span>
            </div>
            <div className={classes.Comments}>
                <span>{post?.commentsCount || '-'}</span>
            </div>
            <div className={classes.Likes}>
                <span>{post?.likesCount || '-'}</span>
            </div>
            <div className={classes.Types}>
                <span>{getContentType(post?.media)}</span>
            </div>
            <div className={classes.Featured}>
                <span>{post?.naooFeaturedVideo ? 'Yes' : 'No'}</span>
            </div>
            <div className={classes.More}>
                {
                    !MoreDropdownComponent
                        ? <PostMoreDropdown
                            post={post}
                            postId={post.id}
                            publicationDate={post?.publicationDate}
                            handleShowStats={handleShowStats}
                        />
                        : <MoreDropdownComponent post={post}/>
                }
            </div>
        </TableItem>
    );
};

export default PostTableItem;