import React from 'react';
import classes
    from "./PostTextPlusComments.module.scss";
import BusinessInfo from "../../../../submodules/naoo-web-components/Posts/BusinessInfo/BusinessInfo";
import UserInfo from "../../../../submodules/naoo-web-components/Posts/UserInfo/UserInfo";
import {useMentionData} from "../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";
import PostButtons from "./PostButtons";

const PostTextPlusComments = ({post, MoreDropdown, children}) => {
    const {getTextWithMentions} = useMentionData(post?.text || '', post?.mentions)
    return (
        <div className={classes.PostTextPlusComments}>
            <div className={classes.Header}>
                {
                    post?.business
                        ? <BusinessInfo business={post?.business} createdAt={post?.createdAt}/>
                        : <UserInfo user={post?.user} createdAt={post?.createdAt}/>
                }
                <div className={classes.More}>
                    {MoreDropdown}
                </div>
            </div>
            <div className={classes.PostText}>
                <span>{getTextWithMentions()}</span>
            </div>
            {
                !post?.media?.[0]?.url &&
                <PostButtons post={post}/>
            }
            {children}
        </div>
    );
};

export default PostTextPlusComments;