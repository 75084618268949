import React, {useCallback, useMemo} from 'react';
import MediaWithComments from "../MediaWithComments/MediaWithComments";
import MoreDropdown from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {useMentionData} from "../../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";
import {
    hideConfirmationPopup,
    setPopupState,
    updateReportedComment
} from "../../../../../store/reducers/reportsReducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteOfferThunk} from "../../../../../store/reducers/offersReducer";
import UserMoreItems from "../../../../Users/UserMoreItems/UserMoreItems";


const Offer = ({offer, isReported}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {getTextWithMentions} = useMentionData(offer.text, offer.mentions)


    const handleDeleteOfferClick = useCallback(() => {
        dispatch(setPopupState({
            isOpen: true,
            title: 'Are you sure you want to delete offer?',
            primaryButton: 'Yes, delete offer',
            handlePrimaryClick: () => {
                dispatch(deleteOfferThunk(offer.id))
                    .then((response) => {
                        if (response.meta.requestStatus === 'fulfilled') {
                            navigate(-1)
                            dispatch(hideConfirmationPopup())
                            dispatch(updateReportedComment())
                        }
                    })
            }
        }))
    }, [dispatch, navigate, offer.id])


    const OfferMoreDropdown =useMemo(() => (
        <MoreDropdown width={213}>
            <MoreItem text={'Delete offer'} danger onClick={handleDeleteOfferClick}/>
            <UserMoreItems/>
        </MoreDropdown>
    ),[handleDeleteOfferClick])

    return (
        <MediaWithComments
            entity={offer}
            text={getTextWithMentions()}
            media={[offer?.media?.[0]]}
            MoreDropdown={OfferMoreDropdown}
            isReported={isReported}
        />
    );
};

export default Offer;