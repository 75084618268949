import React from 'react';
import {Field} from "../../../../shared/Form/FormField";
import Radio from "../../../../submodules/naoo-web-components/Components/Radio/Radio";
import classes from "../User.module.scss";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";
import {GENDERS} from "../../../../shared/Constants";

const GenderField = () => {
    const user = useSelector(selectActiveUser)
    return (
        <div className={classes.Buttons}>
            <span className={classes.Subtitle}>Gender</span>
            <Field name={'gender'} initialValue={user?.gender || 0}>
                {
                    (gender, setGender) => {
                        return (
                            <>
                                <Radio
                                    className={classes.Left}
                                    name={'Female'}
                                    value={'Female'}
                                    label={'Female'}
                                    checked={GENDERS[gender] === 'Female'}
                                    onChange={() => {
                                        setGender(1)
                                    }}
                                />
                                <Radio
                                    className={classes.Right}
                                    name={'Male'}
                                    value={'Male'}
                                    label={'Male'}
                                    checked={GENDERS[gender] === 'Male'}
                                    onChange={() => {
                                        setGender(2)
                                    }}
                                />
                            </>
                        )
                    }
                }
            </Field>
        </div>
    );
};

export default GenderField;