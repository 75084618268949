import {combineReducers, configureStore} from '@reduxjs/toolkit'
import poolsReducer from './reducers/poolsReducer'
import tagsReducer from './reducers/tagsReducer'
import businessesReducer from './reducers/businessesReducer'
import messagesReducer from '../submodules/naoo-web-components/Shared/reducers/messagesReducer'
import postsReducer from './reducers/postsReducer'
import competitionsReducer from "./reducers/competitionsReducer";
import cashRedeemRequestsReducer from "./reducers/cashRedeemRequestsReducer";
import offersReducer from "./reducers/offersReducer";
import usersReducer from "./reducers/usersReducer";
import notificationTemplatesReducer from "./reducers/notificationTemplatesReducer";
import promotionsReducer from "./reducers/promotionsReducer";
import vouchersReducer from "./reducers/vouchersReducer";
import reportsReducer from "./reducers/reportsReducer";
import transactionsReducer from "./reducers/transactionsReducer";
import searchParamsReducer from "../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";

const rootReducer = combineReducers({
    polls: poolsReducer,
    tags: tagsReducer,
    businesses: businessesReducer,
    messages:messagesReducer,
    posts: postsReducer,
    competitions:competitionsReducer,
    cashRedeemRequests:cashRedeemRequestsReducer,
    offers:offersReducer,
    users:usersReducer,
    notificationTemplates: notificationTemplatesReducer,
    promotions: promotionsReducer,
    vouchers: vouchersReducer,
    reports: reportsReducer,
    transactions: transactionsReducer,
    searchParamsReducer,
})

export const createReduxStore = (initialState = {}) => {
    return configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: false
        }),
        preloadedState: initialState
    })
}
