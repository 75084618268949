import React, {useState} from 'react';
import classes from "./BusinessOverview.module.scss";
import {ReactComponent as HomeIcon} from "../../../../../../submodules/naoo-web-components/Shared/UI/assets/house.svg";
import {ReactComponent as LocationIcon} from "../../../../../../submodules/naoo-web-components/Shared/UI/assets/location-icon.svg";
import {ReactComponent as TimeIcon} from "../../../../../../submodules/naoo-web-components/Shared/UI/assets/time.svg";
import {ReactComponent as LinkIcon} from "../../../../../../submodules/naoo-web-components/Shared/UI/assets/link.svg";
import {ReactComponent as PhoneIcon} from "../../../../../../submodules/naoo-web-components/Shared/UI/assets/phone.svg";
import {ReactComponent as EmailIcon} from "../../../../../../submodules/naoo-web-components/Shared/UI/assets/email.svg";
import {TextButton} from "../../../../../../submodules/naoo-web-components/Components/Button/Buttons";
import ModalWindow from "../../../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";
import Map from "../../../../../../submodules/naoo-web-components/Components/Map/Map";

const BusinessOverview = ({business}) => {
    const [isShowMap, setIsShowMap] = useState(false)
    const categories =  business?.businessCategories
        ?.map(category=>category.name)
        ?.join(', ')

    const handleMapClick = () => {
       setIsShowMap(true)
    }

    const handleCloseModal = () => {
        setIsShowMap(false)
    }

    return (
        <>
            <ModalWindow
                isOpen={isShowMap}
                onClose={handleCloseModal}
                isOutsideClosed={true}
            >
                <div className={classes.MapContainer}>
                    <Map
                        lat={business?.lat}
                        lng={business?.lng}
                        defaultZoom={15}
                        onClick={()=>{}}
                    />
                </div>
            </ModalWindow>
            <div className={classes.OverviewContainer}>
                <div className={classes.About}>
                    <div className={classes.Title}>
                        <span>About</span>
                    </div>
                    <div className={classes.Description}>
                        <span>{business?.description}</span>
                    </div>
                </div>
                {
                    !!business?.businessCategories &&
                    <div className={classes.BusinessInfo}>
                        <HomeIcon width={16} height={16} className={classes.Icon}/>
                        {
                            categories
                        }
                    </div>
                }

                <div className={classes.BusinessInfo}>
                    <LocationIcon width={16} height={16} className={classes.Icon}/>
                    {business?.address}
                    <TextButton className={classes.Button} onClick={handleMapClick}>
                        Map
                    </TextButton>
                </div>
                <div className={classes.BusinessInfo}>
                    <TimeIcon width={16} height={16} className={classes.Icon}/>
                </div>
                {
                    !!business?.website &&
                    <div className={classes.BusinessInfo}>
                        <LinkIcon width={16} height={16} className={classes.Icon}/>
                        {business?.website}
                        <a className={classes.Button} href={`http://${business?.website}`} target={'_blank'} rel={'noreferrer'}>Go</a>
                    </div>
                }
                {
                    !!business?.phone &&
                    <div className={classes.BusinessInfo}>
                        <PhoneIcon width={16} height={16} className={classes.Icon}/>
                        {business?.phone}
                        <a className={classes.Button} href={`tel:${business?.phone}`}>Call</a>
                    </div>
                }
                {
                    !!business?.email &&
                    <div className={classes.BusinessInfo}>
                        <EmailIcon width={16} height={16} className={classes.Icon}/>
                        {business?.email}
                        <a className={classes.Button} href={`mailto:${business?.email}`}>Email</a>
                    </div>
                }

            </div>
        </>

    );
};

export default BusinessOverview;