import React, {Fragment, useState} from 'react';
import classes from "./OfferTable.module.scss";
import SwitchButton from "../../../submodules/naoo-web-components/Components/Switch/Switch";
import {
    fetchOffersByNextToken, getOfferStatsThunk, selectActiveOffer, selectFetching, selectNextToken,
    selectOffers, selectOffersLength, selectOfferStats, setActiveOffer,
    setFetching,
    updateOfferThunk
} from "../../../store/reducers/offersReducer";
import OfferMoreDropdown from "../../Businesses/Business/OfferMoreDropdown/OfferMoreDropdown";
import {returnObservableItem} from "../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../Spinner.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {useObserver} from "../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import {useLocation} from "react-router-dom";
import OfferStats from "../../../submodules/naoo-web-components/Offer/OfferStats/OfferStats";

const OfferTable = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const [offersLimit, setOffersLimit] = useState(6)
    const offers = useSelector(state => selectOffers(state,offersLimit))

    const [element, setElement] = useState(null)
    const nextToken = useSelector(state => selectNextToken(state))
    const fetching = useSelector(state => selectFetching(state))
    const LIMIT = 10

    const [isShowStat, setIsShowStat] = useState(false)
    const offerForStats = useSelector(selectActiveOffer)
    const offerStats = useSelector(selectOfferStats)
    const offersLength = useSelector(selectOffersLength)

    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchOffersByNextToken
    )

    const handleMoreDropdownShowStatisticsClick = (id) => {
        document.body.style.overflow = 'hidden'
        dispatch(setActiveOffer(id))
        setIsShowStat(true)
    }

    const handleCloseStats = ()=>{
        setIsShowStat(false)
        document.body.style.overflow = null
    }

    return (
        <>
            {
                isShowStat &&
                <OfferStats
                    onClose={handleCloseStats}
                    offer={offerForStats}
                    getOfferStatsThunk={getOfferStatsThunk}
                    stats={offerStats}
                />
            }
            <div className={classes.ItemsContainer}>
                <div className={classes.ItemsHeader}>
                    <div className={classes.Title}>
                        Offers
                    </div>
                </div>
                <div className={classes.OffersTableHeader}>
                    <div className={classes.Name}>
                        <span>Title</span>
                    </div>
                    <div className={classes.Claims}>
                        <span>Claims</span>
                    </div>
                    <div className={classes.Reach}>
                        <span>Reach</span>
                    </div>
                    <div className={classes.Engagement}>
                        <span>Engagement</span>
                    </div>
                    <div className={classes.Status}>
                        <span>Status</span>
                    </div>
                </div>

                {
                    offers?.map((item, index, array)=>(
                        <Fragment key={item.id}>
                            <div className={classes.OfferTableItem} key={item.id}>
                                <img className={classes.Logo} src={item?.media?.[0].url} alt=""/>
                                <div className={classes.Name}>
                                    <span>{item?.title}</span>
                                </div>
                                <div className={classes.Claims}>
                                    <span>{item?.claims || '–' }</span>
                                </div>
                                <div className={classes.Reach}>
                                    <span>{item?.reach || '–' } </span>
                                </div>
                                <div className={classes.Engagement}>
                                    <span>{item?.engagement || '–'}</span>
                                </div>
                                <div className={classes.Status}>
                                    <SwitchButton checked={item.isActive} onChange={(event) => {
                                        dispatch(updateOfferThunk({
                                            offer:{
                                                isActive:event.target.checked
                                            },
                                            offerId:item.id
                                        }))
                                    }}/>
                                    <OfferMoreDropdown
                                        businessId={item?.business.id}
                                        offerId={item.id}
                                        prevLocation={location.pathname}
                                        onShowStatisticsClick={handleMoreDropdownShowStatisticsClick}
                                    />
                                </div>
                            </div>
                            {
                                returnObservableItem(array.length, LIMIT, index, setElement )
                            }
                        </Fragment>
                    ))
                }
                {
                    fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                }
                {
                    offersLength > 6 &&
                    <div className={classes.ItemsFooter}>
                        {
                            offersLimit
                                ? <button className={classes.Button} onClick={()=>setOffersLimit(null)}>
                                    View all
                                </button>
                                : <button className={classes.Button} onClick={()=>setOffersLimit(6)}>
                                    Collapse
                                </button>
                        }
                    </div>
                }
            </div>
        </>

    );
};

export default OfferTable;