import React from 'react';
import UserItem from "../../../submodules/naoo-web-components/Components/UserItem/UserItem";
import classes from './SearchUserItem.module.scss'

const SearchUserItem = ({className, ...props}) => {
    return (
        <UserItem className={`${classes.UserFilter} ${className ?? ''}`} {...props}/>
    );
};

export default SearchUserItem;