import React, {Fragment, useEffect} from 'react';
import classes from "./TagOptions.module.scss";
import {Preloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from '../../../Spinner.module.scss'
import {fetchTags, fetchTagsByNextToken} from "../../../../store/reducers/tagsReducer";
import {unwrapResult} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import {returnObservableItem} from "../../../../submodules/naoo-web-components/Shared/utility/utils";
import {useTagPagination} from "../../../../submodules/naoo-web-components/Components/Tags/useTagPagination";

const TagOptions = ({
                        options, onItemClick, values=[], searchString='',
}) => {
    const dispatch = useDispatch()
    const [setElement, setRootElement, limit, fetching ] = useTagPagination()

    useEffect(() => {
        dispatch(fetchTags({
            q:searchString
        }))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                if (originalPromiseResult.nextToken) {
                    dispatch(fetchTagsByNextToken({
                        nextToken: originalPromiseResult.nextToken
                    }))
                }
            })
    }, [searchString, dispatch])

    return (
        <div className={classes.OptionsContainer} ref={setRootElement}>
            {options
                ?.filter((item) => {
                    const isSelected = values.find((value) => {
                        return item?.id === value?.id
                    })
                    return ((item?.title?.toLowerCase().includes(searchString.toLowerCase()) || item.id.toLowerCase().includes(searchString)) && !isSelected)
                })
                ?.map((item, index, array) => {
                    return (
                        <Fragment key={index}>
                            <div
                                key={index}
                                className={classes.Option}
                                onClick={()=>onItemClick(item)}
                            >
                                <span className={classes.OptionTitle}>{item.id}</span>
                            </div>
                            {
                                returnObservableItem(array.length, limit, index, setElement )
                            }
                        </Fragment>
                    )
                })}
            {
                fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
            }
        </div>
    );
};

export default TagOptions;