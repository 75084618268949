import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {sendImage} from '../../submodules/naoo-web-components/Shared/utility/sendImage'
import {createCompetition, getCompetitions, updateCompetition} from "../../api/competitionApi";
import {createMediaPresignedUrl, getMedia} from "../../api/mediaApi";
import {searchParamsSelector} from "../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";

export const fetchCompetitions = createAsyncThunk(
    'competitions/fetchCompetitions',
    async (payload, {getState}) => {
        const state = getState()
        const {searchString} = searchParamsSelector(state)
        const response = await getCompetitions({
            limit: payload?.limit,
            q: searchString,
        })
        return response.data
    })

export const fetchCompetitionsByNextToken = createAsyncThunk(
    'competitions/fetchCompetitionsByNextToken',
    async (payload, {getState}) => {
        const state = getState()
        const {searchString} = searchParamsSelector(state)
        const response = await getCompetitions({
            q: searchString,
            nextToken: payload?.nextToken,
        })
        return response.data
    }
)

// export const fetchCompetitionByIdThunk = createAsyncThunk('competitions/fetchCompetitionByIdThunk', async (payload) => {
// 	const response = await getCompetitionById(payload)
// 	return response.data
// })

export const createCompetitionThunk = createAsyncThunk('competitions/createCompetitionThunk', async (payload) => {
    const media = await sendImage(payload?.img, createMediaPresignedUrl, getMedia)
    const response = await createCompetition({
        title: payload.title,
        coins: payload.coins,
        probability: payload.probability,
        startTime: payload.startTime,
        endTime: payload.endTime,
        mediaId: media.id,
    })
    return response.data
})

export const updateCompetitionThunk = createAsyncThunk('competitions/updateCompetitionThunk', async (payload) => {
    let media
    const competition = {
        title: payload.competition.title,
        coins: payload.competition.coins,
        probability: payload.competition.probability,
        startTime: payload.competition.startTime,
        endTime: payload.competition.endTime,
    }
    if (payload?.competition?.img) {
        media = await sendImage(payload?.competition?.img, createMediaPresignedUrl, getMedia)
        competition.media = {id: media.id}
    }
    const response = await updateCompetition({
        competition,
        id: payload.id,
    })
    return response.data
})

// export const deleteCompetitionThunk = createAsyncThunk('competitions/deleteCompetitionThunk', async (payload) => {
// 	await deleteCompetition(payload)
// 	return payload
// })

const initialState = {
    competitions: [],
    activeCompetition: null,
    status: null,
    nextToken: '',
    fetching: false,
}

const competitionsSlice = createSlice({
    name: 'competitions',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setFetching: (state, action) => {
            state.fetching = action.payload
        },
        setActiveCompetition: (state, action) => {
            state.activeCompetition = state.competitions.find((item) => item.id === action.payload)
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompetitions.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(fetchCompetitions.fulfilled, (state, action) => {
            state.competitions = action.payload.competitions
            state.status = null
            state.nextToken = action.payload.nextToken
        })
        builder.addCase(fetchCompetitions.rejected, (state) => {
            state.status = 'error'
        })
        builder.addCase(fetchCompetitionsByNextToken.fulfilled, (state, action) => {
            if (action.payload.competitions?.length) {
                state.competitions.push(...action.payload.competitions)
            }
            state.fetching = false
            state.nextToken = action.payload.nextToken
        })
        // builder.addCase(fetchCompetitionByIdThunk.pending, (state, action) => {
        // 	state.status = 'loading'
        // })
        // builder.addCase(fetchCompetitionByIdThunk.fulfilled, (state, action) => {
        // 	state.activeCompetition = action.payload
        // 	state.status = null
        // })
        // builder.addCase(fetchCompetitionByIdThunk.rejected, (state, action) => {
        // 	state.status = 'error'
        // })
        builder.addCase(createCompetitionThunk.pending, (state, action) => {
            state.status = 'loading'
        })
        builder.addCase(createCompetitionThunk.fulfilled, (state, action) => {
            state.competitions.unshift(action.payload)
            state.status = 'successfully_create'
        })
        builder.addCase(createCompetitionThunk.rejected, (state, action) => {
            state.status = 'error'
        })
        builder.addCase(updateCompetitionThunk.pending, (state, action) => {
            state.status = 'loading'
        })
        builder.addCase(updateCompetitionThunk.fulfilled, (state, action) => {
            state.competitions = state.competitions.map((competition) => {
                if (competition.id === action.payload.id) return action.payload
                return competition
            })
            state.status = 'successfully_update'
        })
        builder.addCase(updateCompetitionThunk.rejected, (state, action) => {
            state.status = 'error'
        })
        // builder.addCase(deleteCompetitionThunk.pending, (state, action) => {
        // 	state.status = 'loading'
        // })
        // builder.addCase(deleteCompetitionThunk.fulfilled, (state, action) => {
        // 	state.status = null
        // 	state.competitions = state.competitions.filter((competition) => competition.id !== action.payload)
        // })
        // builder.addCase(deleteCompetitionThunk.rejected, (state, action) => {
        // 	state.status = 'error'
        // })
    },
})

export default competitionsSlice.reducer
export const {setStatus, setFetching, setActiveCompetition} = competitionsSlice.actions

export const selectCompetitions = (state) => state.competitions.competitions
export const selectCompetition = (state) => state.competitions.activeCompetition
export const selectStatus = (state) => state.competitions.status
export const selectNextToken = (state) => state.competitions.nextToken
export const selectFetching = (state) => state.competitions.fetching
