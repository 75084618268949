import React, {useMemo} from 'react';
import classes from './ListRegistrationPromotion.module.scss'
import TopNav from "../../../Components/TopNav/TopNav";
import topNavClasses from "../../../Components/TopNav/TopNav.module.scss";
import {useNavigate} from "react-router-dom";
import PromotionPreview from "../PromotionPreview/PromotionPreview";
import {useSelector} from "react-redux";
import {selectActivations, selectActivePromotion} from "../../../store/reducers/promotionsReducer";
import { ExportToCsv } from 'export-to-csv';
import {format} from "date-fns";

const ListRegistrationPromotion = () => {
    const navigate = useNavigate()

    const promotion = useSelector(selectActivePromotion)
    const activations = useSelector(selectActivations)

    const csvOptions = {
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: 'activations',
    };

    const genders = useMemo(()=>{
        return{
            1: 'Female',
            2: 'Male'
        }
    },[])

    const data = activations?.map(item=>({
            user: item.user ? `${item.user?.firstname} ${item.user?.lastname}` : '-',
            activated: format(new Date(item.createdAt), 'dd.MM.yyyy'),
            gender: genders[item.user?.gender] || '-',
            phone: item.user?.phone || '-',
            email: item.user?.email || '-',
            userId: item.user?.id || '-',
        }
    ))

    const handleBackButtonClick = () => {
        navigate(-1)
    }

    const handleExportClick = () => {
        const csvExporter = new ExportToCsv(csvOptions);
        csvExporter.generateCsv(data);
    }

    const topNavButtons = [
        {
            title: 'Export csv',
            onClick: handleExportClick,
            isDisabled: data.length === 0,
            className: `${topNavClasses.SuccessButton} ${data.length === 0 ? topNavClasses.Disabled : '' }`
        },
    ]



    return (
        <div className={classes.Container}>
            <TopNav
                buttons={topNavButtons}
                onBack={handleBackButtonClick}
            />
            <PromotionPreview promotion={promotion}/>

            <div className={classes.ActivationsContainer}>
                {/*HEADER*/}
                <div className={classes.Header}>
                    <div className={classes.User}>
                        <span>User</span>
                    </div>
                    <div className={classes.Activated}>
                        <span>Activated</span>
                    </div>
                    <div className={classes.Gender}>
                        <span>Gender</span>
                    </div>
                    <div className={classes.Phone}>
                        <span>Phone</span>
                    </div>
                    <div className={classes.Email}>
                        <span>Email</span>
                    </div>
                    <div className={classes.UserID}>
                        <span>User ID</span>
                    </div>
                </div>
                {/*HEADER*/}

                {/*ITEMS*/}
                {
                    activations?.map((item, index,array)=>{
                        return (
                            <div className={classes.ActivationItem} key={item.id}>
                                {
                                    item?.mediaUrl &&
                                    <img className={classes.Logo} src={item?.mediaUrl} alt=""/>
                                }
                                <div className={classes.User}>
                                    <span>{ item.user ? `${item.user?.firstname} ${item.user?.lastname}` : '-'}</span>
                                </div>
                                <div className={classes.Activated}>
                                    <span>{format(new Date(item.createdAt), 'dd.MM.yyyy')}</span>
                                </div>
                                <div className={classes.Gender}>
                                    <span>{genders[item.user?.gender] || '-'}</span>
                                </div>
                                <div className={classes.Phone}>
                                    <span>{item.user?.phone || '-'}</span>
                                </div>
                                <div className={classes.Email}>
                                    <span>{item.user?.email || '-'}</span>
                                </div>
                                <div className={classes.UserID}>
                                    <span>{item.user?.id}</span>
                                </div>

                            </div>

                        )
                    })
                }
                {/*ITEMS*/}

            </div>
        </div>
    );
};

export default ListRegistrationPromotion;