import React, {Fragment, useEffect, useState} from 'react';
import {useOnClickOutside} from "../../../../submodules/naoo-web-components/Shared/hooks/useclickOutside";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCompetitions,
    fetchCompetitionsByNextToken,
    selectCompetitions,
    selectFetching,
    selectNextToken,
    setFetching
} from "../../../../store/reducers/competitionsReducer";
import classes from "./CompetitionsDropdown.module.scss";
import {ReactComponent as ClearIcon} from "../../../../shared/icons/closeIcon.svg";
import {ReactComponent as DownIcon} from "../../../../shared/icons/down.svg";
import {useDebounce} from "../../../../shared/hooks/useDebounce";
import {Preloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../../Spinner.module.scss";
import {unwrapResult} from "@reduxjs/toolkit";
import {returnObservableItem} from "../../../../submodules/naoo-web-components/Shared/utility/utils";
import {useObserver} from "../../../../submodules/naoo-web-components/Shared/hooks/useObserver";

const CompetitionsDropdown = ({competition,label, onItemClick, className, isClearable}) => {
    const dispatch = useDispatch()

    const competitions = useSelector(state=>selectCompetitions(state))
    const [searchString, setSearchString] = useState('')
    const [isShow, setIsShow] = useState(false)
    const [items, setItems] = useState([])

    const [element, setElement] = useState(null)
    const nextToken = useSelector(state => selectNextToken(state))
    const fetching = useSelector(state => selectFetching(state))
    const LIMIT = 10
    const debounceSearchString = useDebounce(searchString, 1000)

    const ref = useOnClickOutside(() => {
        setIsShow(false)
    })


    useEffect(() => {
        dispatch(fetchCompetitions({
            q:debounceSearchString
        }))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                if (originalPromiseResult.nextToken){
                    dispatch(fetchCompetitionsByNextToken({
                        nextToken:originalPromiseResult.nextToken
                    }))
                }

            })
    }, [dispatch, debounceSearchString])

    useEffect(()=>{
        setItems(competitions)
    }, [competitions])


    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchCompetitionsByNextToken
    )

    return (
        <div className={`${classes.Container} ${className}`} ref={ref}>
            <span className={classes.Label}>{label}</span>
            {
                competition
                    ? <div className={'SelectedItem'}>
                        <span className={'SelectedItemTitle'}>
                             {competition.title}
                          </span>
                        <span className={'SelectedItemID'}>
                            {competition.id}
                        </span>

                        {
                            isClearable &&
                            <div className={'CloseIcon'} onClick={() => {
                                onItemClick('')
                            }}>
                                <ClearIcon/>
                            </div>
                        }

                    </div>
                    : <div className={'InputContainer'}
                           onClick={() => {
                               setIsShow(true)
                           }}
                    >
                        <input
                            className={`Input ${isShow && 'InputDropdownOpen'}`}
                            placeholder={'Select competition'}
                            value={''}
                            type="text"
                            onChange={(event) => {
                                setSearchString(event.target.value)
                                if (event.target.value === '') {
                                    setIsShow(false)
                                }
                            }}
                        />
                        <DownIcon className={'Icon'}/>
                    </div>
            }

            {
                isShow &&
                <div className={'ItemsContainer'}>
                    {
                        items?.map((competition, index, array) => {
                            return (
                                <Fragment key={competition.id}>
                                    <div
                                        key={competition.id}
                                        className={'Item'}
                                        onClick={() => {
                                            onItemClick(competition)
                                            setIsShow(false)
                                        }}
                                    >
                                        <div className={'ItemTitle'}>
                                            <span>{competition.title}</span>
                                        </div>
                                        <div className={'ItemID'}>
                                            <span>{competition.id}</span>
                                        </div>
                                    </div>
                                    {
                                        returnObservableItem(array.length, LIMIT, index, setElement )
                                    }
                                </Fragment>
                            )
                        })
                    }
                    {
                        fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                    }
                </div>
            }
        </div>
    );
};

export default CompetitionsDropdown;