import React, { useState } from 'react'
import classes from './MediaField.module.scss'
import { Preloader } from '../../../../submodules/naoo-web-components/Components/Preloader/Preloader'
import Dropzone from '../../../../submodules/naoo-web-components/Components/Dropzone/Dropzone'

const MediaField = ({ onAdd, onRemove, media }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDropzone = (images) => {
    setIsLoading(true)
    let imgUrl
    if (images[0] instanceof File) {
      imgUrl = URL.createObjectURL(images[0])
    } else {
      imgUrl = images[0]
    }

    onAdd(imgUrl)
    setIsLoading(false)
  }
  return (
    <>
      {isLoading ? (
        <div className={classes.Loader}>
          <Preloader />
        </div>
      ) : (
        <Dropzone
          media={media}
          onChange={handleDropzone}
          className={`${classes.Dropzone} ${media && classes.WithoutBorder}`}
          onClear={onRemove}
        />
      )}
    </>
  )
}

export default MediaField
