import React from 'react';
import classes from "./TabBar.module.scss";

const TabBarItem = ({label, activeTab, children, disabled}) => {
    const cls = [classes.TabBarItem]
    if (label === activeTab){
        cls.push(classes.Active)
    }
    return (
        <div className={cls.join(' ')} disabled={disabled}>
            {children}
        </div>
    );
};

export default TabBarItem;