import React, { useEffect, useState } from 'react'
import classes from './User.module.scss'
import TopNav from '../../../Components/TopNav/TopNav'
import { useNavigate } from 'react-router-dom'
import { FullscreenPreloader } from '../../../submodules/naoo-web-components/Components/Preloader/Preloader'
import Input from '../../../submodules/naoo-web-components/Components/Input/Input'
import { handleErrors } from '../../../shared/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { selectStatus, setStatus, updateUserThunk } from '../../../store/reducers/usersReducer'
import ModalWindow from '../ModalWindow'
import topNavClasses from '../../../Components/TopNav/TopNav.module.scss'
import Container from '../../../Components/FormComponents/Container/Container'
import Section from '../../../Components/FormComponents/Section/Section'
import Margin from '../../../submodules/naoo-web-components/Components/Margin/Margin'
import RestrictionsSection from '../RestrictionsSection/RestrictionsSection'
import { useFormContext } from '../../../shared/Form/UseFormContext'
import GenderField from './Fields/GenderField'
import FirstNameField from './Fields/FirstNameField'
import LastNameFiled from './Fields/LastNameFiled'
import BioField from './Fields/BioField'
import LocationField from './Fields/LocationField'
import BirthdateField from './Fields/BirthdateField'
import TeamRoleField from './Fields/TeamRoleField'
import InfoSection from './InfoSection'
import AvatarField from './Fields/AvatarField'
import GalleryField from './Fields/GalleryField'
import PersonalWebsitePublicField from './Fields/PersonalWebsitePublicField'
import { ROUTES } from '../../../submodules/naoo-web-components/Shared/constants'
import InfluencerField from './Fields/InfluencerField'
import VerifiedField from './Fields/VerifiedField'

const User = ({ user }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    form: { values },
    isChanged,
    setForm,
  } = useFormContext()
  const status = useSelector(selectStatus)

  const [isShowModal, setIsShowModal] = useState(false)
  const [restrictions, setRestrictions] = useState(user.restrictions ?? [])
  const [isPersonalWebsitePublic, setIsPersonalWebsitePublic] = useState(user.isPersonalWebsitePublic ?? true)

  const errors = {
    firstName: values?.firstName?.length === 0,
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (status === 'successfullyUpdated') {
      dispatch(setStatus(null))
      navigate(-1)
    }
  }, [dispatch, navigate, status])

  useEffect(() => {
    if (restrictions.includes(20)) {
      setIsPersonalWebsitePublic(false)
    }
    setForm((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        isPersonalWebsitePublic: false,
      },
      changed: {
        ...prev.changed,
        isPersonalWebsitePublic: true,
      },
    }))
  }, [restrictions, setForm])

  const handleSaveUser = () => {
    if (isChanged) {
      dispatch(
        updateUserThunk({
          user: {
            firstname: values?.firstName,
            lastname: values?.lastName,
            gender: values.gender,
            location: values?.location,
            birthdate: values?.birthdate,
            avatarMediaId: values?.avatar?.id,
            naooTeamRole: values?.teamRole,
            bio: values?.bio,
            profileMediaIds: values?.profileMediaIds?.map((item) => item.id),
            restrictions: restrictions,
            isPersonalWebsitePublic: isPersonalWebsitePublic,
            isInfluencer: values?.isInfluencer,
            isVerified: values?.isVerified,
          },
          userId: user.id,
        })
      )
    }
  }

  const handleDeleteUser = () => {
    setIsShowModal(true)
  }
  const handleDiscardUser = () => {
    console.log('discard user')
  }

  const handleGoToPosts = () => {
    navigate(`${ROUTES.ADMIN_DASHBOARD.POSTS}?userId=${user.id}`)
  }

  const topNavButtons = [
    {
      title: 'Show User Posts',
      isDisabled: false,
      className: topNavClasses.ActivateButton,
      onClick: handleGoToPosts,
    },
    {
      title: 'Discard user',
      onClick: handleDiscardUser,
      isDisabled: false,
      className: topNavClasses.DangerButton,
    },
    {
      title: 'Delete user',
      onClick: handleDeleteUser,
      isDisabled: false,
      className: topNavClasses.DangerButton,
    },
    {
      title: 'Save user',
      onClick: handleSaveUser,
      isDisabled: handleErrors(errors) || !isChanged,
      className: topNavClasses.SuccessButton,
    },
  ]

  return (
    <>
      {status === 'loading' && <FullscreenPreloader />}

      <ModalWindow
        isOpen={isShowModal}
        user={user}
        firstName={values?.firstName ?? user.firstname}
        lastName={values?.lastName ?? user.lastname}
        gender={values?.gender ?? user?.gender}
        setIsShowModal={setIsShowModal}
      />
      <Container>
        <TopNav buttons={topNavButtons} onBack={() => navigate(-1)} />
        <div className={classes.FormContainer}>
          <div className={classes.MainContainer}>
            <Section title={'Main'}>
              <GenderField />

              <FirstNameField errors={errors} />

              <LastNameFiled />

              <BioField />

              <LocationField />

              <BirthdateField />

              <Margin top={24}>
                <Input
                  label={'Phone'}
                  name={'phone'}
                  placeholder={'Enter your phone number'}
                  value={user?.phone || ''}
                  error={errors.phone}
                  readOnly
                />
              </Margin>
              <Margin top={24}>
                <Input
                  label={'Email'}
                  name={'email'}
                  placeholder={'Enter your email'}
                  error={errors.email}
                  value={user?.email || ''}
                  readOnly
                />
              </Margin>

              <Margin top={24} />

              <TeamRoleField />

              <Margin top={24} />

              <PersonalWebsitePublicField
                setIsPersonalWebsitePublic={setIsPersonalWebsitePublic}
                isPersonalWebsitePublic={isPersonalWebsitePublic}
              />

              <Margin top={24} />

              <InfluencerField />

              <Margin top={24} />

              <VerifiedField />
            </Section>

            <Section title={'Restrictions'}>
              <RestrictionsSection restrictions={restrictions} setRestrictions={setRestrictions} />
            </Section>

            <InfoSection />
          </div>
          <div className={classes.PreviewContainer}>
            <Section title={'Avatar'}>
              <AvatarField />

              <GalleryField />
            </Section>
          </div>
        </div>
      </Container>
    </>
  )
}

export default User
