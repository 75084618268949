import React from 'react';
import Section from "../../../Components/FormComponents/Section/Section";
import Margin from "../../../submodules/naoo-web-components/Components/Margin/Margin";
import Input from "../../../submodules/naoo-web-components/Components/Input/Input";
import classes from "./User.module.scss";
import {format} from "date-fns";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../store/reducers/usersReducer";

const InfoSection = () => {
    const user = useSelector(selectActiveUser)
    return (
        <Section title={'Info'}>
            <Margin top={24}>
                <Input
                    className={classes.InputInfo}
                    label={'ID'}
                    name={'ID'}
                    placeholder={'ID'}
                    value={user?.id || ''}
                    readOnly
                />
            </Margin>

            <Margin top={24}>
                <Input
                    className={classes.InputInfo}
                    label={'Created at'}
                    name={'createdAt'}
                    placeholder={'Created at'}
                    value={user?.createdAt ? format(new Date(user?.createdAt), 'dd.MM.yyyy') : '-'}
                    readOnly
                />
            </Margin>
            <Margin top={24}>
                <Input
                    className={classes.InputInfo}
                    label={'Updated at'}
                    name={'updatedAt'}
                    placeholder={'Updated at'}
                    value={user?.updatedAt ? format(new Date(user?.updatedAt), 'dd.MM.yyyy') : '-'}
                    readOnly
                />
            </Margin>
            <Margin top={24}>
                <Input
                    className={classes.InputInfo}
                    label={'Deleted at'}
                    name={'deletedAt'}
                    placeholder={'Deleted at'}
                    value={user?.deletedAt ? format(new Date(user?.deletedAt), 'dd.MM.yyyy') : '-'}
                    readOnly
                />
            </Margin>
            <Margin top={24}>
                <Input
                    className={classes.InputInfo}
                    label={'Wallet ID'}
                    name={'walletID'}
                    placeholder={'Wallet ID'}
                    value={user?.wallet?.id || '-'}
                    readOnly
                />
            </Margin>
            <Margin top={24}>
                <Input
                    className={classes.InputInfo}
                    label={'Invitation token'}
                    name={'invitationToken'}
                    placeholder={'Invitation token'}
                    value={user?.invitationToken || '-'}
                    readOnly
                />
            </Margin>
        </Section>
    );
};

export default InfoSection;