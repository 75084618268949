import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getTags} from "../../api/tagApi";
import {searchParamsSelector} from "../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";

const initialState = {
    tags: [],
    activeTag: null,
    nextToken: '',
    fetching: false,
    status: '',
    error: '',
}

export const fetchTags = createAsyncThunk(
    'tags/fetchTags',
    async (payload, {getState}) => {
        const state = getState()
        const {searchString} = searchParamsSelector(state)
        const response = await getTags({
            q: searchString
        })
        return response.data
    })

export const fetchTagsByNextToken = createAsyncThunk(
    'tags/fetchTagsByNextToken',
    async (payload, {getState}) => {
        const state = getState()
        const {searchString} = searchParamsSelector(state)
        const response = await getTags({
            nextToken: payload?.nextToken,
            q: searchString
        })
        return response.data
    })

// export const createTagThunk = createAsyncThunk('tags/createTagThunk', async (payload) => {
// 	try {
// 		const response = await createTag(payload)
// 		return response.data
// 	} catch (error) {
// 		throwNewErrorFromMessage(error)
// 	}
// })
//
// export const updateTagThunk = createAsyncThunk('tags/updateTagThunk', async (payload) => {
// 	try {
// 		const response = await updateTag({
// 			tag: payload?.tag,
// 			id: payload?.id,
// 		})
// 		return response.data
// 	} catch (error) {
// 		throwNewErrorFromMessage(error)
// 	}
// })

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setActiveTag: (state, action) => {
            state.activeTag = state.tags.find((item) => item.id === action.payload)
            state.error = null
        },
        setFetching: (state, action) => {
            state.fetching = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTags.fulfilled, (state, action) => {
            state.tags = action.payload.tags || []
            state.nextToken = action.payload.nextToken
            state.error = null
        })
        builder.addCase(fetchTagsByNextToken.fulfilled, (state, action) => {
            state.tags.push(...action.payload.tags)
            state.nextToken = action.payload.nextToken
            state.fetching = false
            state.error = null
        })
        // builder.addCase(createTagThunk.pending, (state, action) => {
        // 	state.status = 'loading'
        // 	state.error = null
        // })
        // builder.addCase(createTagThunk.fulfilled, (state, action) => {
        // 	state.tags.unshift(action.payload)
        // 	state.status = 'successfully'
        // 	state.error = null
        // })
        // builder.addCase(createTagThunk.rejected, (state, action) => {
        // 	state.status = 'error'
        // 	state.error = action.error.message
        // })
        // builder.addCase(updateTagThunk.pending, (state, action) => {
        // 	state.status = 'loading'
        // 	state.error = null
        // })
        // builder.addCase(updateTagThunk.fulfilled, (state, action) => {
        // 	state.status = 'successfully'
        // 	state.tags = state.tags.map((tag) => {
        // 		if (tag.id === action.payload.id) return action.payload
        // 		return tag
        // 	})
        // 	state.error = null
        // })
        // builder.addCase(updateTagThunk.rejected, (state, action) => {
        // 	state.status = 'error'
        // 	state.error = action.error.message
        // })
    },
})

export default tagsSlice.reducer

export const {setFetching, setActiveTag, setStatus, setError} = tagsSlice.actions

export const selectTags = (state) => state.tags.tags
export const selectNextToken = (state) => state.tags.nextToken
export const selectFetching = (state) => state.tags.fetching
export const selectStatus = (state) => state.tags.status
export const selectActiveTag = (state) => state.tags.activeTag
export const selectError = (state) => state.tags.error
