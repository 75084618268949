import React, {useEffect, useState} from 'react';
import {Outlet, useMatch, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {
    fetchBusinesses,
    fetchBusinessesByNextToken,
    fetchCategories,
    selectBusinesses,
    selectFetching,
    selectNextToken,
    setActiveBusiness,
    setFetching,
    updateBusinessThunk
} from "../../store/reducers/businessesReducer";
import AdminSidebar from "../Sidebar/AdminSidebar";
import SearchInput from "../../Components/SearchInput/SearchInput";
import SwitchButton from "../../submodules/naoo-web-components/Components/Switch/Switch";
import {ROUTES} from "../../submodules/naoo-web-components/Shared/constants";
import layoutClasses from '../Layout.module.scss'
import classes from './Businesses.module.scss'
import spinnerClasses from '../Spinner.module.scss'
import {Preloader} from "../../submodules/naoo-web-components/Components/Preloader/Preloader";
import MoreDropdown from "../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {returnObservableItem} from "../../submodules/naoo-web-components/Shared/utility/utils";
import {useObserver} from "../../submodules/naoo-web-components/Shared/hooks/useObserver";
import TableWrapper from "../../Components/Table/TableWrapper/TableWrapper";
import TableHeader from "../../Components/Table/TableHeader/TableHeader";
import TableItem from "../../Components/Table/TableItem/TableItem";
import SearchContainer from "../../Components/Search/SearchContainer/SearchContainer";
import Avatar from "../../submodules/naoo-web-components/Components/Avatar/Avatar";
import {useFetchWithQueryParams} from "../../submodules/naoo-web-components/Shared/hooks/useFetchWithQueryParams";
import {setSearchParams} from "../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";

const CURRENT_PAGE = 'businesses'
const Businesses = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const LIMIT = 10
    const businesses = useSelector(selectBusinesses)
    const nextToken = useSelector(selectNextToken)
    const fetching = useSelector(selectFetching)
    const [text, setText] = useState('')

    const [element, setElement] = useState(null)

    const handleDebounceFn = (searchString) => {
        dispatch(setSearchParams({searchString}))
    }

    const [{searchString}, isReady, debounceQueryUpdate] = useFetchWithQueryParams(
        {
            initialValue: {
                searchString: '',
                user: null,
            },
            currentPage: CURRENT_PAGE,
            callback: handleDebounceFn
        }
    )


    useEffect(() => {
        if (isReady) {
            dispatch(fetchBusinesses({q: searchString}))
        }
    }, [searchString, isReady, dispatch])

    useEffect(() => {
        dispatch(fetchCategories())
    }, [dispatch])


    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchBusinessesByNextToken
    )

    const match = useMatch(ROUTES.ADMIN_DASHBOARD.BUSINESSES)

    const handleSearchChange = (text) => {
        setText(text)
        debounceQueryUpdate(text)
    }


    return (
        <>
            <Outlet/>
            {
                match &&
                <div className={layoutClasses.Container}>
                    <AdminSidebar>
                        <div style={{height: 50}}/>
                    </AdminSidebar>
                    <div className={layoutClasses.Main}>
                        <SearchContainer>
                            <SearchInput
                                value={text}
                                placeholder={'Search business...'}
                                onChange={handleSearchChange}
                            />
                        </SearchContainer>
                        <TableWrapper>
                            {/*HEADER*/}
                            <TableHeader className={classes.Header}>
                                <div className={classes.Name}>
                                    <span>Business name</span>
                                </div>
                                <div className={classes.OwnerName}>
                                    <span>Owner name</span>
                                </div>
                                <div className={classes.HasDevice}>
                                    <span>Has device</span>
                                </div>
                            </TableHeader>
                            {/*HEADER*/}
                            {/*ITEMS*/}
                            {
                                businesses?.map((business, index, array) => {
                                    return <React.Fragment key={business.id}>
                                        <TableItem
                                            className={`${classes.BusinessItem} ${business.isShadowBanned ? classes.Banned : ''}`}
                                        >
                                            <Avatar
                                                className={classes.Logo}
                                                name={business?.name}
                                                img={business?.logo?.url}
                                            />
                                            <div className={classes.Name}>
                                                <span>{business?.name}</span>
                                            </div>
                                            <div className={classes.OwnerName}>
                                                <span>{business?.ownerName}</span>
                                            </div>
                                            <div className={classes.HasDevice}>
                                                <SwitchButton checked={business.isActive} onChange={(event) => {
                                                    dispatch(updateBusinessThunk({
                                                        business: {
                                                            isActive: event.target.checked
                                                        },
                                                        businessId: business.id
                                                    }))
                                                }}/>
                                                <MoreDropdown>
                                                    <MoreItem
                                                        text={'Edit'}
                                                        onClick={() => {
                                                            dispatch(setActiveBusiness(business.id))
                                                            navigate(`${ROUTES.ADMIN_DASHBOARD.BUSINESSES}/${business.id}/update`)
                                                        }}
                                                    />
                                                    {/*<MoreItem*/}
                                                    {/*    text={business.shadowBanned ? 'Remove shadow ban' : 'Shadow ban'}*/}
                                                    {/*    onClick={() => {*/}
                                                    {/*        if (business.shadowBanned) {*/}
                                                    {/*            dispatch(deleteBusinessShadowBanThunk(business.id))*/}
                                                    {/*        } else {*/}
                                                    {/*            dispatch(createBusinessShadowBanThunk(business.id))*/}
                                                    {/*        }*/}
                                                    {/*    }}*/}
                                                    {/*    danger={!business.shadowBanned}*/}
                                                    {/*/>*/}
                                                </MoreDropdown>
                                            </div>
                                        </TableItem>
                                        {
                                            returnObservableItem(array.length, LIMIT, index, setElement)
                                        }
                                    </React.Fragment>
                                })
                            }
                            {
                                fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                            }
                            {/*ITEMS*/}
                        </TableWrapper>
                    </div>
                </div>
            }
        </>
    );
};

export default Businesses;
