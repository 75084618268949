import React, { useCallback, useEffect, useState } from 'react'
import classes from './Promotion.module.scss'
import { FullscreenPreloader, Preloader } from '../../../submodules/naoo-web-components/Components/Preloader/Preloader'
import spinnerClasses from '../../Spinner.module.scss'
import TopNav from '../../../Components/TopNav/TopNav'
import topNavClasses from '../../../Components/TopNav/TopNav.module.scss'
import { handleErrors, validateNumberInput } from '../../../shared/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  createPromotionThunk,
  fetchPromotionByIdThunk,
  selectActivePromotion,
  selectStatus,
  setActivePromotion,
  setStatus,
  updatePromotionThunk,
} from '../../../store/reducers/promotionsReducer'
import { useNavigate, useParams } from 'react-router-dom'
import UserItem from '../../../submodules/naoo-web-components/Components/UserItem/UserItem'
import { PlusButton } from '../../../submodules/naoo-web-components/Components/Button/Buttons'
import Dropzone from '../../../submodules/naoo-web-components/Components/Dropzone/Dropzone'
import Input from '../../../submodules/naoo-web-components/Components/Input/Input'
import { sendImage } from '../../../submodules/naoo-web-components/Shared/utility/sendImage'
import { ReactComponent as NaooIcon } from '../../../shared/icons/naooIcon.svg'
import BusinessItem from '../../Businesses/BusinessItem/BusinessItem'
import { isValidEmail } from '../../../submodules/naoo-web-components/Shared/utility/emailValidation'
import DeletePromotionModal from '../DeletePromotionModal'
import Container from '../../../Components/FormComponents/Container/Container'
import Section from '../../../Components/FormComponents/Section/Section'
import Margin from '../../../submodules/naoo-web-components/Components/Margin/Margin'
import { createMediaPresignedUrl, getMedia } from '../../../api/mediaApi'

const Promotion = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { id } = useParams()


  const promotion = useSelector(state => selectActivePromotion(state))
  const status = useSelector(state => selectStatus(state))

  const [users, setUsers] = useState(promotion?.users || [])
  const [businesses, setBusinesses] = useState(promotion?.businesses || [])
  const [img, setImg] = useState(promotion?.mediaUrl || null)
  const [imgUrl, setImgUrl] = useState(promotion?.mediaUrl || '')
  const [imgLoading, setImgLoading] = useState(false)
  const [englishMessage, setEnglishMessage] = useState(promotion?.message || '')
  const [germanMessage, setGermanMessage] = useState(promotion?.messageDe || '')
  const [points, setPoints] = useState(promotion?.points || '')
  const [email, setEmail] = useState(promotion?.emailForNotifications || '')
  const [name, setName] = useState(promotion?.nameForNotifications || '')

  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false)

  const errors = {
    englishMessage: englishMessage.length === 0,
    germanMessage: germanMessage.length === 0,
    points: points.length === 0,
    email: !isValidEmail(email) && email.length !== 0,
  }
  useEffect(() => {
    if (id) {
      dispatch(fetchPromotionByIdThunk(id))
    }
  }, [id, dispatch])

  useEffect(() => {
    setUsers(!promotion?.users || promotion?.users?.length === 0 ? [] : promotion?.users)
    setBusinesses(!promotion?.businesses || promotion?.businesses?.length === 0 ? [] : promotion?.businesses)
    setImg(promotion?.mediaUrl || null)
    setImgUrl(promotion?.mediaUrl || '')
    setEnglishMessage(promotion?.message || '')
    setGermanMessage(promotion?.messageDe || '')
    setPoints(promotion?.points || '')
    setEmail(promotion?.emailForNotifications || '')
    setName(promotion?.nameForNotifications || '')
  }, [promotion])

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  const handleSaveClick = () => {
    const data = {
      message: englishMessage,
      messageDe: germanMessage,
      points: +points,
      mediaUrl: imgUrl,
      businessIds: businesses?.filter((item) => item !== null && item !== '').map((item => item.id)),
      userIds: users?.filter((item) => item !== null && item !== '').map((item => item.id)),
      emailForNotifications: email,
      nameForNotifications: name,
    }

    promotion
      ? dispatch(updatePromotionThunk({
        promotion: data,
        id: promotion.id,
      }))
      : dispatch(createPromotionThunk(data))
  }

  const handleDiscardClick = () => {
    navigate(-1)
  }

  const handleDeleteClick = () => {
    setIsShowDeleteModal(true)
  }

  const topNavButtons = [
    {
      title: promotion ? 'Delete promotion' : 'Discard promotion',
      onClick: promotion ? handleDeleteClick : handleDiscardClick,
      isDisabled: false,
      className: topNavClasses.DangerButton,
    },
    {
      title: 'Save',
      onClick: handleSaveClick,
      isDisabled: handleErrors(errors),
      className: `${topNavClasses.SuccessButton} ${handleErrors(errors) ? topNavClasses.Disabled : ''}`,
    },
  ]

  const handleDropzone = useCallback((images) => {
    setImgLoading(true)
    sendImage(URL.createObjectURL(images[0]), createMediaPresignedUrl, getMedia)
      .then((data) => {
        setImg(data.mediaUrl)
        setImgUrl(data.mediaUrl)
        setImgLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setImgLoading(false)
      })
  }, [])

  const clearDropzone = () => {
    setImg('')
    setImgUrl('')
  }

  const handlePointsChange = (event) => {
    const isValidInput = validateNumberInput(event)
    if (isValidInput) {
      setPoints(event.target.value)
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value)
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  useEffect(() => {
    if (status === 'successfully') {
      dispatch(setStatus(null))
      dispatch(setActivePromotion())
      navigate(-1)
    }
  }, [dispatch, navigate, status])

  const handleAddUserClick = () => {
    setUsers(prevState => {
      return [...prevState, '']
    })
  }

  const handleAddBusinessClick = () => {
    setBusinesses(prevState => {
      return [...prevState, '']
    })
  }

  return (
    <>
      {
        (status === 'loading') &&
        <FullscreenPreloader
          overlayClassName={spinnerClasses.lightBlurPreloader}
          spinnerClassName={spinnerClasses.spinnerSize}
        />
      }
      {
        isShowDeleteModal &&
        <DeletePromotionModal
          isOpen={isShowDeleteModal}
          message={promotion?.message}
          promotionId={promotion?.id}
          setIsShowModal={setIsShowDeleteModal}
        />
      }
      <Container>
        <TopNav
          buttons={topNavButtons}
          onBack={handleBackButtonClick}
        />
        <div className={classes.FormContainer}>
          <div className={classes.MainContainer}>
            <Section title={'Main'}>
              {
                promotion &&
                <Input
                  label={'ID'}
                  value={promotion.id}
                  onChange={() => {
                  }}
                  maxLength={36}
                />
              }
              <div className={classes.UserContainer}>
                {
                  users.map((user, index) =>
                    <Margin top={16} key={index}>
                      <UserItem label={'User'}
                                user={user}
                                index={index}
                                key={index}
                                isClearable={true}
                                onItemClick={(selectedUser) => {
                                  setUsers((prevState) => {
                                    const stateCopy = [...prevState]
                                    stateCopy[index] = selectedUser
                                    return [...stateCopy]
                                  })
                                }}
                      />
                    </Margin>,
                  )
                }
                <PlusButton
                  className={classes.AddUserButton}
                  onClick={handleAddUserClick}
                >
                  Add user
                </PlusButton>
              </div>
              <div className={classes.BusinessItemContainer}>
                {
                  businesses.map((business, index) =>
                    <BusinessItem
                      key={index}
                      business={business}
                      label={'Business'}
                      isClearable={true}
                      onItemClick={(business) => {
                        setBusinesses((prevState) => {
                          const stateCopy = [...prevState]
                          stateCopy[index] = business
                          return [...stateCopy]
                        })
                      }}
                    />,
                  )
                }
                <PlusButton
                  className={classes.AddUserButton}
                  onClick={handleAddBusinessClick}
                >
                  Add business
                </PlusButton>
              </div>
              <Margin top={32}>
                <Input
                  label={'Email for notifications'}
                  name={'email'}
                  placeholder={'Enter Email'}
                  value={email}
                  onChange={handleEmailChange}
                  error={errors.email}
                />
              </Margin>
              <Margin top={32}>
                <Input
                  label={'Name for notifications'}
                  name={'Name'}
                  placeholder={'Enter Name'}
                  value={name}
                  onChange={handleNameChange}
                  error={errors.name}
                />
              </Margin>
            </Section>
            <Section title={'Message'}>
              <div className={classes.DescriptionContainer}>
                <span className={`${classes.DescriptionLabel} ${classes.Label}`}>English message</span>
                <textarea
                  name={'text'}
                  className={classes.DescriptionInput}
                  placeholder='Your message in English'
                  value={englishMessage}
                  maxLength={512}
                  onChange={(event) => {
                    setEnglishMessage(event.target.value)
                  }}
                />
                <span className={classes.DescriptionLength}>{englishMessage.length}/512</span>
              </div>
              <div className={classes.DescriptionContainer}>
                <span className={`${classes.DescriptionLabel} ${classes.Label}`}>German message</span>
                <textarea
                  name={'text'}
                  className={classes.DescriptionInput}
                  placeholder='Your message in German'
                  value={germanMessage}
                  maxLength={512}
                  onChange={(event) => {
                    setGermanMessage(event.target.value)
                  }}
                />
                <span className={classes.DescriptionLength}>{germanMessage.length}/512</span>
              </div>
            </Section>
            <Section title={'Points'}>
              <Margin top={24}>
                <Input
                  label={'Points amount'}
                  name={'points'}
                  placeholder={'Points amount'}
                  value={points}
                  onChange={handlePointsChange}
                  Icon={NaooIcon}
                />
              </Margin>
            </Section>
          </div>

          <div className={classes.PreviewContainer}>
            <Section title={'Image preview'}>
              {
                imgLoading
                  ? <div className={classes.Loader}>
                    <Preloader />
                  </div>
                  : <Dropzone
                    media={img}
                    onChange={handleDropzone}
                    className={`${classes.DropZone} ${img && classes.WithoutBorder}`}
                    onClear={clearDropzone}
                  />
              }
              <Input
                className={classes.Input}
                label={'Or enter image URL'}
                name={'title'}
                placeholder={'Your image URL'}
                value={imgUrl}
                onChange={(event) => {
                  setImgUrl(event.target.value)
                  setImg(event.target.value)
                }}
              />
            </Section>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Promotion