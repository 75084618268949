import React from 'react';
import Dropdown from "../../../submodules/naoo-web-components/Components/Dropdown/Dropdown";
import classes from './SearchDropdown.module.scss'

const SearchDropdown = ({className, ...props}) => {
    return (
       <Dropdown className={`${classes.DropDown} ${className ?? ''}`} {...props}/>
    );
};

export default SearchDropdown;