import {
    generateUrlWithQueryParams
} from "../submodules/naoo-web-components/Api/generateUrlWithQueryParams/generateUrlWithQueryParams";
import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {withAdmin} from "./withAdmin";

const getUsersReports = ({status, nextToken}) => {
    const url = generateUrlWithQueryParams('/user_content_reports', {
        nextToken,
        status
    })
    return  createRequest('main', 'get', withAdmin(url),null)
}
const updateUserReport = ({report, id}) =>
    createRequest('main', 'patch', withAdmin(`/user_content_reports/${id}`), report )

export {
    getUsersReports,
    updateUserReport,
}