import React from 'react';
import {useNavigate} from "react-router-dom";
import classes from "../CreateOrUpdateOffer.module.scss";
import ModalWindow from "../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";

const DiscardModal = ({offerTitle, isShow, onClose}) => {
    const navigate = useNavigate()

    const handleButtonClick = ()=>{
        navigate(-1)
    }

    return (
        <ModalWindow
            isOpen={isShow}
            title={'Discard changes?'}
            primaryButton={'Discard changes and leave page'}
            handlePrimaryClick={handleButtonClick}
            onClose={onClose}
        >
            <div className={classes.Text}>
                Your changes in the offer <strong>"{offerTitle}" </strong> won’t be saved.
            </div>
        </ModalWindow>
    );
};

export default DiscardModal;