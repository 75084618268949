import React from 'react';
import PostMediaPreview from "./PostMediaPreview";
import ImgAndButtons from "./ImgAndButtons";
import {useSelector} from "react-redux";
import {selectActivePost} from "../../../../store/reducers/postsReducer";
import PostButtons from "./PostButtons";
import PostTextPlusComments from "./PostTextPlusComments";
import Comments from "../../../Reports/Report/Components/Comments/Comments";

const PreviewPost = () => {
    const post = useSelector(selectActivePost)
    return (
        <PostMediaPreview>
            {
                post?.media?.[0]?.url &&
                <ImgAndButtons post={post}>
                    <PostButtons post={post}/>
                </ImgAndButtons>
            }
            <PostTextPlusComments post={post}>
                <Comments/>
            </PostTextPlusComments>

        </PostMediaPreview>
    );
};

export default PreviewPost;