import React from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import classes from './DeleteBusinessModal.module.scss'
import {deleteBusinessThunk} from "../../../../store/reducers/businessesReducer";
import ModalWindow from "../../../../submodules/naoo-web-components/Components/ModalWindow/ModalWindow";

const DeleteBusinessModal = ({business, isOpen, setIsShowModal}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleDeleteButtonClick = () => {
        dispatch(deleteBusinessThunk(business.id))
            .then(() => {
                navigate(-1)
            })
    }

    const handleCloseClick = () => {
        setIsShowModal(false)
    }

    return (
        <ModalWindow
            isOpen={isOpen}
            title={`Are you sure you want to delete ${business.name} and all data?`}
            onClose={handleCloseClick}
            primaryButton={'Delete'}
            handlePrimaryClick={handleDeleteButtonClick}
            secondaryButton={'Cancel'}
            handleSecondaryClick={handleCloseClick}
        >
            <div className={classes.ModalText}>
                This can’t be undone.
            </div>
        </ModalWindow>
    );
};

export default DeleteBusinessModal;