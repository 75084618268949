import React from 'react';
import classes from "../Notification.module.scss";
import Dropzone from "../../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {Preloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import Section from "../../../../Components/FormComponents/Section/Section";

const Preview = ({imgUrl, setImgUrl, setImg, img, isLoading, handleDropzone, onClear, isDisabled}) => {
    return (
        <div className={classes.PreviewContainer}>
            <Section>
                <div className={classes.Preview}>
                    <div className={classes.Title}>
                        <span>Image preview</span>
                    </div>
                    {
                        isLoading
                            ? <div className={classes.Loader}>
                                <Preloader/>
                            </div>
                            : <Dropzone
                                media={img}
                                onChange={handleDropzone}
                                className={`${classes.DropZone} ${img && classes.WithoutBorder}`}
                                onClear={onClear}
                            />
                    }
                    <Input
                        className={classes.Input}
                        label={'Or enter image URL'}
                        name={'title'}
                        placeholder={'Your image URL'}
                        value={imgUrl}
                        onChange={(event) => {
                            setImgUrl(event.target.value)
                            setImg(event.target.value)
                        }}
                    />
                </div>
                {
                    isDisabled && <div className={classes.DisablePreview}/>
                }
            </Section>
        </div>
    );
};

export default Preview;
