import React from 'react';
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";
import {Field} from "../../../../shared/Form/FormField";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import Margin from "../../../../submodules/naoo-web-components/Components/Margin/Margin";

const FirstNameField = ({errors}) => {
    const user = useSelector(selectActiveUser)
    return (
        <Margin top={24}>
            <Field name={'firstName'} initialValue={user?.firstname || ''}>
                {
                    (firstName, setFirstName) => (
                        <Input
                            label={'First name'}
                            name={'firstName'}
                            maxLength={48}
                            placeholder={'First name'}
                            error={errors.firstName}
                            value={firstName}
                            onChange={(event) => {
                                setFirstName(event.target.value)
                            }}
                        />
                    )
                }
            </Field>
        </Margin>
    );
};

export default FirstNameField;