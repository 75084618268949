import React from 'react';
import classes from './Section.module.scss'
import Title from "./Title/Title";

const Section = ({title, children}) => {
    return (
        <div className={classes.Section}>
            {
                title && <Title text={title}/>
            }
            {children}
        </div>
    );
};

export default Section;