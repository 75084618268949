import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    createVoucherThunk,
    selectActiveVoucher,
    selectStatus, setActiveVoucher, setStatus,
    updateVoucherThunk
} from "../../../store/reducers/vouchersReducer";
import {FullscreenPreloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../Spinner.module.scss";
import classes from "./VoucherForm.module.scss";
import TopNav from "../../../Components/TopNav/TopNav";
import Input from "../../../submodules/naoo-web-components/Components/Input/Input";
import Dropzone from "../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";
import {useNavigate} from "react-router-dom";
import topNavClasses from "../../../Components/TopNav/TopNav.module.scss";
import {selectBusiness} from "../../../store/reducers/businessesReducer";

const VoucherForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const status = useSelector(selectStatus)
    const voucher = useSelector(selectActiveVoucher)
    const business = useSelector(selectBusiness)

    const [title, setTitle] = useState(voucher?.title || '')
    const [description, setDescription] = useState(voucher?.description || '')
    const [img, setImg] = useState(voucher?.media?.url || null)
    const [isSendImg, setIsSendImg] = useState(false)

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    const handleDropZone = (images) => {
        setImg(URL.createObjectURL(images[0]))
        setIsSendImg(true)
    }
    const handleClearDropzone = () => {
        setImg(null)
    }

    const handleSaveClick = () => {
        if (voucher){
            dispatch(updateVoucherThunk({
                voucher:{
                    title,
                    description,
                    img,
                    businessId: business.id,
                    isSendImg
                },
                id: voucher.id
            }))
        }
        else{
            dispatch(createVoucherThunk({
                title,
                description,
                img,
                businessId: business.id
            }))
        }
    }

    const handleDeleteVoucherClick = () => {

    }


    const topNavButtons = [
        {
            title: 'Save',
            onClick: handleSaveClick,
            isDisabled: title === '',
            className: `${topNavClasses.SuccessButton} ${title === '' ? topNavClasses.Disabled : ''}`
        },
    ]

    if (voucher) {
        topNavButtons.unshift({
            title: 'Delete voucher',
            onClick: handleDeleteVoucherClick,
            isDisabled: false,
            className: topNavClasses.DangerButton
        })
    }

    useEffect(() => {
        if (status === 'successfully') {
            dispatch(setStatus(null))
            dispatch(setActiveVoucher())
            navigate(-1)
        }
    }, [status, dispatch, navigate])

    return (
        <div>
            {
                status === 'loading' &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }
            <div className={classes.Container}>
                <TopNav
                    buttons={topNavButtons}
                    onBack={() => navigate(-1)}
                />

                <div className={classes.FormContainer}>
                    <div className={classes.MainContainer}>
                        <div className={classes.Section}>
                            <div className={classes.Title}>
                                <span>Main</span>
                            </div>

                            <Input
                                className={classes.Input}
                                label={'Title'}
                                placeholder={'Enter the voucher title'}
                                name={'title'}
                                value={title}
                                onChange={handleTitleChange}
                                error={title === ''}
                            />

                            <div className={classes.DescriptionContainer}>
                                <span className={`${classes.DescriptionLabel} ${classes.Label}`}>Description</span>
                                <textarea
                                    name={'text'}
                                    className={classes.DescriptionInput}
                                    placeholder="Enter channel description"
                                    value={description}
                                    maxLength={512}
                                    onChange={handleDescriptionChange}
                                />
                            </div>

                        </div>
                    </div>

                    <div className={classes.PreviewContainer}>
                        <div className={classes.Section}>
                            <div className={classes.Title}>
                                <span>Voucher cover</span>
                            </div>
                            <Dropzone
                                media={img}
                                onClear={handleClearDropzone}
                                className={classes.Dropzone}
                                onChange={handleDropZone}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VoucherForm;