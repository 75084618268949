import React from 'react'
import { RESTRICTIONS } from '../UserRestrictionsMoreOptionsItem/UserRestrictionsMoreOptionsItem'
import CheckBox from '../../../submodules/naoo-web-components/Components/CheckBox/CheckBox'
import classes from './RestrictionsSection.module.scss'
import { Field } from '../../../shared/Form/FormField'
import { useSelector } from 'react-redux'
import { selectActiveUser } from '../../../store/reducers/usersReducer'

const checkRestrictionExist = (restriction, restrictions) => {
  return restrictions?.includes(restriction.id)
}

const RestrictionsSection = ({ restrictions, setRestrictions }) => {
  const user = useSelector(selectActiveUser)

  return (
    <Field name={'restrictions'} initialValue={user?.restrictions ?? []}>
      {(_restrictions, _setRestrictions) => {
        const handleRestrictionClick = (restriction, isRestrictionExist) => () => {
          if (!isRestrictionExist) {
            setRestrictions([...restrictions, restriction.id])
          } else {
            setRestrictions(restrictions.filter((item) => item !== restriction.id))
          }
        }

        return (
          <>
            {RESTRICTIONS.map((item) => {
              const isRestrictionExist = checkRestrictionExist(item, restrictions)
              return (
                <div
                  key={item.text}
                  onClick={handleRestrictionClick(item, isRestrictionExist)}
                  className={classes.Container}>
                  <CheckBox isChecked={isRestrictionExist} onClick={(e) => e.stopPropagation()} readOnly />
                  <span className={classes.RestrictionLabel}>{item.text}</span>
                </div>
              )
            })}
          </>
        )
      }}
    </Field>
  )
}

export default RestrictionsSection
