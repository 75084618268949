import React, {useState} from 'react';
import classes from "../User.module.scss";
import Title from "../../../../Components/FormComponents/Section/Title/Title";
import {Field} from "../../../../shared/Form/FormField";
import {sendImage} from "../../../../submodules/naoo-web-components/Shared/utility/sendImage";
import {createMediaPresignedUrl, getMedia} from "../../../../api/mediaApi";
import Gallery from "../../../../submodules/naoo-web-components/Components/Gallery/Gallery";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";

const GalleryField = () => {
    const user = useSelector(selectActiveUser)
    const [isLoadingGallery, setIsLoadingGallery] = useState(false)

    return (
        <div className={classes.GalleryContainer}>
            <Title text={'Gallery'}/>
            <Field name={'profileMediaIds'} initialValue={user?.profileMedia || []}>
                {
                    (galleryItems, setGalleryItems) => {
                        const handleAddGalleryItems = (urlList) => {
                            setIsLoadingGallery(true)
                            for (const url of urlList) {
                                sendImage(url, createMediaPresignedUrl, getMedia, user.id)
                                    .then((response) => {
                                        setGalleryItems((prevState) => {
                                            return [...prevState, {url, id: response.id}]
                                        })
                                        setIsLoadingGallery(false)
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        setIsLoadingGallery(false)
                                    })
                            }
                        }

                        const deleteGalleryItem = (item) => {
                            setGalleryItems((prevState) => {
                                return prevState.filter((media) => media?.id !== item)
                            })
                        }

                        return (
                            <Gallery
                                items={galleryItems}
                                onAdd={handleAddGalleryItems}
                                onDelete={deleteGalleryItem}
                                isLoading={isLoadingGallery}
                                className={classes.Gallery}
                                itemClassName={classes.ItemStyle}
                                dropzoneClassName={!!galleryItems.length ? classes.GalleryDropzoneWithMedia : classes.GalleryDropzoneWithoutMedia}
                            />
                        )
                    }
                }
            </Field>
        </div>
    );
};

export default GalleryField;