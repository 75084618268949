import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {
    generateUrlWithQueryParams
} from "../submodules/naoo-web-components/Api/generateUrlWithQueryParams/generateUrlWithQueryParams";

 const getPosts = (params) => {
     return createRequest('main', 'get', '/posts', null, params)
}
 const createPost = (post) =>
    createRequest('main', 'post', '/posts', post )
 const getPost = (postId) =>
    createRequest('main', 'get', `/posts/${postId}`, null )
 const updatePost = (post, postId) =>
    createRequest('main', 'patch', `/posts/${postId}`, post)
 const deletePost = (postId) =>
    createRequest('main', 'delete', `/posts/${postId}`, null)

 const createLike = (postId) =>
    createRequest('main', 'post', `/posts/${postId}/likes`, null)
 const deleteLike = (postId) =>
    createRequest('main', 'delete', `/posts/${postId}/likes`, null)
 const getPostStats = ({id, startTime, endTime }) => {
    const url = generateUrlWithQueryParams( `/posts/${id}/stats_history`, {
        startTime,
        endTime
    })
    return createRequest('main', 'get', url , null)
}
const getComments = ({id, nextToken}) =>{

    const url = generateUrlWithQueryParams(`/posts/${id}/comments`,{
        nextToken
    })
    return createRequest('main', 'get', url, null)
}

const deleteComment = ({pid, cid}) => createRequest('main', 'delete', `/posts/${pid}/comments/${cid}`)

export {
    getPosts,
    getPost,
    getPostStats,
    createPost,
    updatePost,
    deletePost,
    createLike,
    deleteLike,
    getComments,
    deleteComment,
}