import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createPoll, getPolls, updatePoll} from "../../api/pollApi";

const initialState = {
    polls: [],
    activePoll: null,
    status: null,
    nextToken: '',
    fetching: false,
}

export const fetchPolls = createAsyncThunk('pools/fetchPolls', async (payload) => {
    const response = await getPolls({
        offset: payload?.offset,
        limit: payload?.limit,
        filterByTags: payload?.filterByTags,
        filterByOptionTags: payload?.filterByOptionTags,
        q: payload?.q,
        excludeInactive: payload?.excludeInactive,
    })
    return response.data
})

export const createPollThunk = createAsyncThunk('polls/createPoll', async (payload) => {
    const response = await createPoll(payload)
    return response.data
})

export const updatePollThunk = createAsyncThunk('polls/updatePoll', async (payload) => {
    const response = await updatePoll(payload?.pollObject, payload?.id)
    return response.data
})

const poolsSlice = createSlice({
    name: 'polls',
    initialState,
    reducers: {
        setActivePoll: (state, action) => {
            state.activePoll = state.polls.find((item) => item.id === action.payload)
        },
        resetActivePoll: (state) => {
            state.activePoll = null
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setFetching: (state, action) => {
            state.fetching = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPolls.fulfilled, (state, action) => {
            state.polls = action.payload
            state.nextToken = action.payload.nextToken
        })
        builder.addCase(createPollThunk.pending, (state) => {
            state.status = 'loading'
        })
        builder.addCase(createPollThunk.fulfilled, (state, action) => {
            state.polls.unshift(action.payload)
            state.status = 'successfully'
        })
        builder.addCase(createPollThunk.rejected, (state) => {
            state.status = 'error'
        })
        builder.addCase(updatePollThunk.pending, (state, action) => {
            state.status = 'loading'
        })
        builder.addCase(updatePollThunk.fulfilled, (state, action) => {
            state.polls = state.polls.map((poll) => {
                if (poll.id === action.payload.id) return action.payload
                return poll
            })
            state.activePoll = action.payload
            state.status = 'successfully'
        })
        builder.addCase(updatePollThunk.rejected, (state, action) => {
            state.status = 'error'
        })
    },
})

export default poolsSlice.reducer
export const {setActivePoll, resetActivePoll, setStatus, setFetching} = poolsSlice.actions

export const selectPolls = (state, {q, pollTag, answerTag, isOnlyActive}) => {
    const polls = state.polls.polls

    const lowerCaseSearch = q?.toLowerCase()

    return polls.filter(
        poll =>  {
            const isQMatch = !!q ? poll?.text?.toLowerCase().includes(lowerCaseSearch) : true
            const isPollTagMatch = pollTag ? poll.tags?.map(tag=>tag.id).includes(pollTag.id) : true
            const isAnswerTagMatch = answerTag
                ? poll?.options.map(option=>option.tags).flat().filter(Boolean).map(it => it.id).includes(answerTag.id)
                : true
            const isOnlyActiveMatch = isOnlyActive ? poll.isActive === isOnlyActive  : true
            return (isQMatch && isPollTagMatch && isAnswerTagMatch && isOnlyActiveMatch)
        }
    )
}

export const selectActivePoll = (state) => state.polls.activePoll
export const selectStatus = (state) => state.polls.status
export const selectNextToken = (state) => state.polls.nextToken
export const selectFetching = (state) => state.polls.fetching
