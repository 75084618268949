import React from 'react';
import classes from './PostedBy.module.scss'
import Avatar from "../../../../submodules/naoo-web-components/Components/Avatar/Avatar";

const PostedBy = ({mediaClass, textClass, channel, user}) => {
    return (
        <>
            {
                channel
                    ? <>
                        <Avatar
                            className={mediaClass}
                            name={channel?.title}
                            img={channel?.media?.url}
                        />
                        <div className={`${textClass} ${classes.Text}`}>
                            <div className={classes.Title}>
                                {channel?.title}
                            </div>
                            <div className={classes.User}>
                               ({user?.firstname} {user?.lastname})
                            </div>
                        </div>
                      </>
                    :<>
                        <Avatar
                            className={mediaClass}
                            name={user?.firstname}
                            lastname={user?.lastname}
                            img={user?.avatar?.url}
                        />
                        <div className={`${textClass} ${classes.Text}`}>
                            <div className={classes.Title}>
                                {user?.firstname} {user?.lastname}
                            </div>
                        </div>
                    </>
            }

        </>
    );
};

export default PostedBy;