import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import topNavClasses from "../../../Components/TopNav/TopNav.module.scss";
import {
    createCompetitionThunk,
    selectCompetition,
    selectStatus, setStatus,
    updateCompetitionThunk
} from "../../../store/reducers/competitionsReducer";
import {FullscreenPreloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../Spinner.module.scss";
import TopNav from "../../../Components/TopNav/TopNav";
import Input from "../../../submodules/naoo-web-components/Components/Input/Input";
import classes from './Competition.module.scss'
import Dropzone from "../../../submodules/naoo-web-components/Components/Dropzone/Dropzone";
import {validateFloatNumberInput, validateNumberInput} from "../../../shared/utils/utils";
import {ReactComponent as NaooIcon} from "../../../shared/icons/naooIcon.svg";
import {ReactComponent as PercentIcon} from "../../../shared/icons/percent.svg";
import {ROUTES} from "../../../submodules/naoo-web-components/Shared/constants";
import Container from "../../../Components/FormComponents/Container/Container";
import Section from "../../../Components/FormComponents/Section/Section";
import Margin from "../../../submodules/naoo-web-components/Components/Margin/Margin";
import {format} from "date-fns";
import DateTimePicker from "../../../submodules/naoo-web-components/Components/Date/DateTimePicker/DateTimePicker";
import {getUserById} from "../../../api/userApi";
import {getUsername} from "../../../submodules/naoo-web-components/Shared/utility/utils";

const Competition = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const competition = useSelector(state => selectCompetition(state))
    const status = useSelector(state => selectStatus(state))

    const viewFlag = location.pathname === `${ROUTES.ADMIN_DASHBOARD.COMPETITIONS}/${competition?.id}`

    const [title, setTitle] = useState(competition?.title || '')
    const [points, setPoints] = useState(competition?.coins || '')
    const [startTime, setStartTime] = useState(competition?.startTime || null)
    const [endTime, setEndTime] = useState(competition?.endTime || null)
    const [probability, setProbability] = useState(competition?.probability * 100 || '')
    const [img, setImg] = useState(competition?.media?.url || null)
    const [isSendImg, setIsSendImg] = useState(false)
    const [winner, setWinner] = useState(null)

    useEffect(() => {
        if (competition?.latestWin?.userId){
            getUserById(competition.latestWin?.userId)
                .then((res)=> setWinner( res.data ))
        }
    }, [competition?.latestWin]);

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    const handlePointsChange = (event) => {
        const isValidInput = validateNumberInput(event)
        if (isValidInput) {
            setPoints(event.target.value)
        }
    }

    const handleProbabilityChange = (event) => {
        const isValidInput = validateFloatNumberInput(event)
        const probability = +event.target.value
        if (isValidInput && probability >= 0 && probability <= 100) {
            setProbability(event.target.value)
        }
    }

    const handleProbabilityBlur = () => {
        setProbability(parseFloat(probability))
    }

    const handleDropZone = (images) => {
        setIsSendImg(true)
        setImg(URL.createObjectURL(images[0]))
    }

    const handleClearDropzone = () => {
        setImg(null)
    }

    const handleStartTime = (value) => {
        setStartTime(value)
    }

    const handleEndTime = (value) => {
        setEndTime(value)
    }

    const handleOnSuccessClick = () => {
        if (viewFlag) {
            navigate(`${ROUTES.ADMIN_DASHBOARD.COMPETITIONS}/${competition?.id}/update`)
            return
        }

        const data = {
            title,
            coins: +points,
            probability: +probability / 100,
            startTime,
            endTime,
        }
        if (isSendImg) data.img = img

        if (competition) {
            dispatch(updateCompetitionThunk({
                competition: data,
                id: competition.id
            }))
        } else {
            dispatch(createCompetitionThunk(data))
        }
    }


    const topNavButtons = [
        {
            title: viewFlag ? 'Edit' : 'Save',
            onClick: handleOnSuccessClick,
            isDisabled: false,
            className: topNavClasses.SuccessButton
        },
    ]

    const emptyHandler = () => {
    }

    if (status === 'successfully_update') {
        dispatch(setStatus(null))
        navigate(-2)
    } else if (status === 'successfully_create') {
        dispatch(setStatus(null))
        navigate(-1)
    }
    return (
        <>
            {
                status === 'loading' &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }

            <Container>
                <TopNav
                    buttons={topNavButtons}
                    onBack={() => navigate(-1)}
                />
                <div className={classes.FormContainer}>
                    <div className={classes.MainContainer}>
                        <Section title={'Competition'}>
                            <Margin bottom={24}>
                                <Input
                                    label={'Title'}
                                    placeholder={'Your competition title'}
                                    name={'Title'}
                                    value={title}
                                    onChange={viewFlag ? emptyHandler : handleTitleChange}
                                />
                            </Margin>
                            <Margin bottom={24}>
                                <Input
                                    label={'Points amount'}
                                    placeholder={'Enter points amount'}
                                    name={'Points'}
                                    value={points}
                                    onChange={viewFlag ? emptyHandler : handlePointsChange}
                                    Icon={NaooIcon}
                                />
                            </Margin>
                            <Margin bottom={24}>
                                <Input
                                    label={'Probability'}
                                    placeholder={'Enter probability'}
                                    name={'Probability'}
                                    value={probability}
                                    onChange={viewFlag ? emptyHandler : handleProbabilityChange}
                                    onBlur={handleProbabilityBlur}
                                    Icon={PercentIcon}
                                />
                            </Margin>
                            <div className={classes.DateContainer}>
                                <div>
                                    {
                                        viewFlag ?
                                            <Margin bottom={24}>
                                                <Input
                                                    label={'Start'}
                                                    placeholder={'Start'}
                                                    name={'Start'}
                                                    value={startTime ? format(new Date(startTime), "dd.MM.yyyy 'at' HH:mm") : '-'}
                                                    onChange={emptyHandler}
                                                />
                                            </Margin>
                                            :
                                            <>
                                                <span className={classes.Label}>Start</span>
                                                <DateTimePicker
                                                    className={classes.DatePicker}
                                                    placeholder={'Start'}
                                                    value={new Date(startTime || Date.now())}
                                                    format={"dd.MM.yyyy 'in' HH:mm"}
                                                    onChange={handleStartTime}
                                                    maxDate={new Date(endTime || Date.now())}
                                                />
                                            </>
                                    }
                                </div>
                                <div>
                                    {
                                        viewFlag ?
                                            <Margin bottom={24}>
                                                <Input
                                                    label={'End'}
                                                    placeholder={'End'}
                                                    name={'End'}
                                                    value={endTime ? format(new Date(endTime), "dd.MM.yyyy 'at' HH:mm") : '-'}
                                                    onChange={emptyHandler}
                                                />
                                            </Margin>
                                            :
                                            <>
                                                <span className={classes.Label}>End</span>
                                                <DateTimePicker
                                                    className={classes.DatePicker}
                                                    placeholder={'End'}
                                                    value={new Date(endTime || Date.now())}
                                                    format={"dd.MM.yyyy 'at' HH:mm"}
                                                    onChange={handleEndTime}
                                                    minDate={new Date(startTime || Date.now())}
                                                />
                                            </>
                                    }
                                </div>
                            </div>
                            {
                                viewFlag &&
                                <div className={classes.ViewData}>
                                    <Margin bottom={24}>
                                        <Input
                                            label={'Updated'}
                                            placeholder={'Updated'}
                                            name={'Updated'}
                                            value={competition?.updatedAt ?  format(new Date(competition?.updatedAt), "dd.MM.yyyy 'at' HH:mm") : '-'}
                                            onChange={emptyHandler}
                                        />
                                    </Margin>
                                    <span className={classes.Label}>Winner</span>
                                    <div className={classes.SelectedUser}>
                                    <span className={classes.SelectedUserName}>
                                        {
                                            winner
                                                ? getUsername(winner)
                                                : '-'
                                        }
                                     </span>
                                        <span className={classes.SelectedUserID}>
                                         {winner?.id}
                                    </span>
                                    </div>
                                    <Margin bottom={24}>
                                        <Input
                                            label={'ID'}
                                            placeholder={'ID'}
                                            name={'ID'}
                                            value={competition?.id || '-'}
                                            onChange={emptyHandler}
                                        />
                                    </Margin>
                                </div>
                            }
                        </Section>
                    </div>
                    <div className={classes.PreviewContainer}>
                        <Section title={'Attachments'}>
                            {
                                viewFlag
                                    ? <img src={img} className={classes.PreviewImg} alt=""/>
                                    : <Dropzone
                                        media={img}
                                        onClear={handleClearDropzone}
                                        className={classes.Dropzone}
                                        onChange={handleDropZone}
                                    />
                            }
                        </Section>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Competition;