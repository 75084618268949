import { createRequest } from '../submodules/naoo-web-components/Api/createRequest'
import { withAdmin } from './withAdmin'

const getUsers = (params) => {
  return createRequest('main', 'get', '/users', null, params)
}

const updateUser = (user, userId) => createRequest('main', 'patch', `/users/${userId}`, user)
const getUserById = (id) => createRequest('main', 'get', `/users/${id}`, null)
const deleteUser = (id) => createRequest('main', 'delete', `/users/${id}`, null)
const createUserShadowBan = (id) => createRequest('main', 'post', withAdmin(`/users/${id}/shadow_ban`), null)
const deleteUserShadowBan = (id) => createRequest('main', 'delete', withAdmin(`/users/${id}/shadow_ban`), null)
const addUserRestriction = ({ userId, restrictionType }) =>
  createRequest('main', 'post', withAdmin(`/users/${userId}/restrictions/${restrictionType}`))
const removeUserRestriction = ({ userId, restrictionType }) =>
  createRequest('main', 'delete', withAdmin(`/users/${userId}/restrictions/${restrictionType}`))

const getSelf = () => createRequest('main', 'get', '/users/self ', null)

export {
  getUsers,
  getUserById,
  updateUser,
  deleteUser,
  addUserRestriction,
  removeUserRestriction,
  createUserShadowBan,
  deleteUserShadowBan,
  getSelf,
}
