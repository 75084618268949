import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {withAdmin} from "./withAdmin";

const getPointsTransactions = (params) =>
    createRequest('main', 'get', withAdmin('/points_transactions'), null, params)

const getPointsTransactionsStats = (params) =>
    createRequest('main', 'get', withAdmin('/points_transactions_stats'), null, params)


export {
    getPointsTransactions,
    getPointsTransactionsStats
}