import React from 'react';
import {Field} from "../../../../shared/Form/FormField";
import MapDropdown from "../../../../submodules/naoo-web-components/Components/MapDropdown/MapDropdown";
import classes from "../User.module.scss";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";

const LocationField = () => {
    const user = useSelector(selectActiveUser)
    return (
        <>
            <span className={`${classes.Label}`}>Location</span>
            <Field name={'location'} initialValue={user?.location || ''}>
                {
                    (location, setLocation) => {
                        const handleLocation = (date) => {
                            setLocation(date)
                        }
                        return (
                            <MapDropdown
                                value={location}
                                className={classes.MapDropdown}
                                placeholder={'Your Location'}
                                onChange={handleLocation}
                                isClearable={true}
                            />
                        )
                    }
                }
            </Field>
        </>
    );
};

export default LocationField;