import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from './UseFormContext'
import deepEqual from 'deep-equal'

const Field = ({ initialValue, name, children }) => {
  const [state, setState] = useState(initialValue)
  const { setForm } = useFormContext()

  const savedInitialValue = useMemo(() => {
    return initialValue
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!deepEqual(state, savedInitialValue)) {
      setForm((prev) => ({
        ...prev,
        values: { ...prev.values, [name]: state },
        changed: { ...prev.changed, [name]: true },
      }))
    } else {
      setForm((prev) => ({
        ...prev,
        values: { ...prev.values, [name]: undefined },
        changed: { ...prev.changed, [name]: false },
      }))
    }
  }, [state, savedInitialValue, name, setForm])

  return children(state, setState)
}

export { Field }
