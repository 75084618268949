import React, {useEffect, useRef, useState} from 'react';
import classes from './TagsMultiselect.module.scss'
import {useOnClickOutside} from "../../../../submodules/naoo-web-components/Shared/hooks/useclickOutside";
import TagItem from "./TagItem/TagItem";

import TagOptions from "../TagOptions/TagOptions";

const TagsMultiselect = ({initialValue = [], options, onChange}) => {
    const [values, setValues] = useState(initialValue)
    const [isShow, setIsShow] = useState(false)
    const [searchString, setSearchString] = useState('')

    const ref = useOnClickOutside(() => {
        setIsShow(false)
        setSearchString('')
    })
    const inputRef = useRef(null)

     useEffect(()=>{
        onChange(values)
    },[values, onChange])

    return (
        <div className={classes.Container}>
            {
                values.map((value, index) => {
                    return (
                        <TagItem key={value.id} tag={value}
                                 onClick={(tag) => {
                                     setValues(prev=>[...values.filter((value)=>value.id!==tag.id)])
                                 }}
                        />
                    )
                })
            }
            <div className={classes.InputContainer} ref={ref}>
                <input type="text"
                       ref={inputRef}
                       value={searchString}
                       placeholder={'Enter tags'}
                       onChange={(event) => {
                           setSearchString(event.target.value)
                       }}
                       onClick={() => {
                           setIsShow(!isShow)
                           if (isShow) {
                             inputRef.current.blur()
                           }
                           else{
                               inputRef.current.focus()
                           }
                       }}
                />
                {
                    isShow &&
                        <TagOptions
                            values={values}
                            options={options}
                            onItemClick={(item)=>{
                                setValues(prev => [...prev, item])
                                setSearchString('')
                                inputRef.current.focus()
                            }}
                            searchString={searchString}
                        />
                }
            </div>
               <span className={classes.Length}>{values.length} tags</span>
        </div>
    );
};

export default TagsMultiselect;
