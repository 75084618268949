import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {
    generateUrlWithQueryParams
} from "../submodules/naoo-web-components/Api/generateUrlWithQueryParams/generateUrlWithQueryParams";
import {withAdmin} from "./withAdmin";

const getPolls = ({offset, limit, filterByTags, filterByOptionTags, tag, q, nextToken, excludeInactive}) => {
    const url = generateUrlWithQueryParams(withAdmin('/polls'), {
        nextToken,
        limit,
        filterByTags,
        filterByOptionTags,
        tag,
        q,
        excludeInactive,
        offset
    })
    return createRequest('main', 'get', url, null)
}
const createPoll = (pollObject) =>
    createRequest('main', 'post', withAdmin('/polls'), pollObject)
const updatePoll = (pollObject, pollId) =>
    createRequest('main', 'patch', withAdmin(`/polls/${pollId}`), pollObject)

export {getPolls, createPoll, updatePoll}