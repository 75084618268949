import React from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {deleteTemplateThunk, setActiveTemplate, setStatus} from "../../../store/reducers/notificationTemplatesReducer";
import MoreDropdown from "../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";

const NotificationMoreDropdown = ({templateId}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleEditClick = (event)=>{
        event.stopPropagation()
        dispatch(setActiveTemplate(templateId))
        dispatch(setStatus(null))
        navigate(`/notifications/${templateId}/update`)
    }

    const handleEditCopyClick = (event)=>{
        event.stopPropagation()
        dispatch(setActiveTemplate(templateId))
        navigate(`/notifications/${templateId}/update?copy=true`)
    }

    const handleDeleteClick = (event)=>{
        event.stopPropagation()
        dispatch(deleteTemplateThunk(templateId))
    }

    return (
            <MoreDropdown>
                <MoreItem text={'Edit'} onClick={handleEditClick}/>
                <MoreItem text={'Edit Copy'} onClick={handleEditCopyClick}/>
                <MoreItem text={'Delete'} onClick={handleDeleteClick} danger/>
            </MoreDropdown>
    );
};

export default NotificationMoreDropdown;