import React from 'react';
import classes from "./PostMediaPreview.module.scss";
import ModalOverlay from "../../../../submodules/naoo-web-components/Components/ModalOverlay/ModalOverlay";
import {useNavigate} from "react-router-dom";

const PostMediaPreview = ({children}) => {
    const navigate = useNavigate()
    return (
        <ModalOverlay onClose={() => navigate(-1)}>
            <div className={classes.Container}>
                {children}
            </div>
        </ModalOverlay>
    );
};

export default PostMediaPreview;