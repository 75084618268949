import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {withAdmin} from "./withAdmin";

const getCompetitions = (params) => {
    return createRequest('main', 'get', withAdmin('/competitions'), null, params)
}
const createCompetition = (competition) =>
    createRequest('main', 'post', withAdmin('/competitions'), competition)
const updateCompetition = ({competition, id})=>
    createRequest('main', 'patch', withAdmin(`/competitions/${id}`), competition)

export {
    getCompetitions,
    createCompetition,
    updateCompetition,
}