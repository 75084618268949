import React from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {fetchActivationsPromotion, setActivePromotion,} from "../../../store/reducers/promotionsReducer";
import MoreDropdown from "../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";

const PromotionsMoreDropdown = ({promotionId, promotion, deleteClickHandler}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleEditClick = ()=>{
        dispatch(setActivePromotion(promotionId))
         navigate(`/promotions/${promotionId}/update`)
    }

    const handleDeleteClick = ()=>{
        deleteClickHandler(promotion)
    }

    const handleShowActivationClick = ()=>{
        dispatch(setActivePromotion(promotionId))
        dispatch(fetchActivationsPromotion(promotionId))
        navigate(`/promotions/${promotionId}/activations`)
    }

    return (
        <MoreDropdown>
            <MoreItem text={'Edit'} onClick={handleEditClick}/>
            <MoreItem text={'Show activations'} onClick={handleShowActivationClick}/>
            <MoreItem text={'Delete promotion'} danger onClick={handleDeleteClick}/>
        </MoreDropdown>
    );
};

export default PromotionsMoreDropdown;