import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {selectActivePoll, updatePollThunk} from "../../../store/reducers/poolsReducer";
import TopNav from "../../../Components/TopNav/TopNav";
import Tag from "../../../Components/Tag/Tag";
import Answer from "../../../Components/Answer/Answer";
import answerClasses from './Answer.module.scss'
import topNavClasses from "../../../Components/TopNav/TopNav.module.scss";
import classes from './Poll.module.scss'
import Container from "../../../Components/FormComponents/Container/Container";
import Section from "../../../Components/FormComponents/Section/Section";
import Radio from "../../../submodules/naoo-web-components/Components/Radio/Radio";
import {ROUTES} from "../../../submodules/naoo-web-components/Shared/constants";

const Poll = () => {
    const [radioButtonTypes, setRadioButtonTypes] = useState('percentage')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const poll = useSelector(state => selectActivePoll(state))

    let allSelectedCount = 0
    poll?.options.forEach((item) => {
        allSelectedCount += item.selectedCount
    })

    const topNavButtons = [
        {
            title: poll?.isActive ? 'Deactivate poll' : 'Activate poll',
            onClick:() => {
                dispatch(updatePollThunk({
                    pollObject:{
                        isActive: !poll.isActive
                    },
                    id:poll.id
                }))
            },
            isDisabled: false,
            className: poll.isActive? topNavClasses.DangerButton : topNavClasses.ActivateButton
        },
        {
            title: 'Edit',
            onClick:() => {
                navigate(ROUTES.ADMIN_DASHBOARD.POLLS + `/${poll?.id}/update`)
            },
            isDisabled: false,
            className: topNavClasses.SuccessButton
        },
    ]

    return (
        <Container>
            <TopNav
                buttons={topNavButtons}
                onBack={() => {
                    navigate(-1)
                }}
            />
            <Section>
                <div className={classes.Top}>
                    <div className={classes.TagsAndInfo}>
                        <div className={classes.Tags}>
                            {
                                poll?.tags?.map((tag) => {
                                    return <Tag key={tag.id} className={classes.Tag} title={tag.id} onClick={() => {
                                    }}/>
                                })
                            }
                        </div>
                        <div className={classes.Info}>
                            {poll?.answersCount} responses
                        </div>
                    </div>
                    <div className={classes.Title}>
                        {poll?.text}
                    </div>
                    <div className={classes.Amount}>
                        {poll?.options.length} options
                    </div>
                    <div className={classes.RadioButtons}>
                        <Radio
                            className={classes.Left} name={'typeStat'} value={'percentage'}
                            checked={radioButtonTypes === 'percentage'} label={'Percentages'}
                            onChange={() => {
                                setRadioButtonTypes('percentage')
                            }}
                        />
                        <Radio
                            className={classes.Right} name={'typeStat'} value={'counts'}
                            checked={radioButtonTypes === 'counts'} label={'Counts'}
                            onChange={() => {
                                setRadioButtonTypes('counts')
                            }}
                        />
                    </div>

                    <div className={classes.OptionsContainer}>
                        {
                            poll?.options.map((option, index) => {
                                return (
                                    <div key={index} className={classes.Option}>
                                        <Answer
                                            styles={answerClasses}
                                            title={option.text}
                                            progress={Math.round(option?.selectedCount / allSelectedCount * 100)}
                                            percent={radioButtonTypes === 'percentage'}
                                            stat={option?.selectedCount}
                                        />
                                        <div className={classes.TagsAmount}>
                                            {option?.tags?.length} {option?.tags && 'tags'}
                                        </div>
                                        <div className={classes.Tags}>
                                            {
                                                option?.tags?.map((tag) => {
                                                    return (
                                                        <Tag
                                                            key={tag.id}
                                                            className={classes.Tag}
                                                            title={tag.id}
                                                            onClick={() => {}}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </Section>
        </Container>
    );
};

export default Poll;
