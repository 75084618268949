import React from "react"
import {useDispatch, useSelector} from "react-redux";
import Messages from "./submodules/naoo-web-components/Components/Messages/Messages";
import {selectMessages} from "./submodules/naoo-web-components/Shared/reducers/messagesReducer";
import {useProjectRoutes} from "./routes/routes";
import ModalWindow from "./submodules/naoo-web-components/Components/ModalWindow/ModalWindow";
import {hideConfirmationPopup, selectConfirmationPopup} from "./store/reducers/reportsReducer";


function App() {
    const dispatch = useDispatch()
    const messages = useSelector(selectMessages)
    const routes = useProjectRoutes()

    const {
        isOpen, title,
        primaryButton,
        handlePrimaryClick
    } = useSelector(selectConfirmationPopup)

    return <>
        {routes}
        <Messages messages={messages}/>
        <ModalWindow
            isOpen={isOpen}
            title={title}
            primaryButton={primaryButton}
            secondaryButton={'No, cancel'}
            handlePrimaryClick={handlePrimaryClick}
            handleSecondaryClick={()=> dispatch(hideConfirmationPopup())}
            onClose={()=> dispatch(hideConfirmationPopup())}
        />
    </>
}

export default App;