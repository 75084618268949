import React from 'react';
import classes from './UserCard.module.scss'
import MoreDropdown from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import UserMoreItems from "../../../../../Users/UserMoreItems/UserMoreItems";

const UserCard = ({user}) => {
    return (
        <div className={classes.Container}>
            <div className={classes.LogoContainer}>
                {
                    user?.avatar?.url &&
                    <img className={classes.Logo} src={ user?.avatar?.url} alt="Avatar"/>
                }
            </div>
            <div className={classes.InfoContainer}>
                <div className={classes.Name}>
                    <span>{user.firstname} {user.lastname}</span>
                </div>
                <div className={classes.Info}>
                    <span>{user.followersCount} followers</span>
                    <span>{user.followeesCount} following</span>
                </div>
                <div className={classes.More}>
                    <MoreDropdown>
                        <UserMoreItems isReported/>
                    </MoreDropdown>
                </div>
            </div>
        </div>
    );
};

export default UserCard;