import React from 'react';
import {Bar, BarChart, Cell, ResponsiveContainer, Text, XAxis, YAxis} from "recharts";

const YAxisLeftTick = ({y, payload: {value}}) => {
    return (
        <Text
            y={y}
            x={24}
            textAnchor="start"
            verticalAnchor="middle"
            style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 14,
                lineHeight: 18,
            }}
            fill={'#5E5F70'}
        >
            {
                value.length > 27
                    ? `${value.slice(0, 25).trim()}...`
                    : value
            }
        </Text>
    );
};
const YAxisLeftTickSecond = ({y, payload}) => {
    return (
        <Text
            y={y}
            x={210}
            textAnchor="start"
            verticalAnchor="middle"
            style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 14,
                lineHeight: 18,
            }}
            fill={'#1F2A99'}
        >
            {payload.value}
        </Text>
    );
};

const HorizontalBarChart = ({data, valueKey, categoryKey, onCategoryClick}) => {

    return (
        <ResponsiveContainer width={"100%"} height={30 * data.length} debounce={50} padding={{bottom: 24}}>
            <BarChart
                data={data}
                layout="vertical"
                margin={{left: 10}}
            >
                <XAxis hide axisLine={false} type="number"/>
                <YAxis
                    width={180}
                    yAxisId={1}
                    dataKey={categoryKey}
                    type="category"
                    axisLine={false}
                    tickLine={false}
                    tick={<YAxisLeftTick/>}

                />
                <YAxis
                    width={70}
                    yAxisId={0}
                    dataKey={valueKey}
                    type="category"
                    axisLine={false}
                    tickLine={false}
                    tick={<YAxisLeftTickSecond/>}
                />
                <Bar dataKey={valueKey} minPointSize={2}>
                    {data.map((d, idx) => {
                        return <Cell key={d[categoryKey]} radius={8} fill={'#E4E6F7'} cursor={'pointer'}
                                     onClick={() => onCategoryClick(d)}/>;
                    })}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default HorizontalBarChart;