import React, {useEffect, useRef, useState} from 'react';
import DropdownIcon from '../../../../shared/icons/down.svg'
import classes from './TagsDropdown.module.scss'
import {useOnClickOutside} from "../../../../submodules/naoo-web-components/Shared/hooks/useclickOutside";
import CloseIcon from "../../../../shared/icons/closeIcon.svg"
import TagOptions from "../TagOptions/TagOptions";

const TagsDropdown = (
    {
        initialValue = null, options,
        onChange, addPlaceholder = false,
        placeholder, styles = classes,
    }) => {
    const [isShow, setIsShow] = useState(false)
    const [tag, setTag] = useState(initialValue)
    const [searchString, setSearchString] = useState('')

    const ref = useOnClickOutside(() => {
        setIsShow(false)
        setSearchString('')
    })
    const inputRef = useRef(null)

    useEffect(() => (
        setTag(initialValue)
    ), [initialValue])

    useEffect(() => {
        onChange(tag)
    }, [tag, onChange])


    const clearOrCloseValues = (event) => {
        event.stopPropagation()
        setTag(null)
        setIsShow(false)
    }

    return (
        <div ref={ref} className={styles.Container}>
            <div className={styles.DropdownInputContainer} onClick={() => {
                setIsShow(!isShow)
            }}>
                {
                    tag !== null
                        ?
                        <span
                            className={`
                        ${styles.DropdownInput}
                        ${isShow ? styles.DropdownInputOpen : ''}
                        ${tag !== null ? styles.DropdownInputText : styles.DropdownInputPlaceholder}`
                            }
                            placeholder={placeholder}
                        >
                    {
                        tag !== null
                            ? addPlaceholder ? placeholder + ': ' + tag.id : tag.id
                            : placeholder
                    }
                </span>
                        : <input type="text"
                                 ref={inputRef}
                                 className={classes.DropdownInput}
                                 value={searchString}
                                 placeholder={'Enter tags'}
                                 onChange={(event) => {
                                     setSearchString(event.target.value)
                                 }}
                                 onClick={() => {
                                     setIsShow(!isShow)
                                     if (isShow) {
                                         inputRef.current.blur()
                                     } else {
                                         inputRef.current.focus()
                                     }
                                 }}
                        />
                }

                {
                    initialValue ? <img
                            src={CloseIcon}
                            alt="Close Icon" onClick={clearOrCloseValues}/>
                        : <img src={DropdownIcon} style={isShow ? {'transform': 'rotate(180deg)'} : {}}
                               alt="dropdown icon"/>
                }

            </div>
            {
                isShow &&
                <TagOptions
                    onItemClick={(item) => {
                        setTag(item)
                        setIsShow(!isShow)
                        setSearchString('')
                    }}
                    options={options}
                    searchString={searchString}
                />
            }
        </div>
    );
};

export default TagsDropdown;
