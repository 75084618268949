import React, {useState} from 'react';
import {ReactComponent as Confirmed} from "../../shared/icons/status/confirmed.svg";
import {ReactComponent as Pending} from "../../shared/icons/status/time.svg";
import {ReactComponent as Rejected} from "../../shared/icons/status/rejected.svg";
import {ReactComponent as Fulfilled} from "../../shared/icons/status/fulfilled.svg";
import classes from "./Status.module.scss";
import {useOnClickOutside} from "../../submodules/naoo-web-components/Shared/hooks/useclickOutside";
import {useDispatch} from "react-redux";

const Status = ({status, requestId, handlers}) => {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenStatuses, setIsOpenStatuses] = useState(false)
    const ref = useOnClickOutside(() => setIsOpen(false))

    const {reject, confirm, fulfilled} = handlers

    const getIconAndTitleByStatus = () => {
        switch (status) {
            case 'confirmed':
                return <><Confirmed/> <span>Confirmed</span></>
            case 'pending':
                return <><Pending/> <span>Pending</span></>
            case 'rejected':
                return <><Rejected/> <span>Rejected</span></>
            case 'fulfilled':
                return <><Fulfilled/> <span>Fulfilled</span></>
            default :
                return null
        }
    }

    const handleStatusClick = (event) => {
        event.stopPropagation()
        setIsOpen(!isOpen)
    }

    const handleMoreOptionsMouseEnter = () => {
        setIsOpenStatuses(true)
    }
    const handleMoreOptionsMouseLeave = () => {
        setIsOpenStatuses(false)
    }

    const handleReject = () => {
        dispatch(reject(requestId))
    }

    const handleFulfilled = () => {
        dispatch(fulfilled(requestId))
    }
    const handleConfirm = () => {
        dispatch(confirm(requestId))
    }

    return (
        <div className={classes.Container} ref={ref} onClick={handleStatusClick}>
            {getIconAndTitleByStatus()}
            {
                isOpen &&
                <div
                    className={classes.MoreOptions}
                    onMouseEnter={handleMoreOptionsMouseEnter}
                    onMouseLeave={handleMoreOptionsMouseLeave}
                >
                    <div className={classes.ChangeStatus}>
                        <span>Change status</span>
                        {
                            isOpenStatuses &&
                            <div className={classes.Statuses}>
                                <div className={classes.Status} onClick={handleConfirm}>
                                    <span>Confirmed</span>
                                </div>
                                <div className={classes.Status} onClick={handleFulfilled}>
                                    <span>Fulfilled</span>
                                </div>
                                <div className={classes.Status} onClick={handleReject}>
                                    <span>Rejected</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default Status;