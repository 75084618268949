export const handleErrors = (errors)=>{
    for (const error in errors) {
        if (errors[error]) return true
    }
    return false
}

export const validateNumberInput = (event) => {
    const numbersRegexp = /^[0-9\b]+$/
    return !event.target.value || numbersRegexp.test(event.target.value)
}

export const validateFloatNumberInput = (event) => {
    const regexp  = /([-]?([0-9]{1,})?[.]?[0-9]{1,})/
    return !event.target.value || regexp.test(event.target.value)
}
