import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectTags} from "../../../store/reducers/tagsReducer";
import TagsMultiselect from "../Tags/TagsMultiselect/TagsMultiselect";
import {ReactComponent as DeleteIcon} from '../../../shared/icons/deleteIcon.svg';
import {ReactComponent as DragIcon} from "../../../shared/icons/drag.svg";
import classes from './Option.module.scss'

const Option = ({initialValue, id, index, onChange, onDelete, provided}) => {
    const tags = useSelector(state => selectTags(state))
    const [option, setOption] = useState(initialValue || {
        text: '',
        textDe: '',
        tags: [],
        id
    })
    const [isShowDeleteIcon, setIsShowDeleteIcon] = useState(false)

    useEffect(() => {
        onChange(index, option)
    }, [index, option, onChange])


    const handleTagsSelect = useCallback((tags) => {
        setOption(prev => {
                return {
                    ...prev,
                    tags
                }
            }
        )
    }, [])

    const handleInput = (event) => {
        setOption(prev => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        })
    }


    return (
        <div
            className={classes.Container}
            onMouseEnter={() => {
                setIsShowDeleteIcon(true)
            }}
            onMouseOver={() => {
                setIsShowDeleteIcon(true)
            }}
            onMouseLeave={() => {
                setIsShowDeleteIcon(false)
            }}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            {
                isShowDeleteIcon &&
                <div
                    className={classes.DragIconContainer}
                    {...provided.dragHandleProps}
                >
                    <DragIcon/>
                </div>
            }
            <div className={classes.Answer}>
                <div>
                    <input
                        type={'text'}
                        name={'textEn'}
                        value={option.textEn}
                        placeholder={'Poll answer in English'}
                        onChange={handleInput}
                        style={{marginBottom: 2}}
                    />
                    <input
                        type={'text'}
                        name={'textDe'}
                        value={option.textDe}
                        placeholder={'Poll answer in German'}
                        onChange={handleInput}
                    />
                </div>
                {
                    isShowDeleteIcon &&
                    <div className={classes.IconContainer} onClick={() => onDelete({index, id: option.id})}>
                        <DeleteIcon/>
                    </div>
                }
            </div>
            <div className={classes.Tags}>
                <TagsMultiselect
                    initialValue={option.tags}
                    options={tags}
                    onChange={handleTagsSelect}
                />
            </div>
        </div>
    );
};

export default Option;
