import React, {Fragment, useEffect, useState} from 'react';
import classes from './Tags.module.scss'
import layoutClasses from "../Layout.module.scss";
import AdminSidebar from "../Sidebar/AdminSidebar";
import {Button} from "../../submodules/naoo-web-components/Components/Button/Buttons";
import SearchInput from "../../Components/SearchInput/SearchInput";
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import {
    fetchTags,
    fetchTagsByNextToken,
    selectFetching,
    selectNextToken,
    selectTags,
    setActiveTag,
    setFetching
} from "../../store/reducers/tagsReducer";
import {returnObservableItem} from "../../submodules/naoo-web-components/Shared/utility/utils";
import {useObserver} from "../../submodules/naoo-web-components/Shared/hooks/useObserver";
import SearchContainer from "../../Components/Search/SearchContainer/SearchContainer";
import TableWrapper from "../../Components/Table/TableWrapper/TableWrapper";
import TableHeader from "../../Components/Table/TableHeader/TableHeader";
import TableItem from "../../Components/Table/TableItem/TableItem";
import {setSearchParams} from "../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";
import {useFetchWithQueryParams} from "../../submodules/naoo-web-components/Shared/hooks/useFetchWithQueryParams";

const CURRENT_PAGE ='tags'
const Tags = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const tags = useSelector(selectTags)

    const [searchString, setSearchString] = useState('')
    const [element, setElement] = useState(null)
    const nextToken = useSelector(selectNextToken)
    const fetching = useSelector(selectFetching)
    const LIMIT = 10

    const handleDebounceFn = (searchString) => {
        dispatch(setSearchParams({searchString}))
    }

    const [params, isReady, debounceQueryUpdate] = useFetchWithQueryParams(
        {
            initialValue: {
                searchString: '',
            },
            currentPage: CURRENT_PAGE,
            callback: handleDebounceFn
        })

    useEffect(() => {
        if (isReady) {
            dispatch(fetchTags())
        }
    }, [dispatch, params, isReady])


    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchTagsByNextToken
    )

    const match = useMatch('/tags')

    const handleSearchChange = (text) => {
        setSearchString(text)
        debounceQueryUpdate(text)
    }

    const handleSidebarButtonChanged = () => {
        dispatch(setActiveTag(null))
        navigate('/tags/create')
    }

    return (
        <>
            <Outlet/>
            {
                match &&
                <div className={layoutClasses.Container}>
                    <AdminSidebar>
                        <Button onClick={handleSidebarButtonChanged}>New tag</Button>
                    </AdminSidebar>
                    <div className={layoutClasses.Main}>
                        <SearchContainer>
                            <SearchInput
                                value={searchString}
                                placeholder={'Search tags...'}
                                onChange={handleSearchChange}
                            />
                        </SearchContainer>
                        <TableWrapper>
                            {/*HEADER*/}
                            <TableHeader className={classes.Header}>
                                <div className={classes.ID}>
                                    <span>ID</span>
                                </div>
                                <div className={classes.Title}>
                                    <span>Title</span>
                                </div>
                            </TableHeader>
                            {/*HEADER*/}
                            {/*ITEMS*/}
                            {
                                tags?.map((item, index, array) => {
                                    return (
                                        <Fragment key={item.id}>
                                            <TableItem className={classes.Item}>
                                                <div className={classes.ID}>
                                                    <span>{item?.id}</span>
                                                </div>
                                                <div className={classes.Title}>
                                                    <span>{item?.title}</span>
                                                </div>
                                            </TableItem>
                                            {
                                                returnObservableItem(array.length, LIMIT, index, setElement)
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                            {/*ITEMS*/}
                        </TableWrapper>
                    </div>
                </div>
            }
        </>
    );
};

export default Tags;