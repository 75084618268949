import React from 'react'
import classes from '../User.module.scss'
import { Field } from '../../../../shared/Form/FormField'
import Radio from '../../../../submodules/naoo-web-components/Components/Radio/Radio'
import { useSelector } from 'react-redux'
import { selectActiveUser } from '../../../../store/reducers/usersReducer'

const VerifiedField = () => {
  const user = useSelector(selectActiveUser)
  return (
    <div className={classes.Buttons}>
      <span className={classes.Subtitle}>Verified</span>
      <Field name={'isVerified'} initialValue={user?.isVerified || false}>
        {(isVerified, setIsVerified) => (
          <>
            <Radio
              className={classes.Left}
              name='isVerified'
              value='No'
              label='No'
              checked={!isVerified}
              onChange={() => {
                setIsVerified(false)
              }}
            />
            <Radio
              className={classes.Right}
              name='isVerified'
              value='Yes'
              label='Yes'
              checked={isVerified}
              onChange={() => {
                setIsVerified(true)
              }}
            />
          </>
        )}
      </Field>
    </div>
  )
}

export default VerifiedField
