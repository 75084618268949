import React, {Fragment, useEffect, useState} from 'react';
import classes from './Transactions.module.scss'
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import layoutClasses from "../Layout.module.scss";
import AdminSidebar from "../Sidebar/AdminSidebar";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchPointsTransactions,
    fetchPointsTransactionsByNextToken,
    fetchPointsTransactionsStats,
    selectFetching,
    selectNextToken,
    selectPointsTransactionsStats,
    selectTransactions,
    setActiveTransaction,
    setFetching,
    setStatus
} from "../../store/reducers/transactionsReducer";
import {ROUTES} from "../../submodules/naoo-web-components/Shared/constants";
import {useObserver} from "../../submodules/naoo-web-components/Shared/hooks/useObserver";
import {ReactComponent as NaooIcon} from "../../submodules/naoo-web-components/Shared/UI/assets/naooIcon.svg";
import {getUsername, returnObservableItem} from "../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../Spinner.module.scss";
import {TRANSACTION_CATEGORIES} from "../../shared/Constants";
import DateTimeRangePicker
    from "../../submodules/naoo-web-components/Components/Date/DateTimeRangePicker/DateTimeRangePicker";
import TabBar from "../../submodules/naoo-web-components/Components/TabBar/TabBar";
import HorizontalBarChart from "./HorizontalBarChart/HorizontalBarChart";
import TabBarItem from "../../submodules/naoo-web-components/Components/TabBar/TabBarItem";
import {TextButton} from "../../submodules/naoo-web-components/Components/Button/Buttons";
import {ExportToCsv} from "export-to-csv";
import {format, subDays} from "date-fns";
import SearchDropdown from "../../Components/Search/SearchDropdown/SearchDropdown";
import SearchUserItem from "../../Components/Search/SearchUserItem/SearchUserItem";
import SearchComponentWrapper from "../../Components/Search/SearchComponentWrapper/SearchComponentWrapper";
import SearchContainer from "../../Components/Search/SearchContainer/SearchContainer";
import TableWrapper from "../../Components/Table/TableWrapper/TableWrapper";
import TableHeader from "../../Components/Table/TableHeader/TableHeader";
import TableItem from "../../Components/Table/TableItem/TableItem";
import {setSearchParams} from "../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";
import {useFetchWithQueryParams} from "../../submodules/naoo-web-components/Shared/hooks/useFetchWithQueryParams";
import {getTransactionName} from "./getTransactionName";

const csvOptions = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: 'total_points_given_out_stats',
};

const CURRENT_PAGE = 'transactions'

const Transactions = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const transactions = useSelector(selectTransactions)
    const transactionsStats = useSelector(selectPointsTransactionsStats)
    const nextToken = useSelector(selectNextToken)
    const fetching = useSelector(selectFetching)
    const [element, setElement] = useState(null)
    const LIMIT = 10

    const [activeTab, setActiveTab] = useState('History')

    const [{
        sourceUser,
        targetUser,
        category,
        startDate,
        endDate,
    }] = useFetchWithQueryParams(
        {
            initialValue: {
                sourceUser: null,
                targetUser: null,
                category: null,
                startDate: subDays(new Date(), 7),
                endDate: new Date(),
            },
            currentPage: CURRENT_PAGE,
        })

    useEffect(() => {
        dispatch(fetchPointsTransactions())
        dispatch(fetchPointsTransactionsStats())
    }, [sourceUser, targetUser, category, startDate, endDate, dispatch])


    const categoriesOptions = TRANSACTION_CATEGORIES.map((item) => (
        {
            title: item?.name,
            value: item?.id
        }
    ))

    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchPointsTransactionsByNextToken
    )

    const handleSourceUserFilterChange = (user) => {
        dispatch(setSearchParams({sourceUser: user}))
    }

    const handleTargetUserFilterChange = (user) => {
        dispatch(setSearchParams({targetUser: user}))
    }

    const handleCategoryChange = (category) => {
        dispatch(setSearchParams({category}))
    }


    const match = useMatch(ROUTES.ADMIN_DASHBOARD.POINTS_TRANSACTIONS)

    const handleApplyDateFilterClick = ([startDate, endDate]) => {
        dispatch(setSearchParams({startDate, endDate}))
    }

    const handleTransactionClick = (transaction) => () => {
        dispatch(setStatus(null))
        dispatch(setActiveTransaction(transaction))
        navigate(`${ROUTES.ADMIN_DASHBOARD.POINTS_TRANSACTIONS}/${transaction.id}`)
    }


    const handleCategoryClick = (event) => {
        console.log(event)
    }


    const handleExportToCSVClick = () => {
        const data = transactionsStats.map((item) => (
            {
                category_id: item.categoryId,
                category_title: item.category,
                points_amount: item.totalPoints
            }
        ))

        const csvExporter = new ExportToCsv(
            {
                ...csvOptions,
                showTitle: true,
                title: `sourceWalletId:${sourceUser?.wallet?.id ?? '-'};targetWalletId:${targetUser?.wallet?.id ?? '-'};startDate:${startDate?.toISOString() ?? '-'}; endDate:${endDate?.toISOString() ?? '-'}`
            });
        csvExporter.generateCsv(data);
    }
    return (
        <>
            <Outlet/>
            {
                match &&
                <div className={layoutClasses.Container}>
                    <AdminSidebar>
                        <div style={{height: 50}}/>
                    </AdminSidebar>
                    <div className={layoutClasses.Main}>
                        <SearchContainer>
                            <SearchUserItem
                                placeholder={'Source'}
                                user={sourceUser}
                                isClearable={true}
                                onItemClick={handleSourceUserFilterChange}
                            />
                            <SearchComponentWrapper>
                                <SearchUserItem
                                    placeholder={'Target'}
                                    user={targetUser}
                                    isClearable={true}
                                    onItemClick={handleTargetUserFilterChange}
                                />
                            </SearchComponentWrapper>
                            <SearchComponentWrapper>
                                <SearchDropdown
                                    className={classes.CategoryFilter}
                                    options={categoriesOptions}
                                    value={category}
                                    onChange={handleCategoryChange}
                                    placeholder={'Category'}
                                    isClearable={true}
                                />
                            </SearchComponentWrapper>
                            <SearchComponentWrapper>
                                <div className={classes.DatePickerWrapper}>
                                    <DateTimeRangePicker
                                        isClearable={true}
                                        placeholder={'Date'}
                                        format={'dd.MM'}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onSubmit={handleApplyDateFilterClick}
                                    />
                                </div>
                            </SearchComponentWrapper>
                        </SearchContainer>
                        <TabBar initialActiveTab={activeTab} navItemClick={(tab) => setActiveTab(tab)}>
                            <TabBarItem label={'History'}>
                                <TableWrapper>
                                    {/*HEADER*/}
                                    <TableHeader className={classes.Header}>
                                        <div className={classes.User}>
                                            <span>Source user</span>
                                        </div>
                                        <div className={classes.Date}>
                                            <span>Date</span>
                                        </div>
                                        <div className={classes.TargetUser}>
                                            <span>Target user</span>
                                        </div>
                                        <div className={classes.Category}>
                                            <span>Category</span>
                                        </div>
                                        <div className={classes.Points}>
                                            <span>Points</span>
                                        </div>
                                    </TableHeader>
                                    {/*HEADER*/}

                                    {/*ITEMS*/}
                                    {
                                        transactions?.map((item, index, array) => {
                                            const sourceUser = getUsername(item?.sourceUser)
                                            const targetUser = getUsername(item?.targetUser)

                                            return (
                                                <Fragment key={item.id}>
                                                    <TableItem
                                                        className={classes.Item}
                                                        onClick={handleTransactionClick(item)}
                                                    >
                                                        <div className={classes.User}>
                                                            <span>{sourceUser}</span>
                                                        </div>
                                                        <div className={classes.Date}>
                                                            <span>{format(new Date(item.updatedAt || item.createdAt), 'dd.MM.yy')}</span>
                                                        </div>
                                                        <div className={classes.TargetUser}>
                                                            <span>{targetUser}</span>
                                                        </div>
                                                        <div className={classes.Category}>
                                                            <span>{getTransactionName(item?.categoryId)}</span>
                                                        </div>
                                                        <div className={classes.Points}>
                                                            <span>{item.amount}</span>
                                                            <NaooIcon/>
                                                        </div>
                                                    </TableItem>
                                                    {
                                                        returnObservableItem(array.length, LIMIT, index, setElement)
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                    {
                                        fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                                    }
                                    {/*ITEMS*/}
                                </TableWrapper>
                            </TabBarItem>
                            <TabBarItem label={'Chart'}>
                                <div className={classes.ChartContainer}>
                                    <div className={classes.Header}>
                                        <div className={classes.Title}>
                                            <span>
                                                Points
                                            </span>
                                        </div>

                                        <TextButton className={classes.Button} onClick={handleExportToCSVClick}>
                                            Export to CSV
                                        </TextButton>
                                    </div>
                                    {
                                        activeTab === 'Chart' &&
                                        <HorizontalBarChart
                                            data={transactionsStats}
                                            categoryKey={'category'}
                                            valueKey={'totalPoints'}
                                            onCategoryClick={handleCategoryClick}
                                        />
                                    }
                                </div>
                            </TabBarItem>
                        </TabBar>
                    </div>
                </div>
            }
        </>
    );
};

export default Transactions;