import React from 'react'
import classes from './RedeemOption.module.scss'
import { ReactComponent as NaooIcon } from '../../../shared/icons/naooIcon.svg'
import MoreDropdown from './MoreDropdown/MoreDropdown'

const RedeemOption = ({ option, className, isPreview, logoMediaUrl }) => {
  const { id, color, cash, points, isActive, claimsLimit, claimsPerUserLimit } = option

  const bgCardColor = isPreview ? color : `#${color}`

  return (
    <div
      className={`${classes.Container} ${className}`}
      style={{
        backgroundColor: bgCardColor,
      }}>
      {!isPreview && !isActive && <div className={classes.NotActive} />}
      {!isPreview && <MoreDropdown optionId={id} isActive={isActive} type={option.type} />}

      <div className={classes.MainContainer}>
        <div>
          <div className={'Price'}>
            {isPreview ? (
              !cash ? (
                <span className={'Sum'}>{'____'}</span>
              ) : (
                <span className={'Sum'}>{cash}</span>
              )
            ) : (
              <span className={'Sum'}>{cash}</span>
            )}

            <span className={'Currency'}>CHF</span>
          </div>
          <div className={'Title'}>
            {option?.description?.en ? (
              <span>{option?.description?.en}</span>
            ) : isPreview ? (
              !points ? (
                <span>Redeem for ____ naoo points </span>
              ) : (
                <span>Redeem for {points.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} naoo points</span>
              )
            ) : (
              <span>Redeem for {points.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} naoo points</span>
            )}
          </div>
        </div>
        <div className={classes.LogoContainer}>{logoMediaUrl && <img src={logoMediaUrl} alt='logo' />}</div>
      </div>

      <div className={'Footer'}>
        <div className={'Points'}>
          <span>{points.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}</span>
          <NaooIcon width={22} height={10} />
        </div>
        {!!claimsLimit && (
          <div className={'Limit'}>
            <span>
              {claimsLimit} limit {claimsPerUserLimit && ', ' + claimsPerUserLimit + ' per user'}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default RedeemOption
