
const REASONS = {
    1:'Spam',
    2: 'Inappropriate Content',
    default: '-'
}
const getReasonReport = (number) => {
    return REASONS[number] ?? REASONS.default
}

export {getReasonReport}