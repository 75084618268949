import React, {Fragment, useEffect, useState} from 'react';
import classes from './ReportedUser.module.scss'
import UserCard from "./UserCard/UserCard";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchPosts,
    fetchPostsByNextToken, selectFetching,
    selectNextToken,
    selectPosts,
    setFetching
} from "../../../../../store/reducers/postsReducer";
import Post from "../../../../Posts/Components/Post/Post";
import {useObserver} from "../../../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import {returnObservableItem} from "../../../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../../../Spinner.module.scss";

const ReportedUser = ({user}) => {
    const dispatch = useDispatch()
    const posts = useSelector(selectPosts)
    const [element, setElement] = useState(null)
    const LIMIT = 50
    const nextToken = useSelector(state => selectNextToken(state))
    const fetching = useSelector(state => selectFetching(state))

    useEffect(()=>{
        dispatch(fetchPosts({
            limit: LIMIT,
            userId: user?.id
        }))
    }, [dispatch, user.id])

    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchPostsByNextToken
    )


    return (
        <div className={classes.Container}>
            <UserCard user={user}/>
            <div className={classes.Posts}>
                {
                    posts?.map((post, index, array)=>(
                        <Fragment key={post.id}>
                            <Post post={post}/>
                            {
                                returnObservableItem(array.length, LIMIT, index, setElement )
                            }
                        </Fragment>
                    ))
                }
                {
                    fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                }
            </div>
        </div>
    );
};

export default ReportedUser;