import React from 'react';
import classes from "../Poll.module.scss";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import TagsMultiselect from "../../Tags/TagsMultiselect/TagsMultiselect";
import Section from "../../../../Components/FormComponents/Section/Section";
import Dropdown from "../../../../submodules/naoo-web-components/Components/Dropdown/Dropdown";

const Question = ({
                      pollTitle, pollTitleDe, pollTags, tags,
                      handlePollTagsSelect, setPollTitle, setPollTitleDe,
                      lengthOptions, setAnswerMinOptions, setAnswerMaxOptions,
                      answerMinOptions, answerMaxOptions,

                  }) => {
    const dropdownOptions = new Array(lengthOptions).fill(null).map((item, index) => {
        return index + 1
    })
    return (
        <Section title={'Poll with tags'}>
            <Input label={'Poll'} placeholder={'Poll question in English'} value={pollTitle} styles={{
                letterSpacing: 'normal',
                marginBottom: 2
            }} onChange={(event) => {
                setPollTitle(event.target.value)
            }}/>
            <Input placeholder={'Poll question in German'} value={pollTitleDe}
                   styles={{letterSpacing: 'normal'}}
                   onChange={(event) => setPollTitleDe(event.target.value)}
            />
            <div className={classes.SubTitle}>Poll tags</div>
            <TagsMultiselect
                initialValue={pollTags}
                options={tags}
                onChange={handlePollTagsSelect}
            />
            <div className={classes.CountOptionsContainer}>
                <span>
                    User must select from
                </span>
                <Dropdown className={classes.Dropdown} value={answerMinOptions}
                          options={dropdownOptions.filter(item => item < answerMaxOptions)}
                          onChange={(item) => setAnswerMinOptions(item)}
                />
                <span>up to</span>
                <Dropdown className={classes.Dropdown} value={answerMaxOptions}
                          options={dropdownOptions.filter(item => item >= answerMinOptions)}
                          onChange={(item) => setAnswerMaxOptions(item)}/>
                <span>options</span>
            </div>
        </Section>
    );
};

export default Question;
