import React from 'react';
import classes from './PromotionPreview.module.scss'
import {ReactComponent as PointsIcon} from "../../../submodules/naoo-web-components/Shared/UI/assets/naooIcon.svg";

const PromotionPreview = ({ promotion }) => {
    return (
        <div className={classes.Container}>
            {
                promotion?.mediaUrl &&
                <img className={classes.Logo} src={promotion?.mediaUrl} alt=""/>
            }
            <div className={classes.TextAndId}>
                <div className={classes.Text}>
                    <span>{promotion?.message}</span>
                </div>
                <div className={classes.Id}>
                    <span>ID: {promotion?.id}</span>
                </div>
            </div>
            <div className={classes.Points}>
                <span>{promotion?.points}</span>
                <PointsIcon height={18}/>
            </div>
            {
                !!promotion?.users?.length &&
                <div className={classes.TargetUsers}>
                    <span>{promotion?.users?.length} target users </span>
                </div>
            }
            {
                !!promotion?.businesses?.length &&
                <div className={classes.TargetUsers}>
                    <span>{promotion?.businesses?.length} target businesses </span>
                </div>
            }
        </div>
    );
};

export default PromotionPreview;