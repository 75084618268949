import React from 'react';
import {useDispatch} from "react-redux";
import {
    deletePostThunk,
    setActivePost,
    setIsSendImg,
    setStatus,
    updatePostThunk
} from "../../../../store/reducers/postsReducer";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../submodules/naoo-web-components/Shared/constants";
import MoreDropdown from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {isAfter} from "date-fns";

const PostMoreDropdown = ({post, postId, publicationDate, handleShowStats}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleEditClick = ()=>{
        dispatch(setActivePost(post))
        dispatch(setIsSendImg(false))
        navigate(`${ROUTES.ADMIN_DASHBOARD.POSTS}/${post?.id}/update`)
    }

    const handlePublishClick = () => {
        dispatch(updatePostThunk({
            id: postId,
            post:{
                publicationDate: new Date().toISOString()
            }
        })).then(()=>{
            dispatch(setStatus(null))
        })
    }

    const handleShowStatisticsClick = (event) => {
        event.stopPropagation()
        dispatch(setActivePost(post))
        handleShowStats()
    }

    const featureMenuItemClick = () => {
        dispatch(updatePostThunk({
            id: postId,
            post:{
                naooFeaturedVideo: !post?.naooFeaturedVideo
            }
        })).then(()=>{
            dispatch(setStatus(null))
        })
    }
    const deleteMenuItemClick = () => {
        dispatch(deletePostThunk(post.id))
    }

    return (
        <MoreDropdown width={200}>
            <MoreItem text={'Edit'} onClick={handleEditClick}/>
            {
                isAfter(new Date(publicationDate), Date.now()) &&
                    <MoreItem text={'Publish Now'} onClick={handlePublishClick}/>
            }
            <MoreItem text={'Show statistics'} onClick={handleShowStatisticsClick}/>
            <MoreItem text={post?.naooFeaturedVideo ? 'Remove from featured' : 'Feature this post'}  onClick={featureMenuItemClick}/>
            <MoreItem text={'Delete post'} onClick={deleteMenuItemClick} danger/>
        </MoreDropdown>
    );
};

export default PostMoreDropdown;