import React from 'react'
import classes from '../User.module.scss'
import { Field } from '../../../../shared/Form/FormField'
import Radio from '../../../../submodules/naoo-web-components/Components/Radio/Radio'
import { useSelector } from 'react-redux'
import { selectActiveUser } from '../../../../store/reducers/usersReducer'

const PersonalWebsitePublic = ({ isPersonalWebsitePublic, setIsPersonalWebsitePublic }) => {
  const user = useSelector(selectActiveUser)
  return (
    <div className={classes.Buttons}>
      <span className={classes.Subtitle}>Personal website public</span>
      <Field name={'isPersonalWebsitePublic'} initialValue={user?.isPersonalWebsitePublic || true}>
        {(_isPersonalWebsitePublic, _setIsPersonalWebsitePublic) => (
          <>
            <Radio
              className={classes.Left}
              name='isPersonalWebsitePublic'
              value='No'
              label='No'
              checked={!isPersonalWebsitePublic}
              onChange={() => {
                setIsPersonalWebsitePublic(false)
              }}
            />
            <Radio
              className={classes.Right}
              name='isPersonalWebsitePublic'
              value='Yes'
              label='Yes'
              checked={isPersonalWebsitePublic}
              onChange={() => {
                setIsPersonalWebsitePublic(true)
              }}
            />
          </>
        )}
      </Field>
    </div>
  )
}

export default PersonalWebsitePublic
