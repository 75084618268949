import React from 'react';
import {useDispatch} from "react-redux";
import Notification from "./Notification";
import topNavClasses from "../../../Components/TopNav/TopNav.module.scss";
import {useNotification} from "./useNotification";
import {addMessageThunk} from "../../../submodules/naoo-web-components/Shared/reducers/messagesReducer";
import {createMessage, MESSAGES_TYPES} from "../../../submodules/naoo-web-components/Components/Message/Message";
import {sendNotification, sendNotificationWithPoints} from "../../../api/notificationApi";

const NotificationSendContainer = ({handleBackClick}) => {
    const dispatch = useDispatch()

    const {
        title, setTitle,
        img, setImg,
        imgUrl, setImgUrl,
        imgLoading,
        description, setDescription,
        targetType, setTargetType,
        contentType, setContentType,
        contentValue,
        users, setUsers,
        isLoading, setIsLoading,
        handleErrors,
        handleDropzone,
        clearDropzone,
        handleContentChange,
        handleCompetitionChange,
        handlePointsChange,
        handleUsersDropdown,
        combineData,
        combinePointsData
    } = useNotification()

    const onSuccessClickHandler = (event, sendNotificationFunction) => {
        let data
        if (contentType.idName === 'points'){
            data = combinePointsData()
        }
        else{
            data = combineData()
            data.text = description
        }

        setIsLoading(true)
        sendNotificationFunction(data)
            .then(() => {
                setIsLoading(false)
                dispatch(addMessageThunk({
                    message: createMessage({
                        type: MESSAGES_TYPES.SUCCESS,
                        message: 'Notification sent',
                    })
                }))
            })
            .catch(error => {
                console.error(error)
                setIsLoading(false)
            })
    }

    const topNavButtons = [
        {
            title:'Discard notification',
            onClick:handleBackClick,
            isDisabled: false,
            className: topNavClasses.DangerButton
        },
        {
            title:'Send notification',
            onClick:(event)=>{
                onSuccessClickHandler(event, contentType.idName === 'points'
                    ? sendNotificationWithPoints
                    : sendNotification
                )
            },
            isDisabled: handleErrors(),
            className: `${topNavClasses.SuccessButton} ${handleErrors() ? topNavClasses.Disabled: ''}`
        },
    ]

    return (
        <Notification
            users={users}
            topNavButtons={topNavButtons}
            title={title}
            targetType={targetType}
            setUsers={setUsers}
            setTitle={setTitle}
            setTargetType={setTargetType}
            setImgUrl={setImgUrl}
            setImg={setImg}
            setDescription={setDescription}
            imgLoading={imgLoading}
            setContentType={setContentType}
            onBackClickHandler={handleBackClick}
            handleUsersDropdown={handleUsersDropdown}
            handlePointsChange={handlePointsChange}
            handleDropzone={handleDropzone}
            handleContentChange={handleContentChange}
            isLoading={isLoading}
            description={description}
            clearDropzone={clearDropzone}
            contentValue={contentValue}
            contentType={contentType}
            imgUrl={imgUrl}
            img={img}
            handleCompetitionChange={handleCompetitionChange}
        />
    );
};

export default NotificationSendContainer;