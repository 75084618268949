import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createUserShadowBanThunk,
  deleteUserShadowBanThunk,
  getUsersByNextToken,
  getUsersThunk,
  selectFetching,
  selectNextToken,
  selectUsers,
  setActiveUser,
  setFetching,
  setStatus,
} from '../../store/reducers/usersReducer'
import AdminSidebar from '../Sidebar/AdminSidebar'
import { Button } from '../../submodules/naoo-web-components/Components/Button/Buttons'
import classes from './Users.module.scss'
import layoutClasses from '../Layout.module.scss'
import ModalWindow from './ModalWindow'
import { Preloader } from '../../submodules/naoo-web-components/Components/Preloader/Preloader'
import spinnerClasses from '../Spinner.module.scss'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../submodules/naoo-web-components/Shared/constants'
import MoreDropdown from '../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown'
import MoreItem from '../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem'
import { returnObservableItem } from '../../submodules/naoo-web-components/Shared/utility/utils'
import { useObserver } from '../../submodules/naoo-web-components/Shared/hooks/useObserver'
import DateRangePicker from '../../submodules/naoo-web-components/Components/Date/DateRangePicker/DateRangePicker'
import SearchDropdown from '../../Components/Search/SearchDropdown/SearchDropdown'
import SearchComponentWrapper from '../../Components/Search/SearchComponentWrapper/SearchComponentWrapper'
import SearchContainer from '../../Components/Search/SearchContainer/SearchContainer'
import TableWrapper from '../../Components/Table/TableWrapper/TableWrapper'
import TableHeader from '../../Components/Table/TableHeader/TableHeader'
import TableItem from '../../Components/Table/TableItem/TableItem'
import { format } from 'date-fns'
import Avatar from '../../submodules/naoo-web-components/Components/Avatar/Avatar'
import { useFetchWithQueryParams } from '../../submodules/naoo-web-components/Shared/hooks/useFetchWithQueryParams'
import { setSearchParams } from '../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer'
import { RESTRICTIONS } from './UserRestrictionsMoreOptionsItem/UserRestrictionsMoreOptionsItem'
import SearchInput from '../../Components/SearchInput/SearchInput'
import validator from 'validator'
import { useDebounce } from '../../shared/hooks/useDebounce'

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

const CURRENT_PAGE = 'users'
const Users = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isShowModal, setIsShowModal] = useState(false)

  const genders = useMemo(() => {
    return {
      1: 'Female',
      2: 'Male',
    }
  }, [])

  const [user, setUser] = useState(null)
  const [gender, setGender] = useState('')
  const [restrictionType, setRestrictionType] = useState(null)
  const [emptyNameFilter, setEmptyNameFilter] = useState('All users')
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 1000)

  const [params, isReady] = useFetchWithQueryParams({
    initialValue: {
      gender: null,
      excludeEmptyNames: null,
      startTime: null,
      endTime: null,
      restrictionType: null,
      phone: null,
      email: null,
    },
    currentPage: CURRENT_PAGE,
  })
  const [element, setElement] = useState(null)
  const users = useSelector(selectUsers)
  const LIMIT = 10
  const nextToken = useSelector((state) => selectNextToken(state))
  const fetching = useSelector((state) => selectFetching(state))

  const handleDeleteClick = (user) => () => {
    setIsShowModal(true)
    setUser(user)
  }

  const handleDateFilterChange = ([start, end]) => {
    dispatch(setSearchParams({ startTime: start }))
    dispatch(setSearchParams({ endTime: end }))
  }

  const handleGenderFilterChange = (gender) => {
    setGender(gender)
    dispatch(setSearchParams({ gender: getKeyByValue(genders, gender) }))
  }

  const handleRestrictionFilterChange = (restriction) => {
    setRestrictionType(restriction)
    dispatch(setSearchParams({ restrictionType: restriction.id }))
  }

  useEffect(() => {
    if (isReady) {
      dispatch(getUsersThunk())
    }
  }, [dispatch, params, isReady])

  useObserver(element, fetching, setFetching, nextToken, getUsersByNextToken)

  const listUsers = users

  const match = useMatch(ROUTES.ADMIN_DASHBOARD.USERS)

  const handleSidebarButtonChanged = () => {
    console.log('create new user')
  }

  const navigateToUserDetails = (user) => {
    dispatch(setActiveUser(user))
    dispatch(setStatus(null))
    navigate(`${ROUTES.ADMIN_DASHBOARD.USERS}/${user.id}/update`)
  }

  const handleSearchChange = (value) => {
    setSearchValue(value)
  }

  useEffect(() => {
    if (
      validator.isUUID(debouncedSearchValue) ||
      debouncedSearchValue.startsWith('Google') ||
      debouncedSearchValue.startsWith('SignInWithApple')
    ) {
      dispatch(setSearchParams({ id: debouncedSearchValue }))
    } else if (validator.isEmail(debouncedSearchValue)) {
      dispatch(
        setSearchParams({
          email: debouncedSearchValue,
          id: undefined,
          phone: undefined,
          searchString: undefined,
        }),
      )
    } else if (validator.isMobilePhone(debouncedSearchValue)) {
      dispatch(
        setSearchParams({
          phone: debouncedSearchValue,
          id: undefined,
          email: undefined,
          searchString: undefined,
        }),
      )
    } else {
      dispatch(
        setSearchParams({
          searchString: debouncedSearchValue,
          id: undefined,
          email: undefined,
          phone: undefined,
        }),
      )
    }
  }, [debouncedSearchValue, dispatch])

  return (
    <>
      <Outlet />
      <ModalWindow
        isOpen={isShowModal}
        user={user}
        setIsShowModal={setIsShowModal}
        firstName={user?.firstname}
        lastName={user?.lastname}
        gender={user?.gender}
      />
      {match && (
        <div className={layoutClasses.Container}>
          <AdminSidebar>
            <Button onClick={handleSidebarButtonChanged}>New User</Button>
          </AdminSidebar>
          <div className={layoutClasses.Main}>
            <SearchContainer>
              <SearchComponentWrapper>
                <SearchDropdown
                  value={gender}
                  options={['Male', 'Female']}
                  placeholder={'All genders'}
                  onChange={handleGenderFilterChange}
                  isClearable={true}
                />
              </SearchComponentWrapper>
              <SearchComponentWrapper>
                <SearchDropdown
                  value={restrictionType}
                  options={RESTRICTIONS.map(({ id, text }) => ({ id, title: text }))}
                  placeholder={'Restriction'}
                  onChange={handleRestrictionFilterChange}
                  isClearable={true}
                />
              </SearchComponentWrapper>
              <SearchComponentWrapper>
                <DateRangePicker
                  className={classes.DateTimePicker}
                  placeholder={'Registration date'}
                  value={params.startTime}
                  startDate={params.startTime}
                  endDate={params.endTime}
                  format={'dd.MM.yyyy'}
                  onChange={handleDateFilterChange}
                />
              </SearchComponentWrapper>
              <SearchComponentWrapper>
                <SearchDropdown
                  value={emptyNameFilter}
                  options={['All users', 'Only with name']}
                  onChange={(filter) => {
                    setEmptyNameFilter(filter)
                    dispatch(
                      setSearchParams({
                        excludeEmptyNames: filter === 'Only with name',
                      }),
                    )
                  }}
                  placeholder={'Type'}
                  isClearable={true}
                />
              </SearchComponentWrapper>
            </SearchContainer>
            <div className={classes.SearchContainer}>
              <SearchInput onChange={handleSearchChange} value={searchValue} placeholder='Search users...' />
            </div>
            <TableWrapper>
              <TableHeader className={classes.Header}>
                <div className={classes.User}>
                  <span>User</span>
                </div>
                <div className={classes.Gender}>
                  <span>Gender</span>
                </div>
                <div className={classes.Registration}>
                  <span>Registration</span>
                </div>
                <div className={classes.Email}>
                  <span>Email</span>
                </div>
                <div className={classes.Phone}>
                  <span>Phone</span>
                </div>
              </TableHeader>
              {listUsers?.map((user, index, array) => {
                return (
                  <Fragment key={user.id}>
                    <TableItem
                      className={`${classes.UserItem} ${user.isShadowBanned ? classes.Banned : ''}`}
                      onClick={() => navigateToUserDetails(user)}>
                      <Avatar
                        className={classes.Logo}
                        name={user?.firstname}
                        lastname={user?.lastname}
                        img={user?.avatar?.url}
                      />
                      <div className={classes.User}>
                        <span>
                          {user?.firstname} {user?.lastname}
                        </span>
                      </div>
                      <div className={classes.Gender}>
                        <span>{genders[user?.gender] || '-'}</span>
                      </div>
                      <div className={classes.Registration}>
                        <span>{format(new Date(user?.createdAt), 'dd.MM.yyyy')}</span>
                      </div>
                      <div className={classes.Email}>
                        <span>{user?.email || '-'}</span>
                      </div>
                      <div className={classes.Phone}>
                        <span>{user?.phoneNumber || '-'}</span>
                      </div>
                      <div className={classes.More}>
                        <MoreDropdown>
                          <MoreItem
                            text={'Edit'}
                            onClick={() => {
                              navigateToUserDetails(user)
                            }}
                          />
                          <MoreItem
                            text={user.isShadowBanned ? 'Remove shadow ban' : 'Shadow ban'}
                            onClick={() => {
                              if (user.isShadowBanned) {
                                dispatch(deleteUserShadowBanThunk(user.id))
                              } else {
                                dispatch(createUserShadowBanThunk(user.id))
                              }
                            }}
                            danger={!user.isShadowBanned}
                          />
                          <MoreItem text={'Delete user and all data'} onClick={handleDeleteClick(user)} danger />
                        </MoreDropdown>
                      </div>
                    </TableItem>
                    {returnObservableItem(array.length, LIMIT, index, setElement)}
                  </Fragment>
                )
              })}
              {fetching && <Preloader className={spinnerClasses.paginationSpinnerSize} />}
            </TableWrapper>
          </div>
        </div>
      )}
    </>
  )
}

export default Users
