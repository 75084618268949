import classes from "../../Reports.module.scss";
import React from "react";
import {getReportType, REPORT_TYPES} from "../getReportType/getReportType";
import {ReactComponent as PostPlaceholderIcon} from "../../icons/post.svg";
import {ReactComponent as CommentsPlaceholderIcon} from "../../../../submodules/naoo-web-components/Shared/UI/assets/comment_Icon.svg";
import {useMentionData} from "../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";

const PostContent = ({report}) => {
    const {getTextWithMentions: getPostTextWithMentions} = useMentionData(report?.reportedPost?.text, report?.reportedPost?.mentions)
    return(
        <>
            {
                report?.reportedPost?.media?.length
                    ? <video
                        className={classes.Logo}
                        src={report?.reportedPost?.media[0]?.url}
                        autoPlay={true}
                        muted={true}
                        poster={report?.reportedPost?.media[0]?.url}
                        loop={true}
                    />
                    : <div className={`${classes.Logo} ${classes.ImgPlaceholder}`}>
                        <PostPlaceholderIcon width={24} height={24}/>
                    </div>
            }
            {
                makeContent(getPostTextWithMentions(), 'Post ID', report.reportedPost?.id, report)
            }
        </>
    )
}

const CommentContent = ({report}) => {
    const {getTextWithMentions: getCommentTextWithMentions} = useMentionData(report?.reportedComment?.text, report?.reportedComment?.mentions)

    return (
        <>
            <div className={`${classes.Logo} ${classes.ImgPlaceholder}`}>
                <CommentsPlaceholderIcon width={24} height={24}/>
            </div>
            {
                makeContent(getCommentTextWithMentions(), 'Comment ID', report.reportedComment?.id, report)
            }
        </>
    )
}

const ChannelContent = ({report}) => {
    return (
        <>
            {
                report.reportedChannel?.media?.url
                    ? <img className={classes.Logo} src={report.reportedChannel?.media?.url} alt=""/>
                    : <div className={`${classes.Logo} ${classes.ImgPlaceholder}`}>
                        <span className={classes.Letter}>{report.reportedChannel?.title?.[0]}</span>
                    </div>
            }
            {
                makeContent(report.reportedChannel?.title, 'Channel ID', report.reportedChannel?.id, report)
            }
        </>
    )
}

const UserContent = ({report}) => {
    return (
        <>
            {
                report.reportedUser?.avatar?.url
                    ? <img className={classes.Logo} src={report.reportedUser?.avatar?.url} alt=""/>
                    : <div className={`${classes.Logo} ${classes.ImgPlaceholder}`}>
                        <span className={classes.Letter}>{report.reportedUser?.firstname?.[0]}{report.reportedUser?.lastname?.[0]}</span>
                    </div>
            }
            {
                makeContent(`${report.reportedUser?.firstname} ${report.reportedUser?.lastname}`, 'User ID', report.reportedUser?.id, report)
            }
        </>
    )
}

const OfferContent = ({report}) => {
    return (
        <>
            {
                report.reportedOffer?.media?.[0]?.url
                    ?   <img className={classes.Logo} src={report.reportedOffer?.media?.[0]?.url} alt=""/>
                    : <div className={`${classes.Logo} ${classes.ImgPlaceholder}`}>
                        <span className={classes.Letter}>{report.reportedOffer?.title?.[0]}</span>
                    </div>
            }
            {
                makeContent(report.reportedOffer?.title, 'Offer ID', report.reportedOffer?.id, report)
            }
        </>
    )
}

const BusinessContent = ({report}) => {
    return (
        <>
            {
                report.reportedBusiness?.logo?.url
                    ?   <img className={classes.Logo} src={report.reportedBusiness?.logo?.url} alt=""/>
                    : <div className={`${classes.Logo} ${classes.ImgPlaceholder}`}>
                        <span className={classes.Letter}>{report.reportedBusiness?.name?.[0]}</span>
                    </div>
            }
            {
                makeContent(report.reportedBusiness?.name, 'Business ID', report.reportedBusiness?.id, report)
            }
        </>
    )
}

const makeContent = (title, typeOfId, reportedId, report) => {
    return (
        <div className={classes.Content}>
            <span className={classes.Title}>{title}</span>
            <span className={classes.ReportedObjectId}>{`${typeOfId}: ${reportedId}`}</span>
            <span className={classes.ReportId}>{'Report ID: ' + report?.id}</span>
        </div>
    )
}

export const makeContentOfReport = (report) => {
    const contentOfReport = {
        [REPORT_TYPES.REPORTED_POST]: (
            <PostContent report={report}/>
        ),
        [REPORT_TYPES.REPORTED_CHANNEL]: (
            <ChannelContent report={report}/>
        ),
        [REPORT_TYPES.REPORTED_COMMENT]: (
            <CommentContent report={report}/>
        ),
        [REPORT_TYPES.REPORTED_USER]: (
          <UserContent report={report}/>
        ),
        [REPORT_TYPES.REPORTED_OFFER]: (
            <OfferContent report={report}/>
        ),
        [REPORT_TYPES.REPORTED_BUSINESS]: (
            <BusinessContent report={report}/>
        )
    }

    return contentOfReport[getReportType(report)] ?? (
        <>
            <div className={classes.Content}>
                <span className={classes.ReportId}>{'Report ID: ' + report.id}</span>
            </div>
        </>
    )
}