import React from 'react';
import MoreItem from "../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {
    hideConfirmationPopup,
    removeDeletedReportFromList,
    setPopupState
} from "../../../store/reducers/reportsReducer";
import {
    createUserShadowBanThunk,
    deleteUserShadowBanThunk,
    deleteUserThunk,
    selectActiveUser
} from "../../../store/reducers/usersReducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import UserRestrictionsMoreOptionsItem from "../UserRestrictionsMoreOptionsItem/UserRestrictionsMoreOptionsItem";

const UserMoreItems = ({isReported}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector(selectActiveUser)

    const handleDeleteUserClick = () => {
        dispatch(setPopupState({
            isOpen: true,
            title: `Are you sure you want to delete the user ${user?.firstname} ${user?.lastname}?`,
            primaryButton: 'Yes, delete user',
            handlePrimaryClick: () => {
                dispatch(deleteUserThunk(user.id))
                    .then((response) => {
                        if (response.meta.requestStatus === 'fulfilled') {
                            if (isReported){
                                navigate(-1)
                                dispatch(removeDeletedReportFromList())
                            }
                            dispatch(hideConfirmationPopup())
                        }
                    })
            }
        }))
    }

    const handleShadowBanClick = () => {
        if (user?.isShadowBanned) {
            dispatch(deleteUserShadowBanThunk(user.id))
        } else {
            dispatch(createUserShadowBanThunk(user.id))
        }
    }

    return (
     <>
         <UserRestrictionsMoreOptionsItem user={user} text={'Add restriction'}/>
         <MoreItem  text={user?.shadowBanned ? 'Remove shadow ban' : 'Shadow ban'} danger={!user?.shadowBanned} onClick={handleShadowBanClick}/>
         <MoreItem text={'Delete user and all his data'} danger onClick={handleDeleteUserClick}/>
     </>
    );
};

export default UserMoreItems;