import React from 'react';
import Slider from "../../../../../../submodules/naoo-web-components/Components/Slider/Slider";
import MoreDropdown from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {setPopupState} from "../../../../../../store/reducers/reportsReducer";
import {deleteOfferThunk} from "../../../../../../store/reducers/offersReducer";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import classes from './OfferCard.module.scss'

const OfferCard = ({offer}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleDeleteOfferClick = () => {
        dispatch(setPopupState({
            isOpen: true,
            title: `Are you sure you want to delete the offer ${offer?.title}?`,
            primaryButton: 'Yes, delete offer',
            handlePrimaryClick: () => {
                dispatch(deleteOfferThunk(offer.id))
                    .then((response) => {
                        if (response.meta.requestStatus === 'fulfilled') {
                            navigate(-1)
                        }
                    })
            }
        }))
    }

    const images = []
    if (offer?.logo) images.push(offer.logo)
    if (offer?.cover) images.push(offer.cover)
    if (offer?.media) {
        images.push(...offer.media)
    }

    return (
        <div className={classes.Container}>
            <div className={classes.LogoContainer}>
                <Slider media={images}/>
            </div>
            <div className={classes.InfoContainer}>
                <div className={classes.Name}>
                    <span>Offer</span>
                </div>
                <div className={classes.More}>
                    <MoreDropdown>
                        <MoreItem text={'Delete offer'} danger onClick={handleDeleteOfferClick}/>
                    </MoreDropdown>
                </div>
            </div>
        </div>
    );
};

export default OfferCard;