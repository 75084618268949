import React, { useEffect, useState } from 'react'
import { Outlet, useMatch, useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchFeaturedPosts,
  fetchFeaturedPostsByNextToken,
  fetchPosts,
  fetchPostsByNextToken,
  fetchPostsWithVideo,
  fetchPostsWithVideoByNextToken,
  fetchPostsWithMediaModerationLabels,
  fetchPostsWithMediaModerationLabelsByNextToken,
  selectFeaturedPosts,
  selectFetching,
  selectFetchingFeatured,
  selectFetchingWithVideo,
  selectFetchingWithMediaModerationLabels,
  selectNextToken,
  selectNextTokenFeatured,
  selectNextTokenWithVideo,
  selectNextTokenWithMediaModerationLabels,
  selectPosts,
  selectPostsWithVideo,
  selectPostsWithMediaModerationLabels,
  setFetching,
  setFetchingFeatured,
  setFetchingWithVideo,
  setFetchingWithMediaModerationLabels,
  setIsSendImg,
  setIsViewed,
  setIsViewedFeatured,
  setIsViewedWithVideo,
  setIsViewedWithMediaModerationLabels,
} from '../../store/reducers/postsReducer'
import AdminSidebar from '../Sidebar/AdminSidebar'
import SearchInput from '../../Components/SearchInput/SearchInput'
import { Button } from '../../submodules/naoo-web-components/Components/Button/Buttons'
import layoutClasses from '../Layout.module.scss'
import { ROUTES } from '../../submodules/naoo-web-components/Shared/constants'
import Posts from './Components/Posts/Posts'
import TabBar from '../../submodules/naoo-web-components/Components/TabBar/TabBar'
import TabBarItem from '../../submodules/naoo-web-components/Components/TabBar/TabBarItem'
import SearchUserItem from '../../Components/Search/SearchUserItem/SearchUserItem'
import SearchDropdown from '../../Components/Search/SearchDropdown/SearchDropdown'
import SearchComponentWrapper from '../../Components/Search/SearchComponentWrapper/SearchComponentWrapper'
import SearchContainer from '../../Components/Search/SearchContainer/SearchContainer'
import Margin from '../../submodules/naoo-web-components/Components/Margin/Margin'
import { setSearchParams } from '../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer'
import { useFetchWithQueryParams } from '../../submodules/naoo-web-components/Shared/hooks/useFetchWithQueryParams'

const CURRENT_PAGE = 'posts'

const PostsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [status, setStatus] = useState('')
  const posts = useSelector(selectPosts)
  const featuredPosts = useSelector(selectFeaturedPosts)
  const withVideoPosts = useSelector(selectPostsWithVideo)
  const withMediaModerationLabelsPosts = useSelector(selectPostsWithMediaModerationLabels)
  const [activeTab, setActiveTab] = useState('All posts')
  const fetching = useSelector(selectFetching)
  const fetchingFeatured = useSelector(selectFetchingFeatured)
  const fetchingWithVideo = useSelector(selectFetchingWithVideo)
  const fetchingWithMediaModerationLabels = useSelector(selectFetchingWithMediaModerationLabels)

  const nextToken = useSelector(selectNextToken)
  const nextTokenFeatured = useSelector(selectNextTokenFeatured)
  const nextTokenWithVideo = useSelector(selectNextTokenWithVideo)
  const nextTokenWithMediaModerationLabels = useSelector(selectNextTokenWithMediaModerationLabels)
  const [q, setQ] = useState('')

  const handleDebounceFn = (searchString) => {
    dispatch(setSearchParams({ searchString }))
  }

  const [{ searchString, user }, isReady, debounceQueryUpdate] = useFetchWithQueryParams({
    initialValue: {
      searchString: '',
      user: searchParams.get('userId') ? { id: searchParams.get('userId') } : null,
    },
    currentPage: CURRENT_PAGE,
    callback: handleDebounceFn,
  })

  useEffect(() => {
    if (isReady) {
      dispatch(fetchPosts())
      dispatch(fetchFeaturedPosts())
      dispatch(fetchPostsWithVideo())
      dispatch(fetchPostsWithMediaModerationLabels())
    }
  }, [dispatch, isReady, user, searchString])

  const match = useMatch(ROUTES.ADMIN_DASHBOARD.POSTS)
  const previewMatch = useMatch(ROUTES.ADMIN_DASHBOARD.POSTS + '/:id/preview')

  const handleSidebarButtonChanged = () => {
    navigate(ROUTES.ADMIN_DASHBOARD.POSTS + '/create')
    dispatch(setIsSendImg(true))
  }

  const fetchMorePosts = () => {
    dispatch(setFetching(true))
    if (nextToken) {
      return dispatch(
        fetchPostsByNextToken({
          nextToken,
        }),
      )
    }
  }

  const fetchMoreFeaturedPosts = () => {
    dispatch(setFetchingFeatured(true))
    if (nextTokenFeatured) {
      return dispatch(
        fetchFeaturedPostsByNextToken({
          nextToken: nextTokenFeatured,
        }),
      )
    }
  }

  const fetchMorePostsWithVideo = () => {
    dispatch(setFetchingWithVideo(true))
    if (nextTokenWithVideo) {
      return dispatch(
        fetchPostsWithVideoByNextToken({
          nextToken: nextTokenWithVideo,
        }),
      )
    }
  }

  const fetchMorePostsWithMediaModerationLabels = () => {
    dispatch(setFetchingWithMediaModerationLabels(true))
    if (nextTokenWithMediaModerationLabels) {
      return dispatch(
        fetchPostsWithMediaModerationLabelsByNextToken({
          nextToken: nextTokenWithMediaModerationLabels,
        }),
      )
    }
  }

  return (
    <>
      <Outlet />
      {(match || previewMatch) && (
        <div className={layoutClasses.Container}>
          <AdminSidebar>
            <Button onClick={handleSidebarButtonChanged}>New Post</Button>
          </AdminSidebar>
          <div className={layoutClasses.Main}>
            {isReady && (
              <SearchContainer>
                <SearchInput
                  value={q}
                  placeholder={'Search posts...'}
                  onChange={(searchString) => {
                    setQ(searchString)
                    debounceQueryUpdate(searchString)
                  }}
                />

                <SearchComponentWrapper>
                  <SearchUserItem
                    user={user}
                    onItemClick={(user) => {
                      dispatch(setSearchParams({ user }))
                    }}
                    isClearable={true}
                  />
                </SearchComponentWrapper>
                <SearchComponentWrapper>
                  <SearchDropdown
                    value={status}
                    options={['Draft', 'Sheduled', 'Published']}
                    placeholder={'Status'}
                    onChange={(status) => {
                      setStatus(status)
                    }}
                    isClearable={true}
                  />
                </SearchComponentWrapper>
              </SearchContainer>
            )}

            <Margin top={12} />
            <TabBar initialActiveTab={activeTab} navItemClick={(tab) => setActiveTab(tab)}>
              <TabBarItem label={'All posts'}>
                <Posts
                  posts={posts}
                  fetchMore={fetchMorePosts}
                  setIsViewed={setIsViewed}
                  fetching={fetching}
                  nextToken={nextToken}
                />
              </TabBarItem>
              <TabBarItem label={'Featured'}>
                <Posts
                  posts={featuredPosts}
                  fetchMore={fetchMoreFeaturedPosts}
                  setIsViewed={setIsViewedFeatured}
                  fetching={fetchingFeatured}
                  nextToken={nextTokenFeatured}
                />
              </TabBarItem>
              <TabBarItem label={'Video'}>
                <Posts
                  posts={withVideoPosts}
                  fetchMore={fetchMorePostsWithVideo}
                  setIsViewed={setIsViewedWithVideo}
                  fetching={fetchingWithVideo}
                  nextToken={nextTokenWithVideo}
                />
              </TabBarItem>
              <TabBarItem label={'With Moderation Labels'}>
                <Posts
                  posts={withMediaModerationLabelsPosts}
                  fetchMore={fetchMorePostsWithMediaModerationLabels}
                  setIsViewed={setIsViewedWithMediaModerationLabels}
                  fetching={fetchingWithMediaModerationLabels}
                  nextToken={nextTokenWithMediaModerationLabels}
                />
              </TabBarItem>
            </TabBar>
          </div>
        </div>
      )}
    </>
  )
}

export default PostsPage
