import React, {useCallback, useMemo} from 'react';
import MediaWithComments from "../MediaWithComments/MediaWithComments";
import MoreDropdown from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useMentionData} from "../../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";
import {deletePostThunk} from "../../../../../store/reducers/postsReducer";
import {
    hideConfirmationPopup,
    removeDeletedReportFromList,
    setPopupState,
    updateReportedComment
} from "../../../../../store/reducers/reportsReducer";
import UserMoreItems from "../../../../Users/UserMoreItems/UserMoreItems";

const Post = ({post, isReported, reportId}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {getTextWithMentions} = useMentionData(post.text, post.mentions)

    const handleDeleteMoreItemClick = useCallback(() => {
        dispatch(setPopupState({
            isOpen: true,
            title: 'Are you sure you want to delete post?',
            primaryButton: 'Yes, delete post',
            handlePrimaryClick: ()=>{
                dispatch(deletePostThunk(post.id))
                    .then((response)=>{
                        if(response.meta.requestStatus === 'fulfilled'){
                            navigate(-1)
                            dispatch(hideConfirmationPopup())
                            dispatch(updateReportedComment())
                            if (reportId) {
                                dispatch(removeDeletedReportFromList())
                            }
                        }
                    })
            }
        }))
    },[dispatch, navigate, post.id, reportId])

    const PostMoreDropdown = useMemo(() => {

        return (
            <MoreDropdown width={213}>
                <MoreItem text={'Delete post'} danger onClick={handleDeleteMoreItemClick}/>
                <UserMoreItems/>
            </MoreDropdown>
        )
    }, [handleDeleteMoreItemClick])

    return (
        <MediaWithComments
            entity={post}
            text={getTextWithMentions()}
            media={post?.media}
            MoreDropdown={PostMoreDropdown}
            isReported={isReported}
        />
    );
};

export default Post;