import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	createPromotion,
	deletePromotion,
	getActivationsPromotion,
	getPromotionById,
	getPromotions,
	updatePromotion
} from "../../api/promotionApi";

export const fetchPromotionsThunk = createAsyncThunk('promotions/fetchPromotionsThunk', async () => {
	const response = await getPromotions()
	return response.data
})

export const fetchPromotionByIdThunk = createAsyncThunk(
	'promotions/fetchPromotionByIdThunk',
	async (payload, { getState }) => {
		const { promotions } = getState()
		if (promotions.activePromotion) return promotions.activePromotion
		const response = await getPromotionById(payload)
		return response.data
	}
)

export const createPromotionThunk = createAsyncThunk('promotions/createPromotionThunk', async (payload) => {
	const response = await createPromotion(payload)
	return response.data
})

export const updatePromotionThunk = createAsyncThunk('promotions/updatePromotionThunk', async (payload) => {
	const response = await updatePromotion({
		promotion: payload?.promotion,
		id: payload?.id,
	})
	return response.data
})

export const deletePromotionThunk = createAsyncThunk('promotions/deletePromotionThunk', async (payload) => {
	await deletePromotion(payload)
	return payload
})

export const fetchActivationsPromotion = createAsyncThunk('promotions/fetchActivations', async (payload) => {
	const response = await getActivationsPromotion(payload)
	return response.data
})

const initialState = {
	promotions: [],
	activePromotion: null,
	status: null,
	activations: [],
	// nextToken:'',
	// fetching:false
}

const promotionsSlice = createSlice({
	name: 'promotions',
	initialState,
	reducers: {
		setActivePromotion: (state, action) => {
			state.activePromotion = state.promotions.find((item) => item.id === action.payload)
		},
		setStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPromotionsThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(fetchPromotionsThunk.fulfilled, (state, action) => {
			state.promotions = action.payload
			state.status = null
		})
		builder.addCase(fetchPromotionsThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(fetchPromotionByIdThunk.fulfilled, (state, action) => {
			state.activePromotion = action.payload
		})
		builder.addCase(createPromotionThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(createPromotionThunk.fulfilled, (state, action) => {
			state.promotions.unshift(action.payload)
			state.status = 'successfully'
		})
		builder.addCase(createPromotionThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(updatePromotionThunk.pending, (state, action) => {
			state.status = 'loading'
		})
		builder.addCase(updatePromotionThunk.fulfilled, (state, action) => {
			state.promotions = state.promotions.map((promotion) => {
				if (promotion.id === action.payload.id) return action.payload
				return promotion
			})
			state.status = 'successfully'
		})
		builder.addCase(updatePromotionThunk.rejected, (state, action) => {
			state.status = 'error'
		})
		builder.addCase(deletePromotionThunk.fulfilled, (state, action) => {
			state.promotions = state.promotions.filter((item) => item.id !== action.payload)
			state.status = 'successfully'
		})
		builder.addCase(fetchActivationsPromotion.fulfilled, (state, action) => {
			state.activations = action.payload
		})
	},
})

export default promotionsSlice.reducer

export const { setActivePromotion, setStatus } = promotionsSlice.actions

export const selectPromotions = (state) => state.promotions.promotions
export const selectActivePromotion = (state) => state.promotions.activePromotion
export const selectStatus = (state) => state.promotions.status
export const selectActivations = (state) => state.promotions.activations
