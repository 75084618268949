import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCompetitions,
    fetchCompetitionsByNextToken,
    selectCompetitions,
    selectFetching,
    selectNextToken,
    setActiveCompetition,
    setFetching
} from "../../store/reducers/competitionsReducer";
import AdminSidebar from "../Sidebar/AdminSidebar";
import SearchInput from "../../Components/SearchInput/SearchInput";
import {Button} from "../../submodules/naoo-web-components/Components/Button/Buttons";
import {ReactComponent as NaooIcon} from "../../shared/icons/naooIcon.svg";
import layoutClasses from "../Layout.module.scss";
import classes from "./Competitions.module.scss";
import {Preloader} from "../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../Spinner.module.scss";
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import {ROUTES} from "../../submodules/naoo-web-components/Shared/constants";
import {returnObservableItem} from "../../submodules/naoo-web-components/Shared/utility/utils";
import {useObserver} from "../../submodules/naoo-web-components/Shared/hooks/useObserver";
import SearchDropdown from "../../Components/Search/SearchDropdown/SearchDropdown";
import SearchComponentWrapper from "../../Components/Search/SearchComponentWrapper/SearchComponentWrapper";
import SearchContainer from "../../Components/Search/SearchContainer/SearchContainer";
import TableWrapper from "../../Components/Table/TableWrapper/TableWrapper";
import TableHeader from "../../Components/Table/TableHeader/TableHeader";
import TableItem from "../../Components/Table/TableItem/TableItem";
import {format} from "date-fns";
import {setSearchParams} from "../../submodules/naoo-web-components/Shared/reducers/searchParamsReducer";
import {useFetchWithQueryParams} from "../../submodules/naoo-web-components/Shared/hooks/useFetchWithQueryParams";

const CURRENT_PAGE =  'Competitions'
const Competitions = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const competitions = useSelector(selectCompetitions)
    const [filter, setFilter] = useState('')
    const [element, setElement] = useState(null)
    const [searchString, setSearchString] = useState('')
    const nextToken = useSelector(selectNextToken)
    const fetching = useSelector(selectFetching)
    const LIMIT = 10

    const handleDebounceFn = (searchString) => {
        dispatch(setSearchParams({searchString}))
    }

    const [params, isReady, debounceQueryUpdate] = useFetchWithQueryParams(
        {
            initialValue: {
                searchString: null,
            },
            currentPage: CURRENT_PAGE,
            callback: handleDebounceFn
        })

    useEffect(() => {
        if (isReady) {
            dispatch(fetchCompetitions())
        }
    }, [params.searchString, isReady, dispatch])


    useObserver(
        element,
        fetching, setFetching,
        nextToken, fetchCompetitionsByNextToken
    )
    const match = useMatch(ROUTES.ADMIN_DASHBOARD.COMPETITIONS)

    const handleSearchChange = (text) => {
        setSearchString(text)
        debounceQueryUpdate(text)
    }

    const handleSidebarButtonChanged = () => {
        dispatch(setActiveCompetition(null))
        navigate(ROUTES.ADMIN_DASHBOARD.COMPETITIONS + '/create')
    }

    return (
        <>
            <Outlet/>
            {
                match &&
                <div className={layoutClasses.Container}>
                    <AdminSidebar>
                        <Button className={classes.Button} onClick={handleSidebarButtonChanged}>New competition</Button>
                    </AdminSidebar>
                    <div className={layoutClasses.Main}>
                        <SearchContainer>
                            <SearchInput
                                value={searchString}
                                placeholder={'Search competitions...'}
                                onChange={handleSearchChange}
                            />
                            <SearchComponentWrapper>
                                <SearchDropdown
                                    options={['Active', 'Won', 'Not yet won']}
                                    placeholder={'Filter'}
                                    value={filter}
                                    onChange={(item) => setFilter(item)}
                                />
                            </SearchComponentWrapper>
                        </SearchContainer>
                        <TableWrapper>
                            {/*HEADER*/}
                            <TableHeader className={classes.Header}>
                                <div className={classes.Title}>
                                    <span>Title</span>
                                </div>
                                <div className={classes.Points}>
                                    <span>Points</span>
                                </div>
                                <div className={classes.Probability}>
                                    <span>Probability</span>
                                </div>
                                <div className={classes.StartEnd}>
                                    <span>Start – end</span>
                                </div>
                                <div className={classes.Winner}>
                                    <span>Winner</span>
                                </div>
                            </TableHeader>
                            {/*HEADER*/}
                            {/*ITEMS*/}
                            {
                                competitions?.map((item, index, array) => {
                                    const startTime = item?.startTime ? format(new Date(item?.startTime), 'dd.MM.yyyy') : ''
                                    const endTime = item?.endTime ? format(new Date(item?.endTime),'dd.MM.yyyy') : ''
                                    let probability = Math.round(item?.probability * 10000) / 100
                                    if (probability < 0.0001) {
                                        probability = '<0.0001'
                                    }
                                    const user = item?.latestWin?.user
                                    const winner = user ? `${user?.firstname} ${user?.lastname}` : '–'
                                    return (<Fragment key={item.id}>
                                            <TableItem className={classes.CompetitionsItem} onClick={() => {
                                                dispatch(setActiveCompetition(item.id))
                                                navigate(ROUTES.ADMIN_DASHBOARD.COMPETITIONS + `/${item.id}`)
                                            }}>
                                                <img className={classes.Logo} src={item?.media?.url} alt=""/>
                                                <div className={classes.Title}>
                                                    <span>{item?.title}</span>
                                                </div>
                                                <div className={classes.Points}>
                                                    <span>{item?.coins}</span> <NaooIcon height={12}/>
                                                </div>
                                                <div className={classes.Probability}>
                                                    {/*<span>{Math.round(item?.probability *10000 )/100}%</span>*/}
                                                    <span>{probability}%</span>
                                                </div>
                                                <div className={classes.StartEnd}>
                                                    <span>{startTime} – {endTime}</span>
                                                </div>
                                                <div className={classes.Winner}>
                                                    <span>{winner}</span>
                                                </div>
                                            </TableItem>
                                            {
                                                returnObservableItem(array.length, LIMIT, index, setElement)
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                            {
                                competitions?.length === LIMIT && <div ref={setElement}/>
                            }
                            {
                                fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                            }
                            {/*ITEMS*/}
                        </TableWrapper>
                    </div>
                </div>
            }
        </>
    );
};

export default Competitions;