import React, {useEffect, useMemo, useState} from 'react';
import classes from "../../PostsPage.module.scss";
import spinnerClasses from '../../../Spinner.module.scss'
import PostStats from "../../../../submodules/naoo-web-components/Posts/PostStats/PostStats";
import {fetchComments, setActivePost} from "../../../../store/reducers/postsReducer";
import {ROUTES} from "../../../../submodules/naoo-web-components/Shared/constants";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import TableWrapper from "../../../../Components/Table/TableWrapper/TableWrapper";
import TableHeader from "../../../../Components/Table/TableHeader/TableHeader";
import PostTableItem from "../PostTableItem";
import {Preloader} from "../../../../submodules/naoo-web-components/Components/Preloader/Preloader";

const Posts = ({posts, MoreDropdownComponent, fetchMore, setIsViewed, fetching, nextToken}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isNotViewedCount = useMemo(()=>{
        return posts?.filter(it=>!it.isViewed).length ?? 0
    }, [posts])

    useEffect(() => {
        if (isNotViewedCount < 20 && !fetching && nextToken ) {
           fetchMore()
        }
    }, [isNotViewedCount, nextToken, dispatch, fetching, fetchMore]);


    const [isShowStats, setIsShowStats] = useState(false)

    const handleShowStats = () => {
        setIsShowStats(true)
    }

    const handleCloseStats = () => {
        setIsShowStats(false)
    }

    const handlePostClick = (post) => () => {
        const {id} = post
        dispatch(setActivePost(post))
        dispatch(fetchComments({
            id
        }))
        navigate(`${ROUTES.ADMIN_DASHBOARD.POSTS}/${id}/preview`)
    }

    return (
        <>
            <TableWrapper>
                {/*HEADER*/}
                <TableHeader className={classes.Header}>
                    <div className={classes.Post}>
                        <span>Post</span>
                    </div>
                    <div className={classes.OriginAccount}>
                        <span>Origin Account</span>
                    </div>
                    <div className={classes.PublishDate}>
                        <span>Publication Date</span>
                    </div>
                    <div className={classes.Comments}>
                        <span>Comments</span>
                    </div>
                    <div className={classes.Likes}>
                        <span>Likes</span>
                    </div>
                    <div className={classes.Types}>
                        <span>Content</span>
                    </div>
                    <div className={classes.Featured}>
                        <span>Featured</span>
                    </div>
                </TableHeader>
                {/*HEADER*/}
                {/*ITEMS*/}
                {
                    posts?.map((post, index, array) => (
                        <PostTableItem
                            key={post.id}
                            post={post}
                            handlePostItemClick={handlePostClick}
                            handleShowStats={handleShowStats}
                            setIsViewed={setIsViewed}
                        />
                    ))
                }
                {
                    fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                }
                {/*ITEMS*/}
            </TableWrapper>
            {
                isShowStats &&
                <PostStats onClose={handleCloseStats}/>
            }
        </>
    )
};

export default Posts;