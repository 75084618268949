import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";

const getMedia = (mediaId) =>
    createRequest('main', 'get', `media/${mediaId}`, null)

const createMediaPresignedUrl = (contentType, dimensions, userId='naoo') =>
    createRequest('main', 'post', 'media', {contentType, dimensions, userId})

export {
    getMedia,
    createMediaPresignedUrl
}