import React from 'react';
import classes from './MediaWithComments.module.scss'
import Slider from "../../../../../submodules/naoo-web-components/Components/Slider/Slider";
import BusinessInfo
    from "../../../../../submodules/naoo-web-components/Posts/BusinessInfo/BusinessInfo";
import UserInfo from "../../../../../submodules/naoo-web-components/Posts/UserInfo/UserInfo";
import {useDispatch} from "react-redux";
import {setActiveUser} from "../../../../../store/reducers/usersReducer";
import Comments from "../Comments/Comments";

const MediaWithComments = ({entity, media, text, MoreDropdown, isReported}) => {
    const dispatch = useDispatch()

    const handleMoreClick = () => {
        dispatch(setActiveUser(entity.user))
    }

    return (
        <div className={classes.Container}>
            {
                media &&
                <div className={classes.ImgContainer} data-testid="media">
                    <div className={classes.Img}>
                        <Slider media={media}/>
                    </div>
                </div>
            }
            <div className={classes.TextPlusComments}>
                <div className={`${classes.Description} ${isReported ? classes.Reported : ''}`}>
                    <div className={classes.Header}>
                        {
                            entity?.business
                                ? <BusinessInfo business={entity?.business} createdAt={entity?.createdAt}/>
                                : <UserInfo user={entity?.user} createdAt={entity?.createdAt}/>
                        }
                        <div className={classes.More} onClickCapture={handleMoreClick}>
                            {MoreDropdown}
                        </div>
                    </div>
                    <div className={classes.Text}>
                        <span>{text}</span>
                    </div>
                </div>

                <div className={classes.Comments}>
                    <Comments MoreDropdownComponent={MoreDropdown}/>
                </div>
            </div>
        </div>
    );
};

export default MediaWithComments;