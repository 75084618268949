import React from 'react'
import classes from './PostPreview.module.scss'
import { useMentionData } from '../../../../submodules/naoo-web-components/Shared/hooks/useMentionData'
import LazyLoad from 'react-lazy-load'

const PostPreview = ({ post, mediaClass, textClass, repost, delayed, rowHeight, isVisible }) => {
	const { getTextWithMentions } = useMentionData(post?.text || '', post?.mentions)

	return (
		<>
			{(post?.media?.length && isVisible) && (
				<LazyLoad height={rowHeight}>
					<img
						className={`${mediaClass} ${delayed ? classes.Delayed : ''}`}
						src={post?.thumbnail?.url ?? post?.media[0]?.url}
						alt={''}
					/>
				</LazyLoad>
			)}
			<div className={`${textClass} ${delayed ? classes.Delayed : ''}`}>
				{delayed && (
					<div className={classes.NotPublished}>
						<span>Not published yet</span>
					</div>
				)}
				<div className={classes.Post}>
					<span>{getTextWithMentions()}</span>
				</div>
				{repost && (
					<div className={classes.Repost}>
						<span>{repost}</span>
					</div>
				)}
			</div>
		</>
	)
}

export default PostPreview
