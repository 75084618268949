import React from 'react';
import BackIcon from '../../shared/icons/back.svg'
import classes from './TopNav.module.scss'

const TopNav = (
    {
        buttons,
        onBack, title,
        status
    }) => {
    return (
        <div className={classes.Container}>
            <img className={classes.BackButton} src={BackIcon} alt="Back"
                 onClick={onBack}
            />
            {
                title &&
                <div className={classes.Title}>
                    <span>{title}</span>
                </div>
            }
            {
                status &&
                <div className={classes.Status}>
                    {status}
                </div>
            }

            {
                buttons.map((button, index) => {
                    return (
                        <button key={index} className={`${button.className} ${button.isDisabled ? classes.Disabled : ''}`}
                                onClick={button.onClick}
                                disabled={button.isDisabled}
                        >
                            {button.title}
                        </button>
                    )
                })
            }
        </div>
    );
};

export default TopNav;
