import React, { useEffect, useState } from 'react'
import classes from './Notifications.module.scss'
import AdminSidebar from '../Sidebar/AdminSidebar'
import { Button } from '../../submodules/naoo-web-components/Components/Button/Buttons'
import layoutClasses from '../Layout.module.scss'
import SearchInput from '../../Components/SearchInput/SearchInput'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MoreDropdown from './MoreDropdown/MoreDropdown'
import {
  fetchTemplatesThunk,
  selectNotificationTemplates,
  setActiveTemplate,
  setStatus,
} from '../../store/reducers/notificationTemplatesReducer'
import NotificationSendContainer from './Notification/NotificationSendContainer'
import TableWrapper from '../../Components/Table/TableWrapper/TableWrapper'
import TableHeader from '../../Components/Table/TableHeader/TableHeader'
import TableItem from '../../Components/Table/TableItem/TableItem'
import SearchContainer from '../../Components/Search/SearchContainer/SearchContainer'
import TabBar from '../../submodules/naoo-web-components/Components/TabBar/TabBar'
import TabBarItem from '../../submodules/naoo-web-components/Components/TabBar/TabBarItem'

const Notifications = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [searchString, setSearchString] = useState('')
  const [activeTab, setActiveTab] = useState('Templates')

  const notificationTemplates = useSelector((state) => selectNotificationTemplates(state, searchString))

  useEffect(() => {
    dispatch(fetchTemplatesThunk())
  }, [dispatch])

  const handleBackClick = () => {
    setActiveTab('Templates')
  }

  const handleTemplateClick = (event, templateId) => {
    dispatch(setActiveTemplate(templateId))
    dispatch(setStatus(null))
    navigate(`/notifications/${templateId}/update`)
  }

  const match = useMatch('/notifications')

  const handleSearchChange = (text) => {
    setSearchString(text)
  }

  const handleSidebarButtonChanged = () => {
    navigate('/notifications/create')
  }

  return (
    <>
      <Outlet />
      {match && (
        <div className={layoutClasses.Container}>
          <AdminSidebar>
            <Button onClick={handleSidebarButtonChanged}>New template</Button>
          </AdminSidebar>

          <div className={layoutClasses.Main}>
            <SearchContainer>
              <SearchInput value={searchString} placeholder={'Search templates...'} onChange={handleSearchChange} />
            </SearchContainer>
            <TabBar initialActiveTab={activeTab} navItemClick={(tab) => setActiveTab(tab)}>
              <TabBarItem label={'Templates'}>
                <TableWrapper>
                  {/*HEADER*/}
                  <TableHeader className={classes.Header}>
                    <div className={classes.Title}>
                      <span>Title</span>
                    </div>
                    <div className={classes.Text}>
                      <span>Text</span>
                    </div>
                    <div className={classes.Target}>
                      <span>Target</span>
                    </div>
                  </TableHeader>
                  {/*HEADER*/}
                  {/*ITEMS*/}
                  {notificationTemplates.map((template) => {
                    return (
                      <TableItem
                        className={classes.TemplateItem}
                        key={template.id}
                        onClick={(event) => handleTemplateClick(event, template.id)}>
                        {template?.mediaUrl && <img className={classes.Logo} src={template?.mediaUrl} alt={''} />}
                        <div className={classes.Title}>
                          <span>{template.title}</span>
                        </div>
                        <div className={classes.Text}>
                          <span>{template.message}</span>
                        </div>
                        <div className={classes.Target}>
                          <span>{template?.target || '-'}</span>
                        </div>
                        <div className={classes.More}>
                          <MoreDropdown templateId={template.id} />
                        </div>
                      </TableItem>
                    )
                  })}
                  {/*ITEMS*/}
                </TableWrapper>
              </TabBarItem>
              <TabBarItem label={'Send new'}>
                <NotificationSendContainer handleBackClick={handleBackClick} />
              </TabBarItem>
            </TabBar>
          </div>
        </div>
      )}
    </>
  )
}

export default Notifications
