import React from 'react';
import classes from './Answer.module.scss'
import Emoji from "../Emoji/Emoji";

const Answer = ({title, progress, emoji, stat, percent, styles}) => {

    return (
        <div className={`${classes.Container} ${styles?.Container ? styles.Container : ''}`}>
            {
                emoji && <Emoji name={emoji} className={`${classes.Icon} ${styles?.Icon ? styles.Icon : ''}`}/>
            }
            <span className={`${classes.Title} ${styles?.Title ? styles.Title : ''}`}>{title}</span>
            {
                !!stat &&
                <span className={`${classes.Stat} ${styles?.Stat ? styles.Stat : ''}`}>
                    {`${percent ? progress+'%' : stat}`}
                </span>
            }
            <div className={`${classes.Progress} ${styles?.Progress ? styles.Progress : ''}`}
                 style={{width: `${progress}%`}}/>
        </div>
    );
};

export default Answer;
