import React from 'react';
import {Field} from "../../../../shared/Form/FormField";
import Input from "../../../../submodules/naoo-web-components/Components/Input/Input";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";
import Margin from "../../../../submodules/naoo-web-components/Components/Margin/Margin";

const LastNameFiled = () => {
    const user = useSelector(selectActiveUser)
    return (
        <Margin top={24}>
            <Field name={'lastName'} initialValue={user?.lastname || ''}>
                {
                    (lastName, setLastName) => (
                        <Input
                            label={'Last name'}
                            name={'lastName'}
                            maxLength={48}
                            placeholder={'Last name'}
                            value={lastName}
                            onChange={(event) => {
                                setLastName(event.target.value)
                            }}
                        />
                    )
                }
            </Field>
        </Margin>
    );
};

export default LastNameFiled;