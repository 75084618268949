import React from 'react';
import classes from './UserRestrictionsMoreOptionsItem.module.scss'
import {ReactComponent as NextIcon} from "../../../submodules/naoo-web-components/Shared/UI/assets/dropdown-icon.svg";
import {ReactComponent as CheckIcon} from "../../../submodules/naoo-web-components/Shared/UI/assets/check.svg";
import {useDispatch} from "react-redux";
import {addUserRestrictionThunk, removeUserRestrictionThunk} from "../../../store/reducers/usersReducer";
const RESTRICTIONS = [
    {
        id: 19,
        text: 'Can\'t earn points',
    },
    {
        id: 10,
        text: 'Can\'t create posts',
    },
    {
        id: 11,
        text: 'Can\'t create offers',
    },
    {
        id: 12,
        text: 'Can\'t create shares',
    },
    {
        id: 14,
        text: 'Can\'t comment',
    },
    {
        id: 15,
        text: 'Can\'t like',
    },
    {
        id: 16,
        text: 'Can\'t answer polls',
    },
    {
        id: 17,
        text: 'Can\'t participate in competitions',
    },
    {
        id: 18,
        text: 'Can\'t redeem points',
    },
    {
        id: 20,
        text: 'Can\'t toggle public website setting',
    },
]

const checkRestrictionExist = (restriction, user) => {
    return user?.restrictions?.includes(restriction.id)
}
const UserRestrictionsMoreOptionsItem = ({text, user, width}) => {
    const dispatch = useDispatch()

    const handleRestrictionClick = (restriction, isRestrictionExist) => () => {
        if (!isRestrictionExist){
            dispatch(addUserRestrictionThunk({
                userId: user?.id,
                restrictionType: restriction.id
            }))
        }
        else {
            dispatch(removeUserRestrictionThunk({
                userId: user?.id,
                restrictionType: restriction.id
            }))
        }

    }

    return (
        <div className={classes.MoreItem}>
            <span>{text}</span>
            <NextIcon width={16} height={16} className={classes.NextIcon}/>
            <div className={classes.OptionsContainer}>
                {
                    RESTRICTIONS.map((item) => {
                        const isRestrictionExist = checkRestrictionExist(item, user)
                        return (
                            <div
                                key={item.text}
                                className={`${classes.Option} ${isRestrictionExist ? classes.Checked : ''}`}
                                style={{width}}
                                onClick={handleRestrictionClick(item, isRestrictionExist)}
                            >
                                {
                                    isRestrictionExist &&
                                    <CheckIcon className={classes.CheckIcon} width={16} height={16}/>
                                }
                                <span> {item.text}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export { RESTRICTIONS, checkRestrictionExist }

export default UserRestrictionsMoreOptionsItem;