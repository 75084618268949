import React, {Fragment, useEffect, useState} from 'react';
import {getUsername, returnObservableItem} from "../../../Shared/utility/utils";
import {Preloader} from "../../Preloader/Preloader";
import spinnerClasses from "../../../../../app/Spinner.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {
    searchUsersByNextToken,
    searchUsersThunk,
    selectSearchFetching,
    selectSearchNextToken,
    selectSearchUsers,
    setSearchFetching
} from "../../../../../store/reducers/usersReducer";
import {useObserver} from "../../../Shared/hooks/useObserver";
import TabBar from "../../TabBar/TabBar";
import TabBarItem from "../../TabBar/TabBarItem";
import classes from "../UserItem.module.scss";
import RejectedIcon from "../../../Shared/UI/assets/rejected.svg";
import Avatar from "../../Avatar/Avatar";
import {getUserById} from "../../../../../api/userApi";
import {searchParamsSelector} from "../../../Shared/reducers/searchParamsReducer";

const UserList = ({
                      onItemClick,
                      searchString,
                      setSearchString,
                      setIsShow,
                  }) => {
    const dispatch = useDispatch()
    const nextToken = useSelector(selectSearchNextToken)
    const fetching = useSelector(selectSearchFetching)

    const [activeTab, setActiveTab] = useState('Username')
    const [user, setUser] = useState(null)
    const searchItems = useSelector(selectSearchUsers)
    const [element, setElement] = useState(null)

    const LIMIT = 10

    const {searchString: searchParam} = useSelector(searchParamsSelector)


    useEffect(() => {
        if (activeTab === 'Username') {
            dispatch(searchUsersThunk())
        } else {
            if (searchParam) {
                getUserById(searchParam)
                    .then(response => {
                        setUser(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                        setUser(null)
                    })
            }
        }
    }, [activeTab, searchParam, dispatch])

    useObserver(
        element,
        fetching, setSearchFetching,
        nextToken, searchUsersByNextToken
    )


    return (
        <div className={'Wrapper'}>
            <TabBar className={classes.TabBar} initialActiveTab={activeTab} navItemClick={(tab) => setActiveTab(tab)}>
                <TabBarItem label={'Username'}>
                    <div className={'ItemsContainer'}>
                        {
                            (searchItems.length && searchString)
                                ? searchItems?.map((user, index, array) => {
                                    return (
                                        <Fragment key={user.id}>
                                            <div
                                                className={'UserItem'}
                                                onClick={() => {
                                                    onItemClick(user)
                                                    setIsShow(false)
                                                    setSearchString('')
                                                }}
                                            >
                                                <Avatar
                                                    className={'Logo'}
                                                    img={user?.avatar?.url}
                                                    name={user?.firstname}
                                                    lastname={user?.lastname}
                                                />
                                                <div className={'UserName'}>
                                                    <span>{getUsername(user)}</span>
                                                </div>
                                                <div className={'UserID'}>
                                                    <span>{user.id}</span>
                                                </div>
                                            </div>
                                            {
                                                returnObservableItem(array.length, LIMIT, index, setElement)
                                            }
                                        </Fragment>
                                    )
                                })
                            : <div className={`Rejected UserItem`}>
                                    <img className={'RejectedIcon'} src={RejectedIcon} alt=""/>
                                    <span>Username not found</span>
                            </div>
                        }
                        {
                            fetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                        }
                    </div>
                </TabBarItem>
                <TabBarItem label={'ID'}>
                    <div className={'ItemsContainer'}>
                        {
                            (user && searchString)
                                ? <div
                                    className={'UserItem'}
                                    onClick={() => {
                                        onItemClick(user)
                                        setIsShow(false)
                                        setSearchString('')
                                    }}
                                >
                                    <Avatar
                                        className={'Logo'}
                                        img={user?.avatar?.url}
                                        name={user?.firstname}
                                        lastname={user?.lastname}
                                    />
                                    <div className={'UserName'}>
                                        <span>{getUsername(user)}</span>
                                    </div>
                                    <div className={'UserID'}>
                                        <span>{user.id}</span>
                                    </div>
                                </div>
                                :  <div className={`Rejected UserItem`}>
                                    <img className={'RejectedIcon'} src={RejectedIcon} alt=""/>
                                    <span>User ID not found</span>
                                </div>
                        }
                    </div>
                </TabBarItem>
            </TabBar>
        </div>
    );
};

export default UserList;