import React from 'react';
import classes from './SearchContainer.module.scss'

const SearchContainer = ({children}) => {
    return (
        <div className={classes.Search}>
            {children}
        </div>
    );
};

export default SearchContainer;