import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";
import {withAdmin} from "./withAdmin";
 const getPromotions = ()=>{
    return createRequest('main', 'get', withAdmin('/registration_promotions'), null)
}
 const getPromotionById = (id)=>
    createRequest('main', 'get', withAdmin(`/registration_promotions/${id}`), null)
 const createPromotion = (promotion)=>
    createRequest('main', 'post', withAdmin('/registration_promotions'), promotion)
 const deletePromotion = (id)=>
    createRequest('main', 'delete', withAdmin(`/registration_promotions/${id}`), null)
 const updatePromotion = ({promotion, id})=>
    createRequest('main', 'patch', withAdmin(`/registration_promotions/${id}`), promotion)
 const getActivationsPromotion = (id)=>
    createRequest('main', 'get',withAdmin(`/registration_promotions/${id}/activations`))

export {
     getPromotions,
    getPromotionById,
    createPromotion,
    updatePromotion,
    deletePromotion,
    getActivationsPromotion
}