import React from 'react';
import classes from './Post.module.scss'
import MoreDropdown from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreDropdown";
import MoreItem from "../../../../submodules/naoo-web-components/Components/MoreDropdown/MoreItem/MoreItem";
import {ReactComponent as LikeIcon} from "../../../../submodules/naoo-web-components/Shared/UI/assets/like.svg";
import {ReactComponent as CommentIcon} from "../../../../submodules/naoo-web-components/Shared/UI/assets/shared.svg";
import {ReactComponent as ShareIcon} from "../../../../submodules/naoo-web-components/Shared/UI/assets/comment_Icon.svg";
import UserMoreItems from "../../../Users/UserMoreItems/UserMoreItems";
import {setActiveUser} from "../../../../store/reducers/usersReducer";
import {useDispatch} from "react-redux";
import {hideConfirmationPopup, setPopupState} from "../../../../store/reducers/reportsReducer";
import {deletePostThunk} from "../../../../store/reducers/postsReducer";
import {useMentionData} from "../../../../submodules/naoo-web-components/Shared/hooks/useMentionData";
import {formatDistanceToNow} from "date-fns";

const Post = ({post}) => {
    const dispatch = useDispatch()

    const {getTextWithMentions} = useMentionData(post?.text, post?.mentions)

    const handleMoreIconClick = () => {
        dispatch(setActiveUser(post.user))
    }

    const handleDeletePostClick = () => {
        dispatch(setPopupState({
            isOpen: true,
            title: `Are you sure you want to delete post?`,
            primaryButton: 'Yes, delete post',
            handlePrimaryClick: () => {
                dispatch(deletePostThunk(post.id))
                    .then((response)=>{
                        if (response.meta.requestStatus === 'fulfilled' ) {
                            dispatch(hideConfirmationPopup())
                        }
                    })
            }
        }))
    }

    return (
        <div className={classes.Container}>
            {
                post?.media?.length &&
                <video
                    className={classes.Logo}
                    src={post?.media[0]?.url}
                    autoPlay={true}
                    muted={true}
                    poster={post?.media[0]?.url}
                    loop={true}
                />
            }
            <div className={classes.Post}>
                <div className={classes.Header}>
                    <div className={classes.Info}>
                        <span>{formatDistanceToNow(new Date(post.createdAt), {addSuffix: true})}</span>
                        <div className={classes.More} onClickCapture={handleMoreIconClick}>
                            <MoreDropdown>
                                <MoreItem text={'Delete post'} danger onClick={handleDeletePostClick}/>
                                <UserMoreItems/>
                            </MoreDropdown>
                        </div>
                    </div>
                </div>
                <div className={classes.Text}>
                    <span>{getTextWithMentions()}</span>
                </div>
                <div className={classes.Buttons}>
                    <LikeIcon width={20} height={20}/> <span style={{marginRight:21}}>{post?.likesCount}</span>
                    <CommentIcon width={20} height={20}/> <span style={{marginRight:30}}>{post?.commentsCount}</span>
                    <ShareIcon width={20} height={20}/> <span>{post?.sharesCount}</span>
                </div>
            </div>
        </div>
    );
};

export default Post;