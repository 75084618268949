import {useCallback, useEffect, useState} from "react";
import {contentTypes} from "./NotificationTemplateContainer";
import {sendImage} from "../../../submodules/naoo-web-components/Shared/utility/sendImage";
import {validateNumberInput} from "../../../shared/utils/utils";
import {createMediaPresignedUrl, getMedia} from "../../../api/mediaApi";

export const useNotification = () =>{
    const [title, setTitle] = useState('')
    const [img, setImg] = useState(null)
    const [imgUrl, setImgUrl] = useState('')
    const [imgLoading, setImgLoading] = useState(false)
    const [description, setDescription] = useState('')
    const [targetType, setTargetType] = useState('allUsers')
    const [contentType, setContentType] = useState(contentTypes["Without content"])
    const [contentValue, setContentValue] = useState({
        points: '',
        offerId: '',
        competitionId: '',
        suggestionId: '',
    })
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const errors = {
        title: title.length === 0,
        description: description.length === 0,
        users: targetType !== 'allUsers' && users.filter(user=>user.id).length === 0,
        contentValue: (!!contentType?.idName) && contentValue[contentType.idName] === '',
    }

    const handleErrors = ()=>{
        for (const error in errors) {
            if (errors[error]) return true
        }
        return false
    }

    useEffect(()=>{
        if (contentType.idName === 'points'){
            setTargetType('custom')
        }
    },[contentType.idName])

    const handleDropzone = useCallback((images) => {
        setImgLoading(true)
        sendImage(URL.createObjectURL(images[0]), createMediaPresignedUrl, getMedia)
            .then((data) => {
                setImg(data.mediaUrl)
                setImgUrl(data.mediaUrl)
                setImgLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setImgLoading(false)
            })
    }, [])

    const clearDropzone = () => {
        setImg('')
        setImgUrl('')
    }

    const handleContentChange = (event) => {
        setContentValue(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleCompetitionChange = (competition)=>{
        setContentValue(prevState => {
            return {
                ...prevState,
                competitionId: competition
            }
        })
    }

    const handlePointsChange = (event) => {
        const isValidInput = validateNumberInput(event)
        if (isValidInput) {
            handleContentChange(event)
        }
    }

    const handleUsersDropdown = useCallback((item, index) => {
        setUsers(prevState => {
                prevState[index] = item
                return [...prevState]
            }
        )
    }, [])

    const combineData = ()=>{
        let data
        if (contentType.idName === 'points'){
            data = {
                type: contentType.name,
                targetUserIds: targetType === 'allUsers' ? ['all'] : users.filter(user=>user.id).map(user => user.id),
                title,
                message: description,
                points: +contentValue.points
            }
        }
        else{
            data = {
                title,
                mediaUrl: imgUrl,
                type: contentType.name,
                targetUserIds: targetType === 'allUsers' ? ['all'] : users.filter(user=>user.id).map(user => user.id),
            }
            if(contentType.name === contentTypes.Competition.name){
                data[contentType.idName] = contentValue[contentType.idName].id
            }
            else if (contentType.idName) {
                data[contentType.idName] = contentValue[contentType.idName]
            }
        }
        return data
    }

    const combinePointsData = () => {
        return  {
            amount: +contentValue.points,
            targetUserIds: targetType === 'allUsers' ? ['all'] : users.map(user => user.id),
            notificationTitle: title,
            notificationBody: description,
        }
    }


    return {
        title, setTitle,
        img, setImg,
        imgUrl, setImgUrl,
        imgLoading, setImgLoading,
        description, setDescription,
        targetType, setTargetType,
        contentType, setContentType,
        contentValue, setContentValue,
        users, setUsers,
        isLoading, setIsLoading,
        errors,
        handleErrors,
        handleDropzone,
        clearDropzone,
        handleContentChange,
        handleCompetitionChange,
        handlePointsChange,
        handleUsersDropdown,
        combineData,
        combinePointsData
    }

}