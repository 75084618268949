import React from 'react';
import {Field} from "../../../../shared/Form/FormField";
import classes from "../User.module.scss";
import {useSelector} from "react-redux";
import {selectActiveUser} from "../../../../store/reducers/usersReducer";

const BioField = () => {
    const user = useSelector(selectActiveUser)
    return (
        <div className={classes.DescriptionContainer}>
            <span className={`${classes.DescriptionLabel} ${classes.Label}`}>Bio</span>
            <Field name={'bio'} initialValue={user?.bio || ''}>
                {
                    (bio, setBio) => (
                        <textarea
                            name={'text'}
                            className={classes.DescriptionInput}
                            placeholder="Your notification text"
                            value={bio}
                            maxLength={512}
                            onChange={(event) => {
                                setBio(event.target.value)
                            }}
                        />
                    )
                }
            </Field>
        </div>
    );
};

export default BioField;