import React, { createContext, useContext, useState } from 'react'

const FormContext = createContext(null)
const useFormContext = () => useContext(FormContext)
const FormContextProvider = ({ children }) => {
  const [form, setForm] = useState({ values: {} })

  const isChanged = form?.changed && Object.values(form.changed).filter(Boolean).length !== 0

  return <FormContext.Provider value={{ form, setForm, isChanged }}>{children}</FormContext.Provider>
}

export { FormContextProvider, useFormContext }
