import React, { useEffect, useState } from 'react'
import classes from './Promotions.module.scss'
import layoutClasses from '../Layout.module.scss'
import AdminSidebar from '../Sidebar/AdminSidebar'
import { Button } from '../../submodules/naoo-web-components/Components/Button/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import {
  fetchPromotionsThunk,
  selectPromotions,
  setActivePromotion,
  setStatus,
} from '../../store/reducers/promotionsReducer'
import SearchInput from '../../Components/SearchInput/SearchInput'
import MoreDropdown from './MoreDropdown/MoreDropdown'
import { ReactComponent as NaooIcon } from '../../shared/icons/naooIcon.svg'
import DeletePromotionModal from './DeletePromotionModal'
import SearchContainer from '../../Components/Search/SearchContainer/SearchContainer'
import TableWrapper from '../../Components/Table/TableWrapper/TableWrapper'
import TableHeader from '../../Components/Table/TableHeader/TableHeader'
import TableItem from '../../Components/Table/TableItem/TableItem'
import { format, parseISO } from 'date-fns'

const Promotions = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const promotions = useSelector(state => selectPromotions(state))

  const [searchString, setSearchString] = useState('')
  const [promotion, setPromotion] = useState(null)
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false)

  const handleDeleteButtonClick = (promotion) => {
    setPromotion(promotion)
    setIsShowDeleteModal(true)
  }

  useEffect(() => {
    dispatch(fetchPromotionsThunk())
  }, [dispatch])

  const match = useMatch('/promotions')

  const handleSearchChange = (text) => {
    setSearchString(text)
  }

  const handleSidebarButtonChanged = () => {
    dispatch(setActivePromotion())
    dispatch(setStatus(null))
    navigate('/promotions/create')
  }

  return (
    <>
      <Outlet />
      <DeletePromotionModal
        isOpen={isShowDeleteModal}
        message={promotion?.message}
        promotionId={promotion?.id}
        setIsShowModal={setIsShowDeleteModal}
      />
      {
        match &&
        <div className={layoutClasses.Container}>
          <AdminSidebar>
            <Button onClick={handleSidebarButtonChanged}>New promotion</Button>
          </AdminSidebar>
          <div className={layoutClasses.Promotions}>
            <SearchContainer>
              <SearchInput
                value={searchString}
                placeholder={'Search promotions...'}
                onChange={handleSearchChange}
              />
            </SearchContainer>
            <TableWrapper>
              <TableHeader className={classes.Header}>
                <div className={classes.Promotion}>
                  <span>Promotion</span>
                </div>
                <div className={classes.Points}>
                  <span>Points</span>
                </div>
                <div className={classes.TargetUsers}>
                  <span>Target users</span>
                </div>
                <div className={classes.TargetBusinesses}>
                  <span>Target businesses</span>
                </div>
                <div className={classes.CreatedAt}>
                  <span>Created at</span>
                </div>
              </TableHeader>
              {
                promotions?.map((promotion) => {
                  return (
                    <TableItem className={classes.Item} key={promotion.id}>
                      {
                        promotion?.mediaUrl &&
                        <img className={classes.Logo} src={promotion?.mediaUrl} alt='' />
                      }

                      <div className={classes.Promotion}>
                        <span>{promotion?.message}</span>
                      </div>
                      <div className={classes.Points}>
                        <span>{promotion?.points}</span> <NaooIcon height={12} />
                      </div>
                      <div className={classes.TargetUsers}>
                        <span>{promotion?.users?.length || '-'}</span>
                      </div>
                      <div className={classes.TargetBusinesses}>
                        <span>{promotion?.businesses?.length || '-'}</span>
                      </div>
                      <div className={classes.CreatedAt}>
                        <span>{promotion?.createdAt ? format(parseISO(promotion?.createdAt), 'yyyy-MM-dd') : '-'}</span>
                      </div>
                      <div className={classes.More}>
                        <MoreDropdown
                          promotionId={promotion.id}
                          promotion={promotion}
                          deleteClickHandler={handleDeleteButtonClick}
                        />
                      </div>
                    </TableItem>
                  )
                })
              }
              {/*ITEMS*/}
            </TableWrapper>
          </div>
        </div>
      }
    </>
  )
}

export default Promotions