import React, {useState} from 'react';
import {ReactComponent as DownIcon} from "../../Shared/UI/assets/down.svg";
import {ReactComponent as ClearIcon} from "../../Shared/UI/assets/closeIcon.svg";
import classes from './UserItem.module.scss'
import {useDebounce} from "../../Shared/hooks/useDebounce";
import {useOnClickOutside} from "../../Shared/hooks/useclickOutside";
import UserList from "./UserList/UserList";
import {getUsername} from "../../Shared/utility/utils";
import {useDebounceFunction} from "../../Shared/hooks/useDebounceFunction";
import {useDispatch} from "react-redux";
import {setSearchParams} from "../../Shared/reducers/searchParamsReducer";

const UserItem = (
    {
        user,label, onItemClick, className,
        isShowAvatar, isClearable, onlyWithNames= true,
        placeholder, gender, startTime, endTime
    }) => {
    const dispatch = useDispatch()
    const [searchString, setSearchString] = useState('')
    const [isShow, setIsShow] = useState(false)

    const ref = useOnClickOutside(() => {
        setIsShow(false)
    })

    const debounceSearchString = useDebounce(searchString, 1000)

    const handleUpdateSearchParam = (searchString) => {
        dispatch(setSearchParams({searchString}))
    }

    const debounceUpdateQuery = useDebounceFunction(handleUpdateSearchParam)


    return (
        <div className={`${classes.Container} ${className}`} ref={ref}>
            <span className={classes.Label}>{label}</span>
            {
                user
                    ? <div className={'SelectedUser'}>
                        {
                          isShowAvatar && user?.avatar?.url &&
                          <img className={'Avatar'} src={user?.avatar?.url} alt="Avatar"/>
                        }

                         <span className={'SelectedUserName'}>
                           {getUsername(user)}
                          </span>
                        <span className={'SelectedUserID'}>
                            {user.id}
                        </span>

                        {
                            isClearable &&
                            <div className={'CloseIcon'} onClick={() => {
                                onItemClick('')
                                handleUpdateSearchParam('')
                            }}>
                                <ClearIcon />
                            </div>
                        }

                    </div>
                    : <div className={'InputContainer'}
                           onClick={() => {
                               setIsShow(true)
                           }}
                    >
                        <input
                            className={`Input ${isShow && 'InputDropdownOpen'}`}
                            placeholder={placeholder || 'Select user'}
                            value={searchString}
                            type="text"
                            onChange={(event) => {
                                setSearchString(event.target.value)
                                debounceUpdateQuery(event.target.value)
                                if (event.target.value === '') {
                                    setIsShow(false)
                                }
                            }}
                        />
                        <DownIcon className={'Icon'}/>
                    </div>
            }
            {
                isShow &&
                <UserList
                    searchString={debounceSearchString}
                    onItemClick={onItemClick}
                    setSearchString={setSearchString}
                    setIsShow={setIsShow}
                />
            }
        </div>

    );
};

export default UserItem;
