export const ROUTES = {
    MAIN: '/',
    POLLS: '/POLLS',
    USERS: '/USERS',
    NOTIFICATION: '/NOTIFICATION',
    CASH_REDEMPTIONS: '/CASH_REDEMPTIONS',
    COMPETITIONS: '/COMPETITIONS',
}


export const TRANSACTION_CATEGORIES = [
    {
        id: 1,
        name: 'Onboarding'
    },
    {
        id: 3,
        name: 'Offer Claim'
    },
    {
        id: 4,
        name: 'Poll Answered Reward'
    },
    {
        id: 5,
        name: 'Profile Data Gender Added'
    },
    {
        id: 6,
        name: 'Profile Data Photo Added'
    },
    {
        id: 7,
        name: 'Profile Data Interests Added'
    },
    {
        id: 8,
        name: 'Question Answered Reward'
    },
    {
        id: 9,
        name: 'Competition Participation Fee'
    },
    {
        id: 10,
        name: 'Profile Data Birthdate Added'
    },
    {
        id: 11,
        name: 'Invited User Joined'
    },
    {
        id: 12,
        name: 'Coins Gifted'
    },
    {
        id: 13,
        name: 'Points To Cash'
    },
    {
        id: 14,
        name: 'Points To Cash Returned'
    },
    {
        id: 15,
        name: 'Points For Any Purchase'
    },
    {
        id: 16,
        name: 'Points For Content'
    },
    {
        id: 17,
        name: 'Points For Likes'
    },
    {
        id: 18,
        name: 'Registration Promotion'
    },
    {
        id: 19,
        name: 'Points To Voucher'
    },
    {
        id: 20,
        name: 'Points To Voucher Returned'
    },
    {
        id: 21,
        name: 'Invited User Became Active'
    },
    {
        id: 22,
        name: 'Third Party Voucher Claim'
    },
    {
        id: 23,
        name: 'Points For Most Popular Post Of The Day'
    },
]

export const GENDERS = {
    1: 'Female',
    2: 'Male'
}
