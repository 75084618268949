import {createRequest} from "../submodules/naoo-web-components/Api/createRequest";

const getBusinesses = (params) => {
    return createRequest('main', 'get', '/businesses', null, params)
}
const getBusinessById = (businessId) => {
    return createRequest('main', 'get', `/businesses/${businessId}`, null)
}
const updateBusiness = (business, businessId) =>
    createRequest('main', 'patch', `/businesses/${businessId}`, business)
const getCategories = () =>
    createRequest('main', 'get', '/business_categories', null)
const deleteBusiness = (id) =>
    createRequest('main', 'delete', `/businesses/${id}`, null)

export {
    getBusinesses,
    getBusinessById,
    deleteBusiness,
    updateBusiness,
    getCategories,
}