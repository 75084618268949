import React from 'react';
import Offer from "../Offer/Offer";
import Post from "../Post/Post";
import classes from './ReportedComment.module.scss'
import Comments from "../Comments/Comments";

const ReportedComment = ({comment}) => {

    if (comment?.offer) {
        return <Offer offer={comment.offer}/>
    }
    if (comment?.post) {
        return <Post post={comment.post}/>
    }

    return (
        <div className={classes.Container}>
            <Comments/>
        </div>
    )

};

export default ReportedComment;