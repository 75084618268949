import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getReportType, REPORT_TYPES } from '../../app/Reports/utils/getReportType/getReportType'
import {getUsersReports, updateUserReport} from "../../api/reportApi";

const initialState = {
	reports: [],
	activeReport: null,
	nextToken: '',
	fetching: false,
	status: '',
	error: '',
	confirmationPopupState: {
		isOpen: false,
		title: '',
		primaryButton: '',
	},
}

export const fetchReports = createAsyncThunk('reports/fetchReports', async (payload) => {
	const response = await getUsersReports({
		status: payload.status,
	})
	return response.data
})

export const fetchReportsByNextToken = createAsyncThunk('reports/fetchReportsByNextToken', async (payload) => {
	const response = await getUsersReports({
		nextToken: payload.nextToken,
	})
	return response.data
})

export const updateReportThunk = createAsyncThunk('reports/updateReportThunk', async ({ report, id }) => {
	const response = await updateUserReport({
		report,
		id,
	})
	return response.data
})

const reportsSlice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		setStatus: (state, action) => {
			state.status = action.payload
		},
		setFetching: (state, action) => {
			state.fetching = action.payload
		},
		setError: (state, action) => {
			state.error = action.payload
		},
		setActiveReport: (state, action) => {
			state.activeReport = action.payload
		},
		setPopupState: (state, action) => {
			state.confirmationPopupState = action.payload
		},
		hideConfirmationPopup: (state, action) => {
			state.confirmationPopupState.isOpen = false
		},
		addUserRestrictionInReport: (state, action) => {
			const { userId, restrictionType } = action.payload
			const user =
				state.activeReport?.[getReportType(state.activeReport)]?.user ??
				state.activeReport?.[REPORT_TYPES.REPORTED_USER]
			if (user?.id === userId) {
				if (user.restrictions) {
					user.restrictions?.push(restrictionType)
				} else {
					user.restrictions = [restrictionType]
				}
			}
		},
		removeUserRestrictionInReport: (state, action) => {
			const { userId, restrictionType } = action.payload
			const user =
				state.activeReport?.[getReportType(state.activeReport)]?.user ??
				state.activeReport?.[REPORT_TYPES.REPORTED_USER]
			if (user?.id === userId) {
				user.restrictions = user.restrictions?.filter((item) => item !== restrictionType)
			}
		},
		addUserShadowBanInReport: (state, action) => {
			const userId = action.payload
			const user =
				state?.activeReport?.[getReportType(state.activeReport)]?.user ??
				state?.activeReport?.[REPORT_TYPES.REPORTED_USER]
			if (user?.id === userId) {
				user.isShadowBanned = true
			}
		},
		removeUserShadowBanInReport: (state, action) => {
			const userId = action.payload
			const user =
				state?.activeReport?.[getReportType(state.activeReport)]?.user ??
				state?.activeReport?.[REPORT_TYPES.REPORTED_USER]
			if (user?.id === userId) {
				user.isShadowBanned = false
			}
		},
		removeDeletedReportFromList: (state, action) => {
			const reportId = state.activeReport.id
			if (reportId) {
				state.reports = state.reports.filter((report) => report.id !== reportId)
			}
		},
		updateReportedComment: (state, action) => {
			const reportId = state.activeReport.id
			const comment = state.reports.find((report) => report.id === reportId).reportedComment
			delete comment.post
			delete comment.offer
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchReports.fulfilled, (state, action) => {
			state.reports = action.payload.userContentReports || []
			state.nextToken = action.payload.nextToken
		})
		builder.addCase(fetchReportsByNextToken.fulfilled, (state, action) => {
			if (action.payload.userContentReports) {
				state.reports.push(...action.payload.userContentReports)
			}
			state.nextToken = action.payload.nextToken
			state.fetching = false
		})
		builder.addCase(updateReportThunk.fulfilled, (state, action) => {
			state.reports = state.reports.map((report) => {
				if (report.id === action.payload.id) return action.payload
				return report
			})
			state.activeReport = action.payload
		})
	},
})

export default reportsSlice.reducer

export const {
	setFetching,
	setStatus,
	setError,
	setActiveReport,
	hideConfirmationPopup,
	setPopupState,
	removeUserRestrictionInReport,
	addUserRestrictionInReport,
	addUserShadowBanInReport,
	removeUserShadowBanInReport,
	removeDeletedReportFromList,
	updateReportedComment,
} = reportsSlice.actions

export const selectReports = (state) => {
	return [...state.reports.reports].sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
}
export const selectActiveReport = (state) => state.reports.activeReport
export const selectNextToken = (state) => state.reports.nextToken
export const selectFetching = (state) => state.reports.fetching
export const selectStatus = (state) => state.reports.status
export const selectError = (state) => state.reports.error
export const selectConfirmationPopup = (state) => state.reports.confirmationPopupState
