import React, { useEffect } from 'react'
import classes from './Request.module.scss'
import TopNav from '../../../Components/TopNav/TopNav'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '../../../submodules/naoo-web-components/Components/Input/Input'
import { useDispatch, useSelector } from 'react-redux'
import {
  cashRedeemRequestConfirmThunk,
  cashRedeemRequestFulfilledThunk,
  cashRedeemRequestRejectThunk,
  fetchCashRedeemRequestThunk,
  selectActiveRequest,
} from '../../../store/reducers/cashRedeemRequestsReducer'
import Status from '../../../Components/Status/Status'
import Container from '../../../Components/FormComponents/Container/Container'
import Section from '../../../Components/FormComponents/Section/Section'
import { format } from 'date-fns'
import { getUsername } from '../../../submodules/naoo-web-components/Shared/utility/utils'
import { UserSearchIcon } from 'lucide-react'
import { Button } from '../../../submodules/naoo-web-components/Components/Button/Buttons'
import { ROUTES } from '../../../submodules/naoo-web-components/Shared/constants'
import { FullscreenPreloader } from '../../../submodules/naoo-web-components/Components/Preloader/Preloader'

const Request = () => {
  const navigate = useNavigate()
  const request = useSelector(selectActiveRequest)
  const dispatch = useDispatch()

  const { id } = useParams()

  useEffect(() => {
    if (!request) {
      dispatch(fetchCashRedeemRequestThunk(id))
    }
  }, [id, dispatch, request])

  const onNavigateToUser = () => {
    navigate(`${ROUTES.ADMIN_DASHBOARD.USERS}/${request.user.id}/update`)
  }

  const topNavButtons = []
  return (
    <Container>
      {!request && <FullscreenPreloader />}
      {request &&
        <div><TopNav
          buttons={topNavButtons}
          status={<Status
            requestId={request?.id}
            status={request?.status}
            handlers={{
              reject: cashRedeemRequestRejectThunk,
              fulfilled: cashRedeemRequestFulfilledThunk,
              confirm: cashRedeemRequestConfirmThunk,
            }}
          />}
          onBack={() => navigate(-1)}
        />

          <div className={classes.FormContainer}>
            <div className={classes.MainContainer}>
              <Section title={'Request'}>
                <div className={classes.UserInputContainer}>
                  <div className={classes.UserInput}>
                    <Input
                      className={classes.Input}
                      label={'User'}
                      placeholder={'User'}
                      name={'user'}
                      value={getUsername(request.user)}
                      readOnly
                    />
                  </div>
                  <Button onClick={onNavigateToUser} className={classes.GoToUserButton}><UserSearchIcon
                    size={20} /></Button>
                </div>
                <div className={classes.InputContainer}>
                  <Input
                    className={classes.Input}
                    label={'Created'}
                    placeholder={'Created at'}
                    name={'created'}
                    value={request?.createdAt ? format(new Date(request?.createdAt), 'dd.MM.yyyy') : '-'}
                    readOnly
                  />
                </div>
                <div className={classes.InputContainer}>
                  <Input
                    className={classes.Input}
                    label={'Selected option'}
                    placeholder={'Selected option'}
                    name={'selectedOption'}
                    value={`${request?.cash} CHF / ${request?.points} points`}
                    readOnly
                  />
                </div>
                <div className={classes.InputContainer}>
                  <Input
                    className={classes.Input}
                    label={'IBAN'}
                    placeholder={'IBAN'}
                    name={'IBAN'}
                    value={`${request?.iban?.toUpperCase()}`}
                    copy={true}
                    readOnly
                  />
                </div>
                <div className={classes.InputContainer}>
                  <Input
                    className={classes.Input}
                    label={'Beneficiary'}
                    placeholder={'Beneficiary'}
                    name={'beneficiary'}
                    value={`${request?.firstname} ${request?.lastname}`}
                    copy={true}
                    readOnly
                  />
                </div>
                <div className={classes.InputContainer}>
                  <Input
                    className={classes.Input}
                    label={'Email'}
                    placeholder={'Email'}
                    name={'email'}
                    value={`${request?.email}`}
                    copy={true}
                    readOnly
                  />
                </div>
                <div className={classes.InputContainer}>
                  <Input
                    className={classes.Input}
                    label={'Address'}
                    placeholder={'Address'}
                    name={'address'}
                    value={`${request?.address}`}
                    copy={true}
                    readOnly
                  />
                </div>
              </Section>
            </div>
          </div>
        </div>}
    </Container>
  )
}

export default Request