import React from 'react';
import classes from './Transaction.module.scss'
import {useNavigate} from "react-router-dom";
import {FullscreenPreloader} from "../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../Spinner.module.scss";
import TopNav from "../../../Components/TopNav/TopNav";
import {useSelector} from "react-redux";
import {selectStatus} from "../../../store/reducers/tagsReducer";
import Input from "../../../submodules/naoo-web-components/Components/Input/Input";
import {ReactComponent as NaooIcon} from "../../../shared/icons/naooIcon.svg";
import Margin from "../../../submodules/naoo-web-components/Components/Margin/Margin";
import {selectActiveTransaction} from "../../../store/reducers/transactionsReducer";
import {getUsername} from "../../../submodules/naoo-web-components/Shared/utility/utils";
import Container from "../../../Components/FormComponents/Container/Container";
import Section from "../../../Components/FormComponents/Section/Section";
import {format} from "date-fns";

const Transaction = () => {
    const navigate = useNavigate()

    const transaction = useSelector(selectActiveTransaction)
    const status = useSelector(state => selectStatus(state))

    const handleBackButtonClick = () => {
        navigate(-1)
    }

    return (
        <>
            {
                (status === 'loading') &&
                <FullscreenPreloader
                    overlayClassName={spinnerClasses.lightBlurPreloader}
                    spinnerClassName={spinnerClasses.spinnerSize}
                />
            }

            <Container>
                <TopNav
                    buttons={[]}
                    onBack={handleBackButtonClick}
                />
                <div className={classes.FormContainer}>
                    <div className={classes.MainContainer}>
                        <Section title={'Transaction info'}>
                            <div className={classes.InputGroup}>
                                <div className={classes.Title}>
                                    <span>Transaction</span>
                                </div>
                                {
                                    transaction?.pollAnswer &&
                                    <Input
                                        className={classes.Input}
                                        value={transaction?.pollAnswer?.id}
                                        name={'promotionId'}
                                        readOnly
                                        copy={true}
                                        placeholder={'Poll answer ID'}
                                    />
                                }
                                <Input
                                    className={classes.Input}
                                    value={`Transaction ID: ${transaction?.id}`}
                                    name={'transactionId'}
                                    readOnly
                                    copy={true}
                                    placeholder={'Transaction ID'}
                                />
                            </div>
                            <Input
                                className={classes.Input}
                                label={'Points amount'}
                                placeholder={'Points amount'}
                                name={'Points'}
                                value={transaction?.amount}
                                readOnly
                                Icon={NaooIcon}
                            />
                            <Margin bottom={24}/>
                            <Input
                                label={'Date'}
                                value={format(new Date(transaction.updatedAt || transaction.createdAt), 'dd.MM.yy')}
                                readOnly
                            />
                        </Section>
                        <Section title={'Source and target'}>
                            <div className={classes.InputGroup}>
                                <div className={classes.Title}>
                                    <span>Source</span>
                                </div>
                                <Input
                                    className={classes.Input}
                                    value={getUsername(transaction?.sourceUser)}
                                    name={'name'}
                                    readOnly
                                    placeholder={'Name'}
                                />
                                <Input
                                    className={classes.Input}
                                    value={`User ID: ${transaction?.sourceUser?.id}`}
                                    name={'name'}
                                    readOnly
                                    copy={true}
                                    placeholder={'User ID'}
                                />
                                <Input
                                    className={classes.Input}
                                    value={`Wallet ID:${transaction?.sourceWalletId}`}
                                    name={'name'}
                                    readOnly
                                    copy={true}
                                    placeholder={'Wallet ID'}
                                />
                            </div>
                            <div className={classes.InputGroup}>
                                <div className={classes.Title}>
                                    <span>Target</span>
                                </div>
                                <Input
                                    className={classes.Input}
                                    value={getUsername(transaction?.targetUser)}
                                    name={'name'}
                                    readOnly
                                    placeholder={'Name'}
                                />
                                <Input
                                    className={classes.Input}
                                    value={`User ID: ${transaction?.targetUser?.id}`}
                                    name={'name'}
                                    readOnly
                                    copy={true}
                                    placeholder={'User ID'}
                                />
                                <Input
                                    className={classes.Input}
                                    value={`Wallet ID:${transaction?.targetWalletId}`}
                                    name={'name'}
                                    readOnly
                                    copy={true}
                                    placeholder={'Wallet ID'}
                                />
                            </div>
                        </Section>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Transaction;


