import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import App from './App';
import {createReduxStore} from './store/store'
import './index.scss';

const root = createRoot(document.getElementById("root"))

root.render(
    <Provider store={createReduxStore()}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

