import React, {Fragment, useEffect, useState} from 'react';
import classes from './ReportedBusiness.module.scss'
import BusinessCard from "./BusinessCard/BusinessCard";
import TabBar from "../../../../../submodules/naoo-web-components/Components/TabBar/TabBar";
import TabBarItem from "../../../../../submodules/naoo-web-components/Components/TabBar/TabBarItem";
import BusinessOverview from "./BusinessOverview/BusinessOverview";
import {fetchOffers} from "../../../../../store/reducers/offersReducer";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchPosts,
    fetchPostsByNextToken,
    selectFetching as selectPostFetching,
    selectNextToken as selectPostsNextToken,
    selectPosts,
    setFetching as setPostsFetching
} from "../../../../../store/reducers/postsReducer";
import Post from "../../../../Posts/Components/Post/Post";
import {returnObservableItem} from "../../../../../submodules/naoo-web-components/Shared/utility/utils";
import {Preloader} from "../../../../../submodules/naoo-web-components/Components/Preloader/Preloader";
import spinnerClasses from "../../../../Spinner.module.scss";
import {useObserver} from "../../../../../submodules/naoo-web-components/Shared/hooks/useObserver";
import OfferTable from "../../../../Offers/OfferTable/OfferTable";

const ReportedBusiness = ({business}) => {
    const dispatch = useDispatch()
    const posts = useSelector(selectPosts)

    const [activeTab, setActiveTab] = useState('Overview')

    const businessId = business?.id

    const [postsObserverElement, setPostObserverElement] = useState(null)
    const POSTS_LIMIT = 50
    const postsFetching = useSelector(selectPostFetching)
    const postsNextToken = useSelector(selectPostsNextToken)


    useEffect(()=>{
        dispatch(fetchOffers({businessId}))
        dispatch(fetchPosts({
            businessId,
            limit:POSTS_LIMIT
        }))
    }, [dispatch, businessId, POSTS_LIMIT ])


    useObserver(
        postsObserverElement,
        postsFetching, setPostsFetching,
        postsNextToken, fetchPostsByNextToken
    )



    return (
        <div className={classes.Container}>
            <BusinessCard business={business}/>
            <div className={classes.TabContainer}>
                <TabBar type={'SECONDARY'} initialActiveTab={activeTab} navItemClick={(tab)=>setActiveTab(tab)}>
                    <TabBarItem label={'Overview'} >
                        <BusinessOverview business={business}/>
                    </TabBarItem>
                    <TabBarItem label={'Offers'} >
                        <OfferTable/>
                    </TabBarItem>
                    <TabBarItem label={'Posts'} >
                        <div className={classes.Posts}>
                            {
                                posts?.map((post, index, array)=>(
                                    <Fragment key={post.id}>
                                        <Post post={post}/>
                                        {
                                            returnObservableItem(array.length, POSTS_LIMIT, index, setPostObserverElement )
                                        }
                                    </Fragment>
                                ))
                            }
                            {
                                postsFetching && <Preloader className={spinnerClasses.paginationSpinnerSize}/>
                            }
                        </div>

                    </TabBarItem>
                </TabBar>
            </div>
        </div>
    );
};

export default ReportedBusiness;