export const addUserRestrictionById = (array, userId, restriction) => {
    return array?.map((item) => {
        if (item.user.id === userId) {
            if (item.user.restrictions) {
                item.user.restrictions?.push(restriction)
            } else {
                item.user.restrictions = [restriction]
            }
        }
        return item
    })
}