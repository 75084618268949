import { withAdmin } from './withAdmin'
import { createRequest } from '../submodules/naoo-web-components/Api/createRequest'

const updateUserProperty = (userId, name, value) => {
  const url = withAdmin(`/ct/users/${userId}/profile`)

  return createRequest('main', 'post', url, {
    attributes: [
      { name, value },
    ],
  })
}

export { updateUserProperty }