import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectTags } from '../../../../store/reducers/tagsReducer'
import TopNav from '../../../../Components/TopNav/TopNav'
import UserItem from '../../../../submodules/naoo-web-components/Components/UserItem/UserItem'
import classes from './Post.module.scss'
import {
	createPostThunk,
	deletePostThunk,
	selectStatus,
	setIsSendImg,
	setStatus,
	updatePostThunk,
} from '../../../../store/reducers/postsReducer'
import { ROUTES } from '../../../../submodules/naoo-web-components/Shared/constants'
import { FullscreenPreloader } from '../../../../submodules/naoo-web-components/Components/Preloader/Preloader'
import spinnerClasses from '../../../Spinner.module.scss'
import topNavClasses from '../../../../Components/TopNav/TopNav.module.scss'
import TagsMultiselect from '../../../../submodules/naoo-web-components/Components/Tags/TagsMultiselect/TagsMultiselect'
import TextArea from '../../../../submodules/naoo-web-components/Components/TextArea/TextArea'
import Container from '../../../../Components/FormComponents/Container/Container'
import Section from '../../../../Components/FormComponents/Section/Section'
import DateTimePicker from '../../../../submodules/naoo-web-components/Components/Date/DateTimePicker/DateTimePicker'
import Radio from '../../../../submodules/naoo-web-components/Components/Radio/Radio'
import Gallery from '../../../../submodules/naoo-web-components/Components/Gallery/Gallery'
import { useEffect } from 'react'

const CreateOrUpdatePost = ({ post }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const tags = useSelector((state) => selectTags(state))
	const status = useSelector((state) => selectStatus(state))

	const [text, setText] = useState(post?.text || '')
	const [user, setUser] = useState(post?.user || null)
	const [postTags, setPostTags] = useState(post?.tags || [])
	const [galleryItems, setGalleryItems] = useState(post?.media ?? [])
	const [settings, setSettings] = useState(post?.publicationDate ? 'schedule' : 'postNow')
	const [dateTime, setDateTime] = useState(new Date(post?.publicationDate || new Date()))

	const isClearableUser = !post

	const handleText = (event) => {
		setText(event.target.value)
	}

	const handleUser = (user) => {
		setUser(user)
		dispatch(setIsSendImg(true))
	}


	const handleTags = (tags) => {
		setPostTags(tags)
	}

	const handleDateTime = (value) => {
		setDateTime(value)
	}

	const createPostHandler = () => {
		if (post) {
			dispatch(
				updatePostThunk({
					id: post.id,
					post: {
						text,
						galleryItems,
						userID: user?.id,
						tags: postTags,
						publicationDate: dateTime.toISOString(),
					},
				})
			)
		} else {
			dispatch(
				createPostThunk({
					text,
					galleryItems,
					userID: user?.id,
					tags: postTags,
					publicationDate: dateTime ? dateTime.toISOString() : new Date().toISOString(),
				})
			)
		}
	}

	useEffect(() => {
		if (status === 'successfully') {
			dispatch(setStatus(null))
			navigate(-1)
		}
	}, [status, dispatch, navigate])

	const deletePostHandler = () => {
		if (post) {
			dispatch(deletePostThunk(post.id))
				.then(() => {
					navigate(ROUTES.ADMIN_DASHBOARD.POSTS)
				})
				.catch((err) => console.error(err))
			return
		}
		navigate(-1)
	}

	const handleAddGalleryItems = (urlList) => {
		const newItems = urlList.map((item) => ({
				url: item,
		}))
		setGalleryItems((prevState) => [...prevState, ...newItems])
	}

	const deleteGalleryItem = (item) => {
		setGalleryItems((prevState) => {
			return prevState.filter((media) => {
				if (media.id) return media.id !== item
				return media.url !== item.url
			})
		})
	}

	const topNavButtons = [
		{
			title: 'Delete post',
			onClick: deletePostHandler,
			isDisabled: false,
			className: topNavClasses.DangerButton,
		},
		{
			title: post ? 'Save' : 'Post',
			onClick: createPostHandler,
			isDisabled: false,
			className: topNavClasses.SuccessButton,
		},
	]

	return (
		<>
			{status === 'loading' && (
				<FullscreenPreloader
					overlayClassName={spinnerClasses.lightBlurPreloader}
					spinnerClassName={spinnerClasses.spinnerSize}
				/>
			)}
			<Container>
				<TopNav buttons={topNavButtons} onBack={() => navigate(-1)} />
				<div className={classes.FormContainer}>
					<div className={classes.MainContainer}>
						<Section title={'Post'}>
							<TextArea
								className={classes.Input}
								label={'Text'}
								placeholder={'Your Post Text'}
								name={'text'}
								value={text}
								mentions={post?.mentions}
								onChange={handleText}
							/>
							<UserItem
								className={classes.UserItem}
								label={'Origin Account'}
								user={user}
								onItemClick={handleUser}
								isShowAvatar={true}
								isClearable={isClearableUser}
							/>
							<div className={`${classes.Label} ${classes.LabelMargin}`}>
								<span>Tags</span>
							</div>
							<TagsMultiselect initialValue={postTags} options={tags} onChange={handleTags} />
							<div className={classes.Buttons}>
								<span className={classes.Subtitle}>Post settings</span>
								<Radio
									className={classes.Left}
									name={'settings'}
									value={'postNow'}
									label={'Post Now'}
									checked={settings === 'postNow'}
									onChange={() => {
										setSettings('postNow')
									}}
								/>
								<Radio
									className={classes.Right}
									name={'settings'}
									value={'schedule'}
									label={'Schedule'}
									checked={settings === 'schedule'}
									onChange={() => {
										setSettings('schedule')
									}}
								/>
							</div>
							{settings === 'schedule' && (
								<div className={classes.Schedule}>
									<DateTimePicker
										placeholder={'Choose Date and Time'}
										className={classes.ScheduleInput}
										value={new Date(dateTime || Date.now())}
										format={"dd MMMM yyyy 'at' HH:mm"}
										onChange={handleDateTime}
									/>
								</div>
							)}
						</Section>
					</div>
					<div className={classes.PreviewContainer}>
						<Section title={'Attachments'}>
							<div className={classes.GalleryContainer}>
								<Gallery
									items={galleryItems}
									className={classes.Gallery}
									itemClassName={classes.ItemStyle}
									dropzoneClassName={
										!!galleryItems.length
											? classes.GalleryDropzoneWithMedia
											: classes.GalleryDropzoneWithoutMedia
									}
									onAdd={handleAddGalleryItems}
									onDelete={deleteGalleryItem}
								/>
							</div>
						</Section>
					</div>
				</div>
			</Container>
		</>
	)
}

export default CreateOrUpdatePost
