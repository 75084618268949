import React from 'react';
import Basketball from '../../shared/icons/emoji/basketball.svg'
import Climbing from '../../shared/icons/emoji/climbing.svg'
import Cooking from '../../shared/icons/emoji/cooking.svg'
import Culture from '../../shared/icons/emoji/culture.svg'
import Cycling from '../../shared/icons/emoji/cycling.svg'
import Dancing from '../../shared/icons/emoji/dancing.svg'
import Darts from '../../shared/icons/emoji/darts.svg'
import Fashion from '../../shared/icons/emoji/fashion.svg'
import Food from '../../shared/icons/emoji/food.svg'
import Football from '../../shared/icons/emoji/football.svg'
import Gaming from '../../shared/icons/emoji/gaming.svg'
import Gardening from '../../shared/icons/emoji/gardening.svg'
import Golf from '../../shared/icons/emoji/golf.svg'
import Health from '../../shared/icons/emoji/health.svg'
import Home from '../../shared/icons/emoji/home.svg'
import Joga from '../../shared/icons/emoji/joga.svg'
import Movie from '../../shared/icons/emoji/movie.svg'
import Painting from '../../shared/icons/emoji/painting.svg'
import Photo from '../../shared/icons/emoji/photo.svg'
import Polo from '../../shared/icons/emoji/polo.svg'
import Reading from '../../shared/icons/emoji/reading.svg'
import Running from '../../shared/icons/emoji/running.svg'
import Serfing from '../../shared/icons/emoji/serfing.svg'
import Shopping from '../../shared/icons/emoji/shopping.svg'
import Skiing from '../../shared/icons/emoji/skiing.svg'
import Snowboarding from '../../shared/icons/emoji/snowboarding.svg'
import Sport from '../../shared/icons/emoji/sport.svg'
import Swimming from '../../shared/icons/emoji/swimming.svg'
import Tennis from '../../shared/icons/emoji/tennis.svg'
import Travelling from '../../shared/icons/emoji/travelling.svg'

export const emoji = {
    Basketball: {
        name: 'Basketball',
        path: Basketball
    },
    Climbing: {
        name: 'Climbing',
        path: Climbing
    },
    Cooking: {
        name: 'Cooking',
        path: Cooking
    },
    Culture: {
        name: 'Culture',
        path: Culture
    },
    Cycling: {
        name: 'Cycling',
        path: Cycling
    },
    Dancing: {
        name: 'Dancing',
        path: Dancing
    },
    Darts: {
        name: 'Darts',
        path: Darts
    },
    Fashion: {
        name: 'Fashion',
        path: Fashion
    },
    Food: {
        name: 'Food',
        path: Food
    },
    Football: {
        name: 'Football',
        path: Football
    },
    Gaming: {
        name: 'Gaming',
        path: Gaming
    },
    Gardening: {
        name: 'Gardening',
        path: Gardening
    },
    Golf: {
        name: 'Golf',
        path: Golf
    },
    Health: {
        name: 'Health',
        path: Health
    },
    Home: {
        name: 'Home',
        path: Home
    },
    Joga: {
        name: 'Joga',
        path: Joga
    },
    Movie: {
        name: 'Movie',
        path: Movie
    },
    Painting: {
        name: 'Painting',
        path: Painting
    },
    Photo: {
        name: 'Photo',
        path: Photo
    },
    Polo: {
        name: 'Polo',
        path: Polo
    },
    Reading: {
        name: 'Reading',
        path: Reading
    },
    Running: {
        name: 'Running',
        path: Running
    },
    Serfing: {
        name: 'Serfing',
        path: Serfing
    },
    Shopping: {
        name: 'Shopping',
        path: Shopping
    },
    Skiing: {
        name: 'Skiing',
        path: Skiing
    },
    Snowboarding: {
        name: 'Snowboarding',
        path: Snowboarding
    },
    Sport: {
        name: 'Sport',
        path: Sport
    },
    Swimming: {
        name: 'Swimming',
        path: Swimming
    },
    Tennis: {
        name: 'Tennis',
        path: Tennis
    },
    Travelling: {
        name: 'Travelling',
        path: Travelling
    }
}

const Emoji = ({name, className}) => {

    return (
        <img className={className} src={emoji[name]?.path} alt="emoji"/>
    );
};

export default Emoji;
