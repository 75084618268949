import React from 'react';
import CloseIcon from '../../../../../shared/icons/closeIcon.svg'
import classes from './TagItem.module.scss'

const TagItem = ({tag, onClick}) => {
    return (
        <div className={classes.Container}>
            <span className={classes.Title}>
                {tag?.id}
            </span>
            <img className={classes.CloseIcon} src={CloseIcon} alt="Close icon"
                onClick={()=>{onClick(tag)}}
            />
        </div>
    );
};

export default TagItem;
