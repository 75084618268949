import React, {useEffect, useState} from 'react';
import classes from './MapDropdown.module.scss'
import {useOnClickOutside} from "../../Shared/hooks/useclickOutside";
import {ReactComponent as ClearIcon} from "../../Shared/UI/assets/closeIcon.svg";
import {ReactComponent as DropdownIcon} from "../../Shared/UI/assets/down.svg";
import Map from "../Map/Map";
import Geocode from "react-geocode";

const MapDropdown = ({value, placeholder, className, onChange, isClearable = false}) => {
    const [lat, setLat] = useState(47.3666700)
    const [lng, setLng] = useState(8.55)
    const [isShow, setIsShow] = useState(false)
    const ref = useOnClickOutside(() => setIsShow(false))

    useEffect(() => {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
    }, [])

    useEffect(()=>{
        Geocode.fromAddress(value).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setLat(lat)
                setLng(lng)
            },
            (error) => {
                console.error(error);
            }
        );
    },[value])

    const handleClearIconClick = (event) => {
        event.stopPropagation()
        onChange('')
    }

    const handleClickIntoMap = (address, lat, lng) => {
        onChange(address.formatted_address)
        setLat(lat)
        setLng(lng)
    }

    return (
        <div ref={ref} className={`${classes.Container} ${className}`}>
            <div className={'InputContainer'} onClick={() => setIsShow(!isShow)}>
                <input className={'Input'} type="text" readOnly value={value} placeholder={placeholder}/>
                {
                    (isClearable && value)
                        ? <ClearIcon onClick={handleClearIconClick}/>
                        : <DropdownIcon style={isShow ? {'transform': 'rotate(180deg)'} : {}}/>
                }
            </div>
            {
                isShow &&
                <div className={'MapContainer'}>
                    <Map
                        lat={lat}
                        lng={lng}
                        defaultZoom={15}
                        onClick={handleClickIntoMap}
                    />
                </div>
            }
        </div>
    );
};

export default MapDropdown;