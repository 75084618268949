import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TopNav from '../../../Components/TopNav/TopNav'
import Input from '../../../submodules/naoo-web-components/Components/Input/Input'
import { FullscreenPreloader } from '../../../submodules/naoo-web-components/Components/Preloader/Preloader'
import {
  createCashRedeemOptionsThunk,
  selectActiveOption,
  selectStatus,
  setStatus,
  updateCashRedeemOptionsThunk,
} from '../../../store/reducers/cashRedeemRequestsReducer'
import { handleErrors, validateNumberInput } from '../../../shared/utils/utils'
import { ReactComponent as NaooIcon } from '../../../shared/icons/naooIcon.svg'
import spinnerClasses from '../../Spinner.module.scss'
import topNavClasses from '../../../Components/TopNav/TopNav.module.scss'
import classes from './RedeemOptionForm.module.scss'
import RedeemOption from '../RedeemOption/RedeemOption'
import { ReactComponent as CHF } from '../../../shared/icons/chf.svg'
import Container from '../../../Components/FormComponents/Container/Container'
import Section from '../../../Components/FormComponents/Section/Section'
import Margin from '../../../submodules/naoo-web-components/Components/Margin/Margin'
import ColorPicker from '../../../submodules/naoo-web-components/Components/ColorPicker/ColorPicker'
import Radio from '../../../submodules/naoo-web-components/Components/Radio/Radio'
import TextArea from '../../../submodules/naoo-web-components/Components/TextArea/TextArea'
import DatePicker from '../../../submodules/naoo-web-components/Components/Date/DatePicker/DatePicker'
import MediaField from '../../Users/UserForm/Fields/MediaField'
import { sendImage } from '../../../submodules/naoo-web-components/Shared/utility/sendImage'
import { createMediaPresignedUrl, getMedia } from '../../../api/mediaApi'

const RedeemOptionForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const option = useSelector((state) => selectActiveOption(state))

  const [logoMedia, setLogoMedia] = useState()

  const [optionState, setOptionState] = useState({
    CHF: '',
    points: '',
    limit: '',
    limitPerUser: '',
    type: 'cash',
    description: {},
    successMessage: {},
    termsAndConditionsUrl: {},
  })

  const [color, setColor] = useState('')

  useEffect(() => {
    setOptionState({
      CHF: option?.cash || '',
      points: option?.points || '',
      limit: option?.claimsLimit || '',
      limitPerUser: option?.claimsPerUserLimit || '',
      type: option?.type || 'cash',
      description: option?.description || {},
      successMessage: option?.successMessage || {},
      termsAndConditionsUrl: option?.termsAndConditionsUrl || {},
      expiresAt: option?.expiresAt,
      logoMediaId: option?.logo?.id || undefined,
      logoUrl: option?.logo?.url || undefined,
    })
    setColor(option?.color ? '#' + option?.color : '#fff')
  }, [option])

  const errors = {
    CHF: optionState.CHF.length === 0,
    points: optionState.points.length === 0,
  }

  const status = useSelector((state) => selectStatus(state))

  const handleStateChange = (event) => {
    const isValidInput = validateNumberInput(event)
    if (isValidInput) {
      setOptionState((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }))
    }
  }

  const handleDeactivateOption = () => {
    dispatch(
      updateCashRedeemOptionsThunk({
        option: {
          isActive: false,
          type: optionState.type,
        },
        id: option.id,
      })
    )
  }

  const handleSave = async () => {
    let logoMediaId = optionState.logoMediaId
    if (logoMedia) {
      const media = await sendImage(logoMedia, createMediaPresignedUrl, getMedia, undefined)
      logoMediaId = media.id
    }

    if (option) {
      dispatch(
        updateCashRedeemOptionsThunk({
          option: {
            cash: +optionState.CHF,
            points: +optionState.points,
            claimsLimit: +optionState.limit || null,
            claimsPerUserLimit: +optionState.limitPerUser || null,
            color: color.slice(1),
            type: optionState.type,
            description: optionState?.description || {},
            successMessage: optionState?.successMessage || {},
            termsAndConditionsUrl: optionState?.termsAndConditionsUrl || {},
            expiresAt: optionState?.expiresAt,
            logoMediaId: logoMediaId,
          },
          id: option.id,
        })
      )
    } else {
      dispatch(
        createCashRedeemOptionsThunk({
          cash: +optionState.CHF,
          points: +optionState.points,
          claimsLimit: +optionState.limit || null,
          claimsPerUserLimit: +optionState.limitPerUser || null,
          color: color.slice(1),
          isActive: true,
          type: optionState.type,
          description: optionState?.description || {},
          successMessage: optionState?.successMessage || {},
          termsAndConditionsUrl: optionState?.termsAndConditionsUrl || {},
          expiresAt: optionState?.expiresAt,
          logoMediaId: logoMediaId,
        })
      )
    }
  }

  const topNavButtons = [
    {
      title: 'Deactivate option',
      onClick: handleDeactivateOption,
      isDisabled: !option,
      className: `${topNavClasses.DangerButton} ${!option ? topNavClasses.Disabled : ''}`,
    },
    {
      title: 'Save',
      onClick: handleSave,
      isDisabled: handleErrors(errors),
      className: `${topNavClasses.SuccessButton} ${handleErrors(errors) ? topNavClasses.Disabled : ''}`,
    },
  ]

  const onTypeChange = (type) => {
    setOptionState((prevState) => ({
      ...prevState,
      type,
    }))
  }

  const handleDescriptionChange = (language, value) => {
    setOptionState((prevState) => ({
      ...prevState,
      description: { ...prevState.description, [language]: value },
    }))
  }

  const handleSuccessMessageChange = (language, value) => {
    setOptionState((prevState) => ({
      ...prevState,
      successMessage: { ...prevState.successMessage, [language]: value },
    }))
  }

  const handleTermsAndConditionsChange = (language, value) => {
    setOptionState((prevState) => ({
      ...prevState,
      termsAndConditionsUrl: { ...prevState.termsAndConditionsUrl, [language]: value },
    }))
  }

  const handleAddLogo = (url) => {
    setLogoMedia(url)
  }

  const handleRemoveLogo = () => {
    setOptionState((prevState) => ({
      ...prevState,
      logoMediaId: null,
      logoUrl: undefined,
    }))
    setLogoMedia(null)
  }

  useEffect(() => {
    if (status === 'successfully') {
      dispatch(setStatus(null))
      navigate(-1)
    }
  }, [dispatch, navigate, status])

  return (
    <>
      {status === 'loading' && (
        <FullscreenPreloader
          overlayClassName={spinnerClasses.lightBlurPreloader}
          spinnerClassName={spinnerClasses.spinnerSize}
        />
      )}

      <Container>
        <TopNav buttons={topNavButtons} onBack={() => navigate(-1)} />
        <div className={classes.FormContainer}>
          <div className={classes.MainContainer}>
            <Section title={'Settings'}>
              <Margin bottom={24}>
                <Input
                  label={'CHF amount'}
                  placeholder={'Type CHF amount'}
                  name={'CHF'}
                  value={optionState.CHF}
                  onChange={handleStateChange}
                  Icon={CHF}
                />
              </Margin>
              <Margin bottom={24}>
                <div className={classes.OptionType}>
                  <span className={classes.Label}>Type</span>
                  <Radio
                    className={classes.Cash}
                    name={'type'}
                    value={'cash'}
                    label={'Cash'}
                    checked={optionState.type === 'cash'}
                    onChange={() => {
                      onTypeChange('cash')
                    }}
                  />
                  <Radio
                    className={classes.Voucher}
                    name={'type'}
                    value={'voucher'}
                    label={'Voucher'}
                    checked={optionState.type === 'voucher'}
                    onChange={() => {
                      onTypeChange('voucher')
                    }}
                  />
                </div>
              </Margin>
              <Margin bottom={24}>
                <Input
                  label={'Points amount'}
                  placeholder={'Type Points amount'}
                  name={'points'}
                  value={optionState.points}
                  onChange={handleStateChange}
                  Icon={NaooIcon}
                />
              </Margin>
              <Margin bottom={24}>
                <Input
                  label={'Claims limit'}
                  placeholder={'Without limit'}
                  name={'limit'}
                  value={optionState.limit}
                  onChange={handleStateChange}
                />
              </Margin>
              <Margin bottom={24}>
                <Input
                  label={'Claims limit per user'}
                  placeholder={'Without limit'}
                  name={'limitPerUser'}
                  value={optionState.limitPerUser}
                  onChange={handleStateChange}
                />
              </Margin>
              <Margin bottom={24}>
                <label>Expires at</label>
                <DatePicker
                  placeholder={'Choose Date'}
                  className={classes.Input}
                  value={optionState.expiresAt}
                  format={'dd MMMM yyyy'}
                  // onChange={handleDateTime}
                />
              </Margin>
              <Margin bottom={24}>
                <TextArea
                  label={'Description - English'}
                  placeholder={'Description'}
                  name={'enDescription'}
                  value={optionState.description?.en || ''}
                  onChange={(event) => {
                    handleDescriptionChange('en', event.target.value)
                  }}
                />
              </Margin>
              <Margin bottom={24}>
                <TextArea
                  label={'Description - German'}
                  placeholder={'Description'}
                  name={'deDescription'}
                  value={optionState.description?.de || ''}
                  onChange={(event) => {
                    handleDescriptionChange('de', event.target.value)
                  }}
                />
              </Margin>
              <Margin bottom={24}>
                <TextArea
                  className={classes.Input}
                  label={'Success Message - English'}
                  placeholder={'Success Message'}
                  name={'enSuccessMessage'}
                  value={optionState.successMessage?.en || ''}
                  onChange={(event) => {
                    handleSuccessMessageChange('en', event.target.value)
                  }}
                />
              </Margin>
              <Margin bottom={24}>
                <TextArea
                  className={classes.Input}
                  label={'Success Message - German'}
                  placeholder={'Success Message'}
                  name={'deSuccessMessage'}
                  value={optionState.successMessage?.de || ''}
                  onChange={(event) => {
                    handleSuccessMessageChange('de', event.target.value)
                  }}
                />
              </Margin>
              <Margin bottom={24}>
                <Input
                  label={'Terms And Conditions URL - English'}
                  placeholder={'Terms And Conditions URL'}
                  name={'enTermsAndConitions'}
                  value={optionState.termsAndConditionsUrl?.en || ''}
                  onChange={(event) => {
                    handleTermsAndConditionsChange('en', event.target.value)
                  }}
                />
              </Margin>
              <Margin bottom={24}>
                <Input
                  label={'Terms And Conditions URL - German'}
                  placeholder={'Terms And Conditions URL'}
                  name={'deTermsAndConitions'}
                  value={optionState.termsAndConditionsUrl?.de || ''}
                  onChange={(event) => {
                    handleTermsAndConditionsChange('de', event.target.value)
                  }}
                />
              </Margin>
              <Margin bottom={24}>
                <ColorPicker color={color} setColor={setColor} />
              </Margin>
            </Section>
          </div>
          <div className={classes.PreviewContainer}>
            <Section title={'Logo'}>
              <div className={classes.LogoContainer}>
                <div className={logoMedia || optionState?.logoUrl ? classes.Logo : classes.WithoutLogo}>
                  <MediaField
                    onAdd={handleAddLogo}
                    onRemove={handleRemoveLogo}
                    media={logoMedia ?? optionState?.logoUrl}
                  />
                </div>
              </div>
            </Section>
            <Section title={'Preview'}>
              <RedeemOption
                option={{
                  cash: optionState.CHF,
                  points: optionState.points,
                  claimsLimit: optionState.limit,
                  claimsPerUserLimit: optionState.limitPerUser,
                  color: color,
                  description: optionState.description,
                }}
                logoMediaUrl={logoMedia ?? optionState?.logoUrl}
                className={classes.RedeemOption}
                isPreview={true}
              />
            </Section>
          </div>
        </div>
      </Container>
    </>
  )
}

export default RedeemOptionForm
